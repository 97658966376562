import * as ClientsListing from "@/components/ClientsListing";
import {
  ApplicationListItem,
  EqApplicationStatus,
} from "@/data/applicationsList";
import { ClientStatusResponse, EqUserId, UserId } from "@/data/client";
import {
  ApplicationId,
  ApplicationStatusType,
  ApplicationType,
  EqApplicationType,
} from "@/data/payload";
import { Deferred, NotStarted } from "@/utils/deferred";
import { ApiResult } from "@/utils/request";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { none, Option } from "fp-ts/lib/Option";
import * as S from "fp-ts/lib/Set";

export type Model = {
  exportStatus: Deferred<ApiResult<string>>;
  exportWarningShown: O.Option<[boolean, ApplicationId]>;
  clientStatus: O.Option<ClientStatusResponse>;
  clientListing: O.Option<ClientsListing.Model>;
};

export const init = (clientStatus: O.Option<ClientStatusResponse>): Model => ({
  clientListing: O.some(ClientsListing.init(clientStatus)[0]),
  exportStatus: NotStarted(),
  exportWarningShown: O.none,
  clientStatus,
});

export const initLoading = (): Model => ({
  exportStatus: NotStarted(),
  exportWarningShown: O.none,
  clientStatus: none,
  clientListing: none,
});

export const applySearch =
  (search: string) => (applications: ApplicationListItem[]) =>
    search.length > 0
      ? pipe(
          applications,
          A.filter((app) =>
            app.applicantsName.toLowerCase().includes(search.toLowerCase()),
          ),
        )
      : applications;

export const applyStatusFilter =
  (statusFilter: Option<Set<ApplicationStatusType>>) =>
  (applications: ApplicationListItem[]) =>
    O.isSome(statusFilter)
      ? pipe(
          applications,
          A.filter((app) =>
            pipe(statusFilter.value, S.elem(EqApplicationStatus)(app.status)),
          ),
        )
      : applications;

export const applyCreditCheckAuthorizedFilter =
  (creditCheckAuthorizedFilter: Option<boolean>) =>
  (applications: ApplicationListItem[]) =>
    O.isSome(creditCheckAuthorizedFilter)
      ? pipe(
          applications,
          A.filter(
            (app) =>
              app.isCreditCheckAuthorized === creditCheckAuthorizedFilter.value,
          ),
        )
      : applications;

export const applyMortgageTypeFilter =
  (mortgageTypeFilter: Option<Set<ApplicationType>>) =>
  (applications: ApplicationListItem[]) =>
    O.isSome(mortgageTypeFilter)
      ? pipe(
          applications,
          A.filter((app) =>
            pipe(
              mortgageTypeFilter.value,
              S.elem(EqApplicationType)(app.mortgageType),
            ),
          ),
        )
      : applications;

export const applyAssigneeFilter =
  (assigneeFilter: Option<Set<UserId>>) =>
  (applications: ApplicationListItem[]) =>
    O.isSome(assigneeFilter)
      ? pipe(
          applications,
          A.filter((app) =>
            pipe(
              app.assignees,
              A.map(({ userId }) => userId),
              S.fromArray(EqUserId),
              S.intersection(EqUserId)(assigneeFilter.value),
              (s) => S.size(s) > 0,
            ),
          ),
        )
      : applications;
