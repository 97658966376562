import {
  confirmSocialSecurityNumberCodec,
  emailStringCodec,
  optionalSocialSecurityNumberCodec,
  socialSecurityNumberCodec,
  ValidDateTime,
  validDateTimeCodecS,
} from "@/utils/codecs";
import { NullFromEmptyString } from "@/utils/codecs/nullFromEmptyString";
import { phoneNumberCodec } from "@/utils/codecs/phoneNumber";
import { sequenceS } from "fp-ts/Apply";
import * as E from "fp-ts/Either";
import { Either } from "fp-ts/Either";
import { flow, pipe } from "fp-ts/function";
import * as IO from "fp-ts/IO";
import * as IOEither from "fp-ts/IOEither";
import * as NEA from "fp-ts/NonEmptyArray";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import { Option } from "fp-ts/Option";
import * as Rec from "fp-ts/Record";
import * as t from "io-ts";
import * as tt from "io-ts-types";
import { surveyConsentCodec, SurveyConsentItem } from "./consents";
import {
  AddressPayload,
  addressPayloadCodec,
  applicationTypeCodec,
  bankruptcyStatusPayloadCodec,
  citizenshipStatusCodec,
  employersPayloadCodec,
  IncomeSourcePayload,
  incomeSourcePayloadCodec,
  jointApplicantTypeCodec,
  maritalStatusCodec,
  OrdRetirementAccount,
  propertyTypeCodec,
  referralSourceCodec,
  retirementAccountCodec,
  sourceOfFundsCodec,
  SurveyPayload,
} from "./payload";
import { removeFromLocalStorage } from "@/utils/localstorage";

export const propertyInformationPayloadCodec = t.type({
  propertyType: t.string.pipe(propertyTypeCodec),
  applicationType: t.string.pipe(applicationTypeCodec),
});

export type PropertyInformationPayload = t.TypeOf<
  typeof propertyInformationPayloadCodec
>;

export const contactInformationPayloadCodec = t.type({
  fullName: tt.NonEmptyString,
  email: t.string.pipe(emailStringCodec),
  phoneNumber: phoneNumberCodec,
});

export type ContactInformationPayload = t.TypeOf<
  typeof contactInformationPayloadCodec
>;

export const maritalAndResidencyPayloadCodec = t.type({
  maritalStatus: t.string.pipe(maritalStatusCodec),
  citizenshipStatus: t.string.pipe(citizenshipStatusCodec),
  divorced: t.boolean,
});

export type MaritalAndResidencyPayload = t.TypeOf<
  typeof maritalAndResidencyPayloadCodec
>;

export const fundsAndCoApplicantPayloadCodec = t.type({
  sourceOfFunds: tt.nonEmptyArray(t.string.pipe(sourceOfFundsCodec)),
  jointApplicantType: tt.option(t.string.pipe(jointApplicantTypeCodec)),
});

export type FundsAndCoApplicantPayload = t.TypeOf<
  typeof fundsAndCoApplicantPayloadCodec
>;

export const referralInformationPayloadCodec = t.type({
  referralSource: t.string.pipe(referralSourceCodec),
  workingWithRealEstateAgent: t.boolean,
  realEstateAgentName: tt.optionFromNullable(tt.NonEmptyString),
  realEstateAgentPhoneNumber: tt.optionFromNullable(tt.NonEmptyString),
});

export type ReferralInformationPayload = t.TypeOf<
  typeof referralInformationPayloadCodec
>;

export const retirementAndObligationsPayloadCodec = t.type({
  retirementAccounts: tt.setFromArray(
    t.string.pipe(retirementAccountCodec),
    OrdRetirementAccount,
    "RetirementAccounts",
  ),
  bankruptcyStatus: bankruptcyStatusPayloadCodec,
  foreclosureOrShortSale: t.boolean,
  hasAlimonyOrChildSupportObligation: t.boolean,
});

export type RetirementAndObligationsPayload = t.TypeOf<
  typeof retirementAndObligationsPayloadCodec
>;

export const ssnAndDateOfBirthPayloadCodec = t.type({
  socialSecurityNumber: t.union([
    t.string.pipe(socialSecurityNumberCodec),
    t.string.pipe(confirmSocialSecurityNumberCodec),
    t.string.pipe(optionalSocialSecurityNumberCodec),
  ]),
  dateOfBirth: t.union([
    t.string.pipe(validDateTimeCodecS),
    NullFromEmptyString,
  ]),
});

export type SsnAndDateOfBirthPayload = t.TypeOf<
  typeof ssnAndDateOfBirthPayloadCodec
>;

const surveyFlowApplicantPayloadCodec = t.type({
  contactInformation: tt.optionFromNullable(contactInformationPayloadCodec),
  maritalAndResidency: tt.optionFromNullable(maritalAndResidencyPayloadCodec),
  retirementAndObligations: tt.optionFromNullable(
    retirementAndObligationsPayloadCodec,
  ),
  incomeInformation: tt.optionFromNullable(t.array(incomeSourcePayloadCodec)),
  addresses: tt.optionFromNullable(tt.nonEmptyArray(addressPayloadCodec)),
  ssnAndDateOfBirthPayload: tt.optionFromNullable(
    ssnAndDateOfBirthPayloadCodec,
  ),
  employers: tt.optionFromNullable(t.array(employersPayloadCodec)),
  providedConsentForElectronicBusiness: tt.optionFromNullable(t.boolean),
});

type SurveyFlowApplicantPayload = t.TypeOf<
  typeof surveyFlowApplicantPayloadCodec
>;

export const surveyFlowPayloadCodec = t.type({
  propertyInformation: tt.optionFromNullable(propertyInformationPayloadCodec),
  referralInformation: tt.optionFromNullable(referralInformationPayloadCodec),
  fundsAndCoApplicant: tt.optionFromNullable(fundsAndCoApplicantPayloadCodec),
  primaryApplicantPayload: surveyFlowApplicantPayloadCodec,
  coApplicantPayload: tt.optionFromNullable(surveyFlowApplicantPayloadCodec),
  consents: tt.optionFromNullable(t.array(surveyConsentCodec)),
  loanOfficerNote: tt.optionFromNullable(t.string),
});

export type SurveyFlowPayload = t.TypeOf<typeof surveyFlowPayloadCodec>;

export const emptyApplicantPayload: SurveyFlowApplicantPayload = {
  contactInformation: O.none,
  maritalAndResidency: O.none,
  retirementAndObligations: O.none,
  incomeInformation: O.none,
  addresses: O.none,
  ssnAndDateOfBirthPayload: O.none,
  employers: O.some([]),
  providedConsentForElectronicBusiness: O.none,
};

export const emptyPayload: SurveyFlowPayload = {
  propertyInformation: O.none,
  fundsAndCoApplicant: O.none,
  referralInformation: O.none,
  primaryApplicantPayload: emptyApplicantPayload,
  coApplicantPayload: O.none,
  consents: O.none,
  loanOfficerNote: O.none,
};

export type ApplicantType = "PrimaryApplicant" | "CoApplicant";

export const getAddresses =
  (applicantType: ApplicantType) =>
  (payload: SurveyFlowPayload): Option<NonEmptyArray<AddressPayload>> => {
    switch (applicantType) {
      case "PrimaryApplicant":
        return payload.primaryApplicantPayload.addresses;
      case "CoApplicant":
        return pipe(
          payload.coApplicantPayload,
          O.chain((coApplicantPayload) => coApplicantPayload.addresses),
        );
    }
  };

export const getMaritalAndResidency =
  (applicantType: ApplicantType) =>
  (payload: SurveyFlowPayload): Option<MaritalAndResidencyPayload> => {
    switch (applicantType) {
      case "PrimaryApplicant":
        return payload.primaryApplicantPayload.maritalAndResidency;
      case "CoApplicant":
        return pipe(
          payload.coApplicantPayload,
          O.chain(
            (coApplicantPayload) => coApplicantPayload.maritalAndResidency,
          ),
        );
    }
  };

export const getRetirementAndObligations =
  (applicantType: ApplicantType) =>
  (payload: SurveyFlowPayload): Option<RetirementAndObligationsPayload> => {
    switch (applicantType) {
      case "PrimaryApplicant":
        return payload.primaryApplicantPayload.retirementAndObligations;
      case "CoApplicant":
        return pipe(
          payload.coApplicantPayload,
          O.chain(
            (coApplicantPayload) => coApplicantPayload.retirementAndObligations,
          ),
        );
    }
  };

export const getIncomeInformation =
  (applicantType: ApplicantType) =>
  (payload: SurveyFlowPayload): Option<IncomeSourcePayload[]> => {
    switch (applicantType) {
      case "PrimaryApplicant":
        return payload.primaryApplicantPayload.incomeInformation;
      case "CoApplicant":
        return pipe(
          payload.coApplicantPayload,
          O.chain((coApplicantPayload) => coApplicantPayload.incomeInformation),
        );
    }
  };

export const withContactInformation =
  (
    applicantType: ApplicantType,
    contactInformation: ContactInformationPayload,
  ) =>
  (payload: SurveyFlowPayload): SurveyFlowPayload => {
    switch (applicantType) {
      case "PrimaryApplicant":
        return {
          ...payload,
          primaryApplicantPayload: {
            ...payload.primaryApplicantPayload,
            contactInformation: O.some(contactInformation),
          },
        };
      case "CoApplicant":
        return {
          ...payload,
          coApplicantPayload: pipe(
            payload.coApplicantPayload,
            O.map((coApplicantPayload) => ({
              ...coApplicantPayload,
              contactInformation: O.some(contactInformation),
            })),
            O.alt(() =>
              O.some({
                ...emptyApplicantPayload,
                contactInformation: O.some(contactInformation),
              }),
            ),
          ),
        };
    }
  };

export const withMaritalAndResidency =
  (
    applicantType: ApplicantType,
    maritalAndResidency: MaritalAndResidencyPayload,
  ) =>
  (payload: SurveyFlowPayload): SurveyFlowPayload => {
    switch (applicantType) {
      case "PrimaryApplicant":
        return {
          ...payload,
          primaryApplicantPayload: {
            ...payload.primaryApplicantPayload,
            maritalAndResidency: O.some(maritalAndResidency),
          },
        };
      case "CoApplicant":
        return {
          ...payload,
          coApplicantPayload: pipe(
            payload.coApplicantPayload,
            O.map((coApplicantPayload) => ({
              ...coApplicantPayload,
              maritalAndResidency: O.some(maritalAndResidency),
            })),
            O.alt(() =>
              O.some({
                ...emptyApplicantPayload,
                maritalAndResidency: O.some(maritalAndResidency),
              }),
            ),
          ),
        };
    }
  };

export const withRetirementAndObligations =
  (
    applicantType: ApplicantType,
    retirementAndObligations: RetirementAndObligationsPayload,
  ) =>
  (payload: SurveyFlowPayload): SurveyFlowPayload => {
    switch (applicantType) {
      case "PrimaryApplicant":
        return {
          ...payload,
          primaryApplicantPayload: {
            ...payload.primaryApplicantPayload,
            retirementAndObligations: O.some(retirementAndObligations),
          },
        };
      case "CoApplicant":
        return {
          ...payload,
          coApplicantPayload: pipe(
            payload.coApplicantPayload,
            O.map((coApplicantPayload) => ({
              ...coApplicantPayload,
              retirementAndObligations: O.some(retirementAndObligations),
            })),
            O.alt(() =>
              O.some({
                ...emptyApplicantPayload,
                retirementAndObligations: O.some(retirementAndObligations),
              }),
            ),
          ),
        };
    }
  };

export const withIncomeInformation =
  (applicantType: ApplicantType, incomeInformation: IncomeSourcePayload[]) =>
  (payload: SurveyFlowPayload): SurveyFlowPayload => {
    switch (applicantType) {
      case "PrimaryApplicant":
        return {
          ...payload,
          primaryApplicantPayload: {
            ...payload.primaryApplicantPayload,
            incomeInformation: O.some(incomeInformation),
          },
        };
      case "CoApplicant":
        return {
          ...payload,
          coApplicantPayload: pipe(
            payload.coApplicantPayload,
            O.map((coApplicantPayload) => ({
              ...coApplicantPayload,
              incomeInformation: O.some(incomeInformation),
            })),
            O.alt(() =>
              O.some({
                ...emptyApplicantPayload,
                incomeInformation: O.some(incomeInformation),
              }),
            ),
          ),
        };
    }
  };

export const withAddresses =
  (applicantType: ApplicantType, addresses: NonEmptyArray<AddressPayload>) =>
  (payload: SurveyFlowPayload): SurveyFlowPayload => {
    switch (applicantType) {
      case "PrimaryApplicant":
        return {
          ...payload,
          primaryApplicantPayload: {
            ...payload.primaryApplicantPayload,
            addresses: O.some(addresses),
          },
        };
      case "CoApplicant":
        return {
          ...payload,
          coApplicantPayload: pipe(
            payload.coApplicantPayload,
            O.map((coApplicantPayload) => ({
              ...coApplicantPayload,
              addresses: O.some(addresses),
            })),
            O.alt(() =>
              O.some({
                ...emptyApplicantPayload,
                addresses: O.some(addresses),
              }),
            ),
          ),
        };
    }
  };

export const withSsnAndDateOfBirth =
  (
    primaryApplicant: SsnAndDateOfBirthPayload,
    coApplicant: Option<SsnAndDateOfBirthPayload>,
    consents: SurveyConsentItem[],
    isLoanOfficerInviting: boolean,
    providedConsentForElectronicBusiness: Option<boolean>,
  ) =>
  (payload: SurveyFlowPayload): SurveyFlowPayload => {
    const returnValue = {
      ...payload,
      primaryApplicantPayload: {
        ...payload.primaryApplicantPayload,
        ssnAndDateOfBirthPayload: O.some(primaryApplicant),
        providedConsentForElectronicBusiness:
          providedConsentForElectronicBusiness,
      },
      consents: O.some(
        consents.map((v) => {
          return {
            ...v,
            method: isLoanOfficerInviting ? v.method : null,
          };
        }),
      ),
      coApplicantPayload: pipe(
        payload.coApplicantPayload,
        O.map((coApplicantPayload) => ({
          ...coApplicantPayload,
          ssnAndDateOfBirthPayload: coApplicant,
          providedConsentForElectronicBusiness:
            providedConsentForElectronicBusiness,
        })),
      ),
    };
    return returnValue;
  };

// transform the survey flow payload which represents the structure of the survey flow
// into a survey payload which is what the API expects
// here error type is the key of the SurveyFlowPayload that is missing
export const toSurveyPayload =
  (dateTime: ValidDateTime, referringLoanOfficerId: O.Option<number>) =>
  (
    payload: SurveyFlowPayload,
  ): Either<keyof SurveyFlowPayload, SurveyPayload> => {
    const surveyPayload = pipe(
      sequenceS(E.Apply)({
        propertyInformation: pipe(
          payload.propertyInformation,
          E.fromOption((): keyof SurveyFlowPayload => "propertyInformation"),
        ),
        referralInformation: pipe(
          payload.referralInformation,
          E.fromOption((): keyof SurveyFlowPayload => "referralInformation"),
        ),
        fundsAndCoApplicant: pipe(
          payload.fundsAndCoApplicant,
          E.fromOption((): keyof SurveyFlowPayload => "fundsAndCoApplicant"),
        ),
        primaryApplicantPayload: pipe(
          sequenceS(O.Apply)({
            contactInformation:
              payload.primaryApplicantPayload.contactInformation,
            maritalAndResidency:
              payload.primaryApplicantPayload.maritalAndResidency,
            retirementAndObligations:
              payload.primaryApplicantPayload.retirementAndObligations,
            incomeInformation:
              payload.primaryApplicantPayload.incomeInformation,
            addresses: payload.primaryApplicantPayload.addresses,
            employers: payload.primaryApplicantPayload.employers,
            ssnAndDateOfBirthPayload:
              payload.primaryApplicantPayload.ssnAndDateOfBirthPayload,
            consents: payload.consents,
            providedConsentForElectronicBusiness:
              payload.primaryApplicantPayload
                .providedConsentForElectronicBusiness,
          }),
          E.fromOption(
            (): keyof SurveyFlowPayload => "primaryApplicantPayload",
          ),
        ),
        jointApplicantPayload: pipe(
          payload.fundsAndCoApplicant,
          // here fundsAndCoApplicant is required
          E.fromOption((): keyof SurveyFlowPayload => "fundsAndCoApplicant"),
          E.chain(({ jointApplicantType }) =>
            // but jointApplicantType is optional, hence the Option type
            pipe(
              jointApplicantType,
              // but if jointApplicantType is present
              O.traverse(E.Applicative)((applicantType) =>
                pipe(
                  // then the coApplicantPayload is required
                  // and it becomes part of the top level validation via O.traverse
                  sequenceS(O.Apply)({
                    applicantType: O.some(applicantType),
                    jointApplicantId: O.some(0),
                    contactInformation: pipe(
                      payload.coApplicantPayload,
                      O.chain(({ contactInformation }) => contactInformation),
                    ),
                    maritalAndResidency: pipe(
                      payload.coApplicantPayload,
                      O.chain(({ maritalAndResidency }) => maritalAndResidency),
                    ),
                    retirementAndObligations: pipe(
                      payload.coApplicantPayload,
                      O.chain(
                        ({ retirementAndObligations }) =>
                          retirementAndObligations,
                      ),
                    ),
                    incomeInformation: pipe(
                      payload.coApplicantPayload,
                      O.chain(({ incomeInformation }) => incomeInformation),
                    ),
                    addresses: pipe(
                      payload.coApplicantPayload,
                      O.chain(({ addresses }) => addresses),
                    ),
                    ssnAndDateOfBirthPayload: pipe(
                      payload.coApplicantPayload,
                      O.chain(
                        ({ ssnAndDateOfBirthPayload }) =>
                          ssnAndDateOfBirthPayload,
                      ),
                    ),
                    employers: pipe(
                      payload.coApplicantPayload,
                      O.chain(({ employers }) => employers),
                    ),
                    providedConsentForElectronicBusiness: pipe(
                      payload.coApplicantPayload,
                      O.chain(
                        ({ providedConsentForElectronicBusiness }) =>
                          providedConsentForElectronicBusiness,
                      ),
                    ),
                  }),
                  E.fromOption(
                    (): keyof SurveyFlowPayload => "coApplicantPayload",
                  ),
                ),
              ),
            ),
          ),
        ),
        loanOfficerNote: pipe(
          O.some(payload.loanOfficerNote),
          E.fromOption((): keyof SurveyFlowPayload => "loanOfficerNote"),
        ),
      }),
      E.map(
        (p): SurveyPayload => ({
          referringLoanOfficerId: referringLoanOfficerId,
          applicationType: p.propertyInformation.applicationType,
          propertyType: p.propertyInformation.propertyType,
          initiatedDateTimeUtc: dateTime,
          sourceOfFunds: p.fundsAndCoApplicant.sourceOfFunds,
          workingWithRealEstateAgent:
            p.referralInformation.workingWithRealEstateAgent,
          realEstateAgentName: p.referralInformation.realEstateAgentName,
          realEstateAgentPhone:
            p.referralInformation.realEstateAgentPhoneNumber,
          referralSource: O.some(p.referralInformation.referralSource),
          primaryApplicant: {
            applicantId: 0,
            fullName: p.primaryApplicantPayload.contactInformation.fullName,
            email: p.primaryApplicantPayload.contactInformation.email,
            phone: p.primaryApplicantPayload.contactInformation.phoneNumber,
            addresses: pipe(
              p.primaryApplicantPayload.addresses,
              NEA.map((add) => ({ ...add, addressId: O.some(0) })),
            ),
            maritalStatus:
              p.primaryApplicantPayload.maritalAndResidency.maritalStatus,
            citizenshipStatus:
              p.primaryApplicantPayload.maritalAndResidency.citizenshipStatus,
            ssn: p.primaryApplicantPayload.ssnAndDateOfBirthPayload
              .socialSecurityNumber,
            dateOfBirth:
              p.primaryApplicantPayload.ssnAndDateOfBirthPayload.dateOfBirth,
            incomeSources: p.primaryApplicantPayload.incomeInformation,
            retirementAccounts:
              p.primaryApplicantPayload.retirementAndObligations
                .retirementAccounts,
            bankruptcyStatus:
              p.primaryApplicantPayload.retirementAndObligations
                .bankruptcyStatus,
            foreclosureOrShortSale:
              p.primaryApplicantPayload.retirementAndObligations
                .foreclosureOrShortSale,
            hasAlimonyOrChildSupportObligation:
              p.primaryApplicantPayload.retirementAndObligations
                .hasAlimonyOrChildSupportObligation,
            consents: p.primaryApplicantPayload.consents,
            employers: p.primaryApplicantPayload.employers,
            divorced: p.primaryApplicantPayload.maritalAndResidency.divorced,
            providedConsentForElectronicBusiness:
              p.primaryApplicantPayload.providedConsentForElectronicBusiness,
          },
          jointApplicants: pipe(
            p.jointApplicantPayload,
            O.fold(
              () => [],
              (jointApplicantPayload) => [
                {
                  applicantType: jointApplicantPayload.applicantType,
                  jointApplicantId: jointApplicantPayload.jointApplicantId,
                  applicant: {
                    applicantId: 0,
                    fullName: jointApplicantPayload.contactInformation.fullName,
                    email: jointApplicantPayload.contactInformation.email,
                    phone: jointApplicantPayload.contactInformation.phoneNumber,
                    addresses: pipe(
                      jointApplicantPayload.addresses,
                      NEA.map((add) => ({ ...add, addressId: O.some(0) })),
                    ),
                    maritalStatus:
                      jointApplicantPayload.maritalAndResidency.maritalStatus,
                    citizenshipStatus:
                      jointApplicantPayload.maritalAndResidency
                        .citizenshipStatus,
                    ssn: jointApplicantPayload.ssnAndDateOfBirthPayload
                      .socialSecurityNumber,
                    dateOfBirth:
                      jointApplicantPayload.ssnAndDateOfBirthPayload
                        .dateOfBirth,
                    incomeSources: jointApplicantPayload.incomeInformation,
                    retirementAccounts:
                      jointApplicantPayload.retirementAndObligations
                        .retirementAccounts,
                    bankruptcyStatus:
                      jointApplicantPayload.retirementAndObligations
                        .bankruptcyStatus,
                    foreclosureOrShortSale:
                      jointApplicantPayload.retirementAndObligations
                        .foreclosureOrShortSale,
                    hasAlimonyOrChildSupportObligation:
                      jointApplicantPayload.retirementAndObligations
                        .hasAlimonyOrChildSupportObligation,
                    consents: [],
                    employers: jointApplicantPayload.employers,
                    divorced:
                      jointApplicantPayload.maritalAndResidency.divorced,
                    providedConsentForElectronicBusiness:
                      jointApplicantPayload.providedConsentForElectronicBusiness,
                  },
                },
              ],
            ),
          ),
          loanOfficerNote: p.loanOfficerNote,
        }),
      ),
    );
    return surveyPayload;
  };

export const saveToLocalStorage = (
  payload: SurveyFlowPayload,
): IO.IO<unknown> =>
  pipe(
    surveyFlowPayloadCodec.encode(payload),
    Rec.mapWithIndex(
      (key, value): IO.IO<unknown> =>
        () => {
          try {
            localStorage.setItem(key, JSON.stringify(value));
          } catch (e) {
            console.error(e);
          }
        },
    ),
    sequenceS(IO.Apply),
  );

const loadItem = (key: keyof SurveyFlowPayload): IO.IO<Option<unknown>> =>
  pipe(
    IOEither.tryCatch(
      () => {
        const data = localStorage.getItem(key);
        return data && JSON.parse(data);
      },
      (e) => {
        console.log(e);
        return e;
      },
    ),
    IO.map(O.fromEither),
  );

// decode the payload falling back to None if the decoding fails
const tryDecode =
  <C extends t.Mixed>(codec: C) =>
  (data: unknown): Option<t.TypeOf<C>> =>
    pipe(codec.decode(data), (v) => {
      console.log(v);
      return O.fromEither(v);
    });

export const loadFromLocalStorage: IO.IO<SurveyFlowPayload> = sequenceS(
  IO.Apply,
)({
  propertyInformation: pipe(
    loadItem("propertyInformation"),
    IO.map(O.chain((data) => tryDecode(propertyInformationPayloadCodec)(data))),
  ),
  referralInformation: pipe(
    loadItem("referralInformation"),
    IO.map(O.chain((data) => tryDecode(referralInformationPayloadCodec)(data))),
  ),
  fundsAndCoApplicant: pipe(
    loadItem("fundsAndCoApplicant"),
    IO.map(O.chain((data) => tryDecode(fundsAndCoApplicantPayloadCodec)(data))),
  ),
  primaryApplicantPayload: pipe(
    loadItem("primaryApplicantPayload"),
    IO.map(
      flow(
        O.chain((data) => tryDecode(surveyFlowApplicantPayloadCodec)(data)),
        O.getOrElse(() => emptyApplicantPayload),
      ),
    ),
  ),
  coApplicantPayload: pipe(
    loadItem("coApplicantPayload"),
    IO.map(O.chain((data) => tryDecode(surveyFlowApplicantPayloadCodec)(data))),
  ),
  consents: IO.of(O.none),
  loanOfficerNote: pipe(
    loadItem("loanOfficerNote"),
    IO.map(O.chain((data) => tryDecode(t.string)(data))),
  ),
});

export const clearSurveyFromLocalStorage: IO.IO<void> = () =>
  pipe(
    [
      "consents",
      "coApplicantPayload",
      "primaryApplicantPayload",
      "fundsAndCoApplicant",
      "referralInformation",
      "propertyInformation",
      "loanOfficerNote",
    ],
    A.map(removeFromLocalStorage),
  );
