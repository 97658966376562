import {
  Checkbox,
  Col,
  Error,
  InputField,
  Label,
  Row,
  Select,
  SSNInputField,
} from "@/components/basic";
import { ChildProps } from "@/utils/reducerWithEffect";
import { constFalse, flow, identity, pipe } from "fp-ts/function";
import {
  Action,
  ConsentItemChanged,
  ConsentMethodChanged,
  DateOfBirthChanged,
  SocialSecurityNumberChanged,
} from "./action";
import { Model } from "./model";

import {
  consentMethodOptions,
  ConsentMethods,
  showConsentMethods,
} from "@/data/consents";
import { showApiError } from "@/utils/request";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import { Eq as EqString } from "fp-ts/string";
import { ViewResolver } from "@/utils/viewResolver";

export type Props = ChildProps<Model, Action>;

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const isConsentMethodRequired = pipe(
    model.consentMethod.val,
    E.match(
      (e) =>
        model.form &&
        model.form.consentItems.some((v) => v.raw) ? (
          e
        ) : (
          null
        ),
      () => null,
    ),
  )
  return (
    <>
      <div className="grid-2 gap-xs">
        <Label className={ViewResolver({
          viewModes: [["Default"], ["Mobile-Portrait", "Mobile-Landscape"]],
          resolvedContent: ["applicant-name survey-applicant-name column-span-2", "applicant-name survey-applicant-name"],
        })}>{model.primaryApplicant.name}</Label>

        <SSNInputField
          label="Social Security Number"
          field={model.primaryApplicant.socialSecurityNumber}
          onChange={flow(
            SocialSecurityNumberChanged("PrimaryApplicant"),
            dispatch,
          )}
          required={true}
        />

        <InputField
          type="date"
          label="Date of Birth"
          field={model.primaryApplicant.dateOfBirth}
          onChange={flow(DateOfBirthChanged("PrimaryApplicant"), dispatch)}
          required={true}
        />

        <CoApplicantView {...props}/>
      </div>
      <Col gap="xl">
        <ConsentView {...props} />
        {pipe(
          model.isLoanOfficerInviting,
          O.map(() => (
            <>
              <Col gap="sm">
                <Label>How were the consent received? {isConsentMethodRequired ? '*' : ''}</Label>
                <Select
                  options={consentMethodOptions}
                  selected={O.of(model.consentMethod.raw as ConsentMethods)}
                  valueEq={EqString}
                  renderLabel={(v) => showConsentMethods(v)}
                  onChange={flow(ConsentMethodChanged, dispatch)}
                />
                {isConsentMethodRequired ? <Error errors={isConsentMethodRequired} /> : <></>}
              </Col>
            </>
          )),
          O.getOrElse(() => <></>),
        )}
      </Col>
    </>
  );
}

function ConsentView(props: Props): JSX.Element {
  const { model } = props;
  switch (model.consents.status) {
    case "InProgress":
    case "NotStarted":
      return <>"Loading"</>;
    case "Updating":
    case "Resolved":
      return pipe(
        model.consents.value,
        E.map(() => model.form.consentItems),
        E.fold(
          (error) => (
            <Col padding="sm" gap="sm" className="chart-container">
              <Label>Failed to load consents</Label>
              {showApiError(error)}
            </Col>
          ),
          (consentItems) => {
            return (
              <>
                {consentItems.map((v, i) => {
                  const isLoanOfficerInviting = pipe(
                    model.isLoanOfficerInviting,
                    O.fold(constFalse, identity),
                  );
                  return (
                    <Row key={i}>
                      <Col>
                        <Checkbox
                          className="column-span-2"
                          label={`${v.title} ${isLoanOfficerInviting ? "" : v.requiredForBorrower ? "*" : ""}`}
                          checked={Boolean(v.raw)}
                          onChange={flow(
                            ConsentItemChanged(v.consentVersionId),
                            props.dispatch,
                          )}
                        />
                        <div 
                          className="padleft-lg constent-description"
                          dangerouslySetInnerHTML={{ __html: v.description }}
                        ></div>
                      </Col>
                    </Row>
                  );
                })}
              </>
            );
          },
        ),
      );
  }
}

function CoApplicantView(props: Props): JSX.Element {
  const { model, dispatch } = props;
  const isLoanOfficerInviting = pipe(
    model.isLoanOfficerInviting,
    O.fold(constFalse, identity),
  );
  return (
    <>
    {O.isSome(model.coApplicant) && <>
      <Label className={ViewResolver({
          viewModes: [["Default"], ["Mobile-Portrait", "Mobile-Landscape"]],
          resolvedContent: ["applicant-name survey-applicant-name column-span-2", "applicant-name survey-applicant-name"],
        })}>
        {model.coApplicant.value.name}
      </Label>

      <SSNInputField
        label="Social Security Number"
        field={model.coApplicant.value.socialSecurityNumber}
        onChange={flow(
          SocialSecurityNumberChanged("CoApplicant"),
          dispatch,
        )}
        required={isLoanOfficerInviting ? false : true}
      />

      <InputField
        label="Date of Birth"
        type="date"
        field={model.coApplicant.value.dateOfBirth}
        onChange={flow(DateOfBirthChanged("CoApplicant"), dispatch)}
        required={isLoanOfficerInviting ? false : true}
      />
    </> }
    </>
    
  )
}
