import { EmployersPatchPayload, yearsAtWorkCodec } from "@/data/payload";
import { Api } from "@/utils/api";
import { AsyncOperationStatus, Finished } from "@/utils/asyncOperationStatus";
import { validDateTimeCodecS } from "@/utils/codecs";
import { updateFormField } from "@/utils/formField";
import { Effect, effectOfAsync, noEffect } from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import { EmmployerSelectOption, Model } from "./model";

export type Action =
  | {
      type: "StartDateChanged";
      date: string;
    }
  | {
      type: "EndDateChanged";
      date: string;
    }
  | {
      type: "YearsWorkedChanged";
      years: string;
    }
  | {
      type: "IsReceivingComissionToggled";
      isReceivingComissionToggled: boolean;
    }
  | {
      type: "IsCurrentlyWorkingWithEmployerToggled";
      isCurrentlyWorkingWithEmployerToggled: boolean;
    }
  | {
      type: "ExistingEmployerMapped";
      existingEmployer: EmmployerSelectOption;
    }
  | {
      type: "SaveEmployerAction";
      payload: EmployersPatchPayload;
      operation: AsyncOperationStatus<ApiResult<unknown>>;
      onSuccess: () => void;
    };

export const StartDateChanged = (date: string): Action => ({
  type: "StartDateChanged",
  date,
});

export const EndDateChanged = (date: string): Action => ({
  type: "EndDateChanged",
  date,
});

export const YearsWorkedChanged = (years: string): Action => ({
  type: "YearsWorkedChanged",
  years,
});

export const ExistingEmployerMapped = (
  existingEmployer: EmmployerSelectOption,
): Action => ({
  type: "ExistingEmployerMapped",
  existingEmployer,
});
export const SaveEmployerAction =
  (payload: EmployersPatchPayload, onSuccess: () => void) =>
  (operation: AsyncOperationStatus<ApiResult<unknown>>): Action => ({
    type: "SaveEmployerAction",
    payload,
    operation,
    onSuccess,
  });

export const IsReceivingComissionToggled = (
  isReceivingComissionToggled: boolean,
): Action => ({
  type: "IsReceivingComissionToggled",
  isReceivingComissionToggled,
});

export const IsCurrentlyWorkingWithEmployerToggled = (
  isCurrentlyWorkingWithEmployerToggled: boolean,
): Action => ({
  type: "IsCurrentlyWorkingWithEmployerToggled",
  isCurrentlyWorkingWithEmployerToggled,
});

export const update =
  (api: Api) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    switch (action.type) {
      case "StartDateChanged":
        return [
          {
            ...model,
            startDate: updateFormField(validDateTimeCodecS.decode)(action.date),
          },
          noEffect,
        ];
      case "EndDateChanged":
        return [
          {
            ...model,
            endDate: O.some(
              updateFormField(validDateTimeCodecS.decode)(action.date),
            ),
          },
          noEffect,
        ];
      case "IsReceivingComissionToggled":
        return [
          {
            ...model,
            receivedCommissionFromEmployer: action.isReceivingComissionToggled,
          },
          noEffect,
        ];

      case "IsCurrentlyWorkingWithEmployerToggled":
        return [
          {
            ...model,
            isCurrentlyEmployedByThisEmployer:
              action.isCurrentlyWorkingWithEmployerToggled,
            isErrorWhileSavingEmployer: false,
            endDate: O.some(updateFormField(validDateTimeCodecS.decode)("")),
          },
          noEffect,
        ];
      case "ExistingEmployerMapped":
        return [
          {
            ...model,
            mappedEmployer: O.some(action.existingEmployer),
          },
          noEffect,
        ];
      case "SaveEmployerAction":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                model,
                effectOfAsync(
                  api.putEmployer(action.payload),
                  flow(
                    Finished,
                    SaveEmployerAction(action.payload, action.onSuccess),
                  ),
                ),
              ];
            case "Finished": {
              const result = action.operation.result;
              if (E.isRight(result)) {
                action.onSuccess();
                return [model, noEffect];
              } else {
                //Todo: give error options here
                return [
                  { ...model, isErrorWhileSavingEmployer: true },
                  noEffect,
                ];
              }
            }
          }
        }
        break;
      case "YearsWorkedChanged": {
        return [
          {
            ...model,
            yearInLineOfWork: updateFormField(yearsAtWorkCodec.decode)(
              action.years,
            ),
          },
          noEffect,
        ];
      }
    }
  };
