import {
  EqSourceOfFunds,
  JointApplicantType,
  jointApplicantTypeCodec,
  OrdSourceOfFunds,
  SourceOfFunds,
  sourceOfFundsCodec,
} from "@/data/payload";
import { Model, selectedSourcesOfFunds } from "./model";
import * as S from "fp-ts/Set";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import * as t from "io-ts";
import { updateFormField } from "@/utils/formField";

export type Action =
  | {
      type: "SourceOfFundsToggled";
      sourceOfFunds: SourceOfFunds;
    }
  | {
      type: "JointApplicantTypeSelected";
      jointApplicantType: JointApplicantType;
    }
  | {
      type: "JointApplicantSelected";
      jointApplicant: boolean;
    };

export const SourceOfFundsToggled = (sourceOfFunds: SourceOfFunds): Action => ({
  type: "SourceOfFundsToggled",
  sourceOfFunds,
});

export const JointApplicantTypeSelected = (
  jointApplicantType: JointApplicantType,
): Action => ({
  type: "JointApplicantTypeSelected",
  jointApplicantType,
});

export const JointApplicantSelected = (jointApplicant: boolean): Action => ({
  type: "JointApplicantSelected",
  jointApplicant,
});

export const update = (model: Model, action: Action): Model => {
  switch (action.type) {
    case "SourceOfFundsToggled": {
      // use an intermediate set structure to take care of the ordering and uniqueness implicitly
      const updatedSources = pipe(
        selectedSourcesOfFunds(model),
        S.toggle(EqSourceOfFunds)(action.sourceOfFunds),
        S.toArray(OrdSourceOfFunds),
      );

      return {
        ...model,
        sourceOfFunds: pipe(
          A.head(updatedSources),
          O.getOrElse(() => ""),
          updateFormField(t.string.pipe(sourceOfFundsCodec).decode),
        ),
        additionalSourcesOfFunds: pipe(
          A.tail(updatedSources),
          O.getOrElse((): SourceOfFunds[] => []),
        ),
      };
    }

    case "JointApplicantTypeSelected":
      return {
        ...model,
        jointApplicantType: updateFormField(
          t.string.pipe(jointApplicantTypeCodec).decode,
        )(action.jointApplicantType),
      };
    case "JointApplicantSelected":
      return {
        ...model,
        jointApplicantSelected: action.jointApplicant,
      };
  }
};
