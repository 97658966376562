import * as EditTeam from "@/components/UserManagement/pages/BranchManagement/EditTeam";
import { Button, Col, Icon, Label, Row } from "@/components/basic";
import { ApiCallingModal } from "@/components/basic/Modal/ApiCallingModal";
import {
  Branch,
  BranchId,
  Client,
  EqBranchId,
  Team,
  TeamId,
  User,
  UserId,
} from "@/data/client";
import { TeamPayload } from "@/data/payload";
import { Deferred } from "@/utils/deferred";
import { ChildProps } from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import { RouterContext } from "@/utils/router-context";
import * as M from "fp-ts/Map";
import * as O from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import { useContext, useMemo } from "react";
import { TeamsTable } from "../TeamsTable";
import {
  Action,
  DeleteTeamInitiated,
  DialogClosed,
  EditTeamAction,
  EditTeamInitiated,
  NewTeamInitiated,
} from "./action";
import { DialogModel, Model } from "./model";
import { ViewResolver } from "@/utils/viewResolver";
export type Props = ChildProps<Model, Action> & {
  branches: Map<BranchId, Branch>;
  teams: Map<TeamId, Team>;
  users: Map<UserId, User>;
  client: Client;
  onNewUser: (branchId: Option<BranchId>, teamId: Option<TeamId>) => void;

  onSelectTeam: (teamId: TeamId) => void;
  onSaveTeam: (teamId: Option<TeamId>, payload: TeamPayload) => void;
  onDeleteTeam: (teamId: TeamId) => void;
  onSelectUser: (userId: UserId) => void;
  dialogApiResult: Deferred<ApiResult<unknown>>;
  onCloseModal: () => void;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;
  const selectedBranch = useMemo(
    () => M.lookup(EqBranchId)(model.branchId, props.branches),
    [props.branches, model.branchId],
  );
  const { router } = useContext(RouterContext);

  const modalTitle = pipe(
    model.dialog,
    O.map((v) => {
      const teamName = (v: TeamId) =>
        pipe(
          selectedBranch,
          O.map(
            (branch) =>
              `${branch.teams.find((team) => team.teamId == v)?.name}`,
          ),
          O.fold(constant(""), identity),
        );
      switch (v.type) {
        case "DeleteTeamDialog":
          return `Delete Team: ${teamName(v.teamId)}`;
        case "EditTeamDialog":
          return `Edit Team: ${teamName(v.teamId)}`;
        case "NewTeamDialog":
          return `New Team`;
      }
    }),
  );

  return pipe(
    selectedBranch,
    O.fold(
      () => <Label>Branch not found</Label>,
      (branch) => (
        <Col grow={1} >
          <Row
            alignVertical="center"
            alignHorizontal="space-between"
            wrap={true}
            gap="sm"
            className={ViewResolver({
              viewModes: [
                ["Default"],
                ["Mobile-Landscape", "Mobile-Portrait"]
              ],
              resolvedContent: [
                "", "user-management-mb-buttons"
              ]
            })}
          >
            <Label>
              <Row gap="xxxs" alignVertical="center">
                <Button
                  onClick={O.some(() => router.navigate(`/org/branches`))}
                  type="flat"
                  className="link"
                >
                  {props.client.name}
                </Button>
                <div className="text-xs">
                  <Icon type="chevron-right"></Icon>
                </div>
                <div className="text-bold">{branch.name}</div>
              </Row>
            </Label>
            <Row alignHorizontal="left" gap="xs" wrap={true}
            >
              <Button
                type="secondary"
                onClick={O.some(() =>
                  props.onNewUser(O.some(model.branchId), O.none),
                )}
              >
                Add new user
              </Button>
              <Button
                type="secondary"
                onClick={O.some(flow(NewTeamInitiated, dispatch))}
              >
                Create a new team
              </Button>
            </Row>
          </Row>
          <TeamsTable
            teams={branch.teams}
            onSelectTeam={props.onSelectTeam}
            onEditTeam={O.some(flow(EditTeamInitiated, dispatch))}
            onDeleteTeam={O.some(flow(DeleteTeamInitiated, dispatch))}
          />

          {O.isSome(model.dialog) && (
            <DialogView
              dialog={model.dialog.value}
              branches={props.branches}
              teams={props.teams}
              users={props.users}
              dispatch={dispatch}
              onSaveTeam={(teamId) => (payload) =>
                props.onSaveTeam(teamId, payload)
              }
              onDeleteTeam={props.onDeleteTeam}
              dialogApiResult={props.dialogApiResult}
              onCloseModal={() => {
                flow(DialogClosed, dispatch)();
                props.onCloseModal();
              }}
              title={modalTitle}
            />
          )}
        </Col>
      ),
    ),
  );
}

type DialogViewProps = {
  dialog: DialogModel;
  branches: Map<BranchId, Branch>;
  teams: Map<TeamId, Team>;
  users: Map<UserId, User>;
  dispatch: (action: Action) => void;
  onSaveTeam: (teamId: Option<TeamId>) => (payload: TeamPayload) => void;
  onDeleteTeam: (teamId: TeamId) => void;
  title: O.Option<string>;
  dialogApiResult: Deferred<ApiResult<unknown>>;
  onCloseModal: () => void;
};

function DialogView(props: DialogViewProps): JSX.Element {
  const cancelHandler = O.some(() => props.onCloseModal());
  const title = O.some(
    pipe(props.title, O.fold(constant("Manage Users"), identity)),
  );

  switch (props.dialog.type) {
    case "NewTeamDialog":
    case "EditTeamDialog": {
      const teamIdToBeSaved =
        props.dialog.type == "NewTeamDialog"
          ? O.none
          : O.some(props.dialog.teamId);
      const model = props.dialog.dialogModel;
      const validatedModel = pipe(EditTeam.result(model), O.fromEither);
      return (
        <ApiCallingModal
          actionTitle={
            props.dialog.type == "NewTeamDialog" ? "Create New Team" : "Save"
          }
          onClose={cancelHandler}
          title={title}
          dialogApiResult={props.dialogApiResult}
          actionHander={O.some(() =>
            pipe(
              validatedModel,
              O.map((payload) => props.onSaveTeam(teamIdToBeSaved)(payload)),
            ),
          )}
          cancelHandler={cancelHandler}
        >
          <EditTeam.View
            model={props.dialog.dialogModel}
            dispatch={flow(EditTeamAction, props.dispatch)}
          />
        </ApiCallingModal>
      );
    }

    case "DeleteTeamDialog":
      return (
        <ApiCallingModal
          actionTitle="Delete"
          onClose={cancelHandler}
          title={title}
          dialogApiResult={props.dialogApiResult}
          actionHander={O.some(
            flow(constant(props.dialog.teamId), props.onDeleteTeam),
          )}
          cancelHandler={cancelHandler}
        >
          <Col>
            <Label>Are you sure you want to delete this team?</Label>
          </Col>
        </ApiCallingModal>
      );
  }
}
