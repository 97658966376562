import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import * as IOE from "fp-ts/IOEither";

import { getItem, removeItem, setItem } from "fp-ts-local-storage";
import { identity, pipe } from "fp-ts/lib/function";

export function getFromLocalStorage(key: string) {
  const foo = pipe(IOE.tryCatch(getItem(key), E.toError))();

  return pipe(
    foo,
    O.fromEither,
    O.flatten,
    O.chain((v) => pipe(v, O.fromNullable)),
  );
}

export function saveToLocalStorage(key: string, value: string) {
  const foo = pipe(IOE.tryCatch(setItem(key, value), E.toError))();

  return pipe(
    foo,
    O.fromEither,
    O.fold(() => null, identity),
  );
}

export function removeFromLocalStorage(key: string) {
  const foo = pipe(IOE.tryCatch(removeItem(key), E.toError))();

  pipe(
    foo,
    O.fromEither,
    O.chain((v) => pipe(v, O.fromNullable)),
  );
}


export enum LocalStoragetKeys {
  BackOfficeSignup= "__backOfficeSignup__",
  BackOfficeNonce= "__backOfficeNonce__",
  BorrowerSignup= "__borrowerSignup__",
  BorrowerNonce= "__borrowerNonce__",
  BorrowerSignupInProgress= "__borrowerSignupInProgress__",
  BackOfficeStripeSecret= "__backOfficeStripeSecret__", //Session Storage
  ApplicationModifyStatus = "__applicationModifyStatus__" // will be used for unsaved application model
}