import { Deferred } from "@/utils/deferred";
import { ApiResult } from "@/utils/request";
import { useApiResult } from "@/utils/useApiResult";
import * as O from "fp-ts/lib/Option";
import { Col } from "./Col";

export function ApiError<T>(props: {
  apiResult: Deferred<ApiResult<T>>;
}): JSX.Element {
  const [apiError] = useApiResult(props.apiResult);

  return (
    <>
      {O.isSome(apiError) && (
        <Col gap="xs" foreground="no50">
          {apiError.value}
        </Col>
      )}
    </>
  );
}
