import { User, UserId } from "@/data/client";
import {
  EmailString,
  emailStringCodec,
  nonEmptyStringCodec,
} from "@/utils/codecs";
import { FormField, initFormField } from "@/utils/formField";
import { NonEmptyString } from "io-ts-types";
import { AccountUserPayload } from "@/data/payload";
import { sequenceS } from "fp-ts/lib/Apply";
import * as E from "fp-ts/lib/Either";
import * as t from "io-ts";
import { USState, stateCodec } from "@/data/states";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { PhoneNumber, phoneNumberCodec } from "@/utils/codecs/phoneNumber";

export type Model = {
  userId: UserId;
  firstName: FormField<NonEmptyString>;
  lastName: FormField<NonEmptyString>;
  email: FormField<EmailString>;
  phone: FormField<PhoneNumber>;
  nmlsNumber: FormField<NonEmptyString>;
  address: FormField<NonEmptyString>;
  address2: string;
  city: FormField<NonEmptyString>;
  state: FormField<USState>;
  zip: FormField<NonEmptyString>;
};

export const init = (user: User): Model => ({
  userId: user.userId,
  firstName: pipe(
    user.firstName,
    O.getOrElseW(() => ""),
    initFormField(nonEmptyStringCodec().decode),
  ),
  lastName: pipe(
    user.lastName,
    O.getOrElseW(() => ""),
    initFormField(nonEmptyStringCodec().decode),
  ),
  email: initFormField(emailStringCodec.decode)(user.email),
  phone: pipe(
    user.mobilePhone,
    O.getOrElseW(() => ""),
    initFormField(phoneNumberCodec.decode),
  ),
  nmlsNumber: pipe(
    user.nmlsNumber,
    O.getOrElseW(() => ""),
    initFormField(nonEmptyStringCodec().decode),
  ),
  address: pipe(
    user.address,
    O.getOrElseW(() => ""),
    initFormField(nonEmptyStringCodec().decode),
  ),
  address2: pipe(
    user.address2,
    O.getOrElse(() => ""),
  ),
  city: pipe(
    user.city,
    O.getOrElseW(() => ""),
    initFormField(nonEmptyStringCodec().decode),
  ),
  state: pipe(
    user.state,
    O.getOrElseW(() => ""),
    initFormField(stateCodec.decode),
  ),
  zip: pipe(
    user.zipCode,
    O.getOrElseW(() => ""),
    initFormField(nonEmptyStringCodec().decode),
  ),
});

export const result = (model: Model): t.Validation<AccountUserPayload> =>
  sequenceS(E.Apply)({
    userId: E.right(model.userId),
    firstName: model.firstName.val,
    lastName: model.lastName.val,
    email: model.email.val,
    mobilePhone: model.phone.val,
    nmlsNumber: model.nmlsNumber.val,
    address: model.address.val,
    address2: E.right(model.address2),
    city: model.city.val,
    state: model.state.val,
    zipCode: model.zip.val,
  });
