import * as t from "io-ts";
import * as tt from "io-ts-types";
import * as Eq from "fp-ts/Eq";
import { Eq as EqString } from "fp-ts/string";

export const nonEmptyStringCodec = (
  allowWhitespace = false,
  errorMessage?: string
): t.Type<tt.NonEmptyString, string, string> =>
  tt.withMessage(
    new t.Type<tt.NonEmptyString, string, string>(
      "NonEmptyString",
      tt.NonEmptyString.is,
      (u, c) => tt.NonEmptyString.validate(allowWhitespace ? u : u?.trim(), c),
      tt.NonEmptyString.encode,
    ),
    () => errorMessage ? errorMessage : "Required",
  );

export const nesHead = (s: tt.NonEmptyString): string => s[0];

export const EqNonEmptyString: Eq.Eq<tt.NonEmptyString> = EqString;
