import * as Address from "@/components/Address";
import * as ContactInformation from "@/components/ContactInformation";
import * as FundsAndCoApplicant from "@/components/FundsAndCoApplicant";
import * as IncomeInformation from "@/components/IncomeInformation";
import * as MaritalAndResidency from "@/components/MaritalAndResidency";
import * as PropertyInformation from "@/components/PropertyInformation";
import * as ReferralInformation from "@/components/ReferralInformation";
import * as RetirementAndObligations from "@/components/RetirementAndObligations";
import * as SsnAndConsent from "@/components/SsnAndConsent";
import * as Tup from "fp-ts/lib/Tuple";

import { SurveyConsentItem } from "@/data/consents";
import {
  addAddressPayload,
  AddressPayload,
  IncomeSourcePayload,
  JointApplicantType,
  ReferringLoanOfficerInfo,
  SaveSurveyResult,
  SurveyPayload,
  totalMonthsAtAddressRequired,
} from "@/data/payload";
import {
  ApplicantType,
  ContactInformationPayload,
  emptyApplicantPayload,
  FundsAndCoApplicantPayload,
  getAddresses,
  getIncomeInformation,
  getMaritalAndResidency,
  getRetirementAndObligations,
  MaritalAndResidencyPayload,
  PropertyInformationPayload,
  ReferralInformationPayload,
  RetirementAndObligationsPayload,
  saveToLocalStorage,
  SsnAndDateOfBirthPayload,
  SurveyFlowPayload,
  withAddresses,
  withContactInformation,
  withIncomeInformation,
  withMaritalAndResidency,
  withRetirementAndObligations,
  withSsnAndDateOfBirth,
} from "@/data/surveyFlowPayload";
import { SurveyTip } from "@/data/surveyTips";
import { Api } from "@/utils/api";
import {
  AsyncOperationStatus,
  Finished,
  Started,
} from "@/utils/asyncOperationStatus";
import { Resolved, updatingDeferred } from "@/utils/deferred";
import {
  Effect,
  effectOfAction,
  effectOfAsync,
  effectOfFunc_,
  effectsBatch,
  mapEffect,
  noEffect,
} from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { Either } from "fp-ts/Either";
import { flow, identity, pipe } from "fp-ts/function";
import { sequenceS } from "fp-ts/lib/Apply";
import * as Monoid from "fp-ts/Monoid";
import * as NEA from "fp-ts/NonEmptyArray";
import * as Num from "fp-ts/number";
import * as O from "fp-ts/Option";
import {
  AddressStep,
  ContactInformationStep,
  FundsAndCoApplicantStep,
  IncomeInformationStep,
  MaritalAndResidencyStep,
  Model,
  ReferralInformationStep,
  RetirementAndObligationsStep,
  SsnAndConsentStep,
  WizardStep,
} from "./model";
import { addressStepFactory } from "./utils";

export type Action =
  | {
      type: "PropertyInformationAction";
      action: PropertyInformation.Action;
    }
  | {
      type: "ContactInformationAction";
      action: ContactInformation.Action;
    }
  | {
      type: "MaritalAndResidencyAction";
      action: MaritalAndResidency.Action;
    }
  | {
      type: "FundsAndCoApplicantAction";
      action: FundsAndCoApplicant.Action;
    }
  | {
      type: "RetirementAndObligationsAction";
      action: RetirementAndObligations.Action;
    }
  | {
      type: "IncomeInformationAction";
      action: IncomeInformation.Action;
    }
  | {
      type: "ReferralInformationAction";
      action: ReferralInformation.Action;
    }
  | {
      type: "AddressAction";
      action: Address.Action;
    }
  | {
      type: "SsnAndConsentAction";
      action: SsnAndConsent.Action;
    }
  | {
      type: "PropertyInformationCompleted";
      propertyInformationPayload: PropertyInformationPayload;
    }
  | {
      type: "ContactInformationCompleted";
      applicantType: ApplicantType;
      contactInformationPayload: ContactInformationPayload;
    }
  | {
      type: "CoApplicantSameAddressPrimaryInputToggled";
      choice: boolean;
    }
  | {
      type: "AddressCompleted";
      applicantType: ApplicantType;
      addressIndex: number;
      addressPayload: AddressPayload;
    }
  | {
      type: "MaritalAndResidencyCompleted";
      applicantType: ApplicantType;
      maritalAndResidencyPayload: MaritalAndResidencyPayload;
    }
  | {
      type: "RetirementAndObligationsCompleted";
      applicantType: ApplicantType;
      retirementAndObligationsPayload: RetirementAndObligationsPayload;
    }
  | {
      type: "IncomeInformationCompleted";
      applicantType: ApplicantType;
      incomeInformationPayload: IncomeSourcePayload[];
    }
  | {
      type: "FundsAndCoApplicantCompleted";
      fundsAndCoApplicantPayload: FundsAndCoApplicantPayload;
    }
  | {
      type: "ReferralInformationCompleted";
      referralInformationPayload: ReferralInformationPayload;
    }
  | {
      type: "SsnAndConsentCompleted";
      primaryApplicant: SsnAndDateOfBirthPayload;
      coApplicant: O.Option<SsnAndDateOfBirthPayload>;
      consents: SurveyConsentItem[];
      providedConsentForElectronicBusiness: O.Option<boolean>
    }
  | {
      type: "StepCanceled";
    }
  | {
      type: "LoadTips";
      operation: AsyncOperationStatus<ApiResult<SurveyTip[]>>;
    }
  | {
      type: "BackOfficeSaveSurveyRequested";
      payload: SurveyPayload;
      operation: AsyncOperationStatus<ApiResult<SaveSurveyResult>>;
    }
  |
    {
      type: "UpdateReferringLoanOfficerInfo";
      referralLoanOfficerInfo: O.Option<ReferringLoanOfficerInfo>
    };

export const PropertyInformationAction = (
  action: PropertyInformation.Action,
): Action => ({
  type: "PropertyInformationAction",
  action,
});

export const ContactInformationAction = (
  action: ContactInformation.Action,
): Action => ({
  type: "ContactInformationAction",
  action,
});

export const AddressAction = (action: Address.Action): Action => ({
  type: "AddressAction",
  action,
});

export const MaritalAndResidencyAction = (
  action: MaritalAndResidency.Action,
): Action => ({
  type: "MaritalAndResidencyAction",
  action,
});

export const FundsAndCoApplicantAction = (
  action: FundsAndCoApplicant.Action,
): Action => ({
  type: "FundsAndCoApplicantAction",
  action,
});

export const RetirementAndObligationsAction = (
  action: RetirementAndObligations.Action,
): Action => ({
  type: "RetirementAndObligationsAction",
  action,
});

export const ReferralInformationAction = (
  action: ReferralInformation.Action,
): Action => ({
  type: "ReferralInformationAction",
  action,
});

export const IncomeInformationAction = (
  action: IncomeInformation.Action,
): Action => ({
  type: "IncomeInformationAction",
  action,
});

export const SsnAndConsentAction = (action: SsnAndConsent.Action): Action => ({
  type: "SsnAndConsentAction",
  action,
});

export const PropertyInformationCompleted = (
  propertyInformationPayload: PropertyInformationPayload,
): Action => ({
  type: "PropertyInformationCompleted",
  propertyInformationPayload,
});

export const ContactInformationCompleted =
  (applicantType: ApplicantType) =>
  (contactInformationPayload: ContactInformationPayload): Action => ({
    type: "ContactInformationCompleted",
    applicantType,
    contactInformationPayload,
  });

export const AddressCompleted =
  (applicantType: ApplicantType, addressIndex: number) =>
  (addressPayload: AddressPayload): Action => ({
    type: "AddressCompleted",
    applicantType,
    addressIndex,
    addressPayload,
  });

export const MaritalAndResidencyCompleted =
  (applicantType: ApplicantType) =>
  (maritalAndResidencyPayload: MaritalAndResidencyPayload): Action => ({
    type: "MaritalAndResidencyCompleted",
    applicantType,
    maritalAndResidencyPayload,
  });

export const RetirementAndObligationsCompleted =
  (applicantType: ApplicantType) =>
  (
    retirementAndObligationsPayload: RetirementAndObligationsPayload,
  ): Action => ({
    type: "RetirementAndObligationsCompleted",
    applicantType,
    retirementAndObligationsPayload,
  });

export const IncomeInformationCompleted =
  (applicantType: ApplicantType) =>
  (incomeInformationPayload: IncomeSourcePayload[]): Action => ({
    type: "IncomeInformationCompleted",
    applicantType,
    incomeInformationPayload,
  });

export const FundsAndCoApplicantCompleted = (
  fundsAndCoApplicantPayload: FundsAndCoApplicantPayload,
): Action => ({
  type: "FundsAndCoApplicantCompleted",
  fundsAndCoApplicantPayload,
});

export const ReferralInformationCompleted = (
  referralInformationPayload: ReferralInformationPayload,
): Action => ({
  type: "ReferralInformationCompleted",
  referralInformationPayload,
});

export const SsnAndConsentCompleted = (
  primaryApplicant: SsnAndDateOfBirthPayload,
  coApplicant: O.Option<SsnAndDateOfBirthPayload>,
  consents: SurveyConsentItem[],
  providedConsentForElectronicBusiness: O.Option<boolean>
): Action => ({
  type: "SsnAndConsentCompleted",
  primaryApplicant,
  coApplicant,
  consents,
  providedConsentForElectronicBusiness
});

export const StepCanceled = (): Action => ({
  type: "StepCanceled",
});

export const LoadTips = (
  operation: AsyncOperationStatus<ApiResult<SurveyTip[]>>,
): Action => ({
  type: "LoadTips",
  operation,
});

export const BackOfficeSaveSurveyRequested =
  (payload: SurveyPayload) =>
  (operation: AsyncOperationStatus<ApiResult<SaveSurveyResult>>): Action => ({
    type: "BackOfficeSaveSurveyRequested",
    payload,
    operation,
  });

// here we use Either<WizardStep, WizardStep>
// where Right is the ssnAndConsent step when all required information is provided
// and Left is the fallback step to collect missing information
// this way we leverage the short-circuit semantics of Either
// for navigating to the appropriate step
const navToSsnAndConsent = (
  payload: SurveyFlowPayload,
  isLoanOfficerInviting: O.Option<boolean>,
): Either<WizardStep, WizardStep> => {
  // an init function with initialization payload values baked in
  const ssnAndConsentInit = SsnAndConsent.init(
    payload.primaryApplicantPayload.ssnAndDateOfBirthPayload,
    pipe(
      payload.coApplicantPayload,
      O.chain(({ ssnAndDateOfBirthPayload }) => ssnAndDateOfBirthPayload),
    ),
  );

  // _jointApplicantType is important for type safety when using it in pattern matching below
  const ssnAndConsentWithCoApplicant = (
    _jointApplicantType: JointApplicantType,
  ) =>
    pipe(
      sequenceS(E.Apply)({
        primaryApplicant: pipe(
          payload.primaryApplicantPayload.contactInformation,
          E.fromOption(() =>
            ContactInformationStep("PrimaryApplicant")(
              ContactInformation.init(O.none),
            ),
          ),
        ),
        jointApplicant: pipe(
          payload.coApplicantPayload,
          O.chain(({ contactInformation }) => contactInformation),
          E.fromOption(() =>
            ContactInformationStep("CoApplicant")(
              ContactInformation.init(O.none),
            ),
          ),
        ),
      }),
      E.map(({ primaryApplicant, jointApplicant }) =>
        ssnAndConsentInit(
          primaryApplicant,
          O.some(jointApplicant),
          isLoanOfficerInviting,
        ),
      ),
    );

  const ssnAndConsentWithoutCoApplicant = () =>
    pipe(
      payload.primaryApplicantPayload.contactInformation,
      E.fromOption(() =>
        ContactInformationStep("PrimaryApplicant")(
          ContactInformation.init(O.none),
        ),
      ),
      E.map((primaryApplicant) =>
        ssnAndConsentInit(primaryApplicant, O.none, isLoanOfficerInviting),
      ),
    );

  const nextStep: Either<WizardStep, WizardStep> = pipe(
    payload.fundsAndCoApplicant,
    E.fromOption(() =>
      FundsAndCoApplicantStep(FundsAndCoApplicant.init(O.none)),
    ),
    E.chain(({ jointApplicantType }) =>
      O.match(
        ssnAndConsentWithoutCoApplicant,
        ssnAndConsentWithCoApplicant,
      )(jointApplicantType),
    ),
    E.map(SsnAndConsentStep),
  );

  return nextStep;
};

export const CoApplicantSameAddressPrimaryInputToggled = (
  choice: boolean,
): Action => ({
  type: "CoApplicantSameAddressPrimaryInputToggled",
  choice,
});

export const UpdateReferringLoanOfficerInfo = (
  referralLoanOfficerInfo:O.Option<ReferringLoanOfficerInfo>
): Action => ({
  type: "UpdateReferringLoanOfficerInfo",
  referralLoanOfficerInfo
})

const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

export const update =
  (api: Api) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    switch (action.type) {
      case "PropertyInformationAction":
        if (model.currentStep.type !== "PropertyInformation") {
          return [model, noEffect];
        }

        return [
          {
            ...model,
            currentStep: {
              ...model.currentStep,
              propertyInformationModel: PropertyInformation.update(
                model.currentStep.propertyInformationModel,
                action.action,
              ),
            },
          },
          effectOfFunc_(() => scrollToTop(), undefined),
        ];

      case "ContactInformationAction":
        if (model.currentStep.type !== "ContactInformation") {
          return [model, noEffect];
        }
        return [
          {
            ...model,
            currentStep: {
              ...model.currentStep,
              contactInformationModel: ContactInformation.update(
                model.currentStep.contactInformationModel,
                action.action,
              ),
            },
          },
          noEffect,
        ];

      case "AddressAction":
        if (model.currentStep.type !== "Address") {
          return [model, noEffect];
        }
        return [
          {
            ...model,

            currentStep: {
              ...model.currentStep,
              addressModel: Address.update(action.action)(
                model.currentStep.addressModel,
              ),
            },
          },
          noEffect,
        ];

      case "MaritalAndResidencyAction":
        if (model.currentStep.type !== "MaritalAndResidency") {
          return [model, noEffect];
        }
        return [
          {
            ...model,
            currentStep: {
              ...model.currentStep,
              maritalAndResidencyModel: MaritalAndResidency.update(
                model.currentStep.maritalAndResidencyModel,
                action.action,
              ),
            },
          },
          noEffect,
        ];

      case "FundsAndCoApplicantAction":
        if (model.currentStep.type !== "FundsAndCoApplicant") {
          return [model, noEffect];
        }
        return [
          {
            ...model,
            currentStep: {
              ...model.currentStep,
              fundsAndCoApplicantModel: FundsAndCoApplicant.update(
                model.currentStep.fundsAndCoApplicantModel,
                action.action,
              ),
            },
          },
          noEffect,
        ];

      case "RetirementAndObligationsAction":
        if (model.currentStep.type !== "RetirementAndObligations") {
          return [model, noEffect];
        }
        return [
          {
            ...model,
            currentStep: {
              ...model.currentStep,
              retirementAndObligationsModel: RetirementAndObligations.update(
                model.currentStep.retirementAndObligationsModel,
                action.action,
              ),
            },
          },
          noEffect,
        ];

      case "ReferralInformationAction":
        if (model.currentStep.type !== "ReferralInformation") {
          return [model, noEffect];
        }
        return [
          {
            ...model,
            currentStep: {
              ...model.currentStep,
              referralInformationModel: ReferralInformation.update(
                model.currentStep.referralInformationModel,
                action.action,
              ),
            },
          },
          noEffect,
        ];

      case "IncomeInformationAction":
        if (model.currentStep.type !== "IncomeInformation") {
          return [model, noEffect];
        }
        return [
          {
            ...model,
            currentStep: {
              ...model.currentStep,
              incomeInformationModel: IncomeInformation.update(
                model.currentStep.incomeInformationModel,
                action.action,
              ),
            },
          },
          noEffect,
        ];

      case "SsnAndConsentAction":
        if (model.currentStep.type !== "SsnAndConsent") {
          return [model, noEffect];
        }

        return pipe(
          SsnAndConsent.update(api)(
            model.currentStep.ssnAndConsentModel,
            action.action,
          ),
          Tup.bimap(mapEffect(SsnAndConsentAction), (ssnModel) => {
            return {
              ...model,
              currentStep: {
                ...model.currentStep,
                ssnAndConsentModel: ssnModel,
              },
            };
          }),
        );

      case "PropertyInformationCompleted":
        return [
          {
            ...model,
            currentStep: pipe(
              model.payload.primaryApplicantPayload.contactInformation,
              ContactInformation.init,
              ContactInformationStep("PrimaryApplicant"),
            ),
            payload: {
              ...model.payload,
              propertyInformation: O.some(action.propertyInformationPayload),
            },
            completedSteps: A.append(model.currentStep)(model.completedSteps),
          },
          effectOfFunc_(() => scrollToTop(), undefined),
        ];

      case "ContactInformationCompleted": {
        const payload: SurveyFlowPayload = pipe(
          model.payload,
          withContactInformation(
            action.applicantType,
            action.contactInformationPayload,
          ),
        );
        return [
          {
            ...model,
            currentStep: addressStepFactory(
              model.payload,
              action.applicantType,
              0,
            ),
            payload,
            completedSteps: A.append(model.currentStep)(model.completedSteps),
          },
          effectOfFunc_(() => {
            saveToLocalStorage(payload)();
            scrollToTop();
          }, undefined),
        ];
      }

      case "CoApplicantSameAddressPrimaryInputToggled":
        {
          switch (model.currentStep.type) {
            case "Address": {
              if (action.choice) {
                const addressesLength = pipe(
                  model.payload.primaryApplicantPayload.addresses,
                  O.map((a) => A.size(a)),
                  O.getOrElse(() => 0),
                );

                const payload: SurveyFlowPayload = pipe(
                  model.payload,
                  pipe(
                    model.payload.primaryApplicantPayload.addresses,
                    O.map((a) => a.slice(0, -1)),
                    O.chain((a) => NEA.fromArray(a)),
                    O.getOrElse(() =>
                      NEA.of(null as unknown as AddressPayload),
                    ),
                    (addresses) => withAddresses("CoApplicant", addresses),
                  ),
                );

                const payloadWithCurrentAddressStep: SurveyFlowPayload = pipe(
                  model.payload,
                  pipe(
                    model.payload.primaryApplicantPayload.addresses,
                    O.chain((a) => NEA.fromArray(a)),
                    O.getOrElse(() =>
                      NEA.of(null as unknown as AddressPayload),
                    ),
                    (addresses) => withAddresses("CoApplicant", addresses),
                  ),
                );

                return [
                  {
                    ...model,
                    currentStep: addressStepFactory(
                      payloadWithCurrentAddressStep,
                      "CoApplicant",
                      pipe(
                        payloadWithCurrentAddressStep.coApplicantPayload,
                        O.fold(
                          () => 0,
                          (coApplicantPayload) =>
                            pipe(
                              coApplicantPayload.addresses,
                              O.map((a) => A.size(a) - 1),
                              O.getOrElse(() => 0),
                            ),
                        ),
                      ),
                    ),
                    payload: addressesLength > 1 ? payload : model.payload,
                    completedSteps: model.completedSteps,
                  },
                  effectOfFunc_(() => {
                    saveToLocalStorage(
                      addressesLength > 1 ? payload : model.payload,
                    )();
                    scrollToTop();
                  }, undefined),
                ];
              } else {
                const payload: SurveyFlowPayload = {
                  ...model.payload,
                  coApplicantPayload: pipe(
                    model.payload.coApplicantPayload,
                    O.map((coApplicantPayload) => ({
                      ...coApplicantPayload,
                      addresses: O.none,
                    })),
                    O.alt(() =>
                      O.some({
                        ...emptyApplicantPayload,
                        addresses: O.none,
                      }),
                    ),
                  ),
                };

                return [
                  {
                    ...model,
                    currentStep: addressStepFactory(payload, "CoApplicant", 0),
                    payload,
                  },
                  effectOfFunc_(() => {
                    saveToLocalStorage(model.payload)();
                    scrollToTop();
                  }, undefined),
                ];
              }
            }
            default:
              return [model, noEffect];
          }
          break;
        }
        break;

      case "AddressCompleted": {
        const addresses = pipe(
          action.applicantType,
          (applicantType) =>
            applicantType == "PrimaryApplicant"
              ? model.payload.primaryApplicantPayload.addresses
              : pipe(
                  model.payload.coApplicantPayload,
                  O.chain((payload) => payload.addresses),
                ),

          O.fold(
            () => NEA.of(action.addressPayload),
            addAddressPayload(action.addressPayload),
          ),
        );
        const totalMonths = pipe(
          addresses,
          A.foldMap(Num.MonoidSum)(({ monthsAtAddress }) => monthsAtAddress),
        );
        const lastIndex = pipe(
          addresses,
          A.foldMap(Monoid.max(Num.Bounded))(({ sequence }) => sequence),
        );

        const payload: SurveyFlowPayload = pipe(
          model.payload,
          withAddresses(action.applicantType, addresses),
        );

        const hasNextAddressStep = action.addressIndex < lastIndex;

        return [
          {
            ...model,
            currentStep:
              totalMonths < totalMonthsAtAddressRequired || hasNextAddressStep
                ? pipe(
                    model.payload,
                    getAddresses(action.applicantType),
                    O.chain(A.lookup(action.addressIndex + 1)),
                    Address.init,
                    AddressStep(action.applicantType)(action.addressIndex + 1),
                  )
                : pipe(
                    model.payload,
                    getMaritalAndResidency(action.applicantType),
                    MaritalAndResidency.init,
                    MaritalAndResidencyStep(action.applicantType),
                  ),
            payload,
            completedSteps: A.append(model.currentStep)(model.completedSteps),
          },
          effectOfFunc_(() => {
            saveToLocalStorage(payload)(), scrollToTop();
          }, undefined),
        ];
      }
      case "MaritalAndResidencyCompleted": {
        const payload: SurveyFlowPayload = pipe(
          model.payload,
          withMaritalAndResidency(
            action.applicantType,
            action.maritalAndResidencyPayload,
          ),
        );
        return [
          {
            ...model,
            currentStep: pipe(
              model.payload,
              getIncomeInformation(action.applicantType),
              IncomeInformation.init,
              IncomeInformationStep(action.applicantType),
            ),

            payload,
            completedSteps: A.append(model.currentStep)(model.completedSteps),
          },
          effectOfFunc_(() => {
            saveToLocalStorage(payload)();
            scrollToTop();
          }, undefined),
        ];
      }

      case "IncomeInformationCompleted": {
        const payload: SurveyFlowPayload = pipe(
          model.payload,
          withIncomeInformation(
            action.applicantType,
            action.incomeInformationPayload,
          ),
        );
        return [
          {
            ...model,
            currentStep: pipe(
              model.payload,
              getRetirementAndObligations(action.applicantType),
              RetirementAndObligations.init,
              RetirementAndObligationsStep(action.applicantType),
            ),
            payload,
            completedSteps: A.append(model.currentStep)(model.completedSteps),
          },
          effectOfFunc_(() => {
            saveToLocalStorage(payload)();
            scrollToTop();
          }, undefined),
        ];
      }

      case "RetirementAndObligationsCompleted": {
        const payload: SurveyFlowPayload = pipe(
          model.payload,
          withRetirementAndObligations(
            action.applicantType,
            action.retirementAndObligationsPayload,
          ),
        );
        return [
          {
            ...model,
            currentStep:
              action.applicantType === "PrimaryApplicant"
                ? pipe(
                    model.payload.fundsAndCoApplicant,
                    FundsAndCoApplicant.init,
                    FundsAndCoApplicantStep,
                  )
                : pipe(
                    model.payload.referralInformation,
                    ReferralInformation.init,
                    ReferralInformationStep,
                  ),
            payload,
            completedSteps: A.append(model.currentStep)(model.completedSteps),
          },
          effectOfFunc_(() => {
            saveToLocalStorage(payload)();
            scrollToTop();
          }, undefined),
        ];
      }

      case "FundsAndCoApplicantCompleted": {
        const payload: SurveyFlowPayload = {
          ...model.payload,
          fundsAndCoApplicant: O.some(action.fundsAndCoApplicantPayload),
          // don't forget to remove the coApplicantPayload if it's not a joint application
          coApplicantPayload: O.isSome(
            action.fundsAndCoApplicantPayload.jointApplicantType,
          )
            ? model.payload.coApplicantPayload
            : O.none,
        };
        return [
          {
            ...model,

            currentStep: O.isSome(
              action.fundsAndCoApplicantPayload.jointApplicantType,
            )
              ? pipe(
                  model.payload.coApplicantPayload,
                  O.chain(({ contactInformation }) => contactInformation),
                  ContactInformation.init,
                  ContactInformationStep("CoApplicant"),
                )
              : pipe(
                  model.payload.referralInformation,
                  ReferralInformation.init,
                  ReferralInformationStep,
                ),
            payload,
            completedSteps: A.append(model.currentStep)(model.completedSteps),
          },
          effectOfFunc_(() => {
            saveToLocalStorage(payload)();
            scrollToTop();
          }, undefined),
        ];
      }

      case "ReferralInformationCompleted": {
        const payload: SurveyFlowPayload = {
          ...model.payload,
          referralInformation: O.some(action.referralInformationPayload),
        };
        const loadConsents = effectOfAction(
          SsnAndConsentAction(flow(Started, SsnAndConsent.LoadConsentMeta)()),
        );
        return [
          {
            ...model,
            currentStep: pipe(
              navToSsnAndConsent(model.payload, model.isLoanOfficerInviting),
              E.fold(identity, identity),
            ),
            payload,
            completedSteps: A.append(model.currentStep)(model.completedSteps),
          },
          effectsBatch([
            effectOfFunc_(() => {
              saveToLocalStorage(payload)();
              scrollToTop();
            }, undefined),
            loadConsents,
          ]),
        ];
      }
      case "SsnAndConsentCompleted": {
        const payload = pipe(
          model.payload,
          withSsnAndDateOfBirth(
            action.primaryApplicant,
            action.coApplicant,
            action.consents,
            false,
            action.providedConsentForElectronicBusiness
          ),
        );

        return [
          {
            ...model,
            payload,
            completedSteps: A.append(model.currentStep)(model.completedSteps),
          },
          effectOfFunc_(() => {
            saveToLocalStorage(payload)();
            scrollToTop();
          }, undefined),
        ];
      }

      case "StepCanceled":
        return [
          {
            ...model,
            currentStep: pipe(
              A.last(model.completedSteps),
              O.getOrElse(() => model.currentStep),
            ),
            completedSteps: pipe(
              A.init(model.completedSteps),
              O.getOrElse(() => model.completedSteps),
            ),
          },
          effectOfFunc_(() => {
            saveToLocalStorage(model.payload)();
            scrollToTop();
          }, undefined),
        ];
      case "LoadTips":
        switch (action.operation.status) {
          case "Started":
            return [
              {
                ...model,
                tips: updatingDeferred(model.tips),
              },
              effectOfAsync(api.surveyTips, flow(Finished, LoadTips)),
            ];
          case "Finished":
            return [
              {
                ...model,
                tips: Resolved(action.operation.result),
              },
              noEffect,
            ];
        }
        break;
      case "BackOfficeSaveSurveyRequested":
        switch (action.operation.status) {
          case "Started":
            return [
              {
                ...model,
                savedSurvey: updatingDeferred(model.savedSurvey),
              },
              effectOfAsync(
                api.saveSurvey(action.payload),
                flow(Finished, BackOfficeSaveSurveyRequested(action.payload)),
              ),
            ];
          case "Finished":
            return [
              {
                ...model,
                savedSurvey: Resolved(action.operation.result),
              },
              noEffect,
            ];
          break;
        }
        break
      case "UpdateReferringLoanOfficerInfo": {
        console.log(action.referralLoanOfficerInfo);
        
        return [
          {
            ...model,
            referralLoanOfficerInfo: action.referralLoanOfficerInfo
          },
          noEffect
        ]
      }
    }
  };
