import { updateFormField } from "@/utils/formField";
import {
  emailStringCodec,
  nonEmptyStringCodec,
  socialSecurityNumberCodec,
  validDateTimeCodecS,
} from "@/utils/codecs";
import { Model } from "./model";
import * as Address from "../Address";
import * as A from "fp-ts/lib/Array";
import * as NEA from "fp-ts/lib/NonEmptyArray";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { citizenshipStatusCodec, maritalStatusCodec } from "@/data/payload";
import { phoneNumberCodec } from "@/utils/codecs/phoneNumber";

export type Action =
  | {
      type: "NameChanged";
      name: string;
    }
  | {
      type: "EmailChanged";
      email: string;
    }
  | {
      type: "PhoneChanged";
      phone: string;
    }
  | {
      type: "DateOfBirthChanged";
      dateOfBirth: string;
    }
  | {
      type: "SocialSecurityNumberChanged";
      socialSecurityNumber: string;
    }
  | {
      type: "AddressAdded";
    }
  | {
      type: "AddressAction";
      index: number;
      action: Address.Action;
    }
  | {
      type: "MaritalStatusChanged";
      maritalStatus: string;
    }
  | {
      type: "CitizenshipStatusChanged";
      residencyStatus: string;
    };

export const NameChanged = (name: string): Action => ({
  type: "NameChanged",
  name,
});

export const EmailChanged = (email: string): Action => ({
  type: "EmailChanged",
  email,
});

export const PhoneChanged = (phone: string): Action => ({
  type: "PhoneChanged",
  phone,
});

export const DateOfBirthChanged = (dateOfBirth: string): Action => ({
  type: "DateOfBirthChanged",
  dateOfBirth,
});

export const SocialSecurityNumberChanged = (
  socialSecurityNumber: string,
): Action => ({
  type: "SocialSecurityNumberChanged",
  socialSecurityNumber,
});

export const AddressAdded = (): Action => ({
  type: "AddressAdded",
});

export const AddressAction =
  (index: number) =>
  (action: Address.Action): Action => ({
    type: "AddressAction",
    index,
    action,
  });

export const MaritalStatusChanged = (maritalStatus: string): Action => ({
  type: "MaritalStatusChanged",
  maritalStatus,
});

export const CitizenshipStatusChanged = (residencyStatus: string): Action => ({
  type: "CitizenshipStatusChanged",
  residencyStatus,
});

export const update =
  (action: Action) =>
  (model: Model): Model => {
    switch (action.type) {
      case "NameChanged":
        return {
          ...model,
          fullName: updateFormField(nonEmptyStringCodec().decode)(action.name),
        };
      case "EmailChanged":
        return {
          ...model,
          email: updateFormField(emailStringCodec.decode)(action.email),
        };
      case "PhoneChanged":
        return {
          ...model,
          phone: updateFormField(phoneNumberCodec.decode)(action.phone),
        };
      case "DateOfBirthChanged":
        return {
          ...model,
          dateOfBirth: updateFormField(validDateTimeCodecS.decode)(
            action.dateOfBirth,
          ),
        };
      case "SocialSecurityNumberChanged":
        return {
          ...model,
          socialSecurityNumber: updateFormField(
            socialSecurityNumberCodec.decode,
          )(action.socialSecurityNumber),
        };
      case "AddressAdded":
        return {
          ...model,
          addresses: pipe(model.addresses, A.append(Address.init(O.none))),
        };
      case "AddressAction":
        return {
          ...model,
          addresses: pipe(
            model.addresses,
            NEA.modifyAt(action.index, (a) => Address.update(action.action)(a)),
            O.getOrElse(() => model.addresses),
          ),
        };

      case "MaritalStatusChanged":
        return {
          ...model,
          maritalStatus: updateFormField(maritalStatusCodec.decode)(
            action.maritalStatus,
          ),
        };

      case "CitizenshipStatusChanged":
        return {
          ...model,
          citizenshipStatus: updateFormField(citizenshipStatusCodec.decode)(
            action.residencyStatus,
          ),
        };
    }
  };
