import { pipe } from "fp-ts/lib/function";
import {
  BankruptcyStatusType,
  EqRetirementAccount,
  RetirementAccount,
} from "../../data/payload";
import { Model } from "./model";
import * as S from "fp-ts/lib/Set";

import * as BankruptcyStatus from "@/components/BankruptcyStatus";
import * as IncomeInformation from "@/components/IncomeInformation";

export type Action =
  | {
      type: "IncomeInformationAction";
      action: IncomeInformation.Action;
    }
  | {
      type: "RetirementAccountToggled";
      retirementAccount: RetirementAccount;
    }
  | {
      type: "BankruptcyStatusChanged";
      bankruptcyStatus: BankruptcyStatusType;
    }
  | {
      type: "BankruptcyStatusAction";
      action: BankruptcyStatus.Action;
    }
  | {
      type: "ForeclosureOrShortSaleChanged";
      foreclosureOrShortSale: boolean;
    }
  | {
      type: "AlimonyOrChildSupportChanged";
      alimonyOrChildSupport: boolean;
    };

export const IncomeInformationAction = (
  action: IncomeInformation.Action,
): Action => ({
  type: "IncomeInformationAction",
  action,
});

export const RetirementAccountToggled = (
  retirementAccount: RetirementAccount,
): Action => ({
  type: "RetirementAccountToggled",
  retirementAccount,
});

export const BankruptcyStatusChanged = (
  bankruptcyStatus: BankruptcyStatusType,
): Action => ({
  type: "BankruptcyStatusChanged",
  bankruptcyStatus,
});

export const BankruptcyStatusAction = (
  action: BankruptcyStatus.Action,
): Action => ({
  type: "BankruptcyStatusAction",
  action,
});

export const ForeclosureOrShortSaleChanged = (
  foreclosureOrShortSale: boolean,
): Action => ({
  type: "ForeclosureOrShortSaleChanged",
  foreclosureOrShortSale,
});

export const AlimonyOrChildSupportChanged = (
  alimonyOrChildSupport: boolean,
): Action => ({
  type: "AlimonyOrChildSupportChanged",
  alimonyOrChildSupport,
});

export const update =
  (action: Action) =>
  (model: Model): Model => {
    switch (action.type) {
      case "IncomeInformationAction": {
        return {
          ...model,
          incomeInformationModel: IncomeInformation.update(
            model.incomeInformationModel,
            action.action,
          ),
        };
      }
      case "RetirementAccountToggled": {
        return {
          ...model,
          retirementAccounts: pipe(
            model.retirementAccounts,
            S.toggle(EqRetirementAccount)(action.retirementAccount),
          ),
        };
      }

      case "BankruptcyStatusChanged": {
        return {
          ...model,
          bankruptcyStatus: BankruptcyStatus.init(action.bankruptcyStatus),
        };
      }

      case "BankruptcyStatusAction": {
        return {
          ...model,
          bankruptcyStatus: BankruptcyStatus.update(
            model.bankruptcyStatus,
            action.action,
          ),
        };
      }

      case "ForeclosureOrShortSaleChanged": {
        return {
          ...model,
          foreclosureOrShortSale: action.foreclosureOrShortSale,
        };
      }

      case "AlimonyOrChildSupportChanged": {
        return {
          ...model,
          alimonyOrChildSupport: action.alimonyOrChildSupport,
        };
      }
    }
  };
