import { stringEnumCodec } from "@/utils/codecs";
import * as t from "io-ts";
import * as tt from "io-ts-types";
import { Ord as OrdNumber } from "fp-ts/number";
import * as Ord from "fp-ts/Ord";

export enum SurveyPageId {
  SurveyFinancingType = "survey-financing-type",
  SurveyPropertyType = "survey-property-type",
  SurveyNameContact = "survey-name-contact",
  SurveyLivingArrangement = "survey-living-arrangement",
  SurveyPreviousAddress = "survey-previous-address",
  SurveyMaritalResidencyStatus = "survey-marital-residency-status",
  SurveySourceOfFunds = "survey-source-of-funds",
  SurveyReferralAgent = "survey-referral-agent",
  SurveyIncomeSources = "survey-income-sources",
  SurveyW2Details = "survey-w2-details",
  SurveySelfEmploymentDetails = "survey-self-employment-details",
  SurveyBankruptcyRetirementAccounts = "survey-bankruptcy-retirement-accounts",
  SurveySsnConsent = "survey-ssn-consent",
}

export const surveyPageIdCodec = stringEnumCodec<typeof SurveyPageId>(
  SurveyPageId,
  "SurveyPageId",
);

export const surveyTipCodec = t.type({
  pageTipId: t.number,
  pageIdentifier: t.string.pipe(surveyPageIdCodec),
  prompt: t.string,
  title: t.string,
  content: t.string,
  avatarUrl: t.string,
  sortOrder: t.number,
  isForCoApplicant: tt.optionFromNullable(t.boolean),
});

export type SurveyTip = t.TypeOf<typeof surveyTipCodec>;
export const OrdSurveyTips = Ord.contramap((tip: SurveyTip) => tip.sortOrder)(
  OrdNumber,
);
