import * as t from "io-ts";

const isStringEnumValue = <T extends { [key: string]: string }>(
  e: T,
  u: unknown
): u is T[keyof T] => typeof u === "string" && Object.values(e).includes(u);

export function stringEnumCodec<T extends { [key: string]: string }>(
  e: T,
  name: string
) {
  return new t.Type<T[keyof T], string, string>(
    name,
    (u): u is T[keyof T] => isStringEnumValue(e, u),
    (u, c) => (isStringEnumValue(e, u) ? t.success(u) : t.failure(u, c)),
    (x: unknown) => (x as string)
  );
}


