import get from "lodash-es/get";
import set from "lodash-es/set";
import { ApiError, JsonParseError } from "../request";
import { SurveyConsentItem } from "@/data/consents";
import { pipe } from "fp-ts/lib/function";
import * as E from "fp-ts/lib/Either";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (u:any) => {
    return E.tryCatch<ApiError, unknown>(() => {
      const existingConsents = get(
        u,
        "survey.primaryApplicant.consents",
      ) as unknown as SurveyConsentItem[];
      return set(
        u,
        "survey.primaryApplicant.consents",
        pipe(
          existingConsents,
          (v: SurveyConsentItem[]) => {
            return v.map((item) => {
              return { ...item, method: item?.method ? item?.method?.toLowerCase() : null};
            });
          },
        ),
      );
    }, JsonParseError);
  }