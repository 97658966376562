import { Branch, BranchId } from "@/data/client";
import * as EditBranch from "@/components/UserManagement/pages/ClientManagement/EditBranch";
import * as O from "fp-ts/Option";
import {
  DeleteBranchDialog,
  EditBranchDialog,
  Model,
  NewBranchDialog,
} from "./model";
import { pipe } from "fp-ts/lib/function";

export type Action =
  | { type: "SearchChanged"; search: string }
  | {
      type: "NewBranchInitiated";
    }
  | {
      type: "EditBranchInitiated";
      branch: Branch;
    }
  | {
      type: "DeleteBranchInitiated";
      branchId: BranchId;
    }
  | {
      type: "DialogClosed";
    }
  | {
      type: "EditBranchAction";
      action: EditBranch.Action;
    };

export const SearchChanged = (search: string): Action => ({
  type: "SearchChanged",
  search,
});

export const NewBranchInitiated = (): Action => ({
  type: "NewBranchInitiated",
});

export const EditBranchInitiated = (branch: Branch): Action => ({
  type: "EditBranchInitiated",
  branch,
});

export const DeleteBranchInitiated = (branchId: BranchId): Action => ({
  type: "DeleteBranchInitiated",
  branchId,
});

export const DialogClosed = (): Action => ({
  type: "DialogClosed",
});

export const EditBranchAction = (action: EditBranch.Action): Action => ({
  type: "EditBranchAction",
  action,
});

export const update = (model: Model, action: Action): Model => {
  switch (action.type) {
    case "SearchChanged": {
      return {
        ...model,
        search: action.search,
      };
    }
    case "NewBranchInitiated":
      return {
        ...model,
        dialog: pipe(EditBranch.init(), NewBranchDialog, O.some),
      };
    case "EditBranchInitiated":
      return {
        ...model,
        dialog: pipe(
          EditBranch.initEdit(action.branch),
          EditBranchDialog(action.branch.branchId),
          O.some,
        ),
      };
    case "DeleteBranchInitiated":
      return {
        ...model,
        dialog: pipe(action.branchId, DeleteBranchDialog, O.some),
      };
    case "DialogClosed":
      return {
        ...model,
        dialog: O.none,
      };
    case "EditBranchAction":
      if (
        O.isNone(model.dialog) ||
        model.dialog.value.type === "DeleteBranchDialog"
      ) {
        return model;
      }

      return {
        ...model,
        dialog: O.some({
          ...model.dialog.value,
          dialogModel: EditBranch.update(action.action)(
            model.dialog.value.dialogModel,
          ),
        }),
      };
  }
};
