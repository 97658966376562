import { nonEmptyStringCodec } from "@/utils/codecs";
import { FormField, initFormField } from "@/utils/formField";
import { NonEmptyString } from "io-ts-types";
import * as t from "io-ts";
import { TeamPayload } from "@/data/payload";
import { BranchId, Team } from "@/data/client";
import * as E from "fp-ts/Either";
import { sequenceS } from "fp-ts/lib/Apply";

export type Model = {
  branchId: BranchId;
  teamName: FormField<NonEmptyString>;
};

export const init = (branchId: BranchId): Model => ({
  branchId,
  teamName: initFormField(nonEmptyStringCodec().decode)(""),
});

export const initEdit = (team: Team): Model => ({
  branchId: team.branchId,
  teamName: initFormField(nonEmptyStringCodec().decode)(team.name),
});

export const result = (model: Model): t.Validation<TeamPayload> =>
  sequenceS(E.Apply)({
    branchId: E.right(model.branchId),
    name: model.teamName.val,
  });
