import { BankruptcyStatusPayload, BankruptcyStatusType } from "../../data/payload";

export type Model = BankruptcyStatusPayload;

export const init = (bankruptcyStatus: BankruptcyStatusType): Model => {
  switch (bankruptcyStatus) {
    case 'None':
      return {
        type: bankruptcyStatus
      }
    case 'Chapter7':
      return {
        type: bankruptcyStatus,
        discharged: false
      }
    case 'Chapter13':
      return {
        type: bankruptcyStatus,
        paymentPlanCompleted: false
      }
  }
}