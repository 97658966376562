import * as Address from "@/components/Address";
import {
  ApplicantType,
  getAddresses,
  SurveyFlowPayload,
} from "@/data/surveyFlowPayload";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { AddressStep, WizardStep } from "./model";

export const addressStepFactory = (
  payload: SurveyFlowPayload,
  applicantType: ApplicantType,
  addressIndex: number,
): WizardStep =>
  pipe(
    payload,
    getAddresses(applicantType),
    O.chain(A.lookup(addressIndex)),
    Address.init,
    AddressStep(applicantType)(addressIndex),
  );
