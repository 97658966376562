import * as ClientsListing from "@/components/ClientsListing";
import { Client } from "@/data/client";
import { DashboardAnalytics } from "@/data/dashboardAnalytics";
import { Api } from "@/utils/api";
import { AsyncOperationStatus, Finished } from "@/utils/asyncOperationStatus";
import { Resolved, updatingDeferred } from "@/utils/deferred";
import {
  Effect,
  effectOfAsync,
  effectOfFunc_,
  mapEffect,
  noEffect,
} from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import { DateTime } from "luxon";
import { Model, ReportingInterval, subtractIntervalFrom } from "./model";

export type Action =
  | {
      type: "LoadAnalytics";
      operation: AsyncOperationStatus<ApiResult<DashboardAnalytics>>;
      startDate: DateTime;
      endDate: DateTime;
    }
  | {
      type: "GetCurrentClient";
      operation: AsyncOperationStatus<ApiResult<Client>>;
    }
  | {
      type: "InviteTeamClicked";
    }
  | {
      type: "CancelInvites";
    }
  | {
      type: "BranchChanged";
      branch: string;
    }
  | {
      type: "ApplicationIntervalToggled";
    }
  | {
      type: "IntervalChanged";
      interval: ReportingInterval;
      dateTime: DateTime;
    }
  | {
      type: "CancelFirstLook";
    }
  | {
      type: "ClientsListingAction";
      action: ClientsListing.Action;
    };
export const LoadAnalytics =
  (startDate: DateTime, endDate: DateTime) =>
  (operation: AsyncOperationStatus<ApiResult<DashboardAnalytics>>): Action => ({
    type: "LoadAnalytics",
    operation,
    startDate,
    endDate,
  });

export const BranchChanged = (branch: string): Action => ({
  type: "BranchChanged",
  branch,
});
export const ApplicationIntervalToggled = (): Action => ({
  type: "ApplicationIntervalToggled",
});

export const CancelFirstLook = (): Action => ({
  type: "CancelFirstLook",
});

export const InviteTeamClicked = (): Action => ({
  type: "InviteTeamClicked",
});

export const CancelInvites = (): Action => ({
  type: "CancelInvites",
});

export const GetCurrentClient = (
  operation: AsyncOperationStatus<ApiResult<Client>>,
): Action => ({
  type: "GetCurrentClient",
  operation,
});

export const IntervalChanged =
  (interval: ReportingInterval) =>
  (dateTime: DateTime): Action => ({
    type: "IntervalChanged",
    interval,
    dateTime,
  });

export const ClientsListingAction = (
  action: ClientsListing.Action,
): Action => ({
  type: "ClientsListingAction",
  action,
});

export const update =
  (api: Api) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    switch (action.type) {
      case "BranchChanged":
        return [{ ...model, selectedBranch: O.some(action.branch) }, noEffect];
      case "IntervalChanged": {
        const startDate = subtractIntervalFrom(action.dateTime)(
          action.interval,
        );
        const endDate = action.dateTime;
        return [
          {
            ...model,
            selectedInterval: action.interval,
            analytics: updatingDeferred(model.analytics),
          },
          effectOfAsync(
            api.getDashboardAnalytics(startDate, endDate),
            flow(Finished, LoadAnalytics(startDate, endDate)),
          ),
        ];
      }
      case "LoadAnalytics":
        switch (action.operation.status) {
          case "Started":
            return [
              {
                ...model,
                analytics: updatingDeferred(model.analytics),
              },
              effectOfAsync(
                api.getDashboardAnalytics(action.startDate, action.endDate),
                flow(Finished, LoadAnalytics(action.startDate, action.endDate)),
              ),
            ];

          case "Finished": {
            const result = action.operation.result;
            return [
              {
                ...model,
                analytics: Resolved(result),
                selectedBranch: pipe(
                  model.selectedBranch,
                  O.alt(() =>
                    pipe(
                      result,
                      O.fromEither,
                      O.chain(({ customerProfileAverages }) =>
                        A.head(customerProfileAverages),
                      ),
                      O.map(({ branch }) => branch),
                    ),
                  ),
                ),
              },
              pipe(
                action.operation.result,
                E.fold(
                  (err) => effectOfFunc_(() => console.log(err), undefined),
                  (_) => noEffect,
                ),
              ),
            ];
          }
        }
        break;

      case "GetCurrentClient":
        switch (action.operation.status) {
          case "Started":
            return [
              {
                ...model,
                client: updatingDeferred(model.client),
              },
              effectOfAsync(
                api.getCurrentClient,
                flow(Finished, GetCurrentClient),
              ),
            ];
          case "Finished":
            return [
              {
                ...model,
                client: Resolved(action.operation.result),
              },
              pipe(
                action.operation.result,
                E.fold(
                  (err) => effectOfFunc_(() => console.log(err), undefined),
                  (_) => noEffect,
                ),
              ),
            ];
        }
        break;
      case "ApplicationIntervalToggled": {
        const applicationsInterval =
          model.applicationsInterval == "week" ? "month" : "week";
        return [
          {
            ...model,
            applicationsInterval,
          },
          noEffect,
        ];
        break;
      }

      case "CancelFirstLook":
        return [{ ...model, isFirstLook: false }, noEffect];

      case "InviteTeamClicked":
        return [
          { ...model, isFirstLook: false, invitedMembers: O.some([]) },
          noEffect,
        ];

      case "CancelInvites":
        return [
          { ...model, isFirstLook: false, invitedMembers: O.none },
          noEffect,
        ];

      case "ClientsListingAction": {
        const [clientsListing, clientEffect] = pipe(
          model.clientsListing,
          (model) => ClientsListing.update(api)(model, action.action),
        );
        return [
          { ...model, clientsListing },
          mapEffect(ClientsListingAction)(clientEffect),
        ];
      }
    }
  };
