import * as O from "fp-ts/lib/Option";
import { Team, TeamId } from "@/data/client";
import { Col, IconType, Label, Row, Table } from "@/components/basic";
import { constant, flow, pipe } from "fp-ts/lib/function";
import { Option } from "fp-ts/lib/Option";
import { useContext } from "react";
import { RouterContext } from "@/utils/router-context";
import { OptionMenu } from "@/components/basic/Menu/OptionMenu";
import { getProfileId } from "@/utils/user";
import { ViewResolver } from "@/utils/viewResolver";
import Navigo from "navigo";

export type TeamsTableProps = {
  teams: Team[];
  onSelectTeam: (team: TeamId) => void;
  onEditTeam: Option<(team: Team) => void>;
  onDeleteTeam: Option<(team: TeamId) => void>;
};

export function TeamsTable(props: TeamsTableProps): JSX.Element {
  const { router } = useContext(RouterContext);
  return (
    <Table
      pageInfo={O.none}
      isLoading={false}
      className="data-table teams-table mobile-column"
      itemKey={(team) => team.teamId}
      data={props.teams}
      columns={ViewResolver({
        viewModes: [
          ["Default"],
          ["Mobile-Portrait", "Mobile-Landscape"]
        ],
        resolvedContent: [
          getDesktopColumns(router, props),
          getMobileColumns(router, props)
        ]
      })}
    />
  );
}
function getMobileColumns(router: Navigo, props: TeamsTableProps) {
  return [
    {
      columnKey: "name",
      header: (
        <Row padding="sm">
          <Label className="table-header-label">Teams</Label>
        </Row>
      ),
      view: (team: Team) => (
        <Row
          padding="sm"
          gap="xs"
          className="data-table-cell mobile-column"
          alignVertical="center"
          onClick={flow(constant(`/org/branches/${team.branchId}/teams/${team.teamId}`), router.navigate)}
        >
          <Col grow={1}>
            <Label className="team-name cp">{team.name}</Label>
            <Label className="black">{team.users.length} users</Label>
          </Col>
          <Col className="data-table-cell"
            padding="xs"
            alignVertical="center">
            <OptionMenu
              menuItems={[
                {
                  icon: "pen-to-square" as IconType,
                  label: "Edit",
                  onClick: pipe(
                    props.onEditTeam,
                    O.map((f) => () => f(team)),
                  ),
                },
                {
                  icon: "trash-can" as IconType,
                  label: "Delete",
                  onClick: pipe(
                    props.onDeleteTeam,
                    O.map((f) => () => f(team.teamId)),
                  ),
                },
              ]}
            ></OptionMenu>
          </Col>
        </Row>
      ),
    }
  ]
}
function getDesktopColumns(router: Navigo, props: TeamsTableProps) {
  return [
    {
      columnKey: "name",
      header: (
        <Row padding="sm">
          <Label className="table-header-label">Teams</Label>
        </Row>
      ),
      view: (team: Team) => (
        <Row
          padding="sm"
          gap="xs"
          className="data-table-cell cell-first team-column"
          alignVertical="center"
          onClick={flow(constant(`/org/branches/${team.branchId}/teams/${team.teamId}`), router.navigate)}
        >
          <Col>
            <div className="team-id">
              {pipe(team.name, getProfileId)}
            </div>
          </Col>
          <Col>
            <Row>
              <Label className="team-name cp">{team.name}</Label>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      columnKey: "users",
      header: (
        <Row padding="sm">
          <Label className="table-header-label">Users</Label>
        </Row>
      ),
      headerContainerClassName: 'ml-auto',
      view: (team: Team) => (
        <Row padding="fallback" className="data-table-cell users-column" alignVertical="center" alignHorizontal="right">
          <Label className="black">{team.users.length}</Label>
        </Row>
      ),
    },
    {
      columnKey: "actions",
      header: (
        <Row>
          <></>
        </Row>
      ),
      view: (team: Team) => (
        <Col className="data-table-cell cell-last actions-cell" alignVertical="center" alignHorizontal="right">
          <OptionMenu
            menuItems={[
              {
                icon: "pen-to-square" as IconType,
                label: "Edit",
                onClick: pipe(
                  props.onEditTeam,
                  O.map((f) => () => f(team)),
                ),
              },
              {
                icon: "trash-can" as IconType,
                label: "Delete",
                onClick: pipe(
                  props.onDeleteTeam,
                  O.map((f) => () => f(team.teamId)),
                ),
              },
            ]}
          ></OptionMenu>
        </Col>
      ),
    },
  ]
}
