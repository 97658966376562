import * as EditUser from "@/components/UserManagement/UsersListing/modals/EditUser";
import { BranchId, TeamId, UserId } from "@/data/client";
import { Option, none } from "fp-ts/lib/Option";
import * as O from "fp-ts/Option";

export type DialogModel =
  | {
      type: "NewUserDialog";
      dialogModel: EditUser.Model;
    }
  | {
      type: "EditUserDialog";
      userId: UserId;
      dialogModel: EditUser.Model;
    }
  | {
      type: "DeleteUserDialog";
      userId: UserId;
      reassignTo: Option<UserId>;
      reassignmentConfirmed: boolean
    };

export const NewUserDialog = (dialogModel: EditUser.Model): DialogModel => ({
  type: "NewUserDialog",
  dialogModel,
});

export const EditUserDialog =
  (userId: UserId) =>
  (dialogModel: EditUser.Model): DialogModel => ({
    type: "EditUserDialog",
    userId,
    dialogModel,
  });

export const DeleteUserDialog = (
  userId: UserId,
  reassignTo: Option<UserId>,
): DialogModel => ({
  type: "DeleteUserDialog",
  userId,
  reassignTo,
  reassignmentConfirmed: false
});

export type Model = {
  branchId: O.Option<BranchId>
  teamId: O.Option<TeamId>  
  dialog: Option<DialogModel>;
};

export const init = (): Model => ({
  dialog: none,
  branchId: none, 
  teamId: none,  
});

export const withDialogClosed = (model: Model): Model => ({
  ...model,
  dialog: none,
});
