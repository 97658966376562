import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import { useContext } from "react";
import { UserContext } from "./router-context";
import { isBackOfficeUser } from "./user";

export const useRights = (): { isBackOfficeUser: O.Option<boolean> } => {
  const { user } = useContext(UserContext);
  const isBackOffice = pipe(user, O.map(isBackOfficeUser));

  return { isBackOfficeUser: isBackOffice };
};

/* Converts to O.none when isBackOfficeUser is false */
export const _useRights = (): { isBackOfficeUser: O.Option<boolean> } => {
  const { isBackOfficeUser } = useRights();

  const _isBackOffice = pipe(
    isBackOfficeUser,
    O.fold(
      () => O.none,
      (value) => (value ? O.some(true) : O.none),
    ),
  );

  return { isBackOfficeUser: _isBackOffice };
};

export const useIsUserResolved = (): { isUserResolved: O.Option<boolean> } => {
  const { user } = useContext(UserContext);
  const userResolved = pipe(
    user,
    O.fold(
      () => O.none,
      () => O.some(true),
    ),
  );
  return { isUserResolved: userResolved };
};
