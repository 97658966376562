import { updateFormField } from "@/utils/formField";
import { Model } from "./model";
import { nonEmptyStringCodec } from "@/utils/codecs";

export type Action = {
  type: "TeamNameChanged";
  teamName: string;
};

export const TeamNameChanged = (teamName: string): Action => ({
  type: "TeamNameChanged",
  teamName,
});

export const update =
  (action: Action) =>
  (model: Model): Model => {
    switch (action.type) {
      case "TeamNameChanged":
        return {
          ...model,
          teamName: updateFormField(nonEmptyStringCodec().decode)(
            action.teamName,
          ),
        };
    }
  };
