import { Model } from "./model";
import { updateFormField } from "@/utils/formField";
import { emailStringCodec, nonEmptyStringCodec } from "@/utils/codecs";
import { stateCodec, USState } from "@/data/states";
import { phoneNumberCodec } from "@/utils/codecs/phoneNumber";

export type Action =
  | {
      type: "FirstNameChanged";
      firstName: string;
    }
  | {
      type: "LastNameChanged";
      lastName: string;
    }
  | {
      type: "EmailChanged";
      email: string;
    }
  | {
      type: "PhoneChanged";
      phone: string;
    }
  | {
      type: "NmlsNumberChanged";
      nmlsNumber: string;
    }
  | {
      type: "AddressChanged";
      address: string;
    }
  | {
      type: "Address2Changed";
      address2: string;
    }
  | {
      type: "CityChanged";
      city: string;
    }
  | {
      type: "StateChanged";
      state: USState;
    }
  | {
      type: "ZipChanged";
      zip: string;
    };

export const FirstNameChanged = (firstName: string): Action => ({
  type: "FirstNameChanged",
  firstName,
});

export const LastNameChanged = (lastName: string): Action => ({
  type: "LastNameChanged",
  lastName,
});

export const EmailChanged = (email: string): Action => ({
  type: "EmailChanged",
  email,
});

export const PhoneChanged = (phone: string): Action => ({
  type: "PhoneChanged",
  phone,
});

export const NmlsNumberChanged = (nmlsNumber: string): Action => ({
  type: "NmlsNumberChanged",
  nmlsNumber,
});

export const AddressChanged = (address: string): Action => ({
  type: "AddressChanged",
  address,
});

export const Address2Changed = (address2: string): Action => ({
  type: "Address2Changed",
  address2,
});

export const CityChanged = (city: string): Action => ({
  type: "CityChanged",
  city,
});

export const StateChanged = (state: USState): Action => ({
  type: "StateChanged",
  state,
});

export const ZipChanged = (zip: string): Action => ({
  type: "ZipChanged",
  zip,
});

export const update =
  (action: Action) =>
  (model: Model): Model => {
    switch (action.type) {
      case "FirstNameChanged":
        return {
          ...model,
          firstName: updateFormField(nonEmptyStringCodec().decode)(
            action.firstName,
          ),
        };
      case "LastNameChanged":
        return {
          ...model,
          lastName: updateFormField(nonEmptyStringCodec().decode)(
            action.lastName,
          ),
        };
      case "EmailChanged":
        return {
          ...model,
          email: updateFormField(emailStringCodec.decode)(action.email),
        };

      case "PhoneChanged":
        return {
          ...model,
          phone: updateFormField(phoneNumberCodec.decode)(action.phone),
        };

      case "NmlsNumberChanged":
        return {
          ...model,
          nmlsNumber: updateFormField(nonEmptyStringCodec().decode)(
            action.nmlsNumber,
          ),
        };

      case "AddressChanged":
        return {
          ...model,
          address: updateFormField(nonEmptyStringCodec().decode)(
            action.address,
          ),
        };

      case "Address2Changed":
        return {
          ...model,
          address2: action.address2,
        };

      case "CityChanged":
        return {
          ...model,
          city: updateFormField(nonEmptyStringCodec().decode)(action.city),
        };

      case "StateChanged":
        return {
          ...model,
          state: updateFormField(stateCodec.decode)(action.state),
        };

      case "ZipChanged":
        return {
          ...model,
          zip: updateFormField(nonEmptyStringCodec().decode)(action.zip),
        };
    }
  };
