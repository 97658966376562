import * as Signup from "@/components/SignUp";
import * as O from "fp-ts/Option";
import { Action } from "./action";
import { FlashAdder } from "@/utils/page-types";

export type Model = {
  signupModel: O.Option<Signup.Model>;
};

export const initHomePageModel = (): Model => ({
  signupModel: O.none,
});

export type HomePageProps = {
  model: Model;
  dispatch: (action: Action) => void;
  onSignup: (email: string, phoneNo: string) => void;
  onSignin: () => void
  flashAdder: FlashAdder
};
