import { Model } from "./model";
import { updateFormField } from "@/utils/formField";
import { nonEmptyStringCodec } from "@/utils/codecs";

export type Action = {
  type: "BranchNameChanged";
  branchName: string;
};

export const BranchNameChanged = (branchName: string): Action => ({
  type: "BranchNameChanged",
  branchName,
});

export const update =
  (action: Action) =>
  (model: Model): Model => {
    switch (action.type) {
      case "BranchNameChanged":
        return {
          ...model,
          branchName: updateFormField(nonEmptyStringCodec().decode)(
            action.branchName,
          ),
        };
    }
  };
