import {
  LivingArrangementsComposition,
  LoanTypesComposition,
  SurveyActivity,
} from "@/data/dashboardAnalytics";
import Highcharts, { Point } from "highcharts";

export const pieChart =
  (ref: HTMLDivElement) =>
  (title: string) =>
  (data: Highcharts.PointOptionsObject[]) =>
    Highcharts.chart(ref, {
      chart: {
        style: {
          fontFamily: 'var(--base-font)'
        }
      },

      title: {
        text: title,
        align: "center",
      },
      tooltip: {
        enabled: false,
      },

      plotOptions: {
        pie: {
          // size: "240%",
          innerSize: "65%",
          // center: ["50%", "100%"],
          // startAngle: -90,
          // endAngle: 90,
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          animation: false,
        },
      },

      series: [
        {
          type: "pie",
          name: title,
          colors: ["#6975FF", "#084FD7", "#64BDC6", "#EECA34", "#FE7135"],
          data,
          showInLegend: true,
        },
      ],
      credits: {
        enabled: false
      },
      legend: {
        symbolRadius: 0,
        className: 'app-legend',
        useHTML: true,
        labelFormatter: function () {
          const percentage = `${(this as Point).percentage ? `${(this as Point).percentage?.toFixed(0)}%` : '0%'}`
          return (
            `<div class="legend-item">
              <span class="legend-name">${this.name}</span>
              <span class="legend-percentage">${percentage}</span>
            </div>`
          );
        }
      },
      
    });

export const lineChart =
  (ref: HTMLDivElement) =>
  (title: string) =>
  (series: Highcharts.SeriesOptionsType[]) =>
    Highcharts.chart(ref, {
      chart: {
        type: "line",
        style: {
          fontFamily: 'var(--base-font)'
        }
      },
      title: {
        text: title,
      },
      xAxis: {
        type: "datetime",
        labels: {
          format: "{value:%b %e}",
        },
      },
      yAxis: {
        title: {
          text: title,
        },
      },
      series,
      credits: {
        enabled: false
      }
    });

export const plotLivigArrangementsComposition = (
  livingArrangemnets: LivingArrangementsComposition,
): Highcharts.PointOptionsObject[] => [
  { name: "Living rent free", y: livingArrangemnets.livingRentFreePrecent },
  {
    name: "Own and making payments",
    y: livingArrangemnets.ownAndMakingPaymentsPercent,
  },
  {
    name: "Own and paid in full",
    y: livingArrangemnets.ownAndPaidInFullPercent,
  },
  { name: "Rent", y: livingArrangemnets.rentPercent },
];

export const plotLoanTypesComposition = (
  loanTypes: LoanTypesComposition,
): Highcharts.PointOptionsObject[] => [
  { name: "Refinance", y: loanTypes.refinancePercent },
  { name: "Purchase", y: loanTypes.purchasePercent },
  { name: "Home equity", y: loanTypes.homeEquityPercent },
];

export const plotNewSurveys = (
  surveyActivity: SurveyActivity,
): Highcharts.PointOptionsObject => ({
  x: surveyActivity.activityDate.toMillis(),
  y: surveyActivity.newSurveys,
});

export const plotApplicationExports = (
  surveyActivity: SurveyActivity,
): Highcharts.PointOptionsObject => ({
  x: surveyActivity.activityDate.toMillis(),
  y: surveyActivity.applicationsExported,
});
