import * as t from 'io-ts';
import * as tt from 'io-ts-types';
import { constant, pipe } from 'fp-ts/lib/function';
import { withMessage } from '@/utils/validation';

interface NonNegativeBrand {
  readonly NonNegative: unique symbol;
}

interface PositiveBrand extends NonNegativeBrand {
  readonly Positive: unique symbol;
}

export const numberCodecS = new t.Type<number, string, string>(
  'NumberS',
  t.number.is,
  (u, c) => (isNaN(+u) ? t.failure(u, c, 'is invalid') : t.success(+u)),
  x => x.toString(),
);

export const nonNegativeCodec = pipe(
  t.brand(t.number, (n): n is t.Branded<number, NonNegativeBrand> => n >= 0, 'NonNegative'),
  withMessage(constant('should be non-negative')),
);


export const positiveNumberCodec = pipe(
  t.brand(t.number, (n): n is t.Branded<number, PositiveBrand> => n > 0, 'Positive'),
  withMessage(constant('Should be positive')),
);

export const positiveIntCodec =   t.intersection([
  withMessage<typeof t.Int>(() => 'Should be a whole number')(t.Int),
  positiveNumberCodec,
]);

export const intCodec = new t.Type<t.Int, number, number>(
  "Int",
  t.Int.is,
  t.Int.validate,
  t.Int.encode
);

export const intCodecS: t.Type<t.Int, string, string> = new t.Type<
  t.Int,
  string,
  string
>(
  "IntFromString",
  t.Int.is,
  tt.IntFromString.validate,
  tt.IntFromString.encode
);

export type PositiveNumber = t.TypeOf<typeof positiveNumberCodec>;
export type PositiveInt = t.TypeOf<typeof positiveIntCodec>;
