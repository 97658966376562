import {
  AlignVertical,
  AlignHorizontal,
  Color,
  Spacing,
  spacing,
  palette,
  WidthTypes,
  getWidth,
} from "@/components/basic/constants";
import { RefObject } from "react";
import * as O from "fp-ts/lib/Option";
import { useOnly } from "@/utils/new-only";

export type RowProps = {
  padding?: Spacing | "fallback";
  gap?: Spacing | "fallback";
  grow?: number;
  basis?: string;
  wrap?: boolean;
  alignVertical?: AlignVertical;
  alignHorizontal?: AlignHorizontal;
  background?: Color;
  width?: WidthTypes;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  refContainer?: RefObject<HTMLDivElement>;
  only?: O.Option<boolean>;
  switchToGrid?: boolean
};

function getAlignHorizontal(align: AlignHorizontal): string {
  switch (align) {
    case "center":
      return "center";
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    case "space-between":
      return "space-between";
    case "space-around":
      return "space-around";
    case "stretch":
      return "stretch";
  }
}

function getAlignVertical(align: AlignVertical): string {
  switch (align) {
    case "center":
      return "center";
    case "top":
      return "flex-start";
    case "bottom":
      return "flex-end";
    case "baseline":
      return "baseline";
    case "space-between":
      return "space-between";
    case "space-around":
      return "space-around";
    case "stretch":
      return "stretch";
  }
}

export function Row(props: RowProps): JSX.Element {
  const shouldDisplay = useOnly(props.only);

  return (
    <>
      {shouldDisplay && (
        <div
          ref={props.refContainer}
          className={props.className}
          style={{
            display: props.switchToGrid ? "grid" : "flex",
            flexDirection: "row",
            ...(props.grow ? { flexGrow: props.grow } : {}),
            ...(props.basis ? { flexBasis: props.basis } : {}),
            ...(props.alignVertical
              ? { alignItems: getAlignVertical(props.alignVertical) }
              : {}),
            ...(props.alignHorizontal
              ? { justifyContent: getAlignHorizontal(props.alignHorizontal) }
              : {}),
            ...(props.wrap ? { flexWrap: "wrap" } : {}),
            ...(props.padding && props.padding !== "fallback"
              ? { padding: spacing[props.padding] }
              : props.padding === "fallback"
                ? { padding: "revert-layer" }
                : {}),
            ...(props.width ? { width: getWidth(props.width) } : {}),
            ...(props.gap && props.gap !== "fallback"
              ? { gap: spacing[props.gap] }
              : props.gap === "fallback"
                ? { gap: "revert-layer" }
                : {}),
            ...(props.background
              ? { backgroundColor: palette[props.background] }
              : {}),
          }}
          onClick={props.onClick}
        >
          {props.children}
        </div>
      )}
    </>
  );
}
