import { ChildProps } from "@/utils/reducerWithEffect";
import { Model } from "./model";
import {
  Action,
  EmailChanged,
  FullNameChanged,
  PhoneNumberChanged,
} from "./action";
import { InputField, PhoneInputField } from "@/components/basic";
import { flow } from "fp-ts/function";

export type Props = ChildProps<Model, Action>;

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  return (
    <div className="grid-2 gap-xs collapse-gap-md">
      <InputField
        label="Full Name"
        field={model.fullName}
        onChange={flow(FullNameChanged, dispatch)}
        required={true}
      />

      <></>

      <InputField
        label="Email"
        field={model.email}
        onChange={flow(EmailChanged, dispatch)}
        required={true}
      />

      <PhoneInputField
        label="Phone"
        field={model.phoneNumber}
        onChange={flow(PhoneNumberChanged, dispatch)}
        required={true}
      />
    </div>
  );
}
