import "@/assets/styles/Notes/notes.css";
import { useState } from "react";
import { Col, Label } from "../basic";
import * as O  from 'fp-ts/Option';
import { constant, identity, pipe } from "fp-ts/lib/function";

export type NotesProps = {
    field: O.Option<string>;
    onChange: (value: string) => void
}
export function View(props: NotesProps): JSX.Element {
    const [notes, setNotes] = useState(() => pipe(props.field, O.fold(constant(''), identity)));
    return (
        <Col className="notes-container" grow={1} gap="xs">
            <Label>Notes <i className="fa-solid fa-clipboard ml-left-xxs"></i></Label>
            <textarea
                value={notes}
                onChange={(e) => {
                    setNotes(e.target.value);
                    props.onChange(e.target.value);
                }}
                className="notes-text-area text-md"
                placeholder="Type..."
            ></textarea>
        </Col>
    )
}