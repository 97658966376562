import { ChildProps } from "@/utils/reducerWithEffect";
import { Model } from "./model";
import {
  Action,
  AlimonyOrChildSupportChanged,
  BankruptcyStatusAction,
  BankruptcyStatusChanged,
  ForeclosureOrShortSaleChanged,
  RetirementAccountToggled,
} from "./action";
import { Checkbox, Col, Label, Row, Select } from "@/components/basic";
import {
  EqBankruptcyStatusType,
  EqRetirementAccount,
  retirementAccountTypes,
  showBankruptcyStatusType,
  showRetirementAccountType,
} from "@/data/payload";
import * as S from "fp-ts/Set";
import * as O from "fp-ts/Option";
import { constant, flow, pipe } from "fp-ts/function";
import * as BankruptcyStatus from "@/components/BankruptcyStatus";
import { ViewResolver } from "@/utils/viewResolver";

export type Props = ChildProps<Model, Action>;

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const retirementInfoView = (
    <>
      <Col gap="xs" grow={2}>
        <Label>Do you have any of the following?</Label>
        <Checkbox
          label="Foreclosure or short sale"
          checked={model.foreclosureOrShortSale}
          onChange={flow(ForeclosureOrShortSaleChanged, dispatch)}
        />

        <Checkbox
          label="Bankruptcy"
          checked={model.bankruptcyStatus.type !== "None"}
          onChange={flow(
            (checked) => (checked ? "Chapter7" : "None"),
            BankruptcyStatusChanged,
            dispatch,
          )}
        />

        {model.bankruptcyStatus.type !== "None" && (
          <Select
            options={["Chapter7" as const, "Chapter13" as const]}
            valueEq={EqBankruptcyStatusType}
            renderLabel={showBankruptcyStatusType}
            selected={pipe(
              model.bankruptcyStatus.type,
              O.fromPredicate(
                (status) => status === "Chapter7" || status === "Chapter13",
              ),
            )}
            onChange={flow(BankruptcyStatusChanged, dispatch)}
          />
        )}

        <BankruptcyStatus.View
          model={model.bankruptcyStatus}
          dispatch={flow(BankruptcyStatusAction, dispatch)}
        />

        <Checkbox
          label="Obligation to pay Alimony and/or Child Support"
          checked={model.alimonyOrChildSupport}
          onChange={flow(AlimonyOrChildSupportChanged, dispatch)}
        />
      </Col>
      <Col gap="xs" grow={1}>
        <Label>Which if any retirement accounts do you have?</Label>
        {retirementAccountTypes.map((retirementAccount) => (
          <Checkbox
            key={retirementAccount}
            label={showRetirementAccountType(retirementAccount)}
            checked={S.elem(EqRetirementAccount)(retirementAccount)(
              model.retirementAccounts,
            )}
            onChange={flow(
              constant(retirementAccount),
              RetirementAccountToggled,
              dispatch,
            )}
          />
        ))}
      </Col>
    </>
  );

  const normalView = (
    <Row gap="xs"> {retirementInfoView} </Row>
  );

  const collapsedView = (
    <Col gap="md"> {retirementInfoView} </Col>
  )

  const RetirementInfoViewWrapper = (
    ViewResolver({
      viewModes: [["Default"], ["Mobile-Portrait"]],
      resolvedContent: [normalView, collapsedView]
    })
  );

  return (
    RetirementInfoViewWrapper
  );
}
