import { Col } from "@/components/basic/Col";
import { Error } from "@/components/basic/Error";
import { Input, InputType } from "@/components/basic/Input";
import { Label } from "@/components/basic/Label";
import {
  ConfirmSocialSecurityNumber,
  OptionalSocialSecurityNumber,
  SocialSecurityNumber
} from "@/utils/codecs";
import { PhoneNumber } from "@/utils/codecs/phoneNumber";
import { FormField } from "@/utils/formField";
import { flow, pipe } from "fp-ts/function";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { ReactNode } from "react";
import { Row } from "../Row";

import "@/assets/styles/basic/InputField/input.css";
import { formatConfirmSsn, formatPhoneNumber } from "@/utils/formatters";


export type InputFieldProps<V> = {
  label: string;
  field: FormField<V>;
  type?: InputType;
  className?: string;
  suffix?: string;
  hint?: string;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  onSubmit?: ()=> void
  onChange: (value: string) => void;
};

type FieldWrapperProps<V> = {
  label: string;
  field: FormField<V>;
  className?: string;
  hint?: string;
  children: ReactNode;
  required?: boolean;
};

function FieldWrapper<V>(props: FieldWrapperProps<V>): JSX.Element {
  return (
    <Col gap="xs" className={props.className}>
      <Label>{props.label} {props?.required ? '*' : ''}</Label>
      {pipe(
        O.fromNullable(props.hint),
        O.map((v) => <div className="hint text-xxxs">{v}</div>),
        O.getOrElse(() => <></>),
      )}
      {props.children}
      {E.isLeft(props.field.val)}
      {props.field.dirty && E.isLeft(props.field.val) ? (
        <Error errors={props.field.val.left} />
      ) : (
        <></>
      )}
    </Col>
  );
}

export function InputField<V>(props: InputFieldProps<V>): JSX.Element {
  const inputField = (
    <Input
      type={props.type}
      placeholder={props.placeholder}
      value={props.field.raw}
      onChange={props.onChange}
      onSubmit={props.onSubmit}
      invalid={props.field.dirty && E.isLeft(props.field.val)}
    />
  );
  return (
    <FieldWrapper
      label={props.label}
      field={props.field}
      hint={props.hint}
      className={`${props.suffix ? "grid-item-2" : ""} ${props.className ? props.className : ""}`}
      required={props.required}
    >
      {pipe(
        O.fromNullable(props.suffix),
        O.map(() => (
          <Row alignHorizontal="space-between" className="suffix input">
            {inputField}
            <div className="suffix-part text-md">
              {props.suffix ? props.suffix : null}
            </div>
          </Row>
        )),
        O.getOrElse(() => inputField),
      )}
    </FieldWrapper>
  );
}

// InputFieldProps with generic parameter fixed to PhoneNumber
// and type fixed to "tel"
export type PhoneInputFieldProps = Omit<InputFieldProps<PhoneNumber>, "type">;

export type SSNInputFieldProps = Omit<InputFieldProps<SocialSecurityNumber | ConfirmSocialSecurityNumber | OptionalSocialSecurityNumber>, "type">;

export function PhoneInputField(props: PhoneInputFieldProps): JSX.Element {
  return (
    <FieldWrapper
      label={props.label}
      field={props.field}
      className={props.className}
      required={props.required}
    >
      <Input
        type="tel"
        value={formatPhoneNumber(props.field.raw)}
        onChange={flow(unformatPhoneNumber, props.onChange)}
        invalid={props.field.dirty && E.isLeft(props.field.val)}
      />
    </FieldWrapper>
  );
}

export function SSNInputField(props: SSNInputFieldProps): JSX.Element {
  return (
    <FieldWrapper
      label={props.label}
      field={props.field}
      className={props.className}
      required={props.required}
    >
      <Input
        type="tel"
        value={formatSSN(props.field.raw)}
        onChange={flow(unformatSSN, props.onChange)}
        invalid={props.field.dirty && E.isLeft(props.field.val)}
      />
    </FieldWrapper>
  );
}

function formatSSN(ssn: string): string {
  // check that the input only contains digits
  // otherwise return the input as is
  if (!/^\d+$/.test(ssn) || ssn.length < 3) {
    return ssn;
  }

  const areaSegment = ssn.slice(0,3);
  const groupSegment = ssn.slice(3,5);
  const serialSegment = ssn.slice(5);

  if (ssn.length <= 3) {
    return `${areaSegment}`;
  }
  if (ssn.length <= 5) {
    return `${areaSegment}-${groupSegment}`;
  }

  return `${areaSegment}-${groupSegment}-${serialSegment}`;
}

function unformatPhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/\D/g, "");
}

function unformatSSN(ssn: string): string {
  return ssn.replace(/\D/g, "");
}

export type ConfirmSsnFieldProps = Omit<
  InputFieldProps<ConfirmSocialSecurityNumber>,
  "type"
>;

export function ConfirmSsnInputField(props: ConfirmSsnFieldProps): JSX.Element {
  return (
    <FieldWrapper
      label={props.label}
      field={props.field}
      className={props.className}
      required={props.required}
    >
      <Input
        value={formatConfirmSsn(props.field.raw)}
        onChange={flow(unformatConfirmSsn, props.onChange)}
        invalid={props.field.dirty && E.isLeft(props.field.val)}
      />
    </FieldWrapper>
  );
}

function unformatConfirmSsn(ssn: string): string {
  const value = ssn.replace(/\D/g, "");
  return value;
}
