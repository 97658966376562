import { NonEmptyString } from "io-ts-types";
import { FormField, initFormField } from "@/utils/formField";
import { EmailString, emailStringCodecWithMessage, nonEmptyStringCodec } from "@/utils/codecs";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { sequenceS } from "fp-ts/lib/Apply";
import { ContactInformationPayload } from "@/data/surveyFlowPayload";
import { PhoneNumber, phoneNumberCodecWithMessage } from "@/utils/codecs/phoneNumber";
import { ValidErrorMessage } from "../basic";

export type Model = {
  fullName: FormField<NonEmptyString>;
  email: FormField<EmailString>;
  phoneNumber: FormField<PhoneNumber>;
};

export const init = (
  contactInformation: Option<ContactInformationPayload>,
): Model => ({
  fullName: pipe(
    contactInformation,
    O.fold(
      () => "",
      ({ fullName }) => fullName,
    ),
    initFormField(nonEmptyStringCodec(false, ValidErrorMessage('name')).decode),
  ),
  email: pipe(
    contactInformation,
    O.fold(
      () => "",
      ({ email }) => email,
    ),
    initFormField(emailStringCodecWithMessage(ValidErrorMessage('email address')).decode),
  ),
  phoneNumber: pipe(
    contactInformation,
    O.fold(
      () => "",
      ({ phoneNumber }) => phoneNumber,
    ),
    initFormField(phoneNumberCodecWithMessage(ValidErrorMessage('phone number')).decode),
  ),
});

export const result = (model: Model): t.Validation<ContactInformationPayload> =>
  sequenceS(E.Apply)({
    fullName: model.fullName.val,
    email: model.email.val,
    phoneNumber: model.phoneNumber.val,
  });
