import * as t from "io-ts";
import * as tt from "io-ts-types";
import { DateTime } from "luxon";
import * as Eq from "fp-ts/lib/Eq";

interface ValidDateTimeBrand {
  readonly ValidDate: unique symbol;
}

function isDate(d: unknown): d is DateTime {
  return d instanceof DateTime;
}

export const dateTimeCodec = new t.Type<DateTime, DateTime, unknown>(
  "Date",
  isDate,
  (u, c) => (isDate(u) ? t.success(u) : t.failure(u, c, "Invalid date")),
  t.identity,
);

export const validDateTimeCodec = t.brand(
  dateTimeCodec,
  (d): d is t.Branded<DateTime, ValidDateTimeBrand> => d.isValid,
  "ValidDate",
);


export type ValidDateTime = t.TypeOf<typeof validDateTimeCodec>;



export const toISO = (d: ValidDateTime): tt.NonEmptyString =>
  // Assuming that the date is valid based on the input type.
  // Unsafe typecase is ok in this case.
  d.toISO() as tt.NonEmptyString;

export const toISODate = (d: ValidDateTime): tt.NonEmptyString =>
  // Assuming that the date is valid based on the input type.
  // Unsafe typecase is ok in this case.
  d.toISODate() as tt.NonEmptyString;

export const validDateTimeCodecS = new t.Type<ValidDateTime, string, string>(
  "ValidDateS",
  validDateTimeCodec.is,
  (u, c) => {
    const d = DateTime.fromISO(u, { zone: "utc" });
    return validDateTimeCodec.is(d)
      ? t.success(d)
      : t.failure(u, c, "Invalid date");
  },
  (dt) => dt.setZone("UTC").toISO() || "",
);

export const validDateTimeCodecSWithMessage = (errorMessage: string) => tt.withMessage(
  validDateTimeCodecS,
  () => errorMessage
);

export const EqSameDate: Eq.Eq<ValidDateTime> = Eq.fromEquals(
  (a, b) =>
    a.hasSame(b, "year") && a.hasSame(b, "month") && a.hasSame(b, "day"),
);

// unsafe typecast that relies on luxon's DateTime.now() to produce a valid date
// which is a fairly safe assumption
export const dateTimeNow = (): ValidDateTime => DateTime.now() as ValidDateTime;


export const isoDateCodec = tt.withEncode(validDateTimeCodecS, toISODate);
export type IsoDate = t.TypeOf<typeof isoDateCodec>;