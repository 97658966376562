import { RetirementAccount } from "@/data/payload";
import * as BankruptcyStatus from "@/components/BankruptcyStatus";
import { Option } from "fp-ts/Option";
import * as O from "fp-ts/Option";
import { RetirementAndObligationsPayload } from "@/data/surveyFlowPayload";
import { pipe } from "fp-ts/function";

export type Model = {
  retirementAccounts: Set<RetirementAccount>;
  bankruptcyStatus: BankruptcyStatus.Model;
  foreclosureOrShortSale: boolean;
  alimonyOrChildSupport: boolean;
};

export const init = (
  payload: Option<RetirementAndObligationsPayload>,
): Model => ({
  retirementAccounts: pipe(
    payload,
    O.fold(
      () => new Set<RetirementAccount>(),
      ({ retirementAccounts }) => retirementAccounts,
    ),
  ),
  bankruptcyStatus: pipe(
    payload,
    O.fold(
      () => BankruptcyStatus.init("None"),
      ({ bankruptcyStatus }) => BankruptcyStatus.init(bankruptcyStatus.type),
    ),
  ),
  foreclosureOrShortSale: pipe(
    payload,
    O.fold(
      () => false,
      ({ foreclosureOrShortSale }) => foreclosureOrShortSale,
    ),
  ),
  alimonyOrChildSupport: pipe(
    payload,
    O.fold(
      () => false,
      ({ hasAlimonyOrChildSupportObligation }) =>
        hasAlimonyOrChildSupportObligation,
    ),
  ),
});

export const result = (model: Model): RetirementAndObligationsPayload => ({
  retirementAccounts: model.retirementAccounts,
  bankruptcyStatus: model.bankruptcyStatus,
  foreclosureOrShortSale: model.foreclosureOrShortSale,
  hasAlimonyOrChildSupportObligation: model.alimonyOrChildSupport,
});
