import { AccountSettingsPayload } from "@/data/payload";
import { Api } from "@/utils/api";
import {
  updatingDeferred,
  mapDeferred,
  Resolved,
  isResolved,
} from "@/utils/deferred";
import {
  Effect,
  effectOfAsync,
  effectOfFunc_,
  noEffect,
} from "@/utils/reducerWithEffect";
import { constant, flow, pipe } from "fp-ts/lib/function";
import * as E from "fp-ts/Either";
import * as TE from "fp-ts/TaskEither";
import * as O from "fp-ts/lib/Option";
import { Model } from "./model";
import { User } from "@/data/client";
import { ApiResult } from "@/utils/request";

export type Action =
   {
      type: "SmsNotificationsToggled";
    }
  | {
      type: "SettingsPayloadPrepared";
      payload: AccountSettingsPayload;
    }
  | {
      type: "AccountUserSaved";
      result: ApiResult<User>;
    };

export const SmsNotificationsToggled = (): Action => ({
  type: "SmsNotificationsToggled",
});

export const SettingsPayloadPrepared = (
  payload: AccountSettingsPayload,
): Action => ({
  type: "SettingsPayloadPrepared",
  payload,
});

export const AccountUserSaved = (result: ApiResult<User>): Action => ({
  type: "AccountUserSaved",
  result,
});

export const onBorrowerSettingsSave =
  (settings: O.Option<AccountSettingsPayload>) =>
  (model: Model, dispatch: (action: Action) => void) => {
    return pipe(
      settings,
      O.filter((_) => isResolved(model.accountSettings)),
      O.map((s) => flow(constant(s), SettingsPayloadPrepared, dispatch)),
      O.fold(
        () => {},
        (s) => s(),
      ),
    );
  };

export const update =
  (api: Api) =>
  (action: Action) =>
  (model: Model): [Model, Effect<Action>] => {
    switch (action.type) {
      case "SettingsPayloadPrepared":
        return [
          {
            ...model,
            accountSettings: updatingDeferred(model.accountSettings),
          },
          effectOfAsync(
            pipe(
              api.saveAccountSettings(action.payload),
              TE.chain(() => api.getUser),
            ),
            AccountUserSaved,
          ),
        ];

      case "AccountUserSaved":
        return [
          {
            ...model,
            accountSettings: pipe(
              action.result,
              E.map(({ userId, smsNotificationsDisabled }) => ({
                userId,
                smsNotificationsDisabled,
              })),
              Resolved,
            ),
            dialogApiResult: Resolved(action.result)
          },
          pipe(
            action.result,
            E.fold(
              (err) => effectOfFunc_(() => console.error(err), undefined),
              () => noEffect
            ),
          ),
        ];

      case "SmsNotificationsToggled": {
        return [
            {
              ...model,
              accountSettings: pipe(
                model.accountSettings,
                mapDeferred(
                  E.map((settings) => ({
                    ...settings,
                    smsNotificationsDisabled: !settings.smsNotificationsDisabled,
                  })),
                ),
              ),
            },
            noEffect,
        ];
      }
    }
  };
