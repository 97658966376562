import { Client, PaymentProviderMeta, User } from "@/data/client";
import { AccountSettingsPayload } from "@/data/payload";
import { Deferred, NotStarted, Resolved } from "@/utils/deferred";
import { ApiResult } from "@/utils/request";
import * as E from "fp-ts/Either";
import { pipe } from "fp-ts/lib/function";
import * as EditCompany from "./EditCompany";
import * as EditUser from "./EditUser";
import { isSuperUser } from "@/utils/user";

export type Page =
  | { type: "General" }
  | { type: "Billing" }
  | { type: "Company" }
  | { type: "Notifications" };

export type Model = {
  page: Page;
  editUserModel: Deferred<ApiResult<EditUser.Model>>;
  editCompanyModel: Deferred<ApiResult<EditCompany.Model>>;
  client: Deferred<ApiResult<Client>>;
  user: User;
  accountSettings: Deferred<ApiResult<AccountSettingsPayload>>;
  paymentProviderMeta: Deferred<ApiResult<PaymentProviderMeta>>;
  canSeeBilling: boolean,
  canSeeAccountSettings: boolean
};

export const init = (user: User): Model => ({
  page: { type: "General" },
  client: NotStarted(), 
  editUserModel: pipe(user, EditUser.init, E.right, Resolved),
  editCompanyModel: NotStarted(),
  accountSettings: pipe(
    {
      userId: user.userId,
      smsNotificationsDisabled: user.smsNotificationsDisabled,
    },
    E.right,
    Resolved,
  ),
  user,
  paymentProviderMeta: NotStarted(),
  canSeeBilling:  isSuperUser(user),
  canSeeAccountSettings:  isSuperUser(user)
});
