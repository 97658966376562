import { DocumentTypeId } from "@/data/applicationDocument";
import { Deferred, NotStarted } from "@/utils/deferred";
import { ApiResult } from "@/utils/request";
import * as O from 'fp-ts/Option';

export type Model = {
  isOpen: boolean;
  submissionsState: Deferred<ApiResult<void>>;
  file: O.Option<File>
  documentTypeId: DocumentTypeId
};

export const init = (): Model => ({
  isOpen: false,
  submissionsState: NotStarted(),
  file: O.none,
  documentTypeId: NaN as DocumentTypeId
});

export const updateDocSubmissionResult = (
  model: Model, 
  operation: Deferred<ApiResult<void>>,
): Model => ({
  ...model,
  isOpen: true,
  submissionsState: operation,

});
