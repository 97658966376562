import { ReactNode } from "react";
import "@/assets/styles/basic/Label/label.css";
export type LabelProps = {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  htmlFor?: string;
  fontStyle?: string
};

export function Label(props: LabelProps): JSX.Element {
  return (
    <label
      className={`label ${props.className || ""} ${props.htmlFor ? 'cp' : ''} ${props.fontStyle ? props.fontStyle : 'text-md'}`}
      onClick={props.onClick}
      htmlFor={props.htmlFor}
    >
      {props.children}
    </label>
  );
}
