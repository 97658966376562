
import { TeamId } from "@/data/client";


export type Model = {
  teamId: TeamId;

};

export const init = (teamId: TeamId): Model => ({
  teamId,
  
});

export const withDialogClosed = (model: Model): Model => ({
  ...model,
});
