import "@/assets/styles/basic/Modal/modal.css";
import { Deferred, isApiSuccess, isLoading } from "@/utils/deferred";
import { ApiResult } from "@/utils/request";
import { useApiResult } from "@/utils/useApiResult";
import * as O from "fp-ts/lib/Option";
import { identity, pipe } from "fp-ts/lib/function";
import { ForwardedRef, forwardRef, useEffect, useMemo } from "react";
import { ApiError } from "../ApiError";
import { Button } from "../Button";
import { Row } from "../Row";
import { Modal, ModalProps } from "./Modal";
import { DialogType } from "@/app/model";

type Props = ModalProps & {
  actionTitle: string;
  dialogApiResult: Deferred<ApiResult<unknown>>;
  actionHander: O.Option<() => void>;
  cancelHandler: O.Option<() => void>;
  cancelTitle?: string;
  type?: DialogType
};

export const ApiCallingModal = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
    const { actionTitle, actionHander, cancelHandler, dialogApiResult, title } =
      props;

    const [apiError, hasResolved] = useApiResult(dialogApiResult);

    const isSubmitting = useMemo(
      () => pipe(dialogApiResult, isLoading),
      [dialogApiResult],
    );

    const resolvedActionHandler = useMemo(
      () =>
        pipe(
          isSubmitting,
          (isSubmitting) => (isSubmitting ? O.none : O.some(null)), //no when submitting
          O.chain(() => {
            //Disable when error
            if (hasResolved && O.isSome(apiError)) {
              return O.none;
            } else {
              return O.some(null);
            }
          }),
          O.chain(() => actionHander),
        ),
      [actionHander, apiError, hasResolved, isSubmitting],
    );

    useEffect(() => {
      if (isApiSuccess(dialogApiResult)) {
        pipe(cancelHandler, O.ap(O.some(null)));
      }
    }, [cancelHandler, dialogApiResult]);

    return (
      <Modal ref={ref} {...props} title={title}>
        {props.children}

        <Row padding="xs" gap="xs" alignHorizontal={
          pipe(
            props.type,
            O.fromNullable,
            O.fold(
              () => 'center',
              (type) => type === 'UnFinishedApplicationExit' ? 'left' : 'center'
            )
          )
        }>
          <Button type="primary" onClick={resolvedActionHandler}>
            {actionTitle}
          </Button>
          <Button type="secondary" onClick={cancelHandler}>
            {pipe(
              props.cancelTitle,
              O.fromNullable,
              O.fold(
                () => 'Cancel',
                identity
              )
            )}
          </Button>
        </Row>
        {isSubmitting && <p> Performing Action...</p>}
        <ApiError apiResult={dialogApiResult} />
      </Modal>
    );
  },
);
