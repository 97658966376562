import { FlashType } from "@/app/model";
import "@/assets/styles/basic/Modal/flash-messages.css";
import { useCounter } from "@uidotdev/usehooks";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { isNumber } from "lodash-es";
import { useEffect } from "react";
import { Button } from "../Button";
import { Col } from "../Col";
import { Icon } from "../Icon";
import { Row } from "../Row";

const DISAPPEAR_TIMEOUT_SECS = 5
type Props = {
  messages: FlashType[];
  onRemoveMessage: (index: number[]) => void;
};
export function FlashMessages(props: Props): JSX.Element {
  const { messages, onRemoveMessage } = props;
  const [counter, { increment }] = useCounter();
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      const removedIndices = pipe(
        counter,
        () =>
          messages.map((message, index) =>
            Math.abs(message.pushed.diffNow().as("seconds")) > DISAPPEAR_TIMEOUT_SECS ? index : null,
          ),
        (v) => v.filter(isNumber),
      );
      if (removedIndices.length > 0) {
        onRemoveMessage(removedIndices);
      }
    }, DISAPPEAR_TIMEOUT_SECS *1000);

    return () => clearInterval(intervalId);
  }, [counter, increment, messages, onRemoveMessage]);

  return (
    <div className="flash-message-container">
      <Col alignHorizontal="right" gap="xs">
        {messages.map((flash, index) => (
          <Col padding="xs" key={index} className={`flash-item ${flash.type}`}>
            <Row>
              {flash.message}
              <Button
                type="flat"
                onClick={O.some(() => props.onRemoveMessage([index]))}
              >
                <Icon type="xmark"></Icon>
              </Button>
            </Row>
          </Col>
        ))}
      </Col>
    </div>
  );
}
