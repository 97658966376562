import * as t from 'io-ts';
import * as tt from 'io-ts-types';

const zipCodeRegex = /^\d{5}(-\d{4})?$/;

interface ValidZipCodeBrand {
  readonly ValidZipCode: unique symbol;
}

export const zipCodeCodec = t.brand(tt.NonEmptyString, (s): s is t.Branded<tt.NonEmptyString, ValidZipCodeBrand> => zipCodeRegex.test(s), 'ValidZipCode');

export const zipCodeCodecWithMessage = (errorMessage: string) => tt.withMessage(
  zipCodeCodec,
  () => errorMessage
)

export type ZipCode = t.TypeOf<typeof zipCodeCodec>;