import * as O from "fp-ts/Option";
import { Button, Col, InputField } from "../basic";
import { Props } from "./view";
import { Fragment, useState } from "react";
import { initFormField } from "@/utils/formField";
import * as t from "io-ts";

export function InviteMembers(
  props: Props & { onInvitesDone: O.Option<() => void> },
): JSX.Element {
  const [email, _] = useState("");
  return (
    <Col gap="sm" padding="xs">
      <p> Invite Team Members </p>

      <div className="text-bold">Do you have a large team? </div>

      <p>
        Invite your loan officers on this screen. You can
        <span style={{ color: "var(--grey-40)" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua.
        </span>
        <span className="link text-bold">support@appcatcher.com</span>
      </p>

      <div className="grid-3 gap-xs" style={{alignItems: "end"}}>
        {Array(3)
          .fill({})
          .map((_, i) => (
            <Fragment key={i}>
              <InputField
                label="Date of Birth"
                field={initFormField(t.string.decode)(email)}
                onChange={() => {}}
              />
              <InputField
                label="Date of Birth"
                field={initFormField(t.string.decode)(email)}
                onChange={() => {}}
              />
              <Button onClick={O.some(() => {})} type="tertiary">
                {" "}
                Send Invite{" "}
              </Button>
            </Fragment>
          ))}
      </div>

      <Button onClick={props.onInvitesDone} type="primary">
        Done
      </Button>
    </Col>
  );
}
