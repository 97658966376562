import {
  isoDateCodec,
  stringEnumCodec,
  validDateTimeCodecS,
} from "@/utils/codecs";
import { Eq } from "fp-ts/lib/Eq";
import { Ord } from "fp-ts/lib/Ord";
import { Eq as EqString, Ord as OrdString } from "fp-ts/lib/string";
import * as O from "fp-ts/Option";
import * as t from "io-ts";
import * as tt from "io-ts-types";
import { branchIdCodec, extensionRoleCodec, userIdCodec } from "./client";
import {
  applicationIdCodec,
  ApplicationStatusType,
  applicationStatusTypeCodec,
  applicationTypeCodec,
} from "./payload";
import { pick } from "lodash-es";

export const applicationAssigneeCodec = t.type({
  userId: userIdCodec,
  firstName: tt.NonEmptyString,
  lastName: tt.NonEmptyString,
  authorizationRoleType: t.string.pipe(extensionRoleCodec),
});

export type ApplicationAssignee = t.TypeOf<typeof applicationAssigneeCodec>;

export const EqApplicationStatus: Eq<ApplicationStatusType> = EqString;
export const OrdApplicationStatus: Ord<ApplicationStatusType> = OrdString;

export const applicationListItemCodec = t.type({
  applicationId: applicationIdCodec,
  applicantsName: tt.NonEmptyString,
  isCreditCheckAuthorized: t.boolean,
  mortgageType: t.string.pipe(applicationTypeCodec),
  dateCreated: t.string.pipe(validDateTimeCodecS),
  assignees: t.array(applicationAssigneeCodec),
  status: t.string.pipe(applicationStatusTypeCodec),
  percentComplete: t.number,
});

export type ApplicationListItem = t.TypeOf<typeof applicationListItemCodec>;

const pageInfoCodec = t.type({
  page: t.number,
  pageCount: t.number,
  pageSize: t.number,
  totalCount: t.number,
});
export type PageInfo = t.TypeOf<typeof pageInfoCodec>;

export const applicationListingResponseCodec = t.intersection([
  t.type({
    items: t.array(applicationListItemCodec),
  }),
  pageInfoCodec,
]);

export type ApplicationListingResponse = t.TypeOf<
  typeof applicationListingResponseCodec
>;

export enum ApplicationListingSortColumns {
  ApplicantName = "ApplicantName",
  Status = "Status",
  MortgageType = "MortgageType",
  SubmittedDate = "SubmittedDate",
}

export function showApplicationListingSortColumns(
  applicationType: ApplicationListingSortColumns,
): string {
  return applicationType.valueOf();
}

export const applicationListingSortColumnsCodec = stringEnumCodec<
  typeof ApplicationListingSortColumns
>(ApplicationListingSortColumns, "ApplicationListingSortColumns");

export enum ApplicationListingSortDirection {
  Ascending = "Ascending",
  Descending = "Descending",
}

export function showApplicationListingSortDirection(
  applicationType: ApplicationListingSortDirection,
): string {
  return applicationType.valueOf();
}

export const applicationListingSortDirectionCodec = stringEnumCodec<
  typeof ApplicationListingSortDirection
>(ApplicationListingSortDirection, "ApplicationListingSortDirection");

export const applicationsFilterCodec = t.type({
  startDate: t.union([t.string.pipe(isoDateCodec), t.null]),
  endDate: t.union([t.string.pipe(isoDateCodec), t.null]),
  statusesToInclude: t.array(t.string.pipe(applicationStatusTypeCodec)),
  isCreditCheckAuthorized: t.union([t.boolean, t.null]),

  branchId: tt.optionFromNullable(branchIdCodec),
  assignedToUserId: t.array(userIdCodec),

  searchQuery: tt.optionFromNullable(t.string),
  mortgageTypesToInclude: t.array(t.string.pipe(applicationTypeCodec)),

  page: t.number,
  pageSize: t.number,
  sortColumn: t.string.pipe(applicationListingSortColumnsCodec),

  sortDirection: t.string.pipe(applicationListingSortDirectionCodec),
});
export type ApplicationsFilterPayload = t.TypeOf<
  typeof applicationsFilterCodec
>;
export const emptyApplicationFilter: ApplicationsFilterPayload = {
  startDate: null,
  endDate: null,
  statusesToInclude: [],
  isCreditCheckAuthorized: null,
  branchId: O.none,
  assignedToUserId: [],
  searchQuery: O.none,
  mortgageTypesToInclude: [],
  page: 0,
  pageSize: 5,
  sortColumn: ApplicationListingSortColumns.SubmittedDate,
  sortDirection: ApplicationListingSortDirection.Descending,
};

export function pageInfoFromList(
  applicationListResponse: ApplicationListingResponse,
): PageInfo {
  return pick(applicationListResponse, [
    "page",
    "pageCount",
    "pageSize",
    "totalCount",
  ]);
}
