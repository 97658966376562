import {
  BranchId,
  ExtensionRole,
  extensionRoleCodec,
  OrdExtensionRole,
  TeamId,
  User,
} from "@/data/client";
import {
  EmailString,
  emailStringCodec,
  nonEmptyStringCodec,
} from "@/utils/codecs";
import { FormField, initFormField } from "@/utils/formField";
import { NonEmptyString } from "io-ts-types";
import { Option } from "fp-ts/lib/Option";
import { UserPayload } from "@/data/payload";
import { sequenceS } from "fp-ts/lib/Apply";
import * as E from "fp-ts/lib/Either";
import * as t from "io-ts";
import * as S from "fp-ts/lib/Set";
import { pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";

export type Model = {
  firstName: FormField<NonEmptyString>;
  lastName: FormField<NonEmptyString>;
  email: FormField<EmailString>;
  phone: FormField<NonEmptyString>;
  branchId: Option<BranchId>;
  teamId: Option<TeamId>;
  role: FormField<ExtensionRole>;
  featureBreakdownVisible: boolean;
};

export const init =
  (branchId: Option<BranchId>) =>
  (teamId: Option<TeamId>): Model => ({
    firstName: initFormField(nonEmptyStringCodec().decode)(""),
    lastName: initFormField(nonEmptyStringCodec().decode)(""),
    email: initFormField(emailStringCodec.decode)(""),
    phone: initFormField(nonEmptyStringCodec().decode)(""),
    branchId,
    teamId,
    role: initFormField(extensionRoleCodec.decode)(""),
    featureBreakdownVisible: false,
  });

export const initEdit = (user: User): Model => ({
  firstName: pipe(
    user.firstName,
    O.getOrElseW(() => ""),
    initFormField(nonEmptyStringCodec().decode),
  ),
  lastName: pipe(
    user.lastName,
    O.getOrElseW(() => ""),
    initFormField(nonEmptyStringCodec().decode),
  ),
  email: initFormField(emailStringCodec.decode)(user.email),
  phone: pipe(
    user.mobilePhone,
    O.getOrElseW(() => ""),
    initFormField(nonEmptyStringCodec().decode),
  ),
  branchId: user.branchId,
  teamId: user.teamId,
  role: pipe(
    user.roles,
    S.toArray(OrdExtensionRole),
    A.head,
    O.getOrElseW(() => ""),
    initFormField(extensionRoleCodec.decode),
  ),
  featureBreakdownVisible: false,
});

export const result = (model: Model): t.Validation<UserPayload> =>
  sequenceS(E.Apply)({
    firstName: model.firstName.val,
    lastName: model.lastName.val,
    email: model.email.val,
    mobilePhone: model.phone.val,
    branchId: E.right(model.branchId),
    teamId: E.right(model.teamId),
    roles: pipe(model.role.val, E.map(S.singleton)),
  });
