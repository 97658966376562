export type IconType =
  | "arrow-left"
  | "arrow-right"
  | "chevron-down"
  | "chevron-up"
  | "circle"
  | "circle-solid"
  | "circle-check"
  | "circle-xmark"
  | "ellipsis-vertical"
  | "file-lines"
  | "gauge"
  | "house"
  | "layer-group"
  | "lightbulb"
  | "list-check"
  | "map"
  | "xmark"
  | "piggy-bank"
  | "plus"
  | "trash-can"
  | "user"
  | "wallet"
  | "info"
  | "file-export"
  | "chevron-right"
  | "chevron-left"
  | "download"
  | "eye"
  | "camera"
  | "upload"
  | "arrows-spin"
  | "pen-to-square"
  | "gear"
  | "check"
  | "circle-download"
  | "circle-camera"
  | "circle-upload"
  | "search"
  | "users"
  | "user-tie"
  | "spinner"
  | "phone"
  | "copy"
  | "triangle-exclamation"
  | "circle-exclamation";

export type IconProps = {
  type: IconType;
};

export function Icon({ type }: IconProps): JSX.Element {
  switch (type) {
    case "arrow-left":
      return <i className="fa-solid fa-arrow-left"></i>;
    case "arrow-right":
      return <i className="fa-solid fa-arrow-right"></i>;
    case "chevron-down":
      return <i className="fa-solid fa-chevron-down"></i>;
    case "chevron-up":
      return <i className="fa-solid fa-chevron-up"></i>;
    case "circle":
      return <i className="fa-regular fa-circle"></i>;
    case "circle-solid":
      return <i className="fa-solid fa-circle"></i>;
    case "circle-check":
      return <i className="fa-solid fa-circle-check"></i>;
    case "circle-xmark":
      return <i className="fa-solid fa-circle-xmark"></i>;
    case "ellipsis-vertical":
      return <i className="fa-solid fa-ellipsis-vertical"></i>;
    case "file-lines":
      return <i className="fa-regular fa-file-lines"></i>;
    case "gauge":
      return <i className="fa-solid fa-gauge"></i>;
    case "house":
      return <i className="fa-solid fa-house"></i>;
    case "layer-group":
      return <i className="fa-solid fa-layer-group"></i>;
    case "lightbulb":
      return <i className="fa-regular fa-lightbulb"></i>;
    case "list-check":
      return <i className="fa-solid fa-list-check"></i>;
    case "map":
      return <i className="fa-regular fa-map"></i>;
    case "piggy-bank":
      return <i className="fa-solid fa-piggy-bank"></i>;
    case "plus":
      return <i className="fa-solid fa-plus"></i>;
    case "trash-can":
      return <i className="fa-regular fa-trash-can"></i>;
    case "user":
      return <i className="fa-regular fa-user"></i>;
    case "wallet":
      return <i className="fa-solid fa-wallet"></i>;
    case "info":
      return <i className="fa-solid fa-circle-info"></i>;
    case "xmark":
      return <i className="fa-solid fa-xmark"></i>;
    case "file-export":
      return <i className="fa-solid fa-file-export text-sm"></i>;
    case "chevron-right":
      return <i className="fa-solid fa-chevron-right"></i>;
    case "chevron-left":
      return <i className="fa-solid fa-chevron-left"></i>;
    case "eye":
      return <i className="fa-regular fa-eye"></i>;
    case "download":
      return <i className="fas fa-download"></i>;
    case "gear":
      return <i className="fa-solid fa-gear"></i>;
    case "camera":
      return <i className="fa-solid fa-camera"></i>;
    case "arrows-spin":
      return <i className="fa-solid fa-arrows-spin"></i>;
    case "upload":
      return <i className="fa-solid fa-upload"></i>;
    case "pen-to-square":
      return <i className="fa-regular fa-pen-to-square"></i>;
    case "check":
      return <i className="fa-solid fa-check check-icon"></i>;
    case "circle-download":
      return <i className="fa-solid fa-download icon-circle"></i>;
    case "circle-camera":
      return <i className="fa-solid fa-camera icon-circle"></i>;
    case "circle-upload":
      return <i className="fa-solid fa-upload icon-circle"></i>;
    case "search":
      return <i className="fa-solid fa-magnifying-glass"></i>;
    case "users":
      return <i className="fa-solid fa-users"></i>;
    case "user-tie":
      return <i className="fa-solid fa-user-tie"></i>;
    case "spinner":
      return <i className="fa-solid fa-circle-notch"></i>;
    case "triangle-exclamation":
      return <i className="fa-solid fa-triangle-exclamation warning"></i>;
    case "circle-exclamation":
      return <i className="fa-solid fa-circle-exclamation"></i>;
    case "phone":
      return <i className="fa-solid fa-phone"></i>;
    case "copy":
      return <i className="fa-regular fa-copy"></i>;
  }
}
