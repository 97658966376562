import { constant, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { useMemo } from "react";

export function useOnly(only?: O.Option<boolean>) {
    const shouldHonourOnly = useMemo(() => pipe(
        only,
        O.fromNullable,
        O.flatten,
        O.isSome,
      ), [only]);
      const resolvedValueForOnly = useMemo(() => pipe(
        only,
        O.fromNullable,
        O.flatten,
        O.fold(constant(false), identity),
      ), [only]);

      const shouldDisplay = shouldHonourOnly ? resolvedValueForOnly : true

      return shouldDisplay
}