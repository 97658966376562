import * as AccountSettings from "@/components/AccountSettings";
import * as ApplicationsList from "@/components/ApplicationsList";
import { LoaderMessages, LoaderView } from "@/components/basic/Loaders";
import * as Dashboard from "@/components/Dashboard";
import * as HomebuyerLanding from "@/components/HomebuyerLanding";
import * as HomePage from "@/components/HomePage";
import * as InactiveBorrower from "@/components/InactiveBorrower";
import * as Invite from "@/components/Invite";
import * as Login from "@/components/Login";
import * as LoInvite from "@/components/LoInvite";
import * as Summary from "@/components/Summary";
import * as UserManagement from "@/components/UserManagement";
import * as Wizard from "@/components/Wizard";
import { PageTypes } from "@/utils/page-types";
import { constant, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import Navigo from "navigo";
import { useMemo } from "react";
import {
  AccountSettingsAction,
  Action,
  ApplicationsListAction,
  DashboardAction,
  FlashMessageAdded,
  HomePageAction,
  InviteAction,
  LoanOfficerSignup,
  LoInviteAction,
  SaveSurveyRequested,
  SubmitApplicationRequested,
  SummaryAction,
  UserManagementAction,
  WizardAction
} from "./action";
import { FlashType, Model } from "./model";

export type AppProps = {
  model: Model;
  dispatch: (action: Action) => void;
  router: Navigo;
  onSignin: () => void;
};

export function AppContent({
  model,
  dispatch,
  router,
  onSignin,
}: AppProps): JSX.Element {
  const onCompleteApplication = useMemo(
    () => flow(SubmitApplicationRequested, dispatch),
    [dispatch],
  );

  const onFlashAdded = useMemo(
    () => (flash: FlashType) =>
      flow(constant(flash), FlashMessageAdded, dispatch)(),
    [dispatch],
  );
  switch (model.page.type) {
    case "ApplyNow": {
      const currentMatch = router.getCurrentLocation();
      const refLoanOfficer = pipe(
        currentMatch?.params?.referringLoanOfficerId,
        O.fromNullable,
        O.map((v) => Number(v)),
        O.chain((v) => (isNaN(v) ? O.none : O.some(v))),
      );
      return (
        <Wizard.View
          model={model.page.wizardModel}
          dispatch={flow(WizardAction, dispatch)}
          referringLoanOfficerId={refLoanOfficer}
          onFinish={(payload) =>
            flow(constant(payload), SaveSurveyRequested, dispatch)()
          }
          flashAdder={onFlashAdded}
          clientLogoUrl={pipe(
            model.clientStatus,
            O.fold(
              () => '',
              (clientStatus) => clientStatus.logoUrl
            )
          )}
        ></Wizard.View>
      );
    }
    case "Initializing":
      return <LoaderView loaderMessage={LoaderMessages.default} />;

    case "Dashboard": {
      return (
        <Dashboard.View
          model={model.page.dashboardModel}
          dispatch={flow(DashboardAction, dispatch)}
          onApplicationSelected={(id) => router.navigate(`summary/${id}`)}
        />
      );
    }
    case "Summary": {
      return (
        <Summary.View
          model={model.page.summaryModel}
          dispatch={flow(SummaryAction, dispatch)}
          completeApplication={onCompleteApplication}
          onFlashAdded={onFlashAdded}
        />
      );
    }

    case "ApplicationsList": {
      return (
        <ApplicationsList.View
          onApplicationSelected={(id) => router.navigate(`summary/${id}`)}
          model={model.page.applicationsListModel}
          dispatch={flow(ApplicationsListAction, dispatch)}
        />
      );
    }

    case "Branches": {
      return (
        <UserManagement.View
          model={model.page.branchesModel}
          dispatch={flow(UserManagementAction, dispatch)}
        />
      );
    }

    case "AccountSettings": {
      return (
        <AccountSettings.View
          model={model.page.accountSettingsModel}
          dispatch={flow(AccountSettingsAction, dispatch)}
          flashAdder={onFlashAdded}
        />
      );
    }

    case PageTypes.Login: {
      return <Login.View />;
    }

    //TODO: Remove after Loader Dev
    case PageTypes.Loader: {
      return <LoaderView loaderMessage="Loading" />;
    }

    case PageTypes.HomePage: {
      return (
        <HomePage.View
          model={model.page.homepageModel}
          onSignup={(email: string, phoneNo: string) =>
            flow(LoanOfficerSignup(email, phoneNo), dispatch)()
          }
          onSignin={onSignin}
          dispatch={flow(HomePageAction, dispatch)}
          flashAdder={onFlashAdded}
        />
      );
    }

    case "HomebuyerLanding": {
      const action = pipe(
        model.page.application,
        O.fold(constant("applynow"), (v) => `summary/${v.applicationId}`),
      );

      return (
        <HomebuyerLanding.View
          application={model.page.application}
          doesNotHaveApplication={model.page.doesNotHaveApplication}
          completeApplication={flow(constant(action), router.navigate)}
        />
      );
    }

    case "Invite":
      return (
        <Invite.View
          model={model.page.inviteModel}
          dispatch={flow(InviteAction, dispatch)}
          onApplicationSelected={flow(
            (id) => `/summary/${id}`,
            router.navigate,
          )}
        />
      );
      break;
    case "LoInvite":
      return (
        <LoInvite.View
          model={model.page.loInviteModel}
          dispatch={flow(LoInviteAction, dispatch)}
        />
      );
      break;
    case PageTypes.InactiveBorrower:
      return (
        <InactiveBorrower.View
          model={model.page.inactiveBorrowerModel}
        />
      );
      break;
    default:
      return <div>Loading...</div>;
  }
}
