import { Client, EqExtensionRole, ExtensionRole, User } from "@/data/client";
import * as A from "fp-ts/lib/Array";
import { constant, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import * as S from "fp-ts/Set";

export const isSuperUser = (user: User) =>
  S.elem(EqExtensionRole)(ExtensionRole.SuperUser)(user.roles);

export const isLoanOfficer = (user: User) =>
  S.elem(EqExtensionRole)(ExtensionRole.LoanOfficer)(user.roles);

export const isSupportUser = (user: User) =>
  S.elem(EqExtensionRole)(ExtensionRole.Support)(user.roles);

export const hasNoRoles = (user: User) => S.size(user.roles) == 0;

export const isBackOfficeUser = (user: User) =>
  isSuperUser(user) || isLoanOfficer(user);

export const fullName = (user: User) =>
  pipe(
    O.of(
      (firstName: string) => (lastName: string) => `${firstName} ${lastName}`,
    ),
    O.ap(user.firstName),
    O.ap(user.lastName),
    O.getOrElseW(() => user.email),
  );

export type UserTypes = "BO" | "TEAM";
export type LoginTypes = `SELF_SIGNUP` | "RESET" | "SIGNIN";
export type UserFlowTypes = `${UserTypes}_${LoginTypes}`;


export const loginAuthorityFor = (userflow: UserFlowTypes): string => {
  switch (userflow) {
    case "BO_RESET":
      return import.meta.env.VITE_BO_SIGNIN_RESET_AUTHORITY;
    case "BO_SELF_SIGNUP":
      return import.meta.env.VITE_BO_SIGNUP_AUTHORITY;
    case "BO_SIGNIN":
      return import.meta.env.VITE_BO_SIGNIN_AUTHORITY;
    case "TEAM_RESET":
      return import.meta.env.VITE_LO_SIGNIN_RESET_AUTHORITY;
    case "TEAM_SELF_SIGNUP":
      return import.meta.env.VITE_LO_SIGNUP_AUTHORITY;
    case "TEAM_SIGNIN":
      return import.meta.env.VITE_LO_SIGNIN_AUTHORITY;
  }
};

export const getUserName = (user: User) =>
  pipe(
    O.of(
      (firstName: string) => (lastName: string) => `${firstName} ${lastName}`,
    ),
    O.ap(user.firstName),
    O.ap(user.lastName),
    O.getOrElseW(constant(user.email)),
  );

/* TODO: Convert this to FP */
export const getProfileId = (name: string) => {
  if (!name) {
    return "";
  }
  const spilttedName = name.split(" ");
  const hasLastName = spilttedName.length !== 1;
  if (hasLastName) {
    return `${spilttedName[0].charAt(0).toUpperCase()}${spilttedName[spilttedName.length - 1].charAt(0).toUpperCase()}`;
  } else {
    return spilttedName[0].charAt(0).toUpperCase();
  }
};
export const getAllTeams = (client: Client) =>
  pipe(
    client.branches,
    A.chain((branch) => branch.teams),
  );

export const getLoanOfficers = (client: Client) =>
  getClientUsers(client, isLoanOfficer);
export const getSupportUsers = (client: Client) =>
  getClientUsers(client, isSupportUser);

export const getClientUsers = (
  client: Client,
  userPredicate: (u: User) => boolean = () => true,
) =>
  pipe(
    client.branches,
    A.chain((branch) => branch.users.filter(userPredicate)),
    A.concat(
      pipe(
        client,
        getAllTeams,
        A.chain((team) => team.users.filter(userPredicate)),
      ),
    ),
    A.concat(client.users),
  );

export const getFullName = (user: User) =>
  pipe(
    O.of(
      (firstName: string) => (lastName: string) => `${firstName} ${lastName}`,
    ),
    O.ap(user.firstName),
    O.ap(user.lastName),
    O.getOrElseW(() => user.email),
  );
