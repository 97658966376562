import {
  Action,
  BlankHomepageRequested,
  InactiveBorrrowerPage,
  NewApplicationSelected,
  RouteLoading,
} from "@/app/action";
import { init, Model } from "@/app/model";

import { ClientStatusResponse, User } from "@/data/client";
import {
  CreateClientPayload,
  createClientPayloadCodec,
  SurveyPayload,
  surveyPayloadCodec,
} from "@/data/payload";
import { Api } from "@/utils/api";
import {
  getFromLocalStorage,
  LocalStoragetKeys,
  removeFromLocalStorage,
} from "@/utils/localstorage";
import { Effect, effectOfAction, noEffect } from "@/utils/reducerWithEffect";
import { ApiError, ExceptionError, RedirectState } from "@/utils/request";
import { AuthenticationResult } from "@azure/msal-common";
import * as E from "fp-ts/Either";
import { sequenceT } from "fp-ts/lib/Apply";
import { constant, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import * as T from "fp-ts/Task";
import * as TE from "fp-ts/TaskEither";
import { get } from "lodash-es";
import Navigo from "navigo";

export type NavigationMeta = {
  api: Api;
  userHasRole: boolean;
  user: User;
  clientStatus: ClientStatusResponse;
};

export function tryCompleteSurvey(
  result: AuthenticationResult | null,
  api: Api,
  router: Navigo,
): TE.TaskEither<ApiError, O.Option<[O.Option<User>, ClientStatusResponse]>> {
  return pipe(
    O.fromNullable(result),
    O.chain((authResult) => {
      const state = authResult.state;

      return pipe(
        getFromLocalStorage(LocalStoragetKeys.BorrowerNonce),
        O.chain((v) => {
          try {
            if (!state) {
              return O.none;
            }
            const stateValue: RedirectState = JSON.parse(
              decodeURIComponent(state),
            );
            if (v == stateValue.loginNonce) {
              return getFromLocalStorage(LocalStoragetKeys.BorrowerSignup);
            }
            return O.none;
          } catch {
            return O.none;
          }
        }),
        O.chain((v) => {
          const value = JSON.parse(v);
          const surveyPayload = surveyPayloadCodec.decode(value);
          let returnValue: O.Option<SurveyPayload> = O.none;

          //TODO: To check for roles claims absense.
          if (E.isRight(surveyPayload)) {
            if (
              surveyPayload.right.primaryApplicant.email ===
              get(authResult.idTokenClaims, "emails.[0]")
            ) {
              returnValue = O.some(surveyPayload.right);
            }
          }

          removeFromLocalStorage(LocalStoragetKeys.BorrowerNonce);
          removeFromLocalStorage(LocalStoragetKeys.BorrowerSignup);

          return returnValue;
        }),
      );
    }),
    TE.fromOption(() => ExceptionError("")),
    TE.chain((payload) => {
      router.navigate("homebuyer-landing", { callHooks: true });
      return api.saveSurvey(payload);
    }),
    TE.chain(() => userInfoAndClientStatus(api)),
    TE.map((v) => {
      removeFromLocalStorage(LocalStoragetKeys.BorrowerSignupInProgress);
      return v;
    }),
  );
}

export function tryCompleteBackOfficeSignin(
  result: AuthenticationResult | null,
  api: Api,
  _: Navigo,
): TE.TaskEither<ApiError, O.Option<[O.Option<User>, ClientStatusResponse]>> {
  return pipe(
    O.fromNullable(result),
    O.chain((authResult) => {
      const state = O.tryCatch(
        () =>
          JSON.parse(
            decodeURIComponent(authResult.state as string),
          ) as RedirectState,
      );

      return pipe(
        getFromLocalStorage(LocalStoragetKeys.BackOfficeNonce),
        O.chain((savedNonce) => {
          if (
            pipe(
              state,
              O.map((v) => v.loginNonce),
              O.getOrElse(constant("")),
            ) == savedNonce
          ) {
            return getFromLocalStorage(LocalStoragetKeys.BackOfficeSignup);
          }
          return O.none;
        }),
        O.chain((value) => {
          const createClientPayload = createClientPayloadCodec.decode(
            JSON.parse(value),
          );
          let returnValue: O.Option<CreateClientPayload> = O.none;

          //TODO: To check for roles claims absense.
          if (E.isRight(createClientPayload)) {
            if (
              createClientPayload.right.email ===
              get(authResult.idTokenClaims, "emails.[0]")
            ) {
              returnValue = O.some(createClientPayload.right);
            }
          }

          removeFromLocalStorage(LocalStoragetKeys.BackOfficeNonce);
          removeFromLocalStorage(LocalStoragetKeys.BackOfficeSignup);

          return returnValue;
        }),
      );
    }),
    TE.fromOption(() => ExceptionError("")),
    TE.chain((payload) =>
      pipe(
        () => {
          return api.createClient(payload)();
        },
        TE.map(({ createPaymentCustomerSubscriptionResult }) => ({
          secret:
            createPaymentCustomerSubscriptionResult.paymentMethodSetupClientSecret,
        })),
      ),
    ),
    TE.chain(({ secret }) => {
      sessionStorage.setItem(LocalStoragetKeys.BackOfficeStripeSecret, secret);
      // router.navigate("/");
      return userInfoAndClientStatus(api);
    }),
  );
}

export const userInfoAndClientStatus = (
  api: Api,
): TE.TaskEither<ApiError, O.Option<[O.Option<User>, ClientStatusResponse]>> =>
  pipe(
    sequenceT(TE.ApplyPar)(api.tryGetUser, api.getClientStatus()),
    TE.chain(([user, clientStatus]) => {
      return pipe(user, (user) =>
        O.isNone(user)
          ? TE.of<ApiError, O.Option<[O.Option<User>, ClientStatusResponse]>>(
              O.some([O.none, { ...clientStatus }]),
            )
          : TE.of<ApiError, O.Option<[O.Option<User>, ClientStatusResponse]>>(
              O.some([O.some(user.value), { ...clientStatus }]),
            ),
      );
    }),
  );

export const initModel = (
  user: O.Option<User>,
  clientStatus: O.Option<ClientStatusResponse>,
): [Model, Effect<Action>] => [
  {
    ...init(),
    user: user,
    clientStatus: clientStatus,
  },
  noEffect,
];

export const toLogin = (
  router: Navigo,
  clientStatusResponse: O.Option<ClientStatusResponse>,
): T.Task<[Model, Effect<Action>]> => {
  const currentMatch = router.getCurrentLocation();

  let nextPage = RouteLoading("LoginInitiated");

  switch (currentMatch.route.path) {
    case "applynow":
      nextPage = NewApplicationSelected();
      break;
    case "":
      nextPage = BlankHomepageRequested();
      break;
  }

  return T.of([
    { ...init(), clientStatus: clientStatusResponse },
    effectOfAction(nextPage),
  ]);
};

export const toInactive = (
  router: Navigo,
  status: ClientStatusResponse,
): T.Task<[Model, Effect<Action>]> => {
  const currentMatch = router.getCurrentLocation();

  let isBorrowerRoute = false;

  switch (currentMatch.route.path) {
    case "applynow":
    case "invite":
      isBorrowerRoute = true;
      break;

    default:
      if ((currentMatch.route.path as string).startsWith("summary")) {
        if (currentMatch.params?.type == "borrower") {
          isBorrowerRoute = true;
        }
      }

      break;
  }

  return T.of([
    { ...init(), clientStatus: O.none },
    effectOfAction(InactiveBorrrowerPage(isBorrowerRoute, status)),
  ]);
};
