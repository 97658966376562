import { ChildProps } from "@/utils/reducerWithEffect";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import * as P from "fp-ts/lib/Predicate";
import { flow, identity, pipe } from "fp-ts/lib/function";
import { useMemo } from "react";

import { Started } from "@/utils/asyncOperationStatus";
import {
  Button,
  Checkbox,
  Col,
  InputField,
  Label,
  Row,
  Select,
} from "../basic";
import { ContactLoanOfficer } from "../domain/ContactLoanOfficer/view.tsx";
import {
  Action,
  EndDateChanged,
  ExistingEmployerMapped,
  IsCurrentlyWorkingWithEmployerToggled,
  IsReceivingComissionToggled,
  SaveEmployerAction,
  StartDateChanged,
  YearsWorkedChanged,
} from "./action.ts";
import {
  EmmployerSelectOption as EmployerSelectOption,
  Model,
  result,
} from "./model.ts";

export type Props = ChildProps<Model, Action> & {
  dispatch: (action: Action) => void;
  onEmployerResolved: () => void;
  modalId?: string;
  modalRef?: HTMLDivElement | null
};

export function View({
  model,
  dispatch,
  onEmployerResolved,
  modalRef
}: Props): JSX.Element {
  const { missingEmployer } = model;

  const [validationResult, otherErrors] = useMemo(() => result(model), [model]);

  const saveEmployer = useMemo(
    () =>
      pipe(
        validationResult,
        O.fromEither,
        O.filter(() => O.isNone(otherErrors)),
        O.map((validationResult) =>
          flow(
            Started,
            SaveEmployerAction(validationResult, onEmployerResolved),
            dispatch,
          ),
        ),
      ),
    [validationResult, dispatch, otherErrors, onEmployerResolved],
  );

  const {
    primaryApplicant: {
      personalInformation: { fullName },
    },
    employers,
  } = model;

  const availableEmployers = useMemo(() => {
    return pipe(
      employers,
      O.map((employers) =>
        pipe(
          employers,
          A.filter(
            (v) =>
              O.isSome(v.employerInfo) &&
              v.employerId != missingEmployer.employerId,
          ),
        ),
      ),
      O.chain((v) => (v.length >= 1 ? O.some(v) : O.none)),
    );
  }, [missingEmployer, employers]);
  return (
    <Col width="2/4">
      <Col padding="xs">
        <Col padding="xs">
          <div className="header">
            We see that <span className="text-bold">{fullName.raw}</span> worked
            for <span className="text-bold">{missingEmployer.name}</span>.
            Please answer these questions regarding this employer to help us
            complete your loan application.
          </div>
        </Col>
        {model.isErrorWhileSavingEmployer ? <ContactLoanOfficer /> : null}

        <div className="grid-item-2">
          {pipe(
            availableEmployers,
            O.map((v) => (
              <Col padding="sm" gap="xs">
                <Label>
                  Is this employer a duplicate of another employer? This can
                  happen if your employer has changed names, been acquired, or
                  possible uses the name of their payroll company on pay-stubs
                  or W2's.
                </Label>
                <Select
                  placeholder="Choose from the existing employers below"
                  options={pipe(
                    v,
                    A.map((v) => ({
                      name: v.name,
                      employerId: v.employerId,
                    })),
                    A.insertAt(0, { name: "No", employerId: -1 }),
                    O.getOrElse(() => [] as EmployerSelectOption[]),
                  )}
                  container={modalRef}
                  selected={model.mappedEmployer}
                  valueEq={{ equals: (a, b) => a.employerId == b.employerId }}
                  renderLabel={(v) =>
                    v.employerId > 0
                      ? `Same as {${v.name}}`
                      : `No - this is a different employer`
                  }
                  onChange={flow(ExistingEmployerMapped, dispatch)}
                />
                {pipe(
                  model.mappedEmployer,
                  O.filter((v) => v.employerId > 0),
                  O.map((v) => {
                    return (
                      <>
                        <Button
                          type="primary"
                          onClick={O.some(
                            flow(
                              Started,
                              SaveEmployerAction(
                                {
                                  employerId: model.missingEmployer.employerId,
                                  applicantId:
                                    model.missingEmployer.applicantId,
                                  name: model.missingEmployer.name,
                                  employerInfo: {
                                    linkedEmployerId: v.employerId,
                                    employerId:
                                      model.missingEmployer.employerId,
                                  },
                                },
                                onEmployerResolved,
                              ),
                              dispatch,
                            ),
                          )}
                        >
                          Choose this employer
                        </Button>
                      </>
                    );
                  }),
                  O.getOrElse(() => <></>),
                )}
              </Col>
            )),
            O.getOrElse(() => <></>),
          )}

          {pipe(
            availableEmployers,
            O.fromPredicate(
              (v) =>
                O.isNone(v) ||
                (O.isSome(model.mappedEmployer) &&
                  model.mappedEmployer.value.employerId == -1),
            ),
            O.map(() => (
              <ResolverForm
                {...{
                  model,
                  dispatch,
                  otherErrors,
                  saveEmployer,
                  onEmployerResolved,
                }}
              />
            )),
            O.getOrElse(() => <></>),
          )}
        </div>
      </Col>
    </Col>
  );
}

function ResolverForm({
  model,
  dispatch,
  otherErrors,
  saveEmployer,
}: Props & {
  otherErrors: O.Option<string>;
  saveEmployer: O.Option<() => void>;
}): JSX.Element {
  return (
    <Col>
      <div className="grid-2 gap-md padding-sm">
        <Col className="column-span-2" gap="xs">
          <InputField
            className="column-span-2"
            label="Start Date"
            type="date"
            field={model.startDate}
            onChange={flow(StartDateChanged, dispatch)}
          />
          {pipe(
            otherErrors,
            O.map((v) => <>{v}</>),
            O.getOrElse(() => <></>),
          )}

          {pipe(
            model.isCurrentlyEmployedByThisEmployer,
            O.fromPredicate(P.not(identity)),
            O.chain(() => model.endDate),
            O.map((date) => (
              <InputField
                className="column-span-2"
                label="End Date"
                type="date"
                field={date}
                onChange={flow(EndDateChanged, dispatch)}
              />
            )),
            O.getOrElse(() => <></>),
          )}
        </Col>
        <Col className="column-span-2" gap="xs" alignHorizontal="space-between">
          <Checkbox
            className="column-span-2"
            label="Currently employed by this employer"
            checked={model.isCurrentlyEmployedByThisEmployer}
            onChange={flow(IsCurrentlyWorkingWithEmployerToggled, dispatch)}
          />
          <Checkbox
            className="column-span-2"
            label="Receive(d) Commission, Bonus, and/or Overtime on a regular basis"
            checked={model.receivedCommissionFromEmployer}
            onChange={flow(IsReceivingComissionToggled, dispatch)}
          />
        </Col>
        <InputField
          className="column-span-2"
          label="Number of years in this line of work"
          type="number"
          field={model.yearInLineOfWork}
          onChange={flow(YearsWorkedChanged, dispatch)}
        />
      </div>
      <Row alignHorizontal="center" padding="sm">
        <Button type="primary" onClick={saveEmployer}>
          Save Employer
        </Button>
      </Row>
    </Col>
  );
}
