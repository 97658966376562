import {
  Col,
  Input,
  InputField,
  Label,
  RadioButton,
  Select,
} from "@/components/basic";

import * as YearsAndMonth from "@/components/basic/YearsAndMonth";
import { LivingArrangementType } from "@/data/payload";
import { allUsStates, EqUSState, showUsState } from "@/data/states";
import { ChildProps } from "@/utils/reducerWithEffect";
import { constant, constFalse, flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Eq as EqString } from "fp-ts/lib/string";
import {
  Action,
  CityChanged,
  LivingArrangementTypeChanged,
  MonthsAtAddressChanged,
  RentAmountChanged,
  StateChanged,
  StreetChanged,
  UnitChanged,
  ZipChanged,
} from "./action";
import { Model } from "./model";
import { useState } from "react";

const livingArrangementOptions: LivingArrangementType[] = [
  "LivingRentFree",
  "Rent",
  "OwnAndMakingPayments",
  "OwnAndPaidInFull",
];

function showLivingArrangementType(
  livingArrangementType: LivingArrangementType,
): string {
  switch (livingArrangementType) {
    case "LivingRentFree":
      return "Living Rent Free";
    case "Rent":
      return "Rent";
    case "OwnAndMakingPayments":
      return "Own and Making Payments";
    case "OwnAndPaidInFull":
      return "Own and Paid in Full";
  }
}

export type Props = ChildProps<Model, Action> & {
  onCoApplicantSameAddressPrimaryInputToggled: O.Option<(choice: boolean) => void>;
  sameAddressAsApplicant?: O.Option<boolean>;
};

export function View(props: Props): JSX.Element {

  const [sameAddress, setSameAddress] = useState(props.sameAddressAsApplicant ? props.sameAddressAsApplicant : O.none);
  return (
    <>
      {pipe(
        props.onCoApplicantSameAddressPrimaryInputToggled,
        O.fold(
          () => <AddressDetailsView {...props}/>,
          (updateAddressAsSame) => pipe(
            props.sameAddressAsApplicant ? props.sameAddressAsApplicant : O.none,
            O.fold(
              () => <></>,
              () => (<> 
                <SameAddressAsApplicantView sameAsApplicant={O.isSome(sameAddress) ? sameAddress : O.none} onChange={(change) => {
                setSameAddress(O.some(change)); 
                updateAddressAsSame(change)
               }} />
                {
                  pipe(
                    sameAddress,
                    O.map((v) => v ? O.none : O.some(true)),
                    O.flatten,
                    O.fold(
                      () => <></>,
                      () =>  <AddressDetailsView {...props}/>
                    )
                  )
                }
              </>)
            )
          ),
        ),
      )}
    </>
  );
}

function AddressDetailsView({model, dispatch}: Props): JSX.Element {
  return (
    <div className="grid-2 gap-xs collapse-gap-md grid-item-2">
        <Col gap="xs">
          <Label>Your current living arrangement</Label>
          <Select
            options={livingArrangementOptions}
            selected={pipe(model.livingArrangementType, O.some)}
            valueEq={EqString}
            renderLabel={showLivingArrangementType}
            onChange={flow(LivingArrangementTypeChanged, dispatch)}
          />
        </Col>

        {model.livingArrangementType === "Rent" && (
          <InputField
            type="number"
            label="Rent Amount"
            field={model.monthlyRent}
            onChange={flow(RentAmountChanged, dispatch)}
            required={true}
          />
        )}

        <Col gap="xs" className="grid-item-2">
          <Label>Time Spent at this addres*</Label>
          <YearsAndMonth.View
            field={model.monthsAtAddress}
            onChange={flow(MonthsAtAddressChanged, dispatch)}
          />
        </Col>

        <InputField
          label="Street Address"
          field={model.street}
          onChange={flow(StreetChanged, dispatch)}
          required={true}
        />

        <Col gap="xs">
          <Label>Unit</Label>
          <Input
            type="text"
            value={pipe(model.unit, O.fold(constant(""), identity))}
            onChange={flow(UnitChanged, dispatch)}
          />
        </Col>

        <InputField
          label="City"
          field={model.city}
          onChange={flow(CityChanged, dispatch)}
          required={true}
        />

        <Col gap="xs">
          <Label>State *</Label>
          <Select
            options={allUsStates}
            selected={pipe(model.state.val, O.fromEither)}
            renderLabel={showUsState}
            valueEq={EqUSState}
            menuPlacement="top"
            onChange={flow(StateChanged, dispatch)}
          />
        </Col>

        <InputField
          label="Zip Code"
          field={model.zip}
          onChange={flow(ZipChanged, dispatch)}
          required={true}
        />
      </div>
  )
}
type SameAddressAsApplicantViewProps = {
  sameAsApplicant: O.Option<boolean>,
  onChange: (change: boolean) => void
}
function SameAddressAsApplicantView(props: SameAddressAsApplicantViewProps): JSX.Element {
  return (
    <Col gap="xs">
      <Label>
        Has your co-applicant's address been the same as the Primary Applicant's
        address for the last 2years?
      </Label>

      <RadioButton label="Yes" checked={pipe(props.sameAsApplicant, O.fold(constFalse, (v) => v == true)) } onChange={() => {
        props.onChange(true)
      }} />
      <RadioButton label="No" checked={pipe(props.sameAsApplicant, O.fold(constFalse, (v) => v == false)) } onChange={() => {
        props.onChange(false)
      }} />
    </Col>
  )
}
