import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import '@/assets/styles/index.css'
import '../staticwebapp.config.json'

ReactDOM.createRoot(document.getElementById('root')!).render(
  
    <App />
  
)
