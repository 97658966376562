import type * as t from "io-ts";
import { right } from "fp-ts/Either";
import { trueCodec } from "./codecs/true";

export type FormField<V, T=string> = {
  readonly val: t.Validation<V>;
  readonly raw: T ;
  readonly dirty: boolean;
};

export interface CheckboxFormField extends FormField<boolean, boolean> {
  readonly title: string;
  readonly description: string;
}

export const initFormField =
  <V>(decoderFn: (s: string) => t.Validation<V>) =>
  (raw: string): FormField<V> => ({ raw, val: decoderFn(raw), dirty: false });

export const initCheckboxFormField = (
  raw: boolean,
  title: string,
  description: string,
): CheckboxFormField => ({
  raw, 
  title,
  description,
  val: trueCodec.decode(raw),
  dirty: false,
});

export const updateCheckboxFormField = (
  field: CheckboxFormField,
  raw: boolean
): CheckboxFormField => ({
  ...field,
  raw, 
  val: trueCodec.decode(raw),
  dirty: true,
});

export const updateFormField =
  <V>(decoderFn: (s: string) => t.Validation<V>) =>
  (raw: string): FormField<V> => ({ raw, val: decoderFn(raw), dirty: true });

export const setFormField =
  <V>(encodeFn: (v: V) => string) =>
  (value: V): FormField<V> => ({
    raw: encodeFn(value),
    val: right(value),
    dirty: false,
  });

export const setDirty = <V>(field: FormField<V>): FormField<V> => ({
  ...field,
  dirty: true,
});

export type FieldDefinition<V> = {
  init: (raw: string) => FormField<V>;
  update: (raw: string) => FormField<V>;
};

export function fieldDef<V>(
  decoder: (r: string) => t.Validation<V>,
): FieldDefinition<V> {
  return {
    init: initFormField<V>(decoder),
    update: updateFormField<V>(decoder),
  };
}
