import "@/assets/styles/basic/Tables/pagination.css";
import "@/assets/styles/basic/Tables/table.css";
import { PageInfo } from "@/data/applicationsList";
import { identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { range } from "lodash-es";
import { Fragment, useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ReactPaginate from "react-paginate";
import { Button } from "../Button";
import { Col } from "../Col";
import { Icon } from "../Icon";
import { Row } from "../Row";
import { Label } from "../Label";

export type TableColumn<T> = {
  columnKey: string;
  header: JSX.Element;
  sortable?: boolean;
  headerContainerClassName?: string;
  view: (data: T) => JSX.Element;
};

export type SortDirection = "asc" | "desc";

export type TableProps<T> = {
  className?: string;
  data: T[];
  pageInfo: O.Option<PageInfo>;
  onPageChange?: (o: number) => void;
  itemKey: (data: T) => string | number;
  columns: TableColumn<T>[];
  isLoading: boolean;
  onSortedChanged?: (
    column: TableColumn<T>["columnKey"],
    sd: SortDirection,
  ) => void;
  noDataMessage?: string;
};

export function Table<T>(props: TableProps<T>): JSX.Element {
  const [sortedColumn, setSortedColumn] = useState<
    O.Option<[TableColumn<T>, boolean]>
  >(O.none);

  useEffect(() => {
    pipe(
      sortedColumn,
      O.map(([sortedColumn, isAsc]) => {
        pipe(
          props.onSortedChanged,
          O.fromNullable,
          O.map((_onSortChanged) =>
            _onSortChanged(sortedColumn.columnKey, isAsc ? "asc" : "desc"),
          ),
        );

        return [sortedColumn, isAsc];
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn]);

  const setStateOnSortChanged = useCallback(
    (column: TableColumn<T>) =>
      O.some(() => {
        setSortedColumn((v) =>
          O.some(
            pipe(
              v,
              O.map((v) => v[1]),
              O.fold(
                () => [column, true],
                (v) => [column, !v],
              ),
            ),
          ),
        );
      }),
    [],
  );

  const pagination = pipe(
    props.pageInfo,
    O.fold(
      () => <></>,
      ({ pageCount /* pageSize, totalCount */ }) => {
        const onPageChange = pipe(props.onPageChange, O.fromNullable);

        return (
          <Row gap="xs" alignHorizontal="center">
            <Col>
              <ReactPaginate
                containerClassName="pagination"
                breakLabel="..."
                nextLabel={<Icon type="chevron-right" />}
                onPageChange={(v) => pipe(onPageChange, O.flap(v.selected))}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel={<Icon type="chevron-left" />}
                renderOnZeroPageCount={null}
              />
            </Col>
          </Row>
        );
      },
    ),
  );

  return (
    <Col>
      <div
        className={props.className}
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${props.columns.length}, auto)`,
        }}
      >
        {props.columns.map((column) => {
          const { columnKey, header } = column;
          const sortedIcon = pipe(
            sortedColumn,
            O.map((sorted) =>
              columnKey == sorted[0].columnKey ? (
                <Icon type={sorted[1] ? "chevron-up" : "chevron-down"}></Icon>
              ) : (
                <></>
              ),
            ),
            O.getOrElse(() =>
              column.sortable ? <Icon type="chevron-down"></Icon> : <></>,
            ),
          );
          const onSortToggled = pipe(
            column.sortable,
            O.fromNullable,
            O.chain((v) => (v ? setStateOnSortChanged(column) : O.none)),
          );
          return (
            <Fragment key={`header-${columnKey}`}>
              <Button
                onClick={onSortToggled}
                type="flat"
                className={
                  column.headerContainerClassName
                    ? column.headerContainerClassName
                    : ""
                }
              >
                <Row
                  alignVertical="center"
                  alignHorizontal="center"
                  className={`${column.sortable ? "sortable" : ""}`}
                >
                  {header} <span className="text-xs">{sortedIcon}</span>
                </Row>
              </Button>
            </Fragment>
          );
        })}
        {pipe(
          props.isLoading,
          O.fromPredicate(identity),
          O.fold(
            () => {
              return props.data.length !== 0 ? (
                props.data.map((row) =>
                  props.columns.map((col) => (
                    <Fragment
                      key={`item-${col.columnKey}-${props.itemKey(row)}`}
                    >
                      {col.view(row)}
                    </Fragment>
                  )),
                )
              ) : (
                <>
                  <div
                    style={{
                      gridColumn: `1 / ${props.columns.length}`,
                      textAlign: "center",
                      marginTop: "var(--size-lgx)",
                    }}
                  >
                    <Label className="black text-mbold text-smd">
                      {pipe(
                        props.noDataMessage,
                        O.fromNullable,
                        O.fold(
                          () => "No data found.",
                          (v) => v,
                        ),
                      )}
                    </Label>
                  </div>
                </>
              );
            },
            () => [
              range(5).map((col) => (
                <div
                  key={`blank-item-${col}`}
                  style={{ gridColumn: `1 / ${props.columns.length}` }}
                  className="elevate-thin"
                >
                  <Skeleton height={"3em"} />
                </div>
              )),
            ],
          ),
        )}
      </div>
      <div className="page">{pagination}</div>
    </Col>
  );
}
