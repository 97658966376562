// import { useMemo } from "react";
import { Button, Col, Icon, Modal, Row, TogglePanel } from "@/components/basic";
import * as Documents from "@/components/Documents";
import { Started } from "@/utils/asyncOperationStatus";
import { ChildProps, ToLoadingProps } from "@/utils/reducerWithEffect";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import {
  AcceptExportWarning,
  Action,
  CancelManualDocumentSelection,
  ClearMissingEmployer,
  DocumentsAction,
  EditLoNotes,
  EmployerResolverAction,
  ExportDocuments,
  LoNotesChanged,
  ManualClassificationFileSelected,
  MoveToEditMode,
  PollEmployers,
  ResetExportStatus,
  SaveLoNotes,
  SetMissingEmployer,
  ShowExportWarning,
  StartManualDocumentSelection,
  SubmitManualClasification,
} from "./action";
import { ApplicationViewMode, Model } from "./model";

import "@/assets/styles/basic/Skeleton/style.css";
import * as ManualDocClassifier from "@/components/ManualDocClassifier";
import * as Review from "@/components/Review";
import { DocumentTypeId } from "@/data/applicationDocument";
import { ApplicationId, ApplicationStatusType, EmployersPayload } from "@/data/payload";
import { deferredToOption, mapDeferred } from "@/utils/deferred";
import { FormField } from "@/utils/formField";
import { FlashAdder } from "@/utils/page-types";
import { showApiError } from "@/utils/request";
import { ViewResolver } from "@/utils/viewResolver";
import { useEffect, useMemo, useRef } from "react";
import * as EmployerResolver from "../EmployerResolver";
import { EditApplication } from "./edit";
import { ClientStatus } from "@/data/client";

export type ContentProps = ChildProps<Model, Action> & {
  completeApplication: (applicationId: ApplicationId) => void;
  onFlashAdded: FlashAdder;
};

export type Props = ToLoadingProps<ContentProps, Model>;

export type SubPageProps = {
  onScrollToDocuments: O.Option<() => void>;
  children: JSX.Element;
  loNotesFormField: FormField<O.Option<string>>;
  onSaveLoNotes: () => void;
  onLoNotesChanged: (o: string) => void;
  isCreditCheckAuthorized: boolean;
  onExportApplication: O.Option<() => void>
};

export function View(props: Props): JSX.Element {
  return pipe(
    props.model,
    O.fold(
      () => (
        <>
          {ViewResolver({
            viewModes: [
              ["Default"],
              [
                "Mobile-Portrait",
                "Mobile-Landscape",
                "Tablet-Landscape",
                "Tablet-Portrait",
              ],
            ],
            resolvedContent: [<DesktopLoadingView />, <MobileLoadingView />],
          })}
        </>
      ),
      (model) => <ContentView {...{ ...props, model }}></ContentView>,
    ),
  );
}

function DesktopLoadingView(): JSX.Element {
  return (
    <>
      <Row
        gap="xs"
        className="w-100"
        alignVertical="center"
        alignHorizontal="space-between"
      >
        <Col padding="sm" className="summary-navigation w-25">
          <Button
            className="text-md padding-vertical-lg go-back-btn"
            onClick={O.some(() => window.history.back())}
            type="flat"
          >
            <Icon type="arrow-left" /> Go Back
          </Button>
        </Col>
        <Col alignHorizontal="right" width="2/4">
          <Row gap="xs">
            <div
              className="skeleton"
              style={{ width: "6em", height: "2em" }}
            ></div>
            <div
              className="skeleton"
              style={{ width: "4em", height: "2em" }}
            ></div>
            <div
              className="skeleton"
              style={{ width: "4em", height: "2em" }}
            ></div>
          </Row>
        </Col>
      </Row>
      <div className="grid-2" style={{ gridTemplateColumns: "25% 75%" }}>
        <Col gap="md" padding="md">
          <div className="skeleton" style={{ height: "2em" }}></div>
          <div className="skeleton" style={{ height: "2em" }}></div>
          <div className="skeleton" style={{ height: "2em" }}></div>
        </Col>
        <Col gap="md" padding="md">
          <div className="skeleton" style={{ height: "5em" }}></div>
          <div className="skeleton" style={{ height: "7em" }}></div>
          <div className="skeleton" style={{ height: "4em" }}></div>
        </Col>
      </div>
    </>
  );
}
function MobileLoadingView(): JSX.Element {
  return (
    <>
      <div>
        <Col gap="md" padding="md">
          <div className="skeleton" style={{ height: "4em" }}></div>
          <div className="skeleton" style={{ height: "16em" }}></div>
          <div className="skeleton" style={{ height: "20em" }}></div>
        </Col>
      </div>
    </>
  );
}
function ContentView(props: ContentProps): JSX.Element {
  const { model, dispatch } = props;

  const setMissingEmployerAction = flow(SetMissingEmployer, dispatch);
  const clearMissingEmployerAction = flow(ClearMissingEmployer, dispatch);
  const employerModalRef = useRef<HTMLDivElement>(null);

  const pollEmployeesAction = useMemo(
    () =>
      flow(
        Started,
        PollEmployers(model.applicationId, props.onFlashAdded),
        dispatch,
      ),
    [model.applicationId, props.onFlashAdded, dispatch],
  );

  const documentSectionContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    pollEmployeesAction();
    const intervalId = setInterval(() => {
      pollEmployeesAction();
    }, 1000 * 30);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (model.manualClassification.isOpen) {
      return;
    }
    const missingEmployer = pipe(
      model.employers,
      O.flatMap((employers: EmployersPayload[]) =>
        pipe(
          employers,
          A.filter((employer) => O.isNone(employer.employerInfo)),
          A.head,
        ),
      ),
      O.map((employer) => {
        if (O.isNone(model.employerResolverModel)) {
          setMissingEmployerAction(employer);
        }
        return employer;
      }),
    );

    if (O.isNone(missingEmployer) && O.isSome(model.employerResolverModel)) {
      clearMissingEmployerAction();
    }
  }, [
    model.employers,
    setMissingEmployerAction,
    clearMissingEmployerAction,
    model.employerResolverModel,
    model.manualClassification.isOpen,
  ]);

  const exportingModal = pipe(
    model.exportStatus,
    O.fromPredicate((exportStatus) =>
      ["InProgress", "Resolved"].includes(exportStatus.status),
    ),
    O.map((exportStatus) => {
      switch (exportStatus.status) {
        case "InProgress":
          return (
            <Modal onClose={O.none} title={O.some("Export Status")}>
              Please wait while we are exporting your application.
            </Modal>
          );
        case "Resolved": {
          const { value } = exportStatus;
          if (E.isLeft(value)) {
            return (
              <Modal
                onClose={O.some(flow(ResetExportStatus, dispatch))}
                title={O.some("Export Status")}
              >
                There was an error exporting the documents.
                {showApiError(value.left)}
              </Modal>
            );
          } else {
            return (
              <Modal
                onClose={O.some(flow(ResetExportStatus, dispatch))}
                title={O.some("Export Status")}
              >
                Successfuly exported the application!
              </Modal>
            );
          }
        }
        default:
          return <></>;
      }
    }),
    O.getOrElse(() => <></>),
  );

  const documentsSection = useMemo(
    () => (
      <>
        <div ref={documentSectionContainer}>
          <TogglePanel id="documents-section" title="Documents">
            <Documents.View
              model={model.documents}
              dispatch={flow(DocumentsAction, dispatch)}
              onManualClassification={(documentTypeId: DocumentTypeId) =>
                flow(StartManualDocumentSelection(documentTypeId), dispatch)
              }
            />
          </TogglePanel>
        </div>
      </>
    ),
    [dispatch, model.documents],
  );

  const employerResolverModalId = "modal-employer-resolver";

  const onScrollHandler = useMemo(
    () =>
      O.some(() =>
        documentSectionContainer.current?.scrollIntoView({
          behavior: "smooth",
        }),
      ),
    [],
  );

  const isCreditCheckAuthorized = useMemo(() => {
    return pipe(
      props.model.constentMeta,
      mapDeferred(
        E.map((meta) =>
          meta.find(({ consentType }) => consentType == "SoftCreditPull"),
        ),
      ),
      deferredToOption,
      O.chain(O.fromEither),
      O.chain(O.fromNullable),
      O.chain((v) =>
        O.fromNullable(
          props.model.primaryApplicant.consents.find(
            (c) => c.consentVersionId == v.consentVersionId,
          ),
        ),
      ),
      O.isSome,
    );
  }, [props.model.constentMeta, props.model.primaryApplicant.consents]);

  const onExportApplication = useMemo(
    () =>
      pipe(
        props.model.clientStatus,
        O.chain((clientStatus) =>
          clientStatus.status == ClientStatus.Restricted
            ? O.none
            : O.some(true),
        ),
        O.chain(() => {
          if (
            ![
              ApplicationStatusType.Complete,
              ApplicationStatusType.Exported,
            ].includes(model.application.status)
          ) {
            return O.some(flow(ShowExportWarning, dispatch));
          } else {
            return O.some(
              flow(Started, ExportDocuments(model.applicationId), dispatch),
            );
          }
        }),
      ),
    [
      dispatch,
      props.model.clientStatus,
      model.application.status,
      model.applicationId,
    ],
  );


  const pageContent = pipe(
    model.mode,
    O.fromPredicate((v) => v == ApplicationViewMode.Edit),
    O.fold(
      () => (
        <>
          <Review.View
            user={model.user}
            payload={model.application}
            onEdit={flow(MoveToEditMode, dispatch)}
            isCreditCheckAuthorized={isCreditCheckAuthorized}
            onEditLoNotes={flow(EditLoNotes, dispatch)}
            onSaveLoNotes={flow(Started, SaveLoNotes, dispatch)}
            isEditingLoNotes={model.isEditingLoNotes}
            onExportApplication={onExportApplication}

            onLoNotesChanged={(v: string) =>
              flow(constant(v), LoNotesChanged, dispatch)()
            }
            loNotesFormField={model.loNotesFormField}
            document={model.documents}
            constentMeta={model.constentMeta}
            onScrollToDocuments={onScrollHandler}
          >
            {documentsSection}
          </Review.View>
        </>
      ),
      () => (
        <>
          <EditApplication
            onScrollToDocuments={onScrollHandler}
            {...props}
            onLoNotesChanged={(v: string) =>
              flow(constant(v), LoNotesChanged, dispatch)()
            }
            isCreditCheckAuthorized={isCreditCheckAuthorized}
            loNotesFormField={model.loNotesFormField}
            onExportApplication={onExportApplication}
            onSaveLoNotes={flow(Started, SaveLoNotes, dispatch)}
          >
            {documentsSection}
          </EditApplication>
        </>
      ),
    ),
  );

  return (
    <>
      {pipe(
        model.employerResolverModel,
        O.map((employerResolverModel) => {
          return (
            <Modal ref={employerModalRef} onClose={O.none} title={O.some("We need some information about your employer")} id={employerResolverModalId}>
              <EmployerResolver.View
                model={employerResolverModel}
                dispatch={flow(EmployerResolverAction, dispatch)}
                onEmployerResolved={flow(ClearMissingEmployer, dispatch)}
                modalId={employerResolverModalId}
                modalRef={employerModalRef.current}
              />
            </Modal>
          );
        }),
        O.getOrElse(() => <></>),
      )}
      {
        //show warning on 'incomplete' status exports
        pipe(
          model.exportWarningShown,
          O.chain((v) =>
            pipe(
              v,
              O.fromPredicate((v) => !v),
            ),
          ),
          O.map(() => (
            <Modal
              onClose={O.none}
              title={O.some(
                "This application is incomplete. You may continue with export, but much of the data will be missing from the exported data",
              )}
            >
              <Button
                type="primary"
                onClick={O.some(flow(AcceptExportWarning, dispatch))}
              >
                Ok
              </Button>
            </Modal>
          )),
          O.getOrElse(() => <></>),
        )
      }
      {pipe(
        model.manualClassification?.isOpen,
        O.fromPredicate(identity),
        O.map(() => (
          <ManualDocClassifier.View
            model={model.manualClassification}
            onFileSelect={(file: O.Option<File>) =>
              flow(ManualClassificationFileSelected(file), dispatch)
            }
            onDocumentUpload={(file: File) =>
              flow(Started, SubmitManualClasification(file), dispatch)
            }
            onClose={O.some(flow(CancelManualDocumentSelection, dispatch))}
          />
        )),
        O.getOrElse(() => <></>),
      )}
      {exportingModal}
      {
        <>
          <Row>{pageContent}</Row>
        </>
      }
    </>
  );
}
