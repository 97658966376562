import { ChildProps } from "@/utils/reducerWithEffect";
import { Model } from "./model";
import {
  Action,
  ActiveDutyMilitaryChanged,
  AtLeast2YearsChanged,
  NotWorkingChanged,
  OtherChanged,
  OtherIncomeDescriptionChanged,
  RetirementDisabilityVeteranIncomeChanged,
  RetirementIncomeChanged,
  SelfEmployedChanged,
  SelfEmploymentTypeChanged,
  SelfEmploymentW2WagesChanged,
  SocialSecurityDisabilityChanged,
  W2WagesChanged,
  WorkScheduleTypeChanged,
} from "./action";
import {
  Checkbox,
  Col,
  Input,
  Label,
  RadioButton,
  Row,
} from "@/components/basic";
import {
  SelfEmploymentType,
  selfEmploymentTypes,
  showIncomeSourceType,
  showSelfEmploymentType,
  WorkScheduleType,
  workScheduleTypes,
} from "@/data/payload";
import { constant, flow } from "fp-ts/function";
import { useCallback } from "react";
import { ViewResolver } from "@/utils/viewResolver";

export type Props = ChildProps<Model, Action>;

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  return (
    <div className="grid-2 gap-xs">
      <Label
        className={ViewResolver({
          viewModes: [["Default"], ["Mobile-Portrait", "Mobile-Landscape"]],
          resolvedContent: ["column-span-2", ""],
        })}
      >
        What are your sources of income?
      </Label>

      <Checkbox
        label={showIncomeSourceType("W2Wages")}
        checked={model.w2Wages}
        onChange={flow(W2WagesChanged, dispatch)}
      />

      {model.w2Wages ? <W2WagesChildView {...props} /> : <div></div>}

      <Checkbox
        label={showIncomeSourceType("SelfEmployed")}
        checked={model.selfEmployed}
        onChange={flow(SelfEmployedChanged, dispatch)}
      />

      {model.selfEmployed ? <SelfEmployedChildView {...props} /> : <div></div>}

      <Checkbox
        label={showIncomeSourceType("Active_Duty_Military")}
        checked={model.activeDutyMilitary}
        onChange={flow(ActiveDutyMilitaryChanged, dispatch)}
      />

      <div></div>

      <Checkbox
        label={showIncomeSourceType("Retirement_Income")}
        checked={model.retirementIncome}
        onChange={flow(RetirementIncomeChanged, dispatch)}
      />

      <div></div>

      <Checkbox
        label={showIncomeSourceType("Retired_Disability_Veteran")}
        checked={model.retirementDisabilityVeteran}
        onChange={flow(RetirementDisabilityVeteranIncomeChanged, dispatch)}
      />

      <div></div>

      <Checkbox
        label={showIncomeSourceType("Social_Security_Disability")}
        checked={model.socialSecurityDisability}
        onChange={flow(SocialSecurityDisabilityChanged, dispatch)}
      />

      <div></div>

      <Checkbox
        label="Not Working"
        checked={model.notWorking}
        onChange={flow(NotWorkingChanged, dispatch)}
      />
      
      <div></div>

      <Checkbox
        label={showIncomeSourceType("Other_Alimony_ChildSupport")}
        checked={model.other}
        onChange={flow(OtherChanged, dispatch)}
      />

      {model.other ? <><div></div> <OtherIncomDescriptionView {...props} /> </>: <div></div>}
    </div>
  );
}
/* Had to seperate into different comp since ViewResolver uses hooks and hence will result in render error otherwise*/
function W2WagesChildView(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const workScheduleRadioButton = useCallback(
    (type: WorkScheduleType) => (
      <RadioButton
        key={type}
        label={type}
        checked={model.workSchedule.type === type}
        onChange={flow(constant(type), WorkScheduleTypeChanged, dispatch)}
      />
    ),
    [model.workSchedule.type, dispatch],
  );

  return (
    <Col
      gap="md"
      padding="fallback"
      className={ViewResolver({
        viewModes: [["Default"], ["Mobile-Portrait", "Mobile-Landscape"]],
        resolvedContent: ["", "padleft-md"],
      })}
    >
      <Col gap="xs">
        <Label>What was your employment type?</Label>
        <Row gap="xs" className="flex-wrap">{workScheduleTypes.map(workScheduleRadioButton)}</Row>
      </Col>

      {(model.workSchedule.type === "PartTime" ||
        model.workSchedule.type === "Both") && (
        <Col gap="xs">
          <Label>
            Has the part time employment been continuous for the last 2 years?
          </Label>
          <Row gap="xs">
            <RadioButton
              label="Yes"
              checked={model.workSchedule.atLeast2Years}
              onChange={flow(constant(true), AtLeast2YearsChanged, dispatch)}
            />
            <RadioButton
              label="No"
              checked={!model.workSchedule.atLeast2Years}
              onChange={flow(constant(false), AtLeast2YearsChanged, dispatch)}
            />
          </Row>
        </Col>
      )}
    </Col>
  );
}

function SelfEmployedChildView(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const selfEmploymentTypeRadioButton = useCallback(
    (type: SelfEmploymentType) => (
      <RadioButton
        key={type}
        label={showSelfEmploymentType(type)}
        checked={model.selfEmployment.type === type}
        onChange={flow(constant(type), SelfEmploymentTypeChanged, dispatch)}
      />
    ),
    [model.selfEmployment.type, dispatch],
  );

  return (
    <Col
      gap="sm"
      padding="fallback"
      className={ViewResolver({
        viewModes: [["Default"], ["Mobile-Portrait", "Mobile-Landscape"]],
        resolvedContent: ["", "padleft-md"],
      })}
    >
      <Col gap="xs">
        <Label>What type of self employment?</Label>
        <Row gap="xs" className="flex-wrap">
          {selfEmploymentTypes.map(selfEmploymentTypeRadioButton)}
        </Row>
      </Col>
      {model.selfEmployment.type === "Corporation" ? (
        <Col gap="xs">
          <Label>Do you receive W2 wages from the corporation?</Label>
          <Row gap="xs">
            <RadioButton
              label="Yes"
              checked={model.selfEmployment.w2Wages}
              onChange={flow(
                constant(true),
                SelfEmploymentW2WagesChanged,
                dispatch,
              )}
            />
            <RadioButton
              label="No"
              checked={!model.selfEmployment.w2Wages}
              onChange={flow(
                constant(false),
                SelfEmploymentW2WagesChanged,
                dispatch,
              )}
            />
          </Row>
        </Col>
      ) : (
        <></>
      )}
    </Col>
  );
}

function OtherIncomDescriptionView(props: Props): JSX.Element {
  const { model, dispatch } = props;

  return (
    <Col
      gap="xs"
      padding="fallback"
      className={ViewResolver({
        viewModes: [["Default"], ["Mobile-Portrait", "Mobile-Landscape"]],
        resolvedContent: ["", "padleft-md"],
      })}
    >
      <Label>Description</Label>
      <Input
        type="text"
        value={model.otherDescription}
        onChange={flow(OtherIncomeDescriptionChanged, dispatch)}
        placeholder="Alimony, Child Support etc."
      />
    </Col>
  );
}
