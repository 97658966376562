import * as t from "io-ts";
import { Duration } from "luxon";

interface ValidDurationBrand {
  readonly ValidDuration: unique symbol;
}

function isDuration(d: unknown): d is Duration {
  return d instanceof Duration;
}

export const durationCodec = new t.Type<Duration, Duration, unknown>(
  "Duration",
  isDuration,
  (u, c) => (isDuration(u) ? t.success(u) : t.failure(u, c, "Invalid time")),
  t.identity
);

export const validDurationCodec = t.brand(
  durationCodec,
  (d): d is t.Branded<Duration, ValidDurationBrand> => d.isValid,
  "ValidDuration"
);

export type ValidDuration = t.TypeOf<typeof validDurationCodec>;

export const validDurationCodecS = new t.Type<ValidDuration, string, string>(
  "ValidDurationS",
  validDurationCodec.is,
  (u, c) => {
    const d = Duration.fromISOTime(u);
    return validDurationCodec.is(d)
      ? t.success(d)
      : t.failure(u, c, "Invalid time");
  },
  a => a.toISOTime() || ""
);
