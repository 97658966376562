import '@/assets/styles/basic/Skeleton/style.css';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton, { SkeletonTheme} from "react-loading-skeleton"

//Parent element should have flex-grow:1 

export type SkeletonProps = {
    count?: number;
    circle?: boolean;
    containerClassName?: string
}
export function View(props: SkeletonProps): JSX.Element {
    return <SkeletonTheme>
    <Skeleton containerClassName={`flex-grow-1 height-100 ${props.containerClassName ?? ''}`} count={props.count ?? 1} circle={props.circle ?? false} />
  </SkeletonTheme>
}

