import { Label, Row } from "@/components/basic";
import { useId } from "react";

export type RadioButtonProps = {
  label: string;
  checked: boolean;
  onChange: () => void;
};

export function RadioButton(props: RadioButtonProps): JSX.Element {
  const { label, checked, onChange } = props;
  const id = `radio-${useId()}`;
  return (
    <Row gap="xs" className="cp" alignVertical="center">
      <input type="radio" checked={checked} onChange={onChange} id={id} className="cp"/>
      <Label className="black" htmlFor={id}>{label}</Label>
    </Row>
  );
}
