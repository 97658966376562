import uploadImgUrl from "@/assets/images/upload.svg";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import * as NEA from "fp-ts/lib/NonEmptyArray";
import * as O from "fp-ts/lib/Option";
import { useCallback, useId, useState } from "react";

import { pipe } from "fp-ts/lib/function";
import { Button, Col, Modal, Row } from "../basic/index.ts";
import { ContactLoanOfficer } from "../domain/ContactLoanOfficer/view.tsx";
import { Model } from "./model.ts";

export type Props = {
  onDocumentUpload: (file: File) => () => void;
  onFileSelect: (file: O.Option<File>) => () => void;
  model: Model;
  onClose: O.Option<() => void>;
};

export function View(props: Props): JSX.Element {
  return (
    <Modal onClose={props.onClose} title={O.some("Upload Documentation")}>
      {pipe(
        props.model.submissionsState,
        O.fromPredicate((v) => v.status == "Resolved" && E.isLeft(v.value)),
        O.fold(
          () => <></>,
          () => (
            <>
              <ContactLoanOfficer />
            </>
          ),
        ),
      )}
      <DocumentsUpload {...props} />
    </Modal>
  );
}

function DocumentsUpload(props: Props): JSX.Element {
  const uploadId = useId();

  const [fileStatus, setFileStatus] = useState<"selected" | "uploaded" | null>(
    null,
  );

  const dragOverHandler = useCallback((evt: React.DragEvent) => {
    evt.preventDefault();
  }, []);

  const dropHandler = useCallback((evt: React.DragEvent) => {
    evt.preventDefault();
    const file = pipe(
      O.fromNullable(evt.dataTransfer.items),
      O.chain((itemsList) =>
        pipe(
          Array.from(itemsList),
          A.filterMap((item) => O.fromNullable(item.getAsFile())),
          NEA.fromArray,
        ),
      ),
      O.alt(() => pipe(Array.from(evt.dataTransfer.files), NEA.fromArray)),
      O.map(NEA.head),
    );

    if (O.isSome(file)) {
      setFileStatus("selected");
      props.onFileSelect(file)();
    }
  }, [props]);

  const filesChangedHandler = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      pipe(
        evt.target.files,
        O.fromNullable,
        O.chain((fileList) => pipe(Array.from(fileList), NEA.fromArray)),
        O.map((files) => pipe(files, NEA.head)),
        (file) => props.onFileSelect(file)(),
        () => setFileStatus("selected"),
      );
    },
    [props],
  );

  return (
    <div
      className="drag-n-drop-area"
      onDragOver={dragOverHandler}
      onDrop={dropHandler}
    >
      <Col
        gap="xs"
        padding="xs"
        alignVertical="center"
        alignHorizontal="center"
      >
        <img src={uploadImgUrl} alt="Upload" />
        <Row alignHorizontal="center" alignVertical="center">
          <span className="text-center">
            Drop files here or{" "}
            <label htmlFor={uploadId} className="upload-label text-md">
              select a file
            </label>{" "}
            from your system. Accepted formats are PDF, RTF, JPEG and PNGs up to
            25MB.
          </span>
        </Row>
        <input
          id={uploadId}
          type="file"
          className="upload-input"
          multiple={false}
          onChange={filesChangedHandler}
        />
      </Col>
      <Row alignHorizontal="center" padding="xl">
        {pipe(
          props.model.file,
          O.map((selectedFile) => {
            return (
              <>
                <span>
                  {fileStatus === "uploaded" ? (
                    <>Your file has been uploaded</>
                  ) : (
                    <Button
                      type="primary"
                      onClick={pipe(
                        props.model.submissionsState,
                        O.fromPredicate((v) => v.status == "NotStarted"),
                        O.map(
                          () => () => props.onDocumentUpload(selectedFile)(),
                        ),
                      )}
                    >
                      {pipe(props.model.submissionsState.status, (state) => {
                        switch (state) {
                          case "NotStarted":
                            return <>Upload {selectedFile.name}</>;
                          case "InProgress":
                          case "Updating":
                            return <>Uploading {selectedFile.name}</>;
                          case "Resolved":
                            return <></>;
                        }
                      })}
                    </Button>
                  )}
                </span>
              </>
            );
          }),
          O.getOrElse(() => <></>),
        )}
      </Row>
    </div>
  );
}
