import "@/assets/styles/basic/Dropdown/dropdown.css";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Row } from "@/components/basic";

export type DropdownProps = {
  label: string;
  children: ReactNode;
  className?: string;
};

export function Dropdown(props: DropdownProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const refContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = (event: MouseEvent) => {
        const clickedInsideContainer =
          refContainer.current &&
          refContainer.current.contains(event.target as Node);

        if (!clickedInsideContainer) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [refContainer, isOpen]);

  return (
    <div
      ref={refContainer}
      className={`dropdown-container ${props.className || ""}`}
    >
      <Row
        gap="fallback"
        alignVertical="baseline"
        onClick={() => setIsOpen(!isOpen)}
        className={`${isOpen ? 'open' : ''} dropdown`}
      >
        {props.label}
        {isOpen ? (
          <i className="fa-solid fa-chevron-up"></i>
        ) : (
          <i className="fa-solid fa-chevron-down"></i>
        )}
      </Row>

      {isOpen && <div className="dropdown-values-container">{props.children}</div>}
    </div>
  );
}
