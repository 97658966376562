import {
  AccountSettingsAction,
  AccountSettingsSelected,
  Action,
  ApplicationSelected,
  ApplicationsListSelected,
  BlankHomepageRequested,
  DashboardSelected,
  HomebuyerLandingSelected,
  InactiveBorrrowerPage,
  InviteSelected,
  NewApplicationSelected,
  RouteLoading,
  SetupPaymentsPage,
  UnFinishedApplicationExitInitiated,
  UserManagementSelected
} from "@/app/action";
import { Model } from "@/app/model";
import * as AccountSettings from "@/components/AccountSettings";
import * as Invite from "@/components/Invite";
import { loadStripeTask } from "@/components/SignUp";
import * as UserManagement from "@/components/UserManagement";
import { BranchId, ClientStatus, TeamId, User, UserId } from "@/data/client";
import {
  applicationIdCodec,
  applicationModifyPayloadCodec,
  ApplicationModifyStatus,
  VerificationFlowType,
} from "@/data/payload";
import { Api, LoginTriggerer } from "@/utils/api";
import { Started } from "@/utils/asyncOperationStatus";
import { NotStarted } from "@/utils/deferred";
import {
  getFromLocalStorage,
  LocalStoragetKeys,
  removeFromLocalStorage,
} from "@/utils/localstorage";
import {
  Effect,
  effectOfAction,
  effectsBatch,
} from "@/utils/reducerWithEffect";
import { ApiError } from "@/utils/request";
import {
  hasNoRoles,
  isBackOfficeUser,
  isSuperUser,
  loginAuthorityFor,
  UserFlowTypes,
} from "@/utils/user";
import { AuthenticationResult } from "@azure/msal-browser";
import * as E from "fp-ts/Either";
import {
  constant,
  constFalse,
  constNull,
  flow,
  identity,
  pipe,
} from "fp-ts/lib/function";
import { TaskEither } from "fp-ts/lib/TaskEither";
import * as O from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types";
import { isArray } from "lodash-es";
import { DateTime } from "luxon";
import Navigo, { Match } from "navigo";
import { Dispatch } from "react";
import { RouteNames } from "./routes";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DoneCallbackType = any;

const QueryParams = {
  LoginHint: "login_hint",
  PhoneNumber: "phone_number",
  Token: "token",
};

export function configNavigo(
  routerInstance: Navigo,
  dispatch: Dispatch<Action>,
  model: Model,
  isAppInited: boolean,
  api: Api,
  performUserFlow: LoginTriggerer,
  _: TaskEither<ApiError, AuthenticationResult | null>,
) {
  let triedUser: O.Option<User>;
  const _isSuperUser =
    O.isSome(model.user) &&
    pipe(
      model.user,
      O.map((v) => isSuperUser(v)),
      O.fold(constFalse, identity),
    );
  const _isBackOfficeUser =
    O.isSome(model.user) &&
    pipe(
      model.user,
      O.map((v) => isBackOfficeUser(v)),
      O.fold(constFalse, identity),
    );

  const _isBorrower =
    O.isSome(model.user) &&
    pipe(
      model.user,
      O.map((v) => hasNoRoles(v)),
      O.fold(constFalse, identity),
    );

  const routeToBorrowerLanding = flow(
    constant(RouteNames.BORROWER_HOME),
    routerInstance.navigate,
  );
  const routeLoadingAction = (action: Action["type"]) =>
    flow(constant(action), RouteLoading, dispatch)();

  const redirectToUserFlow = async (
    done: DoneCallbackType,
    userFlow: UserFlowTypes,
    redirectPath: string,
  ) => {
    const token = pipe(await api.checkToken(), O.fromEither, O.flatten);
    if (O.isNone(token)) {
      const logo = pipe(
        model.clientStatus,
        O.map((v) => v.logoUrl),
        O.fold(constNull, identity),
      );
      const loginHint = userFlow.startsWith("BO_")
        ? {
            logoUrl: logo,
          }
        : {};

      await performUserFlow({
        authority: loginAuthorityFor(userFlow),
        state: { redirectPath },
        loginHint: encodeURIComponent(JSON.stringify(loginHint)),
      })();
      done();
    }
  };

  const goToHomepage = () => routerInstance.navigate("/");

  const performResetLoginFlow = (userFlow: UserFlowTypes) => {
    const url = new URL(window.location.href);
    const authority = loginAuthorityFor(userFlow);

    const getQueryParam = (param: string) =>
      pipe(
        url.searchParams.get(param),
        O.fromNullable,
        O.fold(constant(""), identity),
      );

    const state =
      userFlow == "BO_RESET"
        ? RouteNames.BORROWER_INVITE
        : RouteNames.INTERNAL_SIGNUP;

    performUserFlow({
      authority,
      state: { redirectPath: `/${state}` },
      loginHint: pipe(
        [
          getQueryParam(QueryParams.LoginHint),
          getQueryParam(QueryParams.PhoneNumber),
        ],
        ([email, phoneNo]) =>
          encodeURIComponent(JSON.stringify({ email, phoneNo })),
      ),
    })();
  };

  const onlyHomeBuyerRoute =
    (pageName: Action["type"]) => async (done: DoneCallbackType) => {
      if (!isAppInited) {
        done(false);
        return;
      }
      await redirectToUserFlow(done, "BO_SIGNIN", RouteNames.BORROWER_HOME);
      if (O.isNone(model.user)) {
        routeLoadingAction(pageName);
        done(false);
      } else if (_isBackOfficeUser) {
        routerInstance.navigate(RouteNames.DASHBOARD);
        done(false);
      } else {
        flow(constant(O.none), HomebuyerLandingSelected, dispatch)();
        done();
      }
    };

  const onlyBackofficeRoute =
    (_: Action["type"]) => async (done: DoneCallbackType) => {
      if (!isAppInited) {
        done(false);
        return;
      }
      await homePageIfNoToken(done);

      const triedUser = model.user;
      if (O.isNone(triedUser)) {
        routeLoadingAction("LoginInitiated");
        done(false);
        return;
      } else if (isBackOfficeUser(triedUser.value)) {
        done();
        return;
      } else {
        routeToBorrowerLanding();
        done(false);
        return;
      }
    };

  /**
   *
   * @param done Returns to homepage if not logged in.
   * @returns
   */
  const homePageIfNoToken = async (done: DoneCallbackType) => {
    const token = pipe(await api.checkToken(), O.fromEither, O.flatten);
    if (O.isNone(token)) {
      done();
      goToHomepage();
      return;
    }
  };
  routerInstance.destroy();

  //Root
  routerInstance.on(() => {}, {
    async before(done) {
      await homePageIfNoToken(done);
      routeLoadingAction("LoginInitiated");
      triedUser = model.user;
      if (O.isNone(triedUser)) {
        //Home page.
        flow(BlankHomepageRequested, dispatch)();
        done();
        return;
      } else {
        if (isBackOfficeUser(triedUser.value)) {
          const secret = pipe(
            sessionStorage.getItem(LocalStoragetKeys.BackOfficeStripeSecret),
            O.fromNullable,
          );

          if (O.isSome(secret)) {
            sessionStorage.removeItem(LocalStoragetKeys.BackOfficeStripeSecret);
            const stripeInstance = pipe(await loadStripeTask(), O.fromEither);

            if (O.isSome(stripeInstance)) {
              flow(
                constant({
                  stripe: stripeInstance.value,
                  clientSecret: pipe(secret.value as NonEmptyString),
                }),
                SetupPaymentsPage,
                dispatch,
              )();
              done();
              return;
            } else {
              //TODO: Display error to the user.
            }
          } else {
            routerInstance.navigate(RouteNames.DASHBOARD);
            done(true);
            return;
          }
        }
        routerInstance.navigate(RouteNames.BORROWER_HOME);
        done(false);
      }
    },
    already() {
      if (O.isSome(model.user)) {
        if (_isBackOfficeUser) {
          routerInstance.navigate(RouteNames.DASHBOARD);
          return;
        }
        routerInstance.navigate(RouteNames.BORROWER_HOME);
        return;
      }
    },
  });
  // /applynow
  routerInstance.on(
    "/applynow",
    () => {
      flow(NewApplicationSelected, dispatch)();
    },
    {
      before(done) {
        if (!isAppInited) {
          done(false);
          return;
        }

        if (O.isNone(model.user)) {
          done();
          return;
        }
        if (
          O.isSome(model.clientStatus) &&
          pipe(
            model.clientStatus,
            O.map((s) => s.status),
            O.map((v) => v == ClientStatus.Deprecated),
            O.fold(constFalse, identity),
          )
        ) {
          const clientStatus = model.clientStatus.value;
          flow(
            () => InactiveBorrrowerPage(_isBorrower, clientStatus),
            dispatch,
          )();
          done(false);
          return;
        } else if (_isBorrower) {
          routerInstance.navigate(RouteNames.BORROWER_HOME);
          done(false);
          return;
        }
        done();
      },
    },
  );

  routerInstance.on(RouteNames.INTERNAL_SIGNUP, () => {}, {
    async before(done) {
      const user = pipe(await pipe(api.tryGetUser)(), O.fromEither, O.flatten);
      if (O.isNone(user)) {
        done();
        performResetLoginFlow("TEAM_RESET");
        return;
      } else if (
        pipe(
          user,
          O.map((v) => isBackOfficeUser(v)),
          O.getOrElse(constant(false)),
        )
      ) {
        routerInstance.navigate(RouteNames.DASHBOARD);
        done();
        return;
      }
      goToHomepage();
      done();
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inviteDone = async (done: any, match: Match, isAppInited: boolean) => {
    if (!isAppInited) {
      done();
      return;
    }

    const user = pipe(await pipe(api.tryGetUser)(), O.fromEither, O.flatten);
    if (O.isNone(user)) {
      const token = pipe(match?.params?.[QueryParams.Token], O.fromNullable);
      if (O.isSome(token)) {
        const userDetails = pipe(
          await api.verifyBorrowerToken(token.value)(),
          O.fromEither,
        );

        if (O.isSome(userDetails)) {
          const { flow, email, phone } = userDetails.value;
          let userFlow: O.Option<UserFlowTypes> = O.none;
          switch (flow) {
            case VerificationFlowType.reset:
              userFlow = O.some("BO_RESET");
              break;
            case VerificationFlowType.signIn:
              userFlow = O.some("BO_SIGNIN");
              break;
          }

          if (O.isSome(userFlow)) {
            const state =
              userFlow.value == "BO_RESET" ? RouteNames.BORROWER_INVITE : "/";
            performUserFlow({
              authority: loginAuthorityFor(userFlow.value),
              state: { redirectPath: `/${state}` },
              loginHint: encodeURIComponent(
                JSON.stringify({
                  email,
                  phoneNo: phone,
                  logoUrl: pipe(userDetails.value.clientLogoUrl, O.toNullable),
                }),
              ),
            })();
            done();
            return;
          }
        }
      }
      done();
      return;
    } else if (
      pipe(
        user,
        O.map((v) => hasNoRoles(v)),
        O.getOrElse(constant(false)),
      )
    ) {
      pipe(
        await api.getVerificationStatus(),
        O.fromEither,
        O.map((v) => {
          if (v.applicationIsVerified && !v.coApplicantSsnAndDobIsRequired) {
            routerInstance.navigate(RouteNames.BORROWER_HOME);
          } else {
            const inviteModel: Invite.Model = {
              verificationStatus: v,
              consents: NotStarted(),
              tips: NotStarted(),
              form: null as unknown as Invite.ConsentForm,
              ssnConsentVerificationFailed: false,
              user,
            };
            const effects = effectsBatch([
              effectOfAction(Invite.ConsentsInit()),
              effectOfAction(flow(Started, Invite.LoadTips)()),
            ]);

            flow(() => InviteSelected(inviteModel, effects), dispatch)();
          }
        }),
        O.fold(
          () => done(),
          () => done(),
        ),
      );
    } else {
      goToHomepage();
      done(false);
      return;
    }
    done();
  };

  routerInstance.on(RouteNames.BORROWER_INVITE, () => {}, {
    async before(done, match) {
      inviteDone(done, match, isAppInited);
    },
    already(match) {
      inviteDone(() => {}, match, isAppInited);
      console.log("alrady invite");
    },
  });

  routerInstance.on("login", () => {}, {
    async before(done) {
      if (O.isNone(model.user)) {
        await redirectToUserFlow(done, "TEAM_SIGNIN", RouteNames.DASHBOARD);
      } else if (_isBackOfficeUser) {
        routerInstance.navigate(RouteNames.DASHBOARD);
        done(false);
        return;
      } else {
        routerInstance.navigate(RouteNames.BORROWER_HOME);
        done(false);
      }
    },
  });

  routerInstance.on(
    RouteNames.BORROWER_HOME,
    () => {
      // flow(constant(O.none), HomebuyerLandingSelected, dispatch)();
    },
    {
      before: onlyHomeBuyerRoute("HomebuyerLandingSelected"),
    },
  );

  routerInstance.on(
    RouteNames.DASHBOARD,
    () => {
      flow(DashboardSelected, dispatch)(DateTime.now());
    },
    {
      before: onlyBackofficeRoute("LoginInitiated"),
    },
  );

  routerInstance.on(
    "/applications-list",
    () => {
      flow(ApplicationsListSelected, dispatch)();
    },
    {
      before: onlyBackofficeRoute("ApplicationsListSelected"),
    },
  );
  routerInstance.on("/summary/:id", () => {}, {
    async before(done, match) {
      if (!isAppInited) {
        done(false);
        return;
      }
      const userFlow: UserFlowTypes = pipe(
        match?.params?.type,
        O.fromNullable,
        O.foldW(constant("TEAM_SIGNIN"), (v) =>
          v == "borrower" ? "BO_SIGNIN" : "TEAM_SIGNIN",
        ),
      );
      await redirectToUserFlow(done, userFlow, match.url);
      const user = model.user;
      if (O.isNone(user)) {
        done(false);
        return;
      }

      routeLoadingAction("SummaryAction");
      const applicationId = pipe(
        match?.data?.id,
        O.fromNullable,
        O.map(Number),
        O.map((v) => applicationIdCodec.decode(v)),
        O.chain(O.fromEither),
      );

      if (O.isNone(applicationId)) {
        routeToBorrowerLanding();
        done(false);
        return;
      }

      flow(constant(applicationId.value), ApplicationSelected, dispatch)();
      done();
    },

    leave(done, match) {
      return pipe(
        getFromLocalStorage(LocalStoragetKeys.ApplicationModifyStatus),
        O.chain((payload) => {
          const value = JSON.parse(payload);
          const applicationModifyPayload =
            applicationModifyPayloadCodec.decode(value);
          if (E.isRight(applicationModifyPayload)) {
            return O.some(applicationModifyPayload.right);
          } else {
            return O.none;
          }
        }),
        O.fold(
          () => done(),
          (payload) => {
            if (payload.status === ApplicationModifyStatus.Modified) {
              console.log(window.location.href, match, "href");

              const url = isArray(match) ? match[0].url : match.url;
              const windowUrl = window.location.pathname;
              if (windowUrl !== `/summary/${payload.applicationId}`) {
                history.pushState(
                  null,
                  "",
                  `/summary/${payload.applicationId}`,
                );
              }
              flow(
                constant(pipe(url, O.fromNullable)),
                UnFinishedApplicationExitInitiated,
                dispatch,
              )();
              done(false);
            } else {
              removeFromLocalStorage(LocalStoragetKeys.ApplicationModifyStatus);
              done();
            }
          },
        ),
      );
    },
  });
  routerInstance.on(
    "/settings/:name",
    (match) => {
      if (O.isSome(model.user)) {
        flow(constant(model.user.value), AccountSettingsSelected, dispatch)();

        let subAction = AccountSettings.GeneralSelected;
        switch (match?.data?.name) {
          case "company":
            subAction = AccountSettings.CompanySettingsSelected;
            break;
          case "billing":
            subAction = AccountSettings.BillingSelected;
            break;
          case "general":
            subAction = AccountSettings.GeneralSelected;
            break;
          case "notifications":
            subAction = AccountSettings.NotificationsSelected;
            break;
        }
        flow(constant(subAction()), AccountSettingsAction, dispatch)();
      }
    },
    {
      async before(done, match) {
        if (!isAppInited) {
          done(false);
          return;
        }
        await homePageIfNoToken(done);
        const isAccessingSensitiveRoute = ["company", "billing"].includes(
          match?.data?.name || "",
        );
        if (O.isNone(model.user)) {
          routeLoadingAction("AccountSettingsSelected");
          done(false);
          return;
        } else if (isAccessingSensitiveRoute) {
          if (_isSuperUser) {
            done();
          } else if (_isBackOfficeUser) {
            done(false);
            routerInstance.navigate("/settings/general");
          } else {
            routeToBorrowerLanding();
          }
          done();
          return;
        }
        done();
      },
    },
  );

  routerInstance.on(
    "/org/branches",
    () => flow(UserManagementSelected, dispatch)(),
    {
      before: onlyBackofficeRoute("UserManagementSelected"),
    },
  );

  routerInstance.on(
    "org/branches/:branchId",
    (match) => {
      if (O.isSome(model.user)) {
        if (match?.data?.branchId) {
          const pendingEffects = (): Effect<UserManagement.Action> =>
            effectOfAction(
              UserManagement.BranchSelected(
                Number(match?.data?.branchId) as BranchId,
              ),
            );
          flow(constant(pendingEffects), UserManagementSelected, dispatch)();
        } else {
          flow(UserManagementSelected, dispatch)();
        }
      }
    },
    {
      before: onlyBackofficeRoute("UserManagementSelected"),
    },
  );
  routerInstance.on(
    "/org/branches/:branchId/teams/:teamId",
    (match) => {
      if (match?.data?.teamId) {
        const pendingEffects = (): Effect<UserManagement.Action> =>
          effectOfAction(
            UserManagement.TeamSelected(Number(match?.data?.teamId) as TeamId),
          );
        flow(constant(pendingEffects), UserManagementSelected, dispatch)();
      } else {
        flow(UserManagementSelected, dispatch)();
      }
    },
    {
      before: onlyBackofficeRoute("UserManagementSelected"),
    },
  );
  routerInstance.on(
    "org/branches/:branchId/teams/:teamId/users/:userId",
    (match) => {
      if (match?.data?.userId) {
        const pendingEffects = (): Effect<UserManagement.Action> =>
          effectOfAction(
            UserManagement.UserSelected(Number(match?.data?.userId) as UserId),
          );
        flow(constant(pendingEffects), UserManagementSelected, dispatch)();
      } else {
        flow(UserManagementSelected, dispatch)();
      }
    },

    {
      before: onlyBackofficeRoute("UserManagementSelected"),
    },
  );
}
