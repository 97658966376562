import { Checkbox, Col, Dropdown, Input, Label, Row } from "@/components/basic";
import {
  ApplicationsFilterPayload,
  EqApplicationStatus,
} from "@/data/applicationsList";
import { EqUserId, UserId } from "@/data/client";
import {
  ApplicationStatusType,
  ApplicationType,
  EqApplicationType,
} from "@/data/payload";
import { ChildProps } from "@/utils/reducerWithEffect";
import * as A from "fp-ts/lib/Array";
import { constant, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as S from "fp-ts/lib/Set";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Tag } from "../basic/Tag";
import {
  AssigneeFilterAllSelected,
  AssigneeFilterToggled,
  CreditCheckConsentFilterAllSelected,
  CreditCheckConsentFilterSelected,
  MortgageTypeFilterAllSelected,
  MortgageTypeFilterToggled,
  SearchChanged,
  StatusFilterAllSelected,
  StatusFilterToggled,
  SubmissionDateFilterAllSelected,
  SubmissionDateFilterSelected,
} from "./action";
import { Action, Model } from "./index";
import {
  ApplicationAssignees,
  CreditCheckConsentFilterOption,
  EqCreditCheckConsentFilterOption,
  EqSubmissionDateFilterOption,
  OrdApplicationTypeFilter,
  OrdStatusFilter,
  SubmissionDateFilterOption
} from "./types";

export type Props = ChildProps<Model, Action> & {
  deviceInfo: string;
  assignees: ApplicationAssignees;
  performSearch: (args: Partial<ApplicationsFilterPayload>) => void;
};

export function ClientTableFilters(props: Props): JSX.Element {
  const { model, dispatch, deviceInfo, assignees } = props;

  const [btnFilterOpen, setbtnFilterOpen] = useState(false);
  const btnFilterRefContainer = useRef<HTMLDivElement>(null);

  const isStatusSelected = useCallback(
    (status: ApplicationStatusType) =>
      pipe(
        model.statusFilter,
        O.filter(S.elem(EqApplicationStatus)(status)),
        O.isSome,
      ),
    [model.statusFilter],
  );

  const statusCheckbox = useCallback(
    (status: ApplicationStatusType) => (
      <Checkbox
        label={status}
        checked={isStatusSelected(status)}
        onChange={flow(constant(status), StatusFilterToggled, dispatch)}
      />
    ),
    [isStatusSelected, dispatch],
  );

  const isMortgageTypeSelected = useCallback(
    (mortgageType: ApplicationType) =>
      pipe(
        model.mortgageTypeFilter,
        O.filter(S.elem(EqApplicationType)(mortgageType)),
        O.isSome,
      ),
    [model.mortgageTypeFilter],
  );

  const mortgageTypeCheckbox = useCallback(
    (mortgageType: ApplicationType) => (
      <Checkbox
        label={mortgageType}
        checked={isMortgageTypeSelected(mortgageType)}
        onChange={flow(
          constant(mortgageType),
          MortgageTypeFilterToggled,
          dispatch,
        )}
      />
    ),
    [isMortgageTypeSelected, dispatch],
  );

  const isSubmissionDateOptionSelected = useCallback(
    (opt: SubmissionDateFilterOption) =>
      pipe(
        model.submissionDateFilter,
        O.filter((o) => EqSubmissionDateFilterOption.equals(o, opt)),
        O.isSome,
      ),
    [model.submissionDateFilter],
  );

  const isCreditCheckConsentOptionSelected = useCallback(
    (opt: CreditCheckConsentFilterOption) =>
      pipe(
        model.creditCheckFilter,
        O.filter((o) => EqCreditCheckConsentFilterOption.equals(o, opt)),
        O.isSome,
      ),
    [model.creditCheckFilter],
  );

  const submissionDateOptionCheckbox = useCallback(
    (opt: SubmissionDateFilterOption) => (
      <Checkbox
        label={opt}
        checked={isSubmissionDateOptionSelected(opt)}
        onChange={flow(constant(opt), SubmissionDateFilterSelected, dispatch)}
      />
    ),
    [isSubmissionDateOptionSelected, dispatch],
  );

  const creditCheckConsentCheckbox = useCallback(
    (opt: CreditCheckConsentFilterOption) => (
      <Checkbox
        label={opt}
        checked={isCreditCheckConsentOptionSelected(opt)}
        onChange={flow(
          constant(opt),
          CreditCheckConsentFilterSelected,
          dispatch,
        )}
      />
    ),
    [isCreditCheckConsentOptionSelected, dispatch],
  );

  const isAssigneeSelected = useCallback(
    (userId: UserId) =>
      pipe(model.assigneeFilter, O.filter(S.elem(EqUserId)(userId)), O.isSome),
    [model.assigneeFilter],
  );

  useEffect(() => {
    if (btnFilterOpen) {
      const handleClickOutside = (event: MouseEvent) => {
        const clickedInsideContainer =
          btnFilterRefContainer.current &&
          btnFilterRefContainer.current.contains(event.target as Node);

        if (!clickedInsideContainer) {
          setbtnFilterOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [btnFilterRefContainer, btnFilterOpen]);

  return (
    <>
      <Row alignVertical="center" gap="md">
        <Col alignVertical="stretch" className="pos-rel" grow={1}>
          <i className="fa-solid fa fa-search ac-search-icon"></i>
          <Input
            type="text"
            placeholder="Search"
            onChange={flow(SearchChanged, dispatch)}
            value={model.search}
            onSubmit={flow(
              () => model.search.trim(),
              O.fromPredicate((v) => v.length > 0),
              O.map((v) => props.performSearch({ searchQuery: O.some(v) })),
            )}
            className="ac-search-box"
          />
          {pipe(
            model.search.trim(),
            O.fromPredicate((v) => v.length > 0),
            O.fold(
              () => <></>,
              () => (
                <i
                  onClick={() => {
                    model.search = "";
                    props.performSearch({ searchQuery: O.none });
                  }}
                  className="fa-solid fa fa-circle-xmark ac-search-clear-icon"
                ></i>
              ),
            ),
          )}
        </Col>
        <Col>
          {pipe(
            O.some(deviceInfo),
            O.map((deviceInfo) => {
              switch (deviceInfo) {
                case "Mobile-Portrait":
                case "Tablet-Portrait":
                case "Tablet-Landscape":
                  return (
                    <div
                      className={`filter-container ${btnFilterOpen ? "filter-active" : ""}`}
                      ref={btnFilterRefContainer}
                      onClick={() => setbtnFilterOpen(!btnFilterOpen)}
                    >
                      <i className={`fa-solid fa-filter`}></i>
                      {btnFilterOpen && (
                        <Col
                          className="filter-values-container"
                          alignHorizontal="right"
                          alignVertical="center"
                        >
                          <Col padding="lg" className="filter-content" gap="md">
                            <Row>
                              <Col gap="xs">
                                <Label>Filter Status</Label>
                                <Checkbox
                                  label="All"
                                  checked={O.isNone(model.statusFilter)}
                                  onChange={flow(
                                    StatusFilterAllSelected,
                                    dispatch,
                                  )}
                                />
                                {Object.values(ApplicationStatusType).map(
                                  (v) => {
                                    return statusCheckbox(v);
                                  },
                                )}
                              </Col>
                            </Row>
                            {/* <Row>
                                <div className="divider"></div>
                              </Row> */}
                            <div className="divider"></div>
                            <Row>
                              <Col padding="xs" gap="xs">
                                <Label>Mortgage Type</Label>
                                <Checkbox
                                  label="All"
                                  checked={O.isNone(model.mortgageTypeFilter)}
                                  onChange={flow(
                                    MortgageTypeFilterAllSelected,
                                    dispatch,
                                  )}
                                />
                                {mortgageTypeCheckbox(ApplicationType.Purchase)}
                                {mortgageTypeCheckbox(ApplicationType.Equity)}
                                {mortgageTypeCheckbox(
                                  ApplicationType.Refinance,
                                )}
                              </Col>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                              <Col padding="xs" gap="xs">
                                <Label>Submission Date</Label>
                                <Checkbox
                                  label="All"
                                  checked={O.isNone(model.submissionDateFilter)}
                                  onChange={flow(
                                    SubmissionDateFilterAllSelected,
                                    dispatch,
                                  )}
                                />
                                {submissionDateOptionCheckbox(
                                  SubmissionDateFilterOption.Last24Hours,
                                )}
                                {submissionDateOptionCheckbox(
                                  SubmissionDateFilterOption.LastWeek,
                                )}
                                {submissionDateOptionCheckbox(
                                  SubmissionDateFilterOption.Last2Weeks,
                                )}
                                {submissionDateOptionCheckbox(
                                  SubmissionDateFilterOption.LastMonth,
                                )}
                              </Col>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                              <Col padding="xs" gap="xs">
                                <Label>Assignees</Label>
                                <Checkbox
                                  label="All"
                                  checked={O.isNone(model.assigneeFilter)}
                                  onChange={flow(
                                    AssigneeFilterAllSelected,
                                    dispatch,
                                  )}
                                />
                                {pipe(
                                  assignees,
                                  A.map(([userId, name]) => (
                                    <Fragment key={userId}>
                                      <Checkbox
                                        label={name}
                                        checked={isAssigneeSelected(userId)}
                                        onChange={flow(
                                          constant(userId),
                                          AssigneeFilterToggled,
                                          dispatch,
                                        )}
                                      />
                                    </Fragment>
                                  )),
                                )}
                              </Col>
                              </Row>
                              <div className="divider"></div>
                              <Row>
                              <Row>
                                <Col padding="xs" gap="xs">
                                  <Label>Credit Check Consent</Label>
                                  <Checkbox
                                    label="All"
                                    checked={O.isNone(model.creditCheckFilter)}
                                    onChange={flow(
                                      CreditCheckConsentFilterAllSelected,
                                      dispatch,
                                    )}
                                  />
                                  {creditCheckConsentCheckbox(
                                    CreditCheckConsentFilterOption.Authorized,
                                  )}
                                  {creditCheckConsentCheckbox(
                                    CreditCheckConsentFilterOption.NotAuthorized,
                                  )}
                                </Col>
                              </Row>
                            </Row>
                          </Col>
                        </Col>
                      )}
                    </div>
                  );
                default:
                  return <></>;
              }
            }),
            O.getOrElse(() => <></>),
          )}
        </Col>
      </Row>

      {pipe(
        O.some(deviceInfo),
        O.map((deviceInfo) => {
          switch (deviceInfo) {
            case "Mobile-Portrait":
            case "Tablet-Portrait":
            case "Tablet-Landscape":
              return <></>;
            case "Desktop-Landscape":
              return (
                <Row gap="sm" alignVertical="top">
                  <Col gap="xs">
                    <Dropdown label="Filter by status">
                      <Col>
                        <Col padding="xs" gap="xs">
                          <Checkbox
                            label="All"
                            checked={O.isNone(model.statusFilter)}
                            onChange={flow(StatusFilterAllSelected, dispatch)}
                          />
                          {Object.values(ApplicationStatusType).map((v) => {
                            return statusCheckbox(v);
                          })}
                        </Col>
                      </Col>
                    </Dropdown>
                    <Row
                      gap="xs"
                      wrap={true}
                      className="ac-statuss-filter-chips ml-left-sm"
                    >
                      {O.isSome(model.statusFilter) && (
                        <>
                          {pipe(
                            model.statusFilter.value,
                            S.toArray(OrdStatusFilter),
                            A.map((status) => {
                              return (
                                <Tag
                                  type="neutral"
                                  iconAlign="none"
                                  icon={O.some("info")}
                                  className="text-xs font-weight-500"
                                >
                                  {status}
                                  <i
                                    onClick={flow(
                                      constant(status),
                                      StatusFilterToggled,
                                      dispatch,
                                    )}
                                    className="fa-solid fa-close ml-left-xxs"
                                  ></i>
                                </Tag>
                              );
                            }),
                          )}
                        </>
                      )}
                    </Row>
                  </Col>

                  <Col gap="xs">
                    <Dropdown label="Filter by mortgage type">
                      <Col padding="xs" gap="xs">
                        <Checkbox
                          label="All"
                          checked={O.isNone(model.mortgageTypeFilter)}
                          onChange={flow(
                            MortgageTypeFilterAllSelected,
                            dispatch,
                          )}
                        />
                        {mortgageTypeCheckbox(ApplicationType.Purchase)}
                        {mortgageTypeCheckbox(ApplicationType.Equity)}
                        {mortgageTypeCheckbox(ApplicationType.Refinance)}
                      </Col>
                    </Dropdown>
                    <Row gap="xxs" wrap={true} className="ml-left-sm">
                      {O.isSome(model.mortgageTypeFilter) && (
                        <>
                          {pipe(
                            model.mortgageTypeFilter.value,
                            S.toArray(OrdApplicationTypeFilter),
                            A.map((appType) => {
                              return (
                                <Tag
                                  type="neutral"
                                  iconAlign="none"
                                  icon={O.some("info")}
                                  className="text-xs font-weight-500"
                                >
                                  {appType}
                                  <i
                                    onClick={flow(
                                      constant(appType),
                                      MortgageTypeFilterToggled,
                                      dispatch,
                                    )}
                                    className="fa-solid fa-close ml-left-xxs"
                                  ></i>
                                </Tag>
                              );
                            }),
                          )}
                        </>
                      )}
                    </Row>
                  </Col>

                  <Col gap="xs">
                    <Dropdown label="Filter by submission date">
                      <Col padding="xs" gap="xs">
                        <Checkbox
                          label="All"
                          checked={O.isNone(model.submissionDateFilter)}
                          onChange={flow(
                            SubmissionDateFilterAllSelected,
                            dispatch,
                          )}
                        />
                        {submissionDateOptionCheckbox(
                          SubmissionDateFilterOption.Last24Hours,
                        )}
                        {submissionDateOptionCheckbox(
                          SubmissionDateFilterOption.LastWeek,
                        )}
                        {submissionDateOptionCheckbox(
                          SubmissionDateFilterOption.Last2Weeks,
                        )}
                        {submissionDateOptionCheckbox(
                          SubmissionDateFilterOption.LastMonth,
                        )}
                      </Col>
                    </Dropdown>
                    <Row gap="xxs" wrap={true} className="ml-left-sm">
                      {O.isSome(model.submissionDateFilter) && (
                        <>
                          <Tag
                            type="neutral"
                            iconAlign="none"
                            icon={O.some("info")}
                            className="text-xs font-weight-500"
                          >
                            {model.submissionDateFilter.value}
                            <i
                              onClick={flow(
                                SubmissionDateFilterAllSelected,
                                dispatch,
                              )}
                              className="fa-solid fa-close ml-left-xxs"
                            ></i>
                          </Tag>
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col gap="xs">
                    <Dropdown label="Filter by assignee">
                      <Col padding="xs" gap="xs">
                        <Checkbox
                          label="All"
                          checked={O.isNone(model.assigneeFilter)}
                          onChange={flow(AssigneeFilterAllSelected, dispatch)}
                        />
                        {pipe(
                          assignees,
                          A.map(([userId, name]) => (
                            <Checkbox
                              key={userId}
                              label={name}
                              checked={isAssigneeSelected(userId)}
                              onChange={flow(
                                constant(userId),
                                AssigneeFilterToggled,
                                dispatch,
                              )}
                            />
                          )),
                        )}
                      </Col>
                    </Dropdown>
                  </Col>

                  <Col gap="xs">
                    <Dropdown label="Filter by credit authorization">
                      <Col padding="xs" gap="xs">
                        <Checkbox
                          label="All"
                          checked={O.isNone(model.creditCheckFilter)}
                          onChange={flow(
                            CreditCheckConsentFilterAllSelected,
                            dispatch,
                          )}
                        />
                        {creditCheckConsentCheckbox(
                          CreditCheckConsentFilterOption.Authorized,
                        )}
                        {creditCheckConsentCheckbox(
                          CreditCheckConsentFilterOption.NotAuthorized,
                        )}
                      </Col>
                    </Dropdown>
                    <Row gap="xxs" wrap={true} className="ml-left-sm">
                      {O.isSome(model.creditCheckFilter) && (
                        <>
                          <Tag
                            type="neutral"
                            iconAlign="none"
                            icon={O.some("info")}
                            className="text-xs font-weight-500"
                          >
                            {model.creditCheckFilter.value}
                            <i
                              onClick={flow(
                                CreditCheckConsentFilterAllSelected,
                                dispatch,
                              )}
                              className="fa-solid fa-close ml-left-xxs"
                            ></i>
                          </Tag>
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>
              );
            default:
              return <></>;
          }
        }),
        O.getOrElse(() => <></>),
      )}
    </>
  );
}
