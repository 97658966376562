import {
  ConsentItem,
  consentMethodCodec,
  ConsentMethods,
  ConsentVersionId,
  surveyConsentCodec,
  SurveyConsentItem
} from "@/data/consents";
import {
  ContactInformationPayload,
  SsnAndDateOfBirthPayload,
} from "@/data/surveyFlowPayload";
import {
  optionalSocialSecurityNumberCodecWithMessage,
  socialSecurityNumberCodecWithMessage,
  toISODate,
  validDateTimeCodecSWithMessage,
} from "@/utils/codecs";
import { NullFromEmptyString } from "@/utils/codecs/nullFromEmptyString";
import { PersonalDetailsForm } from "@/utils/consent-form-types";
import { Deferred, NotStarted } from "@/utils/deferred";
import { FormField, initFormField } from "@/utils/formField";
import { ApiResult } from "@/utils/request";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { constFalse, identity, pipe } from "fp-ts/function";
import { sequenceS } from "fp-ts/lib/Apply";
import { Separated } from "fp-ts/lib/Separated";
import * as O from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import * as t from "io-ts";
import { SurveyConsentFormItem } from "../Invite";
import { ValidErrorMessage } from "../basic";

export type ValidationError =
  | {
      type: "ApplicantNotFoundError";
    }
  | {
      type: "FormError";
      errors: t.Errors;
    };

export const ApplicantNotFoundError = (): ValidationError => ({
  type: "ApplicantNotFoundError",
});

export const FormError = (errors: t.Errors): ValidationError => ({
  type: "FormError",
  errors,
});

export type ConsentForm = {
  consentItems: SurveyConsentFormItem[];
  providedConsentForElectronicBusiness: Option<boolean>;
};

export type Model = {
  primaryApplicant: PersonalDetailsForm;
  coApplicant: Option<PersonalDetailsForm>;
  consents: Deferred<ApiResult<ConsentItem[]>>;
  form: ConsentForm;
  consentMethod: FormField<ConsentMethods>;
  isLoanOfficerInviting: Option<boolean>;
};

export const init =
  (
    primaryApplicantPayload: Option<SsnAndDateOfBirthPayload>,
    coApplicantPayload: Option<SsnAndDateOfBirthPayload>,
  ) =>
  (
    primaryApplicant: ContactInformationPayload,
    coApplicant: Option<ContactInformationPayload>,
    isLoanOfficerInviting: Option<boolean>,
  ): Model => {
    const _isLoanOfficerInviting = pipe(
      isLoanOfficerInviting,
      O.fold(constFalse, identity),
    );
    return {
      form: null as unknown as ConsentForm,
      consents: NotStarted(),
      consentMethod: initFormField(
        pipe(_isLoanOfficerInviting, (v) =>
          //Special case handled in action::adjustDecoderForConsentMethod
          v ? consentMethodCodec.decode : consentMethodCodec.decode,
        ),
      )(ConsentMethods.None),
      isLoanOfficerInviting: isLoanOfficerInviting,
      primaryApplicant: {
        name: primaryApplicant.fullName,

        socialSecurityNumber: pipe(
          primaryApplicantPayload,
          O.fold(
            () => "",
            ({ socialSecurityNumber }) => socialSecurityNumber,
          ),
          initFormField(socialSecurityNumberCodecWithMessage(ValidErrorMessage('social security number')).decode),
        ),
        dateOfBirth: pipe(
          primaryApplicantPayload,
          O.fold(
            () => "",
            ({ dateOfBirth }) =>
              NullFromEmptyString.is(dateOfBirth) ? "" : toISODate(dateOfBirth),
          ),
          (d) => initFormField(validDateTimeCodecSWithMessage(ValidErrorMessage('date')).decode)(d),
        ),
      },
      coApplicant: pipe(
        coApplicant,
        O.map(({ fullName }) => ({
          name: fullName,
          socialSecurityNumber: pipe(
            coApplicantPayload,
            O.fold(
              () => "",
              ({ socialSecurityNumber }) => socialSecurityNumber,
            ),
            (ssn) =>
              isLoanOfficerInviting
                ? initFormField(optionalSocialSecurityNumberCodecWithMessage(ValidErrorMessage('social security number')).decode)(ssn)
                : initFormField(socialSecurityNumberCodecWithMessage(ValidErrorMessage('social security number')).decode)(ssn),
          ),
          dateOfBirth: pipe(
            coApplicantPayload,
            O.fold(
              () => "",
              ({ dateOfBirth }) =>
                NullFromEmptyString.is(dateOfBirth)
                  ? ""
                  : toISODate(dateOfBirth),
            ),
            (dob) =>
              pipe(isLoanOfficerInviting, (isLo) =>
                isLo && NullFromEmptyString.is(dob)
                  ? initFormField(NullFromEmptyString.decode)(dob)
                  : initFormField(validDateTimeCodecSWithMessage(ValidErrorMessage('date')).decode)(dob),
              ),
          ),
        })),
      ),
    };
  };

export const result = (
  model: Model,
): [
  t.Validation<{
    primaryApplicant: SsnAndDateOfBirthPayload;
    coApplicant: Option<SsnAndDateOfBirthPayload>;
  }>,
  t.Validation<SurveyConsentItem[]>,
  Separated<ConsentVersionId[], boolean[]>,
  providedConsentForElectronicBusiness: Option<boolean>
] => {
  const arrayCodec = t.array(surveyConsentCodec);
  const consentItems = pipe(
    O.fromNullable(model.form),
    O.map((v) =>
      v.consentItems.map((v) => ({
        consentVersionId: v.consentVersionId,
        consentProvided: v.raw,
        method: pipe(
          model.consentMethod,
          O.fromPredicate(
            (consentMethod) => (O.isSome(model.isLoanOfficerInviting) && model.isLoanOfficerInviting.value === true) && consentMethod.raw !== ConsentMethods.None,
          ),
          O.fold(
            () => null,
            () => {
              return model.consentMethod.raw;
            },
          ),
        ),
      })),
    ),
    O.map((v) => arrayCodec.decode(v)),
    O.getOrElse(() => arrayCodec.decode([])),
  );
  const providedConsentForElectronicBusiness = pipe(
    O.fromNullable(model.form),
    O.map((v) => v.providedConsentForElectronicBusiness),
    O.getOrElse(()=> O.some(false))
  )

  const isLoanOfficerInviting = pipe(
    model.isLoanOfficerInviting,
    O.fold(constFalse, identity),
  );
  const validConsents = pipe(
    O.fromNullable(model.form),
    O.map(({ consentItems }) =>
      consentItems.map(
        ({ title, raw, requiredForBorrower, consentVersionId }) =>
          pipe(
            title,
            E.fromPredicate(
              () => {
                if (isLoanOfficerInviting) {
                  //for LO, all consents are optional
                  return true;
                } else {
                  return requiredForBorrower ? raw : true;
                }
              },
              () => consentVersionId,
            ),
            E.chain(() => {
              return E.fromPredicate(
                () => {
                  return isLoanOfficerInviting
                    ? E.isRight(model.consentMethod.val)
                    : model.consentMethod.raw == ConsentMethods.None;
                },
                () => consentVersionId,
              )(false);
            }),
          ),
      ),
    ),
    O.getOrElse((): E.Either<ConsentVersionId, boolean>[] => [E.of(false)]),
  );
  return [
    pipe(
      sequenceS(E.Apply)({
        primaryApplicant: sequenceS(E.Apply)({
          socialSecurityNumber: model.primaryApplicant.socialSecurityNumber.val,
          dateOfBirth: model.primaryApplicant.dateOfBirth.val,
          
        }),
        coApplicant: pipe(
          model.coApplicant,
          O.traverse(E.Applicative)(
            ({
              socialSecurityNumber,
              dateOfBirth,
            }): t.Validation<SsnAndDateOfBirthPayload> =>
              sequenceS(E.Apply)({
                socialSecurityNumber: socialSecurityNumber.val,
                dateOfBirth: dateOfBirth.val,
              }),
          ),
        ),
      }),
    ),
    consentItems,
    A.separate(validConsents),
    providedConsentForElectronicBusiness
  ];
};
