import * as AccountSettings from "@/components/AccountSettings";
import * as ApplicationsList from "@/components/ApplicationsList";
import * as Dashboard from "@/components/Dashboard";
import * as HomePage from "@/components/HomePage";
import * as Invite from "@/components/Invite";
import * as LoInvite from "@/components/LoInvite";
import * as InactiveBorrower from "@/components/InactiveBorrower";
import * as SignUp from "@/components/SignUp";
import * as SubmitApplication from "@/components/SubmitApplication";
import * as Summary from "@/components/Summary";
import * as UserManagement from "@/components/UserManagement";
import * as Wizard from "@/components/Wizard";
import * as BorrowerNotificationSettings from "@/components/BorrowerNotificationSettings";
import * as UnFinishedApplicationExit from "@/components/UnFinishedApplication";
import { ClientStatusResponse, User } from "@/data/client";
import { Application } from "@/data/payload";
import { Option } from "fp-ts/lib/Option";
import * as O from "fp-ts/Option";
import { PageTypes } from "../utils/page-types";
import { DateTime } from "luxon";

export type Page =
  | {
      type: "Initializing";
    }
  | {
      type: PageTypes.Login;
    }
  | {
      type: PageTypes.Signup;
      signUpModel: SignUp.Model;
    }
  | {
      type: PageTypes.ApplyNow;
      wizardModel: Wizard.Model;
    }
  | {
      type: "Summary";
      summaryModel: O.Option<Summary.Model>;
    }
  | { type: "HomebuyerLanding"; application: Option<Application> , doesNotHaveApplication: boolean}
  | {
      type: "Application";
      payload: Application;
    }
  | {
      type: "Invite";
      inviteModel: Invite.Model;
    }
  | {
      type: "LoInvite";
      loInviteModel: LoInvite.Model;
    }
  | {
      type: "ApplicationsList";
      applicationsListModel: ApplicationsList.Model;
    }
  | {
      type: "Dashboard";
      dashboardModel: Dashboard.Model;
    }
  | {
      type: "Branches";
      branchesModel: UserManagement.Model;
    }
  | {
      type: "AccountSettings";
      accountSettingsModel: AccountSettings.Model;
    }
  | {
      type: PageTypes.HomePage;
      homepageModel: HomePage.Model;
    }
  | {
      type: PageTypes.InactiveBorrower;
      inactiveBorrowerModel: InactiveBorrower.Model;
    }
  | {
      type: PageTypes.LoginRedirection;
      redirectionModel: {
        clientStatus: ClientStatusResponse;
        backOfficePayload: O.Option<SignUp.PaymentSetupInfo>;
        borrowerPayload: O.Option<[User, ClientStatusResponse]>;
      };
    }
  | {
      type: PageTypes.Loader;
    };
export type PageType = Page["type"];

export type DialogModel =
  | {
      type: "CompleteApplication";
      model: SubmitApplication.Model;
    }
  | {
      type: "BorrowerSettings";
      model: BorrowerNotificationSettings.Model;
    }
  |{
    type: "UnFinishedApplicationExit";
    model: UnFinishedApplicationExit.Model;
  }; 

export type DialogType = DialogModel["type"];

export type FlashType = {
  pushed: DateTime;
  type: "success" | "info" | "warning" | "error";
  message: string;
};

export type Model = {
  page: Page;
  user: Option<User>;
  flashMessages: FlashType[];
  clientStatus: Option<ClientStatusResponse>;
  dialog: Option<DialogModel>;
};

export const init = (): Model => ({
  page: { type: "Initializing" },
  user: O.none,
  flashMessages: [],
  dialog: O.none,
  clientStatus: O.none,
});

export const CompleteApplicationDialog = (
  model: SubmitApplication.Model,
): DialogModel => ({
  type: "CompleteApplication",
  model,
});

export const BorrowerNotificationsDialog = (
  model: BorrowerNotificationSettings.Model,
): DialogModel => ({
  type: "BorrowerSettings",
  model,
});

export const UnFinishedApplicationExitDialog = (
  model: UnFinishedApplicationExit.Model,
): DialogModel => ({
  type: "UnFinishedApplicationExit",
  model,
});
