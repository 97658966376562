import * as BranchManagement from "@/components/UserManagement/pages/BranchManagement";
import * as ClientManagement from "@/components/UserManagement/pages/ClientManagement";
import * as EditUser from "@/components/UserManagement/UsersListing/modals/EditUser";
import * as TeamManagement from "@/components/UserManagement/UsersListing/modals/TeamManagement";
import * as UsersListing from "@/components/UserManagement/UsersListing";
import * as ClientsListing from "@/components/ClientsListing";
import { ApplicationListingResponse, ApplicationListItem } from "@/data/applicationsList";
import { Client, ClientStatusResponse, User, UserId } from "@/data/client";
import { Started } from "@/utils/asyncOperationStatus";
import { Deferred, NotStarted } from "@/utils/deferred";
import { Effect, effectOfAction } from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import { flow } from "fp-ts/lib/function";
import { Option, none } from "fp-ts/lib/Option";
import { Action, GetCurrentClient } from "./action";

export type DialogModel =
  | {
      type: "NewUserDialog";
      dialogModel: EditUser.Model;
    }
  | {
      type: "EditUserDialog";
      userId: UserId;
      dialogModel: EditUser.Model;
    }
  | {
      type: "DeleteUserDialog";
      userId: UserId;
      assignedLoans: Deferred<ApiResult<ApplicationListingResponse>>;
      reassignmentConfirmed: boolean;
      reassignTo: Option<UserId>;
      deleteDone: boolean
    };
 
export const NewUserDialog = (dialogModel: EditUser.Model): DialogModel => ({
  type: "NewUserDialog",
  dialogModel,
});

export const EditUserDialog =
  (userId: UserId) =>
  (dialogModel: EditUser.Model): DialogModel => ({
    type: "EditUserDialog",
    userId,
    dialogModel,
  });

export const DeleteUserDialog = (
  userId: UserId,
  reassignTo: Option<UserId>,
): DialogModel => ({
  type: "DeleteUserDialog",
  userId,
  reassignTo,
  reassignmentConfirmed: false, 
  assignedLoans: NotStarted(),
  deleteDone: false
});

export type Page =
  | {
      type: "Client";
      model: ClientManagement.Model;
    }
  | {
      type: "Branch";
      model: BranchManagement.Model;
    }
  | {
      type: "Team";
      model: TeamManagement.Model;
    }
  | {
      type: "User";
      user: User;
      applications: Deferred<ApiResult<ApplicationListItem[]>>;
    };

export const reinitPage = (page: Page): Page => {
  switch (page.type) {
    case "Client":
      return { type: "Client", model: ClientManagement.init() };
    case "Branch":
      return {
        type: "Branch",
        model: BranchManagement.init(page.model.branchId),
      };
    case "Team":
      return { type: "Team", model: TeamManagement.init(page.model.teamId) };
    case "User":
      return page;
  }
};

export type Model = {
  client: Deferred<ApiResult<Client>>;
  page: Page;
  userManagementDialog: Option<DialogModel>;
  usersListing: UsersListing.Model;
  pendingEffects?: () => Effect<Action>;

  dialogApiResult: Deferred<ApiResult<unknown>>;
  clientsListing: ClientsListing.Model;
};

export const init = (
  clientStatus: Option<ClientStatusResponse>,
): [Model, Effect<Action>] => {
  return [
    {
      client: NotStarted(),
      page: { type: "Client", model: ClientManagement.init() },
      usersListing: UsersListing.init(),
      userManagementDialog: none,
      clientsListing: ClientsListing.init(clientStatus)[0],
      dialogApiResult: NotStarted(),
    },
    effectOfAction(flow(Started, GetCurrentClient)()),
  ];
};
