import '@/assets/styles/basic/Checkbox/checkbox.css';
import { Label } from "../Label";
import { Row } from "../Row";
import { AlignVertical } from '../constants';
import { useId } from 'react';

export type CheckboxProps = {
  className?: string;
  label: string;
  disabled?: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
  alignVertical?: AlignVertical;
  checkBoxMargin?: string
};

export function Checkbox(props: CheckboxProps): JSX.Element {
  const id = `checkbox-${useId()}`
  return (
    <Row gap="xs" className={`cp fit-content-h ${props?.className ? props.className : ''} `} alignVertical={props.alignVertical ? props.alignVertical : 'center'}>
      <input
        type="checkbox"
        disabled={props.disabled}
        id={id}
        checked={props.checked}
        onChange={(e) => props.onChange(e.target.checked)}
        className='cp'
      />
      
      <Label className="black" htmlFor={id}>{props.label}</Label>
    </Row>
  );
}
