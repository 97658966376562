import "@/assets/styles/Dashboard/dashboard.css";
import {
  Button,
  Col,
  Icon,
  IconType,
  Label,
  Modal,
  Row,
  Select,
} from "@/components/basic";
import * as ClientsListing from "@/components/ClientsListing";
import {
  CustomerProfileAverages,
  DashboardAnalytics,
  LivingArrangementsComposition,
  LoanTypesComposition,
  ReferralSourceItem,
  SurveyActivity,
} from "@/data/dashboardAnalytics";
import { ApplicationId } from "@/data/payload";
import { Started } from "@/utils/asyncOperationStatus";
import { EqSameDate } from "@/utils/codecs";
import { isResolved } from "@/utils/deferred";
import { ChildProps } from "@/utils/reducerWithEffect";
import { showApiError } from "@/utils/request";
import { RouterContext } from "@/utils/router-context";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import * as Eq from "fp-ts/Eq";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import * as SG from "fp-ts/lib/Semigroup";
import * as M from "fp-ts/Map";
import { Eq as EqNumber } from "fp-ts/number";
import * as O from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import { Eq as EqString } from "fp-ts/string";
import Highcharts from "highcharts";
import { last } from "lodash-es";
import { DateTime, Duration } from "luxon";
import { useContext, useEffect, useMemo, useRef } from "react";
import { ClientsListingAction } from "../ApplicationsList";
import { LoaderMessages, LoaderView } from "../basic/Loaders";
import * as Skeleton from "../basic/Loaders/skeleton";
import {
  Action,
  BranchChanged,
  CancelFirstLook,
  CancelInvites,
  IntervalChanged,
  LoadAnalytics,
} from "./action";
import {
  lineChart,
  pieChart,
  plotApplicationExports,
  plotLivigArrangementsComposition,
  plotLoanTypesComposition,
  plotNewSurveys,
} from "./chart-config";
import { InviteMembers } from "./invite-members";
import {
  analyticsIntervals,
  analyticsIntervalValues,
  EqAnalyticsInterval,
  Model,
  ReportingInterval,
} from "./model";
import { WelcomeView } from "./welcome-view";
import { Tag } from "../basic/Tag";
import { ViewResolver } from "@/utils/viewResolver";
import { RouteNames } from "@/routing/routes";

export type Props = ChildProps<Model, Action> & {
  onApplicationSelected: (applicationId: ApplicationId) => void;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const { router } = useContext(RouterContext);

  const applicationsCount = useMemo(() => {
    return pipe(
      model.clientsListing.applicationListReponse,
      O.fromPredicate((v) => v.status == "Resolved"),
      O.chain((v) => O.fromEither(v.value)),
      O.map((v) => v.totalCount),
      O.getOrElse(constant(NaN)),
    );
  }, [model.clientsListing.applicationListReponse]);

  const usersCount = pipe(
    props.model.client,
    O.fromPredicate((v) => v.status == "Resolved"),
    O.chain((v) => (E.isRight(v.value) ? O.some(v.value.right) : O.none)),
    O.map(
      (v) =>
        v.users.length +
        v.branches.reduce((usersCount, b) => usersCount + b.users.length, 0),
    ),
    O.getOrElse(constant(NaN)),
  );

  const shouldShowWelcomeView =
    applicationsCount == 0 && isResolved(model.clientsListing.applicationListReponse);

  const onInvitesDone = O.some(flow(CancelInvites, props.dispatch));

  if (O.isSome(model.invitedMembers)) {
    return <InviteMembers {...{ ...props, onInvitesDone }} />;
  }
  return (
    <Col gap="lg">
      {shouldShowWelcomeView ? (
        <Col padding="xxl" grow={1}>
          <WelcomeView {...{ usersCount, applicationsCount }} />
        </Col>
      ) : (
        <>
          <AnalyticsView {...props} />
          <Col gap="sm">
            <Row alignVertical="baseline">
              <Label className="action-70 text-smd text-mbold">
                Last 7 Days
              </Label>
              <Button
                type="flat"
                onClick={O.some(() =>
                  router.navigate(RouteNames.CLIENTS_LISTING),
                )}
              >
                See all
              </Button>
            </Row>
            <ClientsListing.View
              model={model.clientsListing}
              dispatch={flow(ClientsListingAction, dispatch)}
              showFilters={false}
              onApplicationSelected={(id) => props.onApplicationSelected(id)}
            />
          </Col>
        </>
      )}
      {props.model.isFirstLook && (
        <Modal
          onClose={O.some(flow(CancelFirstLook, props.dispatch))}
          title={O.some("Welcome to AppCatcher")}
        >
          <Col
            alignHorizontal="center"
            gap="sm"
            padding="sm"
            grow={0}
            width="2/4"
          >
            Congrats! You are all set now. Next, you can invite your team into
            AppCatcher to start processing applications!
            <Col gap="md">
              <Button
                className="width-auto"
                type="primary"
                onClick={O.some(flow(CancelFirstLook, props.dispatch))}
              >
                Go to Dashboard
              </Button>
              <Button
                type="secondary"
                onClick={O.some(() => router.navigate("org/branches"))}
              >
                Invite Team Members
              </Button>
            </Col>
          </Col>
        </Modal>
      )}
    </Col>
  );
}

function AnalyticsView(props: Props): JSX.Element {
  const { model, dispatch } = props;

  switch (model.analytics.status) {
    case "NotStarted":
      return (
        <Button
          type="primary"
          onClick={O.some(
            flow(
              Started,
              LoadAnalytics(DateTime.now().minus({ days: 7 }), DateTime.now()),
            ),
          )}
        >
          Load Analytics
        </Button>
      );
    case "InProgress":
    case "Updating":
      return (
        <LoaderView loaderMessage={LoaderMessages.default}>
          <PreLoadView></PreLoadView>
        </LoaderView>
      );
    case "Resolved":
      return pipe(
        model.analytics.value,
        E.fold(
          (error) => (
            <Col padding="sm" gap="sm" className="chart-container">
              <Label>Failed to load analytics</Label>
              {showApiError(error)}
            </Col>
          ),
          (analytics) => (
            <AnalyticsContent
              analytics={analytics}
              selectedBranch={model.selectedBranch}
              selectedInterval={model.selectedInterval}
              dispatch={dispatch}
            />
          ),
        ),
      );
  }
}

type AnalyticsContentProps = Pick<Props, "dispatch"> & {
  analytics: DashboardAnalytics;
  selectedBranch: Option<string>;
  selectedInterval: ReportingInterval;
};

function AnalyticsContent(props: AnalyticsContentProps): JSX.Element {
  const { dispatch } = props;
  const branches = useMemo(
    () =>
      pipe(
        props.analytics.customerProfileAverages.map((x) => x.branch),
        A.concat(props.analytics.livingArrangements.map((x) => x.branch)),
        A.concat(props.analytics.loanTypes.map((x) => x.branch)),
        A.concat(props.analytics.referralSources.map((x) => x.branch)),
        A.concat(props.analytics.surveyActivities.map((x) => x.branch)),
        A.uniq(EqString),
      ),
    [props.analytics],
  );

  return (
    <Col gap="sm">
      <Row gap="sm">
        <Select
          options={branches}
          selected={props.selectedBranch}
          valueEq={EqString}
          placeholder="Select branch"
          renderLabel={identity}
          onChange={flow(BranchChanged, dispatch)}
        />
        <Select
          options={analyticsIntervals}
          selected={O.some(props.selectedInterval)}
          valueEq={EqAnalyticsInterval}
          renderLabel={(key) => analyticsIntervalValues[key]}
          onChange={(key) =>
            flow(DateTime.now, IntervalChanged(key), dispatch)()
          }
        />
      </Row>
      <ChartView {...props} />
      <WorkItemsAndProflieView {...props} />
    </Col>
  );
}

type PieChartProps = {
  data: Highcharts.PointOptionsObject[];
  title: string;
};

function PieChart(props: PieChartProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null);
  // console.log(props.data, 'data');

  useEffect(() => {
    if (chartRef.current) {
      pieChart(chartRef.current)(props.title)(props.data);
    }
  }, [props.data, chartRef, props.title]);

  return (
    <Col
      padding="sm"
      grow={1}
      basis={ViewResolver({
        viewModes: [["Default"], ["Mobile-Landscape", "Mobile-Portrait"]],
        resolvedContent: ["20vw", "60vh"],
      })}
      refContainer={chartRef}
      className="chart-container pie-chart"
    >
      <></>
    </Col>
  );
}

type LineChartProps = {
  series: Highcharts.SeriesOptionsType[];
  title: string;
};

function LineChart(props: LineChartProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null);
  // console.log(props.series, 'data');
  useEffect(() => {
    if (chartRef.current) {
      lineChart(chartRef.current)(props.title)(props.series);
    }
  }, [props.series, chartRef, props.title]);

  return (
    <Col
      padding="sm"
      refContainer={chartRef}
      grow={2}
      basis={ViewResolver({
        viewModes: [["Default"], ["Mobile-Landscape", "Mobile-Portrait"]],
        resolvedContent: ["40vw", "60vh"],
      })}
      className={ViewResolver({
        viewModes: [
          ["Default"],
          [
            "Mobile-Landscape",
            "Mobile-Portrait",
            "Tablet-Portrait",
            "Tablet-Landscape",
          ],
        ],
        resolvedContent: ["chart-container max-width-80", "chart-container"],
      })}
    >
      <></>
    </Col>
  );
}

type StatisticsItemProps = {
  icon: IconType;
  title: string | JSX.Element;
  value: string | number | JSX.Element;
  type?: string;
};

function StatisticsItem(props: StatisticsItemProps): JSX.Element {
  return (
    <Row gap="xs" alignVertical="top">
      <div className="icon-container">
        <Icon type={props.icon} />
      </div>
      <Col className="mg-top-xs">
        <Label fontStyle="text-smd" className="text-mbold grey-100">
          {props.value}
        </Label>
        <Label fontStyle="text-sm" className="grey-100">
          {props.title}
        </Label>
        <Label fontStyle="text-xxs" className="grey-100">
          ({props.type})
        </Label>
      </Col>
    </Row>
  );
}

type CustomerStatisticsProps = {
  customerProfileAverages: CustomerProfileAverages;
};

function CustomerStatistics(props: CustomerStatisticsProps): JSX.Element {
  const comingSoonTag = (
    <>
      <Tag
        type="info"
        iconAlign="none"
        icon={O.none}
        fontStyle="text-xxs"
        className="font-weight-500"
      >
        Coming Soon
      </Tag>
    </>
  );
  return (
    <Col grow={1} padding="md" gap="md" className="chart-container">
      <Label className="text-smd black text-mbold">Customer Profile</Label>
      <div className="grid-3">
        <StatisticsItem
          icon="user"
          title="Age"
          value={props.customerProfileAverages.averageAge}
          type="Average"
        />
        <StatisticsItem
          icon="wallet"
          title="Income"
          value={pipe(
            props.customerProfileAverages.averageAnnualIncome,
            O.fold(
              () => "$$ -",
              (income) => `$${income.toLocaleString()}`,
            ),
          )}
          type="Average"
        />
        <StatisticsItem
          icon="map"
          title="location"
          value={props.customerProfileAverages.mostCommonLocation}
          type="Most Common"
        />
        <StatisticsItem
          icon="list-check"
          title={
            <>
              Survey completion{" "}
              <i className="fa-solid fa-circle-exclamation ml-left-xxs"></i>
            </>
          }
          value={pipe(
            props.customerProfileAverages.averageTimeToCompleteSurveyInSeconds,
            O.fold(
              () => "unknown",
              (seconds) =>
                Duration.fromObject({ seconds }).toFormat("mm 'min' ss 'secs'"),
            ),
          )}
          type="Most Common"
        />
        <StatisticsItem
          icon="file-lines"
          title={
            <>
              Documnet Upload{" "}
              <i className="fa-solid fa-circle-exclamation ml-left-xxs"></i>
            </>
          }
          value={pipe(
            props.customerProfileAverages
              .averageTimeToCompleteDocumentUploadInHours,
            O.fold(
              () => "unknown",
              // days, hours
              (hours) =>
                Duration.fromObject({ hours }).toFormat("d 'days' hh 'hours'"),
            ),
          )}
          type="Most Common"
        />
        <StatisticsItem
          icon="gauge"
          title="Credit score"
          value={pipe(
            props.customerProfileAverages.averageCreditScore,
            O.fold(
              () => <>{comingSoonTag}</>,
              (score) => (score ? score : <>{comingSoonTag}</>),
            ),
          )}
          type="Average"
        />
      </div>
    </Col>
  );
}

function PreLoadView(): JSX.Element {
  const normalView = (
    <Col padding="sm" gap="sm" grow={1}>
      <Row gap="sm" wrap basis="30vh">
        <Col grow={3}>
          <Skeleton.View></Skeleton.View>
        </Col>
        <Col grow={1}>
          <Skeleton.View></Skeleton.View>
        </Col>
        <Col grow={1}>
          <Skeleton.View></Skeleton.View>
        </Col>
        <Col grow={1}>
          <Skeleton.View></Skeleton.View>
        </Col>
      </Row>
      <Row gap="sm" wrap basis="30vh">
        <Col grow={1}>
          <Skeleton.View></Skeleton.View>
        </Col>
        <Col grow={1}>
          <Skeleton.View></Skeleton.View>
        </Col>
      </Row>
      <Row gap="sm" wrap>
        <></>
      </Row>
    </Col>
  );

  const collapseView = (
    <Col padding="sm" gap="sm" grow={1}>
      <Col gap="sm" wrap basis="60vh">
        <Col grow={3}>
          <Skeleton.View></Skeleton.View>
        </Col>
        <Col grow={2}>
          <Skeleton.View></Skeleton.View>
        </Col>
        <Col grow={2}>
          <Skeleton.View></Skeleton.View>
        </Col>
        <Col grow={2}>
          <Skeleton.View></Skeleton.View>
        </Col>
      </Col>
      <Col gap="sm" wrap basis="40vh">
        <Col grow={1}>
          <Skeleton.View></Skeleton.View>
        </Col>
        <Col grow={1}>
          <Skeleton.View></Skeleton.View>
        </Col>
      </Col>
      <Row gap="sm" wrap>
        <></>
      </Row>
    </Col>
  );

  const viewWrapper = ViewResolver({
    viewModes: [["Default"], ["Mobile-Portrait", "Mobile-Landscape"]],
    resolvedContent: [normalView, collapseView],
  });

  return <>{viewWrapper}</>;
}

function ChartView(props: AnalyticsContentProps): JSX.Element {
  const surveyActivitiesMap: Map<string, SurveyActivity[]> = useMemo(
    () =>
      pipe(
        props.analytics.surveyActivities,
        A.map(({ branch, ...activity }): [string, SurveyActivity[]] => [
          branch,
          [activity],
        ]),
        M.fromFoldable(
          EqString,
          A.getUnionSemigroup(
            Eq.struct({
              activityDate: EqSameDate,
              newSurveys: EqNumber,
              applicationsExported: EqNumber,
            }),
          ),
          A.Foldable,
        ),
      ),
    [props.analytics],
  );

  const reducer = (
    sum: Highcharts.PointOptionsObject[],
    a: Highcharts.PointOptionsObject,
  ) => {
    const prev = last(sum);

    if (!prev?.y) {
      sum.push({ ...a });
      return sum;
    }
    sum.push({ ...a, y: prev.y + (a?.y ? a.y : 0) });
    return sum;
  };

  const surveyActivitiesData = useMemo(
    () =>
      pipe(
        props.selectedBranch,
        O.chain((branch) => M.lookup(EqString)(branch)(surveyActivitiesMap)),
        O.map((activities): Highcharts.SeriesOptionsType[] => [
          {
            type: "line",
            name: "New Surveys",
            data: activities.map(plotNewSurveys).reduce(reducer, []),
          },
          {
            type: "line",
            name: "Applications Exported",
            data: activities.map(plotApplicationExports).reduce(reducer, []),
          },
        ]),
      ),
    [surveyActivitiesMap, props.selectedBranch],
  );

  const livingArranegmentsMap: Map<string, LivingArrangementsComposition> =
    useMemo(
      () =>
        pipe(
          props.analytics.livingArrangements,
          A.map(
            ({
              branch,
              ...livingArrangements
            }): [string, LivingArrangementsComposition] => [
              branch,
              livingArrangements,
            ],
          ),
          M.fromFoldable(EqString, SG.last(), A.Foldable),
        ),
      [props.analytics.livingArrangements],
    );

  const livingArranegmentsData: Option<Highcharts.PointOptionsObject[]> =
    useMemo(
      () =>
        pipe(
          props.selectedBranch,
          O.chain((branch) =>
            M.lookup(EqString)(branch)(livingArranegmentsMap),
          ),
          O.map(plotLivigArrangementsComposition),
        ),
      [livingArranegmentsMap, props.selectedBranch],
    );

  const loanTypesMap: Map<string, LoanTypesComposition> = useMemo(
    () =>
      pipe(
        props.analytics.loanTypes,
        A.map(
          ({ branch, ...loanTypeItems }): [string, LoanTypesComposition] => [
            branch,
            loanTypeItems,
          ],
        ),
        M.fromFoldable(EqString, SG.last(), A.Foldable),
      ),
    [props.analytics],
  );

  const loanTypesData: Option<Highcharts.PointOptionsObject[]> = useMemo(
    () =>
      pipe(
        props.selectedBranch,
        O.chain((branch) => M.lookup(EqString)(branch)(loanTypesMap)),
        O.map(plotLoanTypesComposition),
      ),
    [loanTypesMap, props.selectedBranch],
  );

  const referralSourcesMap: Map<string, ReferralSourceItem[]> = useMemo(
    () =>
      pipe(
        props.analytics.referralSources,
        A.map(
          ({ branch, referralSourceItems }): [string, ReferralSourceItem[]] => [
            branch,
            referralSourceItems,
          ],
        ),
        M.fromFoldable(
          EqString,
          A.getUnionSemigroup(
            Eq.struct({ description: EqString, percent: EqNumber }),
          ),
          A.Foldable,
        ),
      ),
    [props.analytics],
  );

  const referralSourcesData: Option<Highcharts.PointOptionsObject[]> = useMemo(
    () =>
      pipe(
        props.selectedBranch,
        O.chain((branch) => M.lookup(EqString)(branch)(referralSourcesMap)),
        O.map(
          A.map(
            (source): Highcharts.PointOptionsObject => ({
              name: source.description,
              y: source.percent,
            }),
          ),
        ),
      ),
    [referralSourcesMap, props.selectedBranch],
  );

  const normalView = (
    <Row gap="sm" wrap={true}>
      {O.isSome(surveyActivitiesData) && (
        <LineChart
          series={surveyActivitiesData.value}
          title="Application Activities"
        />
      )}
      <Row
        gap="sm"
        grow={1}
        className={ViewResolver({
          viewModes: [
            ["Default"],
            [
              "Mobile-Landscape",
              "Mobile-Portrait",
              "Tablet-Portrait",
              "Tablet-Landscape",
            ],
          ],
          resolvedContent: ["max-width-80", ""],
        })}
      >
        {O.isSome(livingArranegmentsData) && (
          <PieChart
            data={livingArranegmentsData.value}
            title="Current living arrangement"
          />
        )}
        {O.isSome(loanTypesData) && (
          <PieChart data={loanTypesData.value} title="Refinance vs Purchase" />
        )}
        {O.isSome(referralSourcesData) && (
          <PieChart
            data={referralSourcesData.value}
            title="Top Referral Sources"
          />
        )}
      </Row>
    </Row>
  );

  const collapseView = (
    <Col gap="sm" wrap={true}>
      {O.isSome(surveyActivitiesData) && (
        <LineChart
          series={surveyActivitiesData.value}
          title="Application Activities"
        />
      )}
      <Col
        gap="sm"
        grow={1}
        className={ViewResolver({
          viewModes: [
            ["Default"],
            [
              "Mobile-Landscape",
              "Mobile-Portrait",
              "Tablet-Portrait",
              "Tablet-Landscape",
            ],
          ],
          resolvedContent: ["max-width-80", ""],
        })}
      >
        {O.isSome(livingArranegmentsData) && (
          <PieChart
            data={livingArranegmentsData.value}
            title="Current living arrangement"
          />
        )}
        {O.isSome(loanTypesData) && (
          <PieChart data={loanTypesData.value} title="Refinance vs Purchase" />
        )}
        {O.isSome(referralSourcesData) && (
          <PieChart
            data={referralSourcesData.value}
            title="Top Referral Sources"
          />
        )}
      </Col>
    </Col>
  );

  const viewWrapper = ViewResolver({
    viewModes: [["Default"], ["Mobile-Portrait"]],
    resolvedContent: [normalView, collapseView],
  });
  return <>{viewWrapper}</>;
}

function WorkItemsAndProflieView(props: AnalyticsContentProps): JSX.Element {
  const timeToExport = useMemo(
    () =>
      Duration.fromObject({
        hours: props.analytics.averageTimeToExportInHours,
      }).toFormat("d 'days' h 'hours'"),
    [props.analytics],
  );

  const customerAveragesMap: Map<string, CustomerProfileAverages> = useMemo(
    () =>
      pipe(
        props.analytics.customerProfileAverages,
        A.map(({ branch, ...averages }): [string, CustomerProfileAverages] => [
          branch,
          averages,
        ]),
        M.fromFoldable(EqString, SG.last(), A.Foldable),
      ),
    [props.analytics.customerProfileAverages],
  );

  const customerAveragesData: Option<CustomerProfileAverages> = useMemo(
    () =>
      pipe(
        props.selectedBranch,
        O.chain((branch) => M.lookup(EqString)(branch)(customerAveragesMap)),
      ),
    [customerAveragesMap, props.selectedBranch],
  );

  const workItemsAndProfileView = (
    <>
      <Col
        grow={1}
        gap="sm"
        padding="md"
        basis="30%"
        className="chart-container"
      >
        <Label className="text-smd black text-mbold">Work items</Label>
        <Col
          className="work-item bg-color-action-70"
          alignHorizontal="center"
          gap="xxs"
          padding="sm"
        >
          <Label className="white text-mbold">
            {props.analytics.applicationsRequiringFollowUp}
          </Label>
          <Label fontStyle="text-xs" className="white">
            Applications requiring follow up
            <i className="fa-solid fa-circle-exclamation ml-left-xxs"></i>
          </Label>
          <Label fontStyle="text-xs" className="white cp text-mbold">
            View applications
            <i className="fa-solid fa-chevron-right ml-left-xxs icon-xxs"></i>
          </Label>
        </Col>
        <Col
          className="work-item grey-border"
          alignHorizontal="center"
          gap="xxs"
          padding="sm"
        >
          <Label className="black text-mbold">{timeToExport}</Label>
          <Label fontStyle="text-xs" className="black">
            Average time spent to export
            <i className="fa-solid fa-circle-exclamation ml-left-xxs"></i>
          </Label>
          <Label fontStyle="text-xs" className="black cp text-mbold">
            My metrics
            <i className="fa-solid fa-chevron-right ml-left-xxs icon-xxs"></i>
          </Label>
        </Col>
      </Col>
      {O.isSome(customerAveragesData) && (
        <CustomerStatistics
          customerProfileAverages={customerAveragesData.value}
        />
      )}
    </>
  );
  const normalView = (
    <Row gap="sm" wrap>
      {workItemsAndProfileView}
    </Row>
  );

  const collapseView = (
    <Col gap="sm" wrap>
      {workItemsAndProfileView}
    </Col>
  );

  const viewWrapper = ViewResolver({
    viewModes: [["Default"], ["Mobile-Portrait", "Mobile-Landscape"]],
    resolvedContent: [normalView, collapseView],
  });
  return <>{viewWrapper}</>;
}
