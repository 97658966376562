import { Col, IconType, Label, Row, Table } from "@/components/basic";
import { Branch, BranchId } from "@/data/client";
import { constant, flow, pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/lib/Array";
import { concatAll } from "fp-ts/Monoid";
import { MonoidSum } from "fp-ts/lib/number";
import * as O from "fp-ts/lib/Option";
import { OptionMenu } from "../../basic/Menu/OptionMenu";
import { useContext } from "react";
import { RouterContext } from "@/utils/router-context";
import { getProfileId } from "@/utils/user";
import { ViewResolver } from "@/utils/viewResolver";
import Navigo from "navigo";

export type BranchesTableProps = {
  branches: Branch[];
  onSelectBranch: (branchId: BranchId) => void;
  onEditBranch: (branch: Branch) => void;
  onDeleteBranch: (branchId: BranchId) => void;
};

const countUsers = (branch: Branch): number =>
  pipe(
    branch.teams,
    A.map((team) => team.users.length),
    concatAll(MonoidSum),
  ) + branch.users.length;

export function BranchesTable(props: BranchesTableProps): JSX.Element {
  const { router } = useContext(RouterContext);
  return (
    <Table
      pageInfo={O.none}
      isLoading={false}
      className="data-table branch-table mobile-column"
      itemKey={(branch) => branch.branchId}
      data={props.branches}
      columns={
        ViewResolver({
          viewModes: [
            ["Default"],
            ["Mobile-Landscape", "Mobile-Portrait"]
          ],
          resolvedContent: [
            getDesktopColumns(router, props),
            getMobileColumns(router, props)
          ]
        })
      }
    />
  );
}
function getMobileColumns(router: Navigo, props: BranchesTableProps) {
  return [
    {
      columnKey: "name",
      header: (
        <Row padding="sm">
          <Label className="table-header-label">Branches</Label>
        </Row>
      ),
      view: (branch: Branch) => (
        <Row
          padding="sm"
          gap="xs"
          className="data-table-cell mobile-column"
          alignVertical="center"
          onClick={flow(constant(`/org/branches/${branch.branchId}`), router.navigate)}
        >
          <Col grow={1}>
            <Label className="branch-name cp">{branch.name}</Label>
            <Label className="black">{countUsers(branch)} users</Label>
          </Col>
          <Col className="data-table-cell" alignVertical="center" padding="xs">
            <OptionMenu
              menuItems={[
                {
                  icon: "pen-to-square" as IconType,
                  label: "Edit",
                  onClick: O.some(flow(constant(branch), props.onEditBranch)),
                },
                {
                  icon: "trash-can" as IconType,
                  label: "Delete",
                  onClick: O.some(
                    flow(constant(branch.branchId), props.onDeleteBranch),
                  ),
                },
              ]}
            ></OptionMenu>
          </Col>
        </Row>
      ),
    }
  ]
}
function getDesktopColumns(router: Navigo, props: BranchesTableProps) {
  return [
    {
      columnKey: "name",
      header: (
        <Row padding="sm">
          <Label className="table-header-label">Branches</Label>
        </Row>
      ),
      view: (branch: Branch) => (
        <Row
          padding="sm"
          gap="xs"
          className="data-table-cell cell-first branch-column"
          alignVertical="center"
          onClick={flow(constant(`/org/branches/${branch.branchId}`), router.navigate)}
        >
          <Col>
            <div className="branch-id">
              {pipe(branch.name, getProfileId)}
            </div>
          </Col>
          <Col>
            <Row>
              <Label className="branch-name cp">{branch.name}</Label>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      columnKey: "users",
      header: (
        <Row padding="sm">
          <Label className="table-header-label">Users</Label>
        </Row>
      ),
      headerContainerClassName: 'ml-auto',
      view: (branch: Branch) => (
        <Row padding="fallback" className="data-table-cell users-column" alignVertical="center" alignHorizontal="right">
          <Label className="black">{countUsers(branch)}</Label>
        </Row>
      ),
    },
    {
      columnKey: "actions",
      header: (
        <Row>
          <></>
        </Row>
      ),
      view: (branch: Branch) => (
        <Col className="data-table-cell cell-last actions-cell" alignVertical="center" alignHorizontal="right">
          <OptionMenu
            menuItems={[
              {
                icon: "pen-to-square" as IconType,
                label: "Edit",
                onClick: O.some(flow(constant(branch), props.onEditBranch)),
              },
              {
                icon: "trash-can" as IconType,
                label: "Delete",
                onClick: O.some(
                  flow(constant(branch.branchId), props.onDeleteBranch),
                ),
              },
            ]}
          ></OptionMenu>
        </Col>
      ),
    },
  ]
}
