import { ClientStatusResponse } from "@/data/client";

export type Model = {
  forBorrower: boolean;
  status: ClientStatusResponse;
};

export const init = (forBorrower: boolean, status: ClientStatusResponse): Model => ({
  forBorrower,
  status,
});
