import Navigo from "navigo";
import { createContext } from "react";
import * as O from "fp-ts/Option";
import { ClientStatusResponse, User } from "@/data/client";

export const RouterContext = createContext<{ router: Navigo }>({
    router: null as unknown as Navigo,
  });
  
export const ErrorInfoContext = createContext<{ message: O.Option<string>, setMessage: (a: O.Option<string>) => void }>({
    message: O.none, 
    setMessage: (s: O.Option<string>) => O.some(s)
  });
  

  export const UserContext = createContext<{ user: O.Option<User>,clientStatus: O.Option<ClientStatusResponse> }>({
    user: O.none, 
    clientStatus: O.none
  });
  