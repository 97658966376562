import { ChildProps } from "@/utils/reducerWithEffect";
import {
  Action
} from "./action";
import { Model } from "./model";


export type Props = ChildProps<Model, Action> 

export function View(_: Props ): JSX.Element {
 
 return <>:o Invite</>
}
