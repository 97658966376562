import {
  AlignHorizontal,
  AlignVertical,
  Color,
  Elevations,
  getWidth,
  palette,
  Spacing,
  spacing,
  WidthTypes,
} from "@/components/basic/constants";
import { useOnly } from "@/utils/new-only";
import * as O from "fp-ts/lib/Option";
import { RefObject } from "react";

function getAlignVertical(align: AlignVertical): string {
  switch (align) {
    case "center":
      return "center";
    case "top":
      return "flex-start";
    case "bottom":
      return "flex-end";
    case "space-between":
      return "space-between";
    case "space-around":
      return "space-around";
    case "stretch":
      return "stretch";
    case "baseline":
      return "baseline";
  }
}

function getElevations(align: Elevations): string {
  switch (align) {
    case "flat":
      return "elevate-flat";
    case "thin":
      return "elevate-thin";
    case "thick":
      return "elevate-thick";
  }
}

function getAlignHorizontal(align: AlignHorizontal): string {
  switch (align) {
    case "center":
      return "center";
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    case "space-between":
      return "space-between";
    case "space-around":
      return "space-around";
    case "stretch":
      return "stretch";
  }
}

export type ColProps = {
  padding?: Spacing | "fallback";
  gap?: Spacing | "fallback";
  alignVertical?: AlignVertical;
  alignHorizontal?: AlignHorizontal;
  className?: string;
  id?: string;
  grow?: number;
  width?: WidthTypes;
  background?: Color;
  foreground?: Color;
  children: React.ReactNode;
  elevation?: Elevations;
  basis?: string;
  wrap?: boolean;
  onClick?: () => void;
  only?: O.Option<boolean>;
  refContainer?: RefObject<HTMLDivElement>;
};

export function Col(props: ColProps): JSX.Element {
  const shouldDisplay = useOnly(props.only);

  return (
    <>
      {shouldDisplay && (
        <div
          ref={props.refContainer}
          id={props.id}
          className={`${props.className ? props.className : ""} ${props.elevation ? getElevations(props.elevation) : ""}`}
          style={{
            ...(props.grow ? { flexGrow: props.grow } : {}),
            ...(props.basis ? { flexBasis: props.basis } : {}),
            ...(props.alignVertical
              ? { justifyContent: getAlignVertical(props.alignVertical) }
              : {}),
            ...(props.alignHorizontal
              ? { alignItems: getAlignHorizontal(props.alignHorizontal) }
              : {}),
            ...(props.wrap ? { flexWrap: "wrap" } : {}),
            ...(props.background
              ? { backgroundColor: palette[props.background] }
              : {}),
            ...(props.foreground ? { color: palette[props.foreground] } : {}),
            ...(props.width ? { width: getWidth(props.width) } : {}),
            display: "flex",
            flexDirection: "column",

            gap:
              props.gap && props.gap !== "fallback"
                ? spacing[props.gap]
                : props.gap === "fallback"
                  ? "revert-layer"
                  : "0",
            padding:
              props.padding && props.padding !== "fallback"
                ? spacing[props.padding]
                : props.padding === "fallback"
                  ? "revert-layer"
                  : "0",
          }}
          onClick={props.onClick}
        >
          {props.children}
        </div>
      )}
    </>
  );
}
