import * as ClientsListing from "@/components/ClientsListing";
import { Api } from "@/utils/api";
import {
  Effect,
  mapEffect,
  noEffect
} from "@/utils/reducerWithEffect";
import { constant, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Model } from "./model";

export type Action = {
  type: "ClientsListingAction";
  action: ClientsListing.Action;
};

export const   ClientsListingAction = (action: ClientsListing.Action): Action => ({
  type: "ClientsListingAction",
  action,
});

export const update =
  (api: Api) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    switch (action.type) {
      case "ClientsListingAction": {
        const [clientModel, clientEffect] = pipe(
          model.clientListing,
          O.map((model) => ClientsListing.update(api)(model, action.action)),
          O.fold(constant([model.clientListing, noEffect]), ([m, e]) => [
            O.some(m),
            e,
          ]),
        );
        return [
          { ...model, clientListing: clientModel },
          mapEffect(ClientsListingAction)(clientEffect),
        ];
      }
    }
  };
