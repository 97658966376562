import loaderImg01 from "@/assets/images/loader-img-01.png";
import loaderImg02 from "@/assets/images/loader-img-02.png";
import { _useRights } from "@/utils/use-rights";
import * as O from "fp-ts/lib/Option";
import { Col } from "../Col";
import { Row } from "../Row";
import { pipe } from "fp-ts/lib/function";
import { BounceLoader } from "react-spinners";
// import { Skeleton } from "./skeleton";


export type LoaderProps = {
  loaderMessage: string;
  children?: React.ReactNode;
  showLoadingIcon?: boolean;
};

export function LoaderView(props: LoaderProps): JSX.Element {
  const {isBackOfficeUser}  = _useRights();
  const defaultContent = (
    <Row
      grow={1}
      alignHorizontal="center"
      alignVertical="center"
      padding="lg"
      className={`loader-content ${isBackOfficeUser ? "back-office" : "applicant"}`}
      gap="lg"
      wrap

    >
      <Col>
      {props.showLoadingIcon && <BounceLoader color="#2A2250"/>}</Col>
      <Col className="loader-message text-lg">
        {props.loaderMessage ?? "Loading"}...
      </Col>
      {!props.showLoadingIcon && <img style={{maxHeight: '40vh'}} src={pipe(
        isBackOfficeUser,
        O.fold(
            () => loaderImg02,
            () => loaderImg01
        )
      )}></img>}
    </Row>
  );
  return (
    <>{props.children ?? defaultContent}</>
  );
}
