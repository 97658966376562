import { Model } from "./model";

export type Action = 
  | {
    type: "DischargedToggled";
  }
  | {
    type: "PaymentPlanCompletedToggled";
  }

export const DischargedToggled = (): Action => ({
  type: "DischargedToggled"
});

export const PaymentPlanCompletedToggled = (): Action => ({
  type: "PaymentPlanCompletedToggled"
});

export const update = (model: Model, action: Action): Model => {
  switch (action.type) {
    case "DischargedToggled": {
      if (model.type !== "Chapter7") {
        return model;
      }

      return {
        ...model,
        discharged: !model.discharged
      };
    }

    case "PaymentPlanCompletedToggled": {
      if (model.type !== "Chapter13") {
        return model;
      }

      return {
        ...model,
        paymentPlanCompleted: !model.paymentPlanCompleted
      };
    }
  }
}