import * as t from "io-ts";
import * as tt from "io-ts-types";
import { nonEmptyStringCodec } from "./nonEmptyString";

// eslint-disable-next-line no-control-regex
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

interface EmailStringBrand {
  readonly EmailString: unique symbol;
}
export const emailStringCodec = t.brand(
  nonEmptyStringCodec(),
  (s): s is t.Branded<tt.NonEmptyString, EmailStringBrand> =>
  emailRegex.test(s),
  "EmailString"
);

export const emailStringCodecWithMessage = (errorMessage: string) => tt.withMessage(
  emailStringCodec,
  () => errorMessage
);


export type EmailString = t.TypeOf<typeof emailStringCodec>;
