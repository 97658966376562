import { emailStringCodecWithMessage, nonEmptyStringCodec } from "@/utils/codecs";
import { phoneNumberCodecWithMessage } from "@/utils/codecs/phoneNumber";
import { updateFormField } from "@/utils/formField";
import { ValidErrorMessage } from "../basic";
import { Model } from "./model";

export type Action =
  | {
      type: "FullNameChanged";
      fullName: string;
    }
  | {
      type: "EmailChanged";
      email: string;
    }
  | {
      type: "PhoneNumberChanged";
      phoneNumber: string;
    };

export const FullNameChanged = (fullName: string): Action => ({
  type: "FullNameChanged",
  fullName,
});

export const EmailChanged = (email: string): Action => ({
  type: "EmailChanged",
  email,
});

export const PhoneNumberChanged = (phoneNumber: string): Action => ({
  type: "PhoneNumberChanged",
  phoneNumber,
});

export const update = (model: Model, action: Action): Model => {
  switch (action.type) {
    case "FullNameChanged":
      return {
        ...model,
        fullName: updateFormField(nonEmptyStringCodec(false, ValidErrorMessage('name')).decode)(action.fullName),
      };
    case "EmailChanged":
      return {
        ...model,
        email: updateFormField(emailStringCodecWithMessage(ValidErrorMessage('email address')).decode)(action.email),
      };
    case "PhoneNumberChanged":
      return {
        ...model,
        phoneNumber: updateFormField(phoneNumberCodecWithMessage(ValidErrorMessage('phone number')).decode)(
          action.phoneNumber,
        ),
      };
  }
};
