import { Deferred, NotStarted } from "@/utils/deferred";
import { ApiResult } from "@/utils/request";
import * as O from "fp-ts/lib/Option";

export type Model = {
    targetRouteUrl: O.Option<string>;
    dialogApiResult: Deferred<ApiResult<unknown>>
};

export const init = (routeUrl: O.Option<string>): Model => ({
    targetRouteUrl: routeUrl,
    dialogApiResult: NotStarted()
})