import { Col, InputField } from "@/components/basic";
import { ChildProps } from "@/utils/reducerWithEffect";
import { flow } from "fp-ts/lib/function";
import { Action, TeamNameChanged } from "./action";
import { Model } from "./model";

export type Props = ChildProps<Model, Action>;

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  return (
    <Col padding="xs" gap="xs">
      <InputField
        label="Name"
        field={model.teamName}
        onChange={flow(TeamNameChanged, dispatch)}
      />
    </Col>
  );
}
