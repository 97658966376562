import ApplicationSubmittedImage from "@/assets/images/ApplicationSubmitted.svg";
import "@/assets/styles/SubmitApplication/submitApplication.css";
import { Model } from "./model";
import { Button, Col } from "@/components/basic";
import * as O from "fp-ts/Option";
import { Application } from "@/data/payload";
import { useMemo } from "react";
import { pipe } from "fp-ts/lib/function";
import * as E from "fp-ts/Either";

export type Props = {
  model: Model;
  goToPortal: (application: Application) => void;
};

export function View(props: Props): JSX.Element {
  const { model } = props;

  const message = useMemo(() => {
    switch (model.operation.status) {
      case "Started":
        return (
          <h1 className="submit-application-header">
            Submitting your application...
          </h1>
        );
      case "Finished":
        return pipe(
          model.operation.result,
          E.fold(
            () => (
              <h1 className="submit-application-header">
                Failed to submit application
              </h1>
            ),
            (application) => (
              <>
                <h1 className="submit-application-header">
                  Your pre-approval application has been submitted successfully
                </h1>

                <span>
                  Your loan officer will be in touch shortly with more
                  information on next steps for your application.
                </span>

                <Button
                  type="primary"
                  onClick={O.some(() => props.goToPortal(application))}
                >
                  Go to my portal
                </Button>
              </>
            ),
          ),
        );
    }
  }, [model.operation, props]);
  return (
    <Col
      gap="xl"
      padding="xl"
      alignHorizontal="center"
      className="submit-application"
    >
      <img
        height={150}
        src={ApplicationSubmittedImage}
        alt="Application Submitted"
      />

      {message}
    </Col>
  );
}
