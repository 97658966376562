import * as t from "io-ts";
import * as tt from "io-ts-types";
import { Option } from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function optionFromString<C extends t.Type<any, string, unknown>>(
  codec: C,
  name = `OptionFromString<${codec.name}>`
) {
  return new t.Type<Option<t.TypeOf<C>>, string, unknown>(
    name,
    (u): u is Option<t.TypeOf<C>> => tt.option(codec).is(u),
    (u, c) => {
      if (u === "") {
        return t.success(O.none);
      } else {
        return pipe(
          codec.validate(u, c),
          E.fold(E.left, val => t.success(O.some(val)))
        );
      }
    },
    x => (O.isSome(x) ? codec.encode(x.value) : "")
  );
}
   