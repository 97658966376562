import * as t from "io-ts";
import * as tt from "io-ts-types";
import { applicantIdCodec } from "./applicationDocument";
import { userIdCodec } from "./client";

export const assignmentsPayloadCodec = t.type({
    applicationId: applicantIdCodec,
    userIds: tt.nonEmptyArray(userIdCodec)
  });

  export type AssignmentsPayload = t.TypeOf<typeof assignmentsPayloadCodec>;