import { User } from "@/data/client"
import { AccountSettingsPayload } from "@/data/payload"
import { Deferred, NotStarted, Resolved } from "@/utils/deferred"
import { ApiResult } from "@/utils/request"
import { pipe } from "fp-ts/lib/function";
import * as E from "fp-ts/Either";

export type Model = {
    accountSettings: Deferred<ApiResult<AccountSettingsPayload>>,
    dialogApiResult: Deferred<ApiResult<unknown>>;
}

export const initNotificationSettings = (user: User): Model => ({
    accountSettings: pipe(
        {
          userId: user.userId,
          smsNotificationsDisabled: user.smsNotificationsDisabled,
        },
        E.right,
        Resolved,
      ),
    dialogApiResult: NotStarted()
})