import {
  EmailString,
  ValidDateTime,
  emailStringCodec,
  toISODate,
  validDateTimeCodecS,
} from "@/utils/codecs";
import {
  NullFromEmptyString,
  NullFromEmptyStringType,
} from "@/utils/codecs/nullFromEmptyString";
import { PhoneNumber, phoneNumberCodec } from "@/utils/codecs/phoneNumber";
import { sequenceS } from "fp-ts/lib/Apply";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import * as NEA from "fp-ts/lib/NonEmptyArray";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import * as Ord from "fp-ts/lib/Ord";
import { flow, pipe } from "fp-ts/lib/function";
import { Ord as OrdNumber } from "fp-ts/lib/number";
import * as t from "io-ts";
import { NonEmptyString } from "io-ts-types";
import {
  AddressPayload,
  ApplicantPersonalInfo,
  CitizenshipStatus,
  MaritalStatus,
  citizenshipStatusCodec,
  maritalStatusCodec,
} from "../../data/payload";
import { nonEmptyStringCodec } from "../../utils/codecs/nonEmptyString";
import {
  SocialSecurityNumber,
  socialSecurityNumberCodec,
} from "../../utils/codecs/socialSecurityNumber";
import { FormField, initFormField } from "../../utils/formField";
import * as Address from "../Address";

export type Model = {
  fullName: FormField<NonEmptyString>;
  email: FormField<EmailString>;
  phone: FormField<PhoneNumber>;
  dateOfBirth: FormField<ValidDateTime | NullFromEmptyStringType>;
  socialSecurityNumber: FormField<SocialSecurityNumber>;
  addresses: NonEmptyArray<Address.Model>;
  maritalStatus: FormField<MaritalStatus>;
  citizenshipStatus: FormField<CitizenshipStatus>;
  applicantId: number;
};

export const init = (
  applicantPersonalInfo: Option<ApplicantPersonalInfo>,
): Model => ({
  fullName: pipe(
    applicantPersonalInfo,
    O.fold(
      () => "",
      ({ fullName }) => fullName,
    ),
    initFormField(nonEmptyStringCodec().decode),
  ),
  email: pipe(
    applicantPersonalInfo,
    O.fold(
      () => "",
      ({ email }) => email,
    ),
    initFormField(emailStringCodec.decode),
  ),
  phone: pipe(
    applicantPersonalInfo,
    O.fold(
      () => "",
      ({ phone }) => phone,
    ),
    initFormField(phoneNumberCodec.decode),
  ),
  dateOfBirth: pipe(
    pipe(
      applicantPersonalInfo,
      O.fold(
        () => initFormField(NullFromEmptyString.decode)(""),
        ({ dateOfBirth }) =>
          NullFromEmptyString.is(dateOfBirth)
            ? initFormField(NullFromEmptyString.decode)(dateOfBirth)
            : initFormField(validDateTimeCodecS.decode)(toISODate(dateOfBirth)),
      ),
    ),
  ),
  socialSecurityNumber: pipe(
    applicantPersonalInfo,
    O.fold(
      () => "",
      ({ ssn }) => (ssn ? ssn : ""),
    ),
    initFormField(socialSecurityNumberCodec.decode),
  ),
  addresses: pipe(
    applicantPersonalInfo,
    O.chain(({ addresses }) =>
      pipe(
        addresses,
        A.sort(
          Ord.contramap((addr: AddressPayload) => addr.sequence)(OrdNumber),
        ),
        A.map(flow(O.some, Address.init)),
        NEA.fromArray,
      ),
    ),
    O.getOrElse(() => NEA.of(Address.init(O.none))),
  ),
  maritalStatus: pipe(
    applicantPersonalInfo,
    O.fold(
      () => "",
      ({ maritalStatus }) => maritalStatus,
    ),
    initFormField(maritalStatusCodec.decode),
  ),
  citizenshipStatus: pipe(
    applicantPersonalInfo,
    O.fold(
      () => "",
      ({ citizenshipStatus }) => citizenshipStatus,
    ),
    initFormField(citizenshipStatusCodec.decode),
  ),
  applicantId: pipe(
    applicantPersonalInfo,
    O.fold(
      () => 0,
      ({ applicantId }) => applicantId,
    ),
  ),
});

export const result = (model: Model): t.Validation<ApplicantPersonalInfo> =>
  sequenceS(E.Apply)({
    fullName: model.fullName.val,
    email: model.email.val,
    phone: model.phone.val,
    dateOfBirth: model.dateOfBirth.val,
    ssn: model.socialSecurityNumber.val,
    addresses: NEA.traverseWithIndex(E.Applicative)(Address.result)(
      model.addresses,
    ),
    maritalStatus: model.maritalStatus.val,
    citizenshipStatus: model.citizenshipStatus.val,
    applicantId: E.right(model.applicantId),
    divorced: E.right(false)
  });
