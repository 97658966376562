import { nonEmptyStringCodec } from "@/utils/codecs";
import { phoneNumberCodec } from "@/utils/codecs/phoneNumber";
import { updateFormField } from "@/utils/formField";
import { Model } from "./model";
import * as O from "fp-ts/lib/Option";

export type Action =
  | {
      type: "CompanyNameChanged";
      firstName: string;
    }
  | {
      type: "PhoneChanged";
      phone: string;
    }
  | {
      type: "WebsiteChanged";
      website: string;
    }
  | {
      type: "NmlsNumberChanged";
      nmlsNumber: string;
    }
  | {
      type: "LogoSelected";
      logo: O.Option<File>;
    };

export const CompanyNameChanged = (firstName: string): Action => ({
  type: "CompanyNameChanged",
  firstName,
});

export const PhoneChanged = (phone: string): Action => ({
  type: "PhoneChanged",
  phone,
});

export const NmlsNumberChanged = (nmlsNumber: string): Action => ({
  type: "NmlsNumberChanged",
  nmlsNumber,
});

export const WebsiteChanged = (website: string): Action => ({
  type: "WebsiteChanged",
  website,
});

export const LogoSelected = (logo: O.Option<File>) => ():Action => ({
  type: "LogoSelected",
  logo: logo,
});

export const update =
  (action: Action) =>
  (model: Model): Model => {
    switch (action.type) {
      case "CompanyNameChanged":
        return {
          ...model,
          companyName: updateFormField(nonEmptyStringCodec().decode)(
            action.firstName,
          ),
        };
      case "PhoneChanged":
        return {
          ...model,
          phone: updateFormField(phoneNumberCodec.decode)(action.phone),
        };

      case "WebsiteChanged":
        return {
          ...model,
          website: updateFormField(phoneNumberCodec.decode)(action.website),
        };

      case "NmlsNumberChanged":
        return {
          ...model,
          nmlsNumber: updateFormField(nonEmptyStringCodec().decode)(
            action.nmlsNumber,
          ),
        };
      case "LogoSelected":
        return {
          ...model,
          logo: action.logo,
        };
    }
  };
