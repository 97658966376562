import { Button, Col, Icon, Label, Row, Table } from "@/components/basic";
import * as ClientsListing from "@/components/ClientsListing";
import { ApplicationListItem } from "@/data/applicationsList";
import { BranchId, Client, TeamId, User } from "@/data/client";
import { Started } from "@/utils/asyncOperationStatus";
import { Deferred } from "@/utils/deferred";
import { ApiResult } from "@/utils/request";
import { RouterContext } from "@/utils/router-context";
import { fullName } from "@/utils/user";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import { useContext, useMemo } from "react";
import {
  Action,
  ClientsListingAction,
  DeleteUserInitiated,
  EditUserInitiated,
} from "../index";
import { UserRoles } from "../UsersListing/UserRoles";
import { ViewResolver } from "@/utils/viewResolver";
type UserViewProps = {
  user: User;
  client: Client;
  applications: Deferred<ApiResult<ApplicationListItem[]>>;
  branchName: (branchId: BranchId) => Option<string>;
  teamName: (teamId: TeamId) => Option<string>;
  dispatch: (action: Action) => void;
  clientsListing: ClientsListing.Model;
};

export function UserView(props: UserViewProps): JSX.Element {
  const branchName = useMemo(
    () => pipe(props.user.branchId, O.chain(props.branchName)),
    [props.branchName, props.user.branchId],
  );

  const { router } = useContext(RouterContext);

  const teamName = useMemo(
    () => pipe(props.user.teamId, O.chain(props.teamName)),
    [props.teamName, props.user.teamId],
  );

  return (
    <Col gap="lg" grow={1} alignHorizontal="stretch">
      <Row alignVertical="center" alignHorizontal="space-between" wrap={true} gap="sm"
        className={ViewResolver({
          viewModes: [
            ["Default"],
            ["Mobile-Landscape", "Mobile-Portrait"]
          ],
          resolvedContent: [
            "", "user-management-mb-buttons"
          ]
        })}
      >
        <Label>
          <Row gap="xxxs" alignVertical="center" >
            <Button
              onClick={O.some(() => router.navigate(`/org/branches`))}
              type="flat"
              className="link"
            >
              {props.client.name}
            </Button>
            <div className="text-xs">
              <Icon type="chevron-right"></Icon>
            </div>
            <Button
              onClick={O.some(() =>
                router.navigate(
                  `/org/branches/${pipe(
                    props.user.branchId,
                    O.fold(constant(0), identity),
                  )}`,
                ),
              )}
              type="flat"
              className="link"
            >
              <div>
                {pipe(
                  branchName,
                  O.fold(() => "", identity),
                )}
              </div>
            </Button>
            <div className="text-xs">
              <Icon type="chevron-right"></Icon>
            </div>
            <Button
              onClick={O.some(() =>
                router.navigate(
                  `/org/branches/${pipe(
                    props.user.branchId,
                    O.fold(constant(0), identity),
                  )}/teams/${pipe(
                    props.user.teamId,
                    O.fold(constant(0), identity),
                  )}`,
                ),
              )}
              type="flat"
              className="link"
            >
              {pipe(
                teamName,
                O.fold(() => "", identity),
              )}
            </Button>
            {getUserName(props)}
          </Row>
        </Label>
        <Row gap="sm">
          <Button
            type="primary"
            onClick={O.some(
              flow(constant(props.user), EditUserInitiated, props.dispatch),
            )}
          >
            Edit user
          </Button>
          <Button
            type="tertiary"
            onClick={O.some(
              flow(
                Started,
                DeleteUserInitiated(props.user.userId),
                props.dispatch,
              ),
            )}
          >
            Delete user
          </Button>
        </Row>
      </Row>
      <Col padding="xs" className="container">
        {getTableView(props, branchName, teamName)}
      </Col>
      <ClientsListing.View
        model={props.clientsListing}
        dispatch={flow(ClientsListingAction, props.dispatch)}
        showFilters={false}
        // onApplicationSelected={(id) => props.onApplicationSelected(id)}
        onApplicationSelected={constant}
      />
    </Col>
  );
}
function getUserName(props: UserViewProps) {
  return (
    <>
      {ViewResolver({
        viewModes: [
          [
            "Default",
            "Tablet-Portrait",
            "Tablet-Landscape"
          ],
          [
            "Mobile-Landscape",
            "Mobile-Portrait"
          ],
        ],
        resolvedContent: [
          <>
            <div className="text-xs">
              <Icon type="chevron-right"></Icon>
            </div>
            <div className="text-bold">{fullName(props.user)}</div>
          </>
          , <></>],
      })}
    </>
  );
}
function getTableView(props: UserViewProps, branchName: Option<string>, teamName: Option<string>) {
  return (
    <>
      {ViewResolver({
        viewModes: [
          [
            "Default",
            "Tablet-Portrait",
            "Tablet-Landscape"
          ],
          [
            "Mobile-Landscape",
            "Mobile-Portrait"
          ],
        ],
        resolvedContent: [
          <>
            <Table
              pageInfo={O.none}
              isLoading={false}
              data={[props.user]}
              itemKey={(user) => user.userId}
              className="data-table"
              columns={
                getDesktopColumns(branchName, teamName)
              }
            />
          </>
          ,
          <>
            {getMobileColumns(branchName, teamName, props)}
          </>
        ],
      })}
    </>
  );
}
function getDesktopColumns(branchName: Option<string>, teamName: Option<string>) {
  return [
    {
      columnKey: "branch",
      header: (
        <Row padding="xs">
          <Label className="table-header-label-black">Branch</Label>
        </Row>
      ),
      view: () => (
        <Row padding="sm" alignVertical="center" className="data-table-cell" >
          <Label className="black">
            {pipe(branchName, O.fold(constant("_"), identity))}
          </Label>
        </Row>
      ),
    },
    {
      columnKey: "team",
      header: (
        <Row padding="xs">
          <Label className="table-header-label-black">Team</Label>
        </Row>
      ),
      view: () => (
        <Row padding="sm" alignVertical="center" className="data-table-cell">
          <Label className="black">
            {pipe(teamName, O.fold(constant("_"), identity))}
          </Label>
        </Row>
      ),
    },
    {
      columnKey: "email",
      header: (
        <Row padding="xs">
          <Label className="table-header-label-black">Email</Label>
        </Row>
      ),
      view: (user: User) => (
        <Row padding="sm" alignVertical="center" className="data-table-cell">
          <Label className="black">{user.email}</Label>
        </Row>
      ),
    },
    {
      columnKey: "roles",
      header: (
        <Row padding="xs">
          <Label className="table-header-label-black">Roles</Label>
        </Row>
      ),
      view: (user: User) => (
        <Row padding="sm" alignVertical="center" className="data-table-cell">
          <UserRoles user={user} />
        </Row>
      ),
    },
    {
      columnKey: "userId",
      header: (
        <Row padding="xs">
          <Label className="table-header-label-black">ID</Label>
        </Row>
      ),
      view: (user: User) => (
        <Row padding="sm" alignVertical="center" className="data-table-cell">
          <Label className="black">{user.userId}</Label>
        </Row>
      ),
    },
  ]
}
function getMobileColumns(branchName: Option<string>, teamName: Option<string>, props: UserViewProps) {
  return (
    <>
      <Col gap="sm" padding="xxs">
        <div className="text-bold text-smd title-header-color">{fullName(props.user)}</div>
        <Col>
          <Row alignVertical="center" gap="sm">
            <Label className="text-md  text-mbold black">
              Branch:
            </Label>
            <Label className="text-md black">
              {pipe(branchName, O.fold(constant("_"), identity))}
            </Label>
          </Row>
        </Col>
        <Col>
          <Row alignVertical="center" gap="sm">
            <Label className="text-md  text-mbold black">
              Team:
            </Label>
            <Label className="text-md black">
              {pipe(teamName, O.fold(constant("_"), identity))}
            </Label>
          </Row>
        </Col>
        <Col>
          <Row alignVertical="center" gap="sm">
            <Label className="text-md  text-mbold black">
              Email ID:
            </Label>
            <Label className="text-md black">
              {props.user.email}
            </Label>
          </Row>
        </Col>
        <Col>
          <Row alignVertical="center" gap="sm">
            <Label className="text-md  text-mbold black">
              User ID:
            </Label>
            <Label className="text-md black">
              {props.user.userId}
            </Label>
          </Row>
        </Col>
        <Col>
          <Row alignVertical="center" gap="sm">
            <Label className="text-md  text-mbold black">
              Role:
            </Label>
            <Label className="text-md">
              <UserRoles user={props.user} />
            </Label>
          </Row>
        </Col>
      </Col>
    </>
  );
}

