import { nonEmptyStringCodec } from "@/utils/codecs";
import { FormField, initFormField } from "@/utils/formField";
import { NonEmptyString } from "io-ts-types";
import * as t from "io-ts";
import { BranchPayload } from "@/data/payload";
import { sequenceS } from "fp-ts/lib/Apply";
import * as E from "fp-ts/lib/Either";
import { Branch } from "@/data/client";

export type Model = {
  branchName: FormField<NonEmptyString>;
};

export const init = (): Model => ({
  branchName: initFormField(nonEmptyStringCodec().decode)(""),
});

export const initEdit = (branch: Branch): Model => ({
  branchName: initFormField(nonEmptyStringCodec().decode)(branch.name),
});

export const result = (model: Model): t.Validation<BranchPayload> =>
  sequenceS(E.Apply)({
    name: model.branchName.val,
  });
