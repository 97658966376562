import * as ClientsListing from "@/components/ClientsListing";
import { ApplicationListingResponse, emptyApplicationFilter } from "@/data/applicationsList";
import { Client, ClientStatusResponse } from "@/data/client";
import { DashboardAnalytics } from "@/data/dashboardAnalytics";
import { Started } from "@/utils/asyncOperationStatus";
import { Deferred, NotStarted } from "@/utils/deferred";
import {
  Effect,
  effectOfAction,
  effectsBatch,
} from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import { Eq } from "fp-ts/lib/Eq";
import { flow } from "fp-ts/lib/function";
import { none, Option } from "fp-ts/lib/Option";
import { Eq as EqString } from "fp-ts/lib/string";
import * as O from "fp-ts/Option";
import { DateTime } from "luxon";
import {
  Action,
  GetCurrentClient,
  LoadAnalytics
} from "./action";
import { dateRanges, SubmissionDateFilterOption } from "../ClientsListing/types";

export type Model = {
  selectedBranch: Option<string>;
  selectedInterval: ReportingInterval;
  analytics: Deferred<ApiResult<DashboardAnalytics>>;
  client: Deferred<ApiResult<Client>>;
  applications: Deferred<ApiResult<ApplicationListingResponse>>;

  isFirstLook: boolean;
  applicationsInterval: ReportingInterval;
  invitedMembers: O.Option<InvitedMember[]>;
  clientsListing: ClientsListing.Model;
};

export const analyticsIntervalValues = {
  week: "Last 7 days",
  month: "Last 30 days",
  year: "Last year",
};

export type InvitedMember = {
  email: string;
  phoneNumber: string;
};

export type ReportingInterval = keyof typeof analyticsIntervalValues;
export const EqAnalyticsInterval: Eq<ReportingInterval> = EqString;
export const analyticsIntervals: ReportingInterval[] = [
  "week",
  "month",
  "year",
] as const;

export const subtractIntervalFrom =
  (dateTime: DateTime) =>
  (interval: ReportingInterval): DateTime => {
    switch (interval) {
      case "week":
        return dateTime.minus({ days: 7 });
      case "month":
        return dateTime.minus({ days: 30 });
      case "year":
        return dateTime.minus({ years: 1 });
    }
  };

export const init = (
  date: DateTime,
  isFirstLook: boolean,
  clientStatus: Option<ClientStatusResponse>,
): [Model, Effect<Action>] => {
  const lastWeekFilterPayload = {
    ...emptyApplicationFilter,
    ...{pageSize: 7},
    ...dateRanges(SubmissionDateFilterOption.LastWeek),
  };
  
  return [
    {
      selectedBranch: none,
      selectedInterval: "month",
      analytics: NotStarted(),
      client: NotStarted(),
      isFirstLook,
      applicationsInterval: "week",
      invitedMembers: none,
      applications: NotStarted(), 
      clientsListing: ClientsListing.init(clientStatus, lastWeekFilterPayload)[0],

    },
    effectsBatch([
      effectOfAction(
        flow(
          Started,
          LoadAnalytics(subtractIntervalFrom(date)("month"), date),
        )(),
      ),
      effectOfAction(flow(Started, GetCurrentClient)()),
    ]),
  ];
};
