import {
  ApplicationListingResponse,
  ApplicationsFilterPayload,
  emptyApplicationFilter
} from "@/data/applicationsList";
import { ClientStatusResponse, UserId } from "@/data/client";
import {
  ApplicationId,
  ApplicationStatusType,
  ApplicationType,
} from "@/data/payload";
import { Deferred, NotStarted } from "@/utils/deferred";
import { Effect, noEffect } from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import { Action } from "./action";
import { CreditCheckConsentFilterOption, SubmissionDateFilterOption } from "./types";
import * as AssignUser from "./AssignUser/index";

export type Model = {
  applicationListReponse: Deferred<ApiResult<ApplicationListingResponse>>;
  search: string;
  statusFilter: Option<Set<ApplicationStatusType>>;
  mortgageTypeFilter: Option<Set<ApplicationType>>;
  submissionDateFilter: Option<SubmissionDateFilterOption>;
  assigneeFilter: Option<Set<UserId>>;
  creditCheckFilter: Option<CreditCheckConsentFilterOption>;
  exportStatus: Deferred<ApiResult<string>>;
  exportWarningShown: O.Option<[boolean, ApplicationId]>;

  clientStatus: O.Option<ClientStatusResponse>;
  searchParams: ApplicationsFilterPayload;

  assingmentModel: O.Option<AssignUser.Model>
};

export const init = (
  clientStatus: O.Option<ClientStatusResponse>,
  defaultSearchParams?: ApplicationsFilterPayload
): [Model, Effect<Action>] => {
  const model: Model = {
    applicationListReponse: NotStarted(),
    search: "",
    statusFilter: O.none,
    mortgageTypeFilter: O.none,
    submissionDateFilter: O.none,
    creditCheckFilter: O.none, 
    assigneeFilter: O.none,
    exportStatus: NotStarted(),
    exportWarningShown: O.none,
    clientStatus,
    searchParams: defaultSearchParams ? defaultSearchParams : emptyApplicationFilter,
    assingmentModel: O.none
  };
  return [model, noEffect];
};

export const withAssignee = (userId: UserId, model: Model) : Model => ({
  ...model, 
  searchParams: { ...model.searchParams, assignedToUserId: [userId]}
})

export const getSearchPayload =
  (model: Model) =>
  (partial: Partial<ApplicationsFilterPayload>): ApplicationsFilterPayload => ({
    ...model.searchParams,
    ...partial,
  });
