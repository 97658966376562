import { validDateTimeCodecS } from "@/utils/codecs";
import * as t from "io-ts";
import * as tt from "io-ts-types";

const branchCodec = t.type({
  branch: t.string,
});

export const customerProfileAveragesCodec = t.type({
  averageAge: t.number,
  averageAnnualIncome: tt.optionFromNullable(t.number),
  averageCreditScore: tt.optionFromNullable(t.number),
  averageTimeToCompleteDocumentUploadInHours: tt.optionFromNullable(t.number),
  averageTimeToCompleteSurveyInSeconds: tt.optionFromNullable(t.number),
  mostCommonLocation: t.string,
});

export const livingArrangementsCompositionCodec = t.type({
  livingRentFreePrecent: t.number,
  ownAndMakingPaymentsPercent: t.number,
  ownAndPaidInFullPercent: t.number,
  rentPercent: t.number,
});

export const loanTypesCompositionCodec = t.type({
  homeEquityPercent: t.number,
  purchasePercent: t.number,
  refinancePercent: t.number,
});

export const referralSourceItemCodec = t.type({
  description: t.string,
  percent: t.number,
});

export const workItemCodec = t.type({
  applicationsRequiringFollowupCount: t.number,
  averageTimeToExportInHours: tt.optionFromNullable(t.number),
});

export const surveyActivityCodec = t.type({
  activityDate: t.string.pipe(validDateTimeCodecS),
  newSurveys: t.number,
  applicationsExported: t.number,
});

export type SurveyActivity = t.TypeOf<typeof surveyActivityCodec>;

export const dashboardAnalyticsCodec = t.type({
  applicationsRequiringFollowUp: t.number,
  averageTimeToExportInHours: t.number,
  customerProfileAverages: t.array(
    t.intersection([branchCodec, customerProfileAveragesCodec]),
  ),

  livingArrangements: t.array(
    t.intersection([branchCodec, livingArrangementsCompositionCodec]),
  ),
  loanTypes: t.array(t.intersection([branchCodec, loanTypesCompositionCodec])),
  referralSources: t.array(
    t.type({
      branch: t.string,
      referralSourceItems: t.array(referralSourceItemCodec),
    }),
  ),
  surveyActivities: t.array(t.intersection([branchCodec, surveyActivityCodec])),
  workItems: t.array(workItemCodec),
});

export type CustomerProfileAverages = t.TypeOf<
  typeof customerProfileAveragesCodec
>;
export type LivingArrangementsComposition = t.TypeOf<
  typeof livingArrangementsCompositionCodec
>;
export type LoanTypesComposition = t.TypeOf<typeof loanTypesCompositionCodec>;
export type ReferralSourceItem = t.TypeOf<typeof referralSourceItemCodec>;
export type WorkItem = t.TypeOf<typeof workItemCodec>;
export type DashboardAnalytics = t.TypeOf<typeof dashboardAnalyticsCodec>;
