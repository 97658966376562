import "@/assets/styles/PropertyInformation/propertyInformation.css";
import citiHouseImage from "@/assets/images/CityHouse.svg";
import secondHomeImage from "@/assets/images/SecondHome.svg";
import enjoyNewHomeImage from "@/assets/images/EnjoyNewHome.svg";
import loanRequestImage from "@/assets/images/LoanRequest.svg";

import { ApplicationType, PropertyType } from "@/data/payload";
import { Button, Col, Icon, Row } from "@/components/basic";
import { constant, flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import { ChildProps } from "@/utils/reducerWithEffect";
import { Model } from "./model";
import { Action, ApplicationTypeSelected } from "./action";
import { PropertyInformationPayload } from "@/data/surveyFlowPayload";
import { ViewResolver } from "@/utils/viewResolver";

export type Props = ChildProps<Model, Action> & {
  onCompleted: (payload: PropertyInformationPayload) => void;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  switch (model.page) {
    case "ApplicationType":
      return (
        <ApplicationTypeView
          applicationTypeSelected={flow(ApplicationTypeSelected, dispatch)}
        />
      );
    case "PropertyType":
      return (
        <PropertyTypeView
          propertyTypeSelected={(propertyType) =>
            props.onCompleted({
              applicationType: model.selectedApplicationType,
              propertyType,
            })
          }
        />
      );
  }
}

const propertyImages = {
  cityHouse: citiHouseImage,
  secondHome: secondHomeImage,
  enjoyNewHome: enjoyNewHomeImage,
  loanRequest: loanRequestImage,
};

type PropertyImage = keyof typeof propertyImages;

type ApplicationTypeProps = {
  applicationTypeSelected: (applicationType: ApplicationType) => void;
};

function ApplicationTypeView(props: ApplicationTypeProps): JSX.Element {
  return (
    <Col gap="sm" padding="sm">
      <div className="survey-flow-header">What are you looking for?</div>
      <p className="survey-flow-description" >
        App Catcher makes the process simple whether you're Purchasing,
        Refinancing to lower your rate or wanting to use your Home Equity for
        Improvements or consolidate debt to lower your monthly payment
        obligations. App Catcher takes the complexity out of obtaining a home
        loan saving you valuable time and making your lender more efficient
        resulting in a better interest rate and lower costs for you!
      </p>

      <PropertyItem
        image="cityHouse"
        title="Purchase a property"
        description={O.none}
        onClick={flow(
          constant(ApplicationType.Purchase),
          props.applicationTypeSelected,
        )}
      />
      <PropertyItem
        image="loanRequest"
        title="Refinance my mortgage"
        description={O.none}
        onClick={flow(
          constant(ApplicationType.Refinance),
          props.applicationTypeSelected,
        )}
      />
      <PropertyItem
        image="enjoyNewHome"
        title="Access home equity"
        description={O.none}
        onClick={flow(
          constant(ApplicationType.Equity),
          props.applicationTypeSelected,
        )}
      />
    </Col>
  );
}

type PropertyTypeProps = {
  propertyTypeSelected: (propertyType: PropertyType) => void;
};

function PropertyTypeView(props: PropertyTypeProps): JSX.Element {
  return (
    <Col gap="sm" padding="sm">
      <div className="survey-flow-header">What are you looking for?</div>
      <p className="survey-flow-description">
        App Catcher makes the process simple whether you're Purchasing,
        Refinancing to lower your rate or wanting to use your Home Equity for
        Improvements or consolidate debt to lower your monthly payment
        obligations. App Catcher takes the complexity out of obtaining a home
        loan saving you valuable time and making your lender more efficient
        resulting in a better interest rate and lower costs for you!
      </p>

      <PropertyItem
        image="enjoyNewHome"
        title="Primary residence"
        description={O.none}
        onClick={flow(
          constant(PropertyType.Primary),
          props.propertyTypeSelected,
        )}
      />
      <PropertyItem
        image="secondHome"
        title="Second home"
        description={O.none}
        onClick={flow(
          constant(PropertyType.SecondHome),
          props.propertyTypeSelected,
        )}
      />
      <PropertyItem
        image="loanRequest"
        title="Investment property"
        description={O.none}
        onClick={flow(
          constant(PropertyType.Investment),
          props.propertyTypeSelected,
        )}
      />
    </Col>
  );
}

type PropertyItemProps = {
  image: PropertyImage;
  title: string;
  description: Option<string>;
  onClick: () => void;
};

function PropertyItem(props: PropertyItemProps): JSX.Element {

  const PropertyItem = (
    <>
      <img className="property-img" src={propertyImages[props.image]} alt={props.image} />
      <Col gap="sm" grow={1}>
        <h3>{props.title}</h3>
        {pipe(
          props.description,
          O.fold(
            () => null,
            (description) => <p>{description}</p>,
          ),
        )}
      </Col>
      <Button
        type="primary"
        onClick={O.some(()=> {})}
        className="property-item-button"
      >
        <Icon type="arrow-right" />
      </Button>
    </>
  );

  const PropertyItemCollapseView = (
    <>
      <Col gap="sm" grow={1} className="w-100">
        <Button
        type="secondary"
        onClick={O.some(props.onClick)}
        className="property-item-button"
      >
         {props.title}
         <i className="fa-solid fa-arrow-right ml-auto"></i>
      </Button>
      </Col>
    </>
  )



  const normalView = (
    <Row padding="lg" gap="sm" alignVertical="center" className="property-item" onClick={props.onClick}>
      {PropertyItem}
    </Row>
  )

  const collapsedView = (
    <Col gap="sm" alignHorizontal="left" className="property-item collapse-view w-100">
      {PropertyItemCollapseView}
    </Col>
  )

  const PropertyItemWrapper = (
    ViewResolver({
      viewModes: [["Default"], ["Mobile-Portrait"]],
      resolvedContent: [normalView, collapsedView]
    })
  )
  return (
    PropertyItemWrapper
  );
}
