import { ApplicationType } from "@/data/payload";
import { Model } from "./model";

export type Action = {
  type: "ApplicationTypeSelected";
  applicationType: ApplicationType;
};

export const ApplicationTypeSelected = (
  applicationType: ApplicationType,
): Action => ({
  type: "ApplicationTypeSelected",
  applicationType,
});

export const update = (model: Model, action: Action): Model => {
  switch (action.type) {
    case "ApplicationTypeSelected":
      return {
        ...model,
        page: "PropertyType",
        selectedApplicationType: action.applicationType,
      };
  }
};
