import { Button, Col, Input, Label, Modal, Select } from "@/components/basic";
import { ApplicationListingResponse } from "@/data/applicationsList";
import { EqUserId, User, UserId } from "@/data/client";
import {
  Deferred,
  deferredToOption,
  isLoading,
  mapDeferred,
} from "@/utils/deferred";
import { ApiResult, showApiError } from "@/utils/request";
import { getFullName } from "@/utils/user";
import * as E from "fp-ts/Either";
import { constant, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import { useMemo, useState } from "react";

export function View(props: {
  userOptions: UserId[];
  user: Option<User>;
  lookupUserName: (userId: UserId) => string;
  reassignTo: Option<UserId>;
  onChangeReassign: (userId: UserId) => void;
  onConfirmReassign: () => void;
  onClose: O.Option<() => void>;
  reassignmentConfirmed: boolean;
  deleteDone: boolean;
  assignedLoans: Deferred<ApiResult<ApplicationListingResponse>>;
}): JSX.Element {
  const { user, assignedLoans } = props;
  const userId = pipe(
    user,
    O.fold(constant(-1), (u) => u.userId),
  );
  const { userOptions, lookupUserName, reassignTo, onChangeReassign } = props;
  const [isDeleteTypingConfirmed, setIsDeleteTypingConfirmed] = useState(
    O.isSome(reassignTo) || props.reassignmentConfirmed,
  );
  const [confirmationText, setConfirmationText] = useState("");

  const totalApplications = useMemo(
    () =>
      pipe(
        assignedLoans,
        mapDeferred(E.map((v) => v.totalCount)),
        deferredToOption,
        O.chain(O.fromEither),
        O.getOrElse(constant(0)),
      ),
    [assignedLoans],
  );
  const isLoanCountLoading = useMemo(
    () => isLoading(assignedLoans),
    [assignedLoans],
  );

  const errorMessage = useMemo(
    () =>
      assignedLoans.status == "Resolved" &&
      E.isLeft(assignedLoans.value) &&
      showApiError(assignedLoans.value.left),
    [assignedLoans],
  );

  if (isLoanCountLoading) {
    return (
      <Modal onClose={props.onClose} title={O.some("Delete User")}>
        <div className="w-20em">
          {" "}
          Plase wait while we are checking if the user can be deleted!
        </div>
      </Modal>
    );
  }

  if (errorMessage) {
    return (
      <Modal onClose={props.onClose} title={O.some("Delete User")}>
        <p>There was an error while processing. Please try later. </p>
        <p> Details</p>
        <p> {errorMessage}</p>
      </Modal>
    );
  }

  const userName = pipe(user, O.fold(constant(""), getFullName));
  if (!isDeleteTypingConfirmed) {
    return (
      <Modal onClose={props.onClose} title={O.some("Are you sure ? ")}>
        <Col
          alignHorizontal="center"
          alignVertical="center"
          padding="md"
          gap="sm"
        >
          <div className="text-center w-20em">
            You have requested that you delete{" "}
            <div className="text-bold">{userName}. </div>
            <div>
              Type the word
              <span className="text-bold"> DELETE</span> below
            </div>
          </div>
          <Col alignHorizontal="center" gap="xs">
            <Input
              type="text"
              className="w-20em"
              value={confirmationText}
              onChange={(value) => setConfirmationText(value)}
            />
            <Button
              className="w-80"
              onClick={O.some(() =>
                confirmationText === "DELETE"
                  ? setIsDeleteTypingConfirmed(true)
                  : identity,
              )}
              type="primary"
            >
              Delete User
            </Button>
          </Col>
        </Col>
      </Modal>
    );
  }

  if (!props.reassignmentConfirmed) {
    return (
      <Modal onClose={props.onClose} title={O.some("Reassign Clients ")}>
        <Col padding="md" gap="xs" alignHorizontal="center">
          <Label>
            <p style={{ width: "20em" }}>
              <span className="text-bold">{userName}</span> has{" "}
              {totalApplications} borrowers which still need to be processed.
              Which user would you like to reassign these clients to?
            </p>
          </Label>
          <Select
            selectContainerClassName="w-100"
            options={userOptions.filter((v) => v != userId)}
            selected={reassignTo}
            valueEq={EqUserId}
            renderLabel={lookupUserName}
            onChange={onChangeReassign}
          />
          <Button
            onClick={O.some(() => props.onConfirmReassign())}
            type="primary"
            className="w-80"
          >
            Continue{" "}
          </Button>
        </Col>
      </Modal>
    );
  }

  if (props.deleteDone) {
    return (
      <Modal onClose={props.onClose} title={O.some("Completed")}>
        <Col alignHorizontal="center" gap="sm" padding="sm">
          <div className="w-20em text-center">
            {totalApplications > 0 ? (
              <>
                Borrowers have been reassigned to
                <p className="text-bold">{userName}</p>
              </>
            ) : (
              <>
                <span className="text-bold">{userName}</span> successfully deleted
              </>
            )}
          </div>
          <Button onClick={props.onClose} type="primary" className="w-80">
            Close
          </Button>
        </Col>
      </Modal>
    );
  }

  return (
    <Col alignHorizontal="center">
      <div className="w-20em text-center">
        {totalApplications > 0 ? (
          <>
            You are about to reassign {totalApplications} borrowers to {" "}
            <span className="text-bold">{userName}</span>
          </>
        ) : (
          <>
            Please confirm deletion of <p className="text-bold">{userName}</p>
          </>
        )}
      </div>
    </Col>
  );
}
