import * as t from "io-ts";
import * as tt from "io-ts-types";

const subdmainRegex = /^[A-Za-z0-9]{4,}$/;

interface ValidSubdomainBrand {
  readonly ValidSubdomainBrand: unique symbol;
}

export const subdomainCodec = tt.withMessage(
  t.brand(
    tt.NonEmptyString,
    (s): s is t.Branded<tt.NonEmptyString, ValidSubdomainBrand> =>
      subdmainRegex.test(s),
    "ValidSubdomainBrand",
  ),
  () => "invalid subdomain",
);
