import { Checkbox, Col, Label, Row } from "@/components/basic";
import { deferredToOption, isResolved } from "@/utils/deferred";
import { constFalse, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Model } from "./model";
import { useMemo } from "react";
import { ChildProps } from "@/utils/reducerWithEffect";
import { SmsNotificationsToggled } from "./action";
import { Action } from "./action";

export type Props = ChildProps<Model, Action>;

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;
  const settings = useMemo(
    () => {
      return pipe(model.accountSettings, deferredToOption, O.chain(O.fromEither))
    },
    [model],
  );
  return (
    <Col padding="xs" gap="md">
      <Checkbox
        checked={pipe(
          settings,
          O.fold(
            constFalse,
            ({ smsNotificationsDisabled }) => !smsNotificationsDisabled,
          ),
        )}
        disabled={!isResolved(model.accountSettings)}
        label="Allow SMS messaging notifications"
        onChange={flow(SmsNotificationsToggled, dispatch)}
      />
      <Col>
        <Label className="action-70 text-bold-600">What type of notifications will I receive?</Label>
        <Row>
          <ul className="notification-settings-ul color-action-70">
            <li className="text-md">Application issues (when there is an issue with your application that requires your attention.</li>
            <li className="text-md">Unfinished, aging application (a warning if your unfinished application is soon to be deleted)</li>
            <li className="text-md">Application deleted</li>
          </ul>
        </Row> 
      </Col>
    </Col>
  );
}
