import { NonEmptyString } from "io-ts-types";
import * as PersonalInformation from "../PersonalInformation";
import * as FinancialInformation from "../FinancialInformation";
import {
  ApplicantFinancialInfo,
  ApplicantPersonalInfo,
  ApplicationInfo,
  JointApplicantPayload,
  JointApplicantType,
  ReferralSource,
  SourceOfFunds,
  jointApplicantTypeCodec,
  sourceOfFundsCodec,
} from "../../data/payload";
import { FormField, initFormField } from "@/utils/formField";
import { nonEmptyStringCodec } from "@/utils/codecs/nonEmptyString";
import * as t from "io-ts";
import { sequenceS } from "fp-ts/Apply";
import * as E from "fp-ts/Either";
import * as A from "fp-ts/Array";
import { pipe, flow } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import * as O from "fp-ts/Option";
import * as NEA from "fp-ts/NonEmptyArray";

export type RealEstateAgent = {
  name: FormField<NonEmptyString>;
  phoneNumber: FormField<NonEmptyString>;
};

export const initRealEstateAgent = (): RealEstateAgent => ({
  name: initFormField(nonEmptyStringCodec().decode)(""),
  phoneNumber: initFormField(nonEmptyStringCodec().decode)(""),
});

export type CoApplicant = {
  jointApplicantType: FormField<JointApplicantType>;
  personalInformation: PersonalInformation.Model;
  financialInformation: FinancialInformation.Model;
  jointApplicantId: number;
};

export const initCoApplicant = (
  coApplicant: Option<JointApplicantPayload>,
): CoApplicant => ({
  jointApplicantType: pipe(
    coApplicant,
    O.fold(
      () => "",
      ({ applicantType }) => applicantType,
    ),
    initFormField(jointApplicantTypeCodec.decode),
  ),
  personalInformation: PersonalInformation.init(
    pipe(
      coApplicant,
      O.map(({ applicant }) => applicant),
    ),
  ),
  financialInformation: FinancialInformation.init(
    pipe(
      coApplicant,
      O.map(({ applicant }) => applicant),
    ),
  ),
  jointApplicantId: pipe(
    coApplicant,
    O.map(({ jointApplicantId }) => jointApplicantId),
    O.fold(
      () => 0,
      (id) => id 
    )
  ),
});

export type Model = {
  isWorkingWithAgent: boolean;
  realEstateAgent: RealEstateAgent;
  coApplicants: CoApplicant[];
  referralSource: Option<ReferralSource>;
  referringLoanOfficerId: Option<number>;
  sourceOfFunds: FormField<SourceOfFunds>;
};

export const init = (applicationInfo: Option<ApplicationInfo>): Model => ({
  isWorkingWithAgent: false,
  realEstateAgent: pipe(
    applicationInfo,
    O.fold(
      initRealEstateAgent,
      ({ realEstateAgentName, realEstateAgentPhone }): RealEstateAgent => ({
        name: pipe(
          realEstateAgentName,
          O.getOrElse(() => ""),
          initFormField(nonEmptyStringCodec().decode),
        ),
        phoneNumber: pipe(
          realEstateAgentPhone,
          O.getOrElse(() => ""),
          initFormField(nonEmptyStringCodec().decode),
        ),
      }),
    ),
  ),
  coApplicants: pipe(
    applicationInfo,
    O.fold(
      () => [],
      ({ jointApplicants }) =>
        pipe(jointApplicants, A.map(flow(O.some, initCoApplicant))),
    ),
  ),
  referralSource: pipe(
    applicationInfo,
    O.chain((a) => a.referralSource),
  ),
  referringLoanOfficerId: pipe(
    applicationInfo,
    O.chain((a) => a.referringLoanOfficerId),
  ),
  sourceOfFunds: pipe(
    applicationInfo,
    O.chain(({ sourceOfFunds }) => A.head(sourceOfFunds)),
    O.getOrElse(() => ""),
    initFormField(sourceOfFundsCodec.decode),
  ),
});

export const applicantResult = (
  model: CoApplicant,
): t.Validation<JointApplicantPayload> =>
  pipe(
    E.of(
      (applicantType: JointApplicantType) =>
        (personal: ApplicantPersonalInfo) =>
        (financial: ApplicantFinancialInfo): JointApplicantPayload => ({
          applicantType,
          applicant: { ...personal, ...financial },
          jointApplicantId: model.jointApplicantId
        }),
    ),
    E.ap(model.jointApplicantType.val),
    E.ap(PersonalInformation.result(model.personalInformation)),
    E.ap(FinancialInformation.result(model.financialInformation)),
  );

export const result = (model: Model): t.Validation<ApplicationInfo> =>
  sequenceS(E.Apply)({
    jointApplicants: A.traverse(E.Applicative)(applicantResult)(
      model.coApplicants,
    ),
    sourceOfFunds: pipe(model.sourceOfFunds.val, E.map(NEA.of)),
    referringLoanOfficerId: E.right(model.referringLoanOfficerId),
    workingWithRealEstateAgent: E.of(model.isWorkingWithAgent),
    realEstateAgentName: pipe(
      model.realEstateAgent.name.val,
      O.fromEither,
      E.right,
    ),
    referralSource: E.right(model.referralSource),
    loanOfficerNote: E.right(O.some("")),
    realEstateAgentPhone: pipe(
      model.realEstateAgent.phoneNumber.val,
      O.fromEither,
      E.right,
    ),
  });
