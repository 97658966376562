import { Model } from "./model";
import { applicationTypeCodec, propertyTypeCodec } from "../../data/payload";
import { updateFormField } from "../../utils/formField";

export type Action = 
  | {
    type: "ApplicationTypeChanged";
    applicationType: string;
  } 
  | {
    type: "PropertyTypeChanged";
    propertyType: string;
  }

export const ApplicationTypeChanged = (applicationType: string): Action => ({
  type: "ApplicationTypeChanged",
  applicationType
});

export const PropertyTypeChanged = (propertyType: string): Action => ({
  type: "PropertyTypeChanged",
  propertyType
});

export const update = (model: Model, action: Action): Model => {
  switch (action.type) {
    case "ApplicationTypeChanged":
      return { 
        ...model, 
        applicationType: updateFormField(applicationTypeCodec.decode)(action.applicationType)
      };

    case "PropertyTypeChanged":
      return { 
        ...model, 
        propertyType: updateFormField(propertyTypeCodec.decode)(action.propertyType)
      };
  }
}