import * as Documents from "@/components/Documents";
import * as EmployerResolver from "@/components/EmployerResolver";
import * as FinancialInformation from "@/components/FinancialInformation";
import * as ManualDocClassifier from "@/components/ManualDocClassifier";
import * as MortgageInformation from "@/components/MortgageInformation";
import * as PersonalInformation from "@/components/PersonalInformation";
import * as SurveyInitiation from "@/components/SurveyInitiation";
import { ClientStatus, ClientStatusResponse, User } from "@/data/client";
import { ConsentItem, SurveyConsentItem } from "@/data/consents";
import {
  Application,
  ApplicationId,
  EmployersPayload,
  LoanOfficerInfo,
  SavedSurveyPayload,
} from "@/data/payload";
import { Started } from "@/utils/asyncOperationStatus";
import { Deferred, NotStarted } from "@/utils/deferred";
import { FormField, initFormField } from "@/utils/formField";
import {
  Effect,
  effectOfAction,
  effectsBatch,
  mapEffect,
} from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import { sequenceS } from "fp-ts/lib/Apply";
import * as E from "fp-ts/lib/Either";
import { constFalse, flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as t from "io-ts";
import * as tt from "io-ts-types";
import { cloneDeep } from "lodash-es";
import { Action, DocumentsAction, LoadConsentMeta } from "./action";

export enum ApplicationViewMode {
  Readonly = "Readonly",
  Edit = "Edit",
}

export type Model = {
  application: Application;
  applicationId: ApplicationId;
  surveyInitiation: SurveyInitiation.Model;
  primaryApplicant: {
    personalInformation: PersonalInformation.Model;
    financialInformation: FinancialInformation.Model;
    consents: SurveyConsentItem[];
  };
  mortgageInformation: MortgageInformation.Model;
  documents: Documents.Model;
  employerResolverModel: O.Option<EmployerResolver.Model>;
  employers: O.Option<EmployersPayload[]>;
  manualClassification: ManualDocClassifier.Model;

  exportStatus: Deferred<ApiResult<string>>;

  clientStatus: O.Option<ClientStatusResponse>;
  //Tristate boolean: None if warning is not needed, and false if not shown, and true if accepted
  exportWarningShown: O.Option<boolean>;

  mode: ApplicationViewMode;
  user: O.Option<User>;

  constentMeta: Deferred<ApiResult<ConsentItem[]>>;
  loanOfficer: O.Option<LoanOfficerInfo>;
  originalApplicationSurveyInfo: SavedSurveyPayload;

  //Lo Notes
  isEditingLoNotes: boolean;
  loNotesFormField: FormField<O.Option<string>>;
  saveLoNotesStatus: Deferred<ApiResult<void>>;
  // missingEmployer: O.Option<EmployersPayload>;
};

export const init = (
  application: Application,
  clientStatus: O.Option<ClientStatusResponse>,
  user: O.Option<User>,
): [Model, Effect<Action>] => {
  const [documentsModel, documentsEffect] = Documents.init(
    application,
    pipe(
      clientStatus,
      O.fold(constFalse, (v) => v.status != ClientStatus.Restricted),
    ),
  );
  const surveyInfo: SavedSurveyPayload = application.survey;
  surveyInfo.primaryApplicant.employers = [];

  const savedSurvey = cloneDeep(surveyInfo);
  savedSurvey.primaryApplicant.consents = [];
  return [
    {
      application,
      applicationId: application.applicationId,
      surveyInitiation: SurveyInitiation.init(E.right(application.survey)),
      primaryApplicant: {
        personalInformation: PersonalInformation.init(
          O.some(application.survey.primaryApplicant),
        ),
        financialInformation: FinancialInformation.init(
          O.some(application.survey.primaryApplicant),
        ),
        consents: application.survey.primaryApplicant.consents,
      },
      mortgageInformation: MortgageInformation.init(O.some(application.survey)),
      documents: documentsModel,
      employers: O.none,
      employerResolverModel: O.none,
      manualClassification: ManualDocClassifier.init(),
      exportStatus: NotStarted(),
      exportWarningShown: O.none,
      clientStatus,
      mode: ApplicationViewMode.Readonly,
      user,
      constentMeta: NotStarted(),
      loanOfficer: O.none,
      originalApplicationSurveyInfo: savedSurvey,
      isEditingLoNotes: false,
      loNotesFormField: pipe(
        application.survey.loanOfficerNote,
        O.fold(() => "", identity),
        initFormField(tt.optionFromNullable(t.string).decode),
      ),
      saveLoNotesStatus: NotStarted(),
    },
    effectsBatch([
      mapEffect(DocumentsAction)(documentsEffect),
      effectOfAction(flow(Started, LoadConsentMeta)()),
    ]),
  ];
};

export function result(model: Model): t.Validation<SavedSurveyPayload> {
  return pipe(
    sequenceS(E.Apply)({
      surveyTypeInfo: SurveyInitiation.result(model.surveyInitiation),
      applicantPersonalInformation: PersonalInformation.result(
        model.primaryApplicant.personalInformation,
      ),
      applicantFinancialInformation: FinancialInformation.result(
        model.primaryApplicant.financialInformation,
        model.application.survey.primaryApplicant
          .providedConsentForElectronicBusiness,
      ),
      applicationInfo: {
        ...MortgageInformation.result(model.mortgageInformation),
      },
      loanOfficerNote: model.loNotesFormField.val,
    }),
    E.map(
      ({
        surveyTypeInfo,
        applicantPersonalInformation,
        applicantFinancialInformation,
        applicationInfo,
        loanOfficerNote,
      }): SavedSurveyPayload => ({
        ...surveyTypeInfo,
        primaryApplicant: {
          ...applicantFinancialInformation,
          ...applicantPersonalInformation,
          consents: [],
        },
        ...applicationInfo,
        loanOfficerNote: pipe(
          loanOfficerNote,
          O.fromPredicate((v) => O.isSome(v) && v.value.trim().length > 0),
          O.flatten
        ),
        applicationId: model.application.applicationId,
        surveyId: model.application.survey.surveyId,
      }),
    ),
  );
}
