import { AssignmentsPayload } from "@/data/application-assignments";
import { Client, ExtensionRole, User } from "@/data/client";
import { Api } from "@/utils/api";
import { AsyncOperationStatus, Finished } from "@/utils/asyncOperationStatus";
import { NotStarted, Resolved, updatingDeferred } from "@/utils/deferred";
import { Effect, effectOfAsync, noEffect } from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import { flow } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import { assignedOfficer, Model } from "./model";

export type Action =
  | {
      type: "GetCurrentClient";
      operation: AsyncOperationStatus<ApiResult<Client>>;
    }
  | {
      type: "ChooseLoanOfficer";
      user: User;
    }
  | {
      type: "PerformAssignments";
      operation: AsyncOperationStatus<ApiResult<unknown>>;
      payload: AssignmentsPayload;
    }
  | {
      type: "ChooseSupportPartner";
      user: User;
    };

export const GetCurrentClient = (
  operation: AsyncOperationStatus<ApiResult<Client>>,
): Action => ({
  type: "GetCurrentClient",
  operation,
});

export const ChooseLoanOfficer = (user: User): Action => ({
  type: "ChooseLoanOfficer",
  user,
});

export const ChooseSupportPartner = (user: User): Action => ({
  type: "ChooseSupportPartner",
  user,
});

export const PerformAssignments =
  (payload: AssignmentsPayload) =>
  (operation: AsyncOperationStatus<ApiResult<unknown>>): Action => ({
    type: "PerformAssignments",
    operation,
    payload,
  });

export const update =
  (api: Api) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {

    switch (action.type) {
      case "GetCurrentClient":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                { ...model, client: updatingDeferred(model.client) },
                effectOfAsync(
                  api.getCurrentClient,
                  flow(Finished, GetCurrentClient),
                ),
              ];

            case "Finished": {
              return [
                {
                  ...model,
                  client: Resolved(action.operation.result),
                  selectedLoanOfficer: assignedOfficer(
                    ExtensionRole.LoanOfficer,
                    model.application,
                    action.operation.result,
                  ),
                  selectedSupportPartner: assignedOfficer(
                    ExtensionRole.Support,
                    model.application,
                    action.operation.result,
                  ),
                },

                noEffect,
              ];
            }
          }
        }
        break;

      case "ChooseLoanOfficer": {
        return [
          {
            ...model,
            selectedLoanOfficer: O.some(action.user),
            assignmentStatus: NotStarted(),
          },
          noEffect,
        ];
      }

      case "ChooseSupportPartner": {
        return [
          {
            ...model,
            selectedSupportPartner: O.some(action.user),
            assignmentStatus: NotStarted(),
          },
          noEffect,
        ];
      }

      case "PerformAssignments": {
        switch (action.operation.status) {
          case "Started":
            return [
              {
                ...model,
                assignmentStatus: updatingDeferred(model.assignmentStatus),
              },
              effectOfAsync(
                api.assignUsersToApplication(action.payload),
                flow(Finished, PerformAssignments(action.payload)),
              ),
            ];

          case "Finished": {
            return [
              { ...model, assignmentStatus: Resolved(action.operation.result) },
              noEffect,
            ];
          }
        }
      }
    }
  };
