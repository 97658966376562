import { LoaderMessages, LoaderView } from "../basic/Loaders"
export type Props = {
  onLogin: () => void;
  onSignUp: () => void;
  onNewApplication: () => void;
};

export function View(): JSX.Element {
  return (
    <LoaderView loaderMessage={LoaderMessages.default}></LoaderView>
  );
}
