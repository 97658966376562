import {
  ApplicationDocument,
  DocumentId,
  EqRequirementId,
  RequirementId,
} from "@/data/applicationDocument";
import { Api } from "@/utils/api";
import { AsyncOperationStatus, Finished } from "@/utils/asyncOperationStatus";
import {
  InProgress,
  mapDeferred,
  NotStarted,
  Resolved,
  updatingDeferred,
} from "@/utils/deferred";
import { Effect, effectOfAsync, noEffect } from "@/utils/reducerWithEffect";
import { ApiResult, DataURIToBlob as dataURIToBlob } from "@/utils/request";
import * as E from "fp-ts/lib/Either";
import { flow, pipe } from "fp-ts/lib/function";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import * as O from "fp-ts/lib/Option";
import { some } from "fp-ts/lib/Option";
import * as S from "fp-ts/lib/Set";
import { Model } from "./model";

export type Action =
  | {
      type: "UploadInitiated";
    }
  | {
      type: "ResetImageUploadStatus";
    }
  | {
      type: "GetDocuments";
      operation: AsyncOperationStatus<ApiResult<ApplicationDocument[]>>;
    }
  | {
      type: "DownloadDocument";
      documentId: DocumentId;
      operation: AsyncOperationStatus<ApiResult<string>>;
    }
  | {
      type: "DownloadAllDocuments";
      operation: AsyncOperationStatus<ApiResult<string>>;
    }
  | {
      type: "UploadImage";
      image: string;
      operation: AsyncOperationStatus<ApiResult<void>>;
    }
  | {
      type: "DocumentsSelected";
      files: NonEmptyArray<File>;
    }
  | {
      type: "SubmitDocuments";
      files: NonEmptyArray<File>;
      operation: AsyncOperationStatus<ApiResult<void>>;
    }
  | {
      type: "DeleteDocument";
      documentId: DocumentId;
      operation: AsyncOperationStatus<ApiResult<void>>;
    }
  | {
      type: "SkipDocumentChanged";
      requirementId: RequirementId;
      skip: boolean;
    };

export const GetDocuments = (
  operation: AsyncOperationStatus<ApiResult<ApplicationDocument[]>>,
): Action => ({
  type: "GetDocuments",
  operation,
});

export const ResetImageUploadStatus = (): Action => ({
  type: "ResetImageUploadStatus",
});

export const DownloadDocument =
  (documentId: DocumentId) =>
  (operation: AsyncOperationStatus<ApiResult<string>>): Action => ({
    type: "DownloadDocument",
    operation,
    documentId,
  });

export const DownloadAllDocuments =
  (operation: AsyncOperationStatus<ApiResult<string>>): Action => ({
    type: "DownloadAllDocuments",
    operation,
  });

export const DocumentsSelected = (files: NonEmptyArray<File>): Action => ({
  type: "DocumentsSelected",
  files,
});

export const SubmitDocuments =
  (files: NonEmptyArray<File>) =>
  (operation: AsyncOperationStatus<ApiResult<void>>): Action => ({
    type: "SubmitDocuments",
    files,
    operation,
  });

export const UploadImage =
  (image: string) =>
  (operation: AsyncOperationStatus<ApiResult<void>>): Action => ({
    type: "UploadImage",
    image,
    operation,
  });

export const DeleteDocument =
  (documentId: DocumentId) =>
  (operation: AsyncOperationStatus<ApiResult<void>>): Action => ({
    type: "DeleteDocument",
    documentId,
    operation,
  });

export const SkipDocumentChanged =
  (requirementId: RequirementId) =>
  (skip: boolean): Action => ({
    type: "SkipDocumentChanged",
    requirementId,
    skip,
  });

export const update = (
  api: Api,
  model: Model,
  action: Action,
): [Model, Effect<Action>] => {
  switch (action.type) {
    case "UploadInitiated": {
      return [model, noEffect];
    }

    case "ResetImageUploadStatus": {
      return [{ ...model, imageUploadStatus: NotStarted() }, noEffect];
    }

    case "GetDocuments":
      {
        switch (action.operation.status) {
          case "Started":
            return [
              {
                ...model,
                requiredDocuments: updatingDeferred(model.requiredDocuments),
              },
              effectOfAsync(
                api.getDocuments(model.applicationId),
                flow(Finished, GetDocuments),
              ),
            ];

          case "Finished":
            return [
              {
                ...model,
                requiredDocuments: Resolved(action.operation.result),
              },
              noEffect,
            ];
        }
      }
      break;

    case "DownloadDocument":
      {
        switch (action.operation.status) {
          case "Started":
            return [
              {
                ...model,
              },
              effectOfAsync(
                api.downloadDocument(model.applicationId, action.documentId),
                flow(Finished, DownloadDocument(action.documentId)),
              ),
            ];

          case "Finished":
            return [
              {
                ...model,
              },
              noEffect,
            ];
        }
      }
      break;

    case "DownloadAllDocuments":
      {
        switch (action.operation.status) {
          case "Started":
            return [
              {
                ...model,
              },
              effectOfAsync(
                api.downloadAllDocuments(model.applicationId),
                flow(Finished, DownloadAllDocuments),
              ),
            ];

          case "Finished":
            return [
              {
                ...model,
              },
              noEffect,
            ];
        }
      }
      break;

    case "DocumentsSelected": {
      return [{ ...model, selectedDocuments: some(action.files) }, noEffect];
    }

    case "SubmitDocuments":
      {
        switch (action.operation.status) {
          case "Started": {
            return [
              { ...model, documentsSubmission: InProgress() },
              effectOfAsync(
                api.submitDocuments(model.applicationId)(action.files),
                flow(Finished, SubmitDocuments(action.files)),
              ),
            ];
          }
          case "Finished":
            return [
              {
                ...model,
                documentsSubmission: Resolved(action.operation.result),
                selectedDocuments: pipe(
                  action.operation.result,
                  E.fold(
                    (_) => model.selectedDocuments,
                    (_) => O.none,
                  ),
                ),
              },
              noEffect,
            ];
        }
      }
      break;

    case "UploadImage":
      {
        switch (action.operation.status) {
          case "Started": {
            const file = dataURIToBlob(action.image);
            const formData = new FormData();
            formData.append("upload", file, "image.jpg");
            return [
              { ...model, imageUploadStatus: InProgress() },
              effectOfAsync(
                api.submitImage(model.applicationId)(formData),
                flow(Finished, UploadImage(action.image)),
              ),
            ];
          }
          case "Finished":
            return [
              {
                ...model,
                imageUploadStatus: Resolved(action.operation.result),
              },
              noEffect,
            ];
        }
      }
      break;

    case "DeleteDocument":
      {
        switch (action.operation.status) {
          case "Started": {
            const updatedDocsList = pipe(
              model.requiredDocuments,
              mapDeferred(
                E.map((docs) =>
                  docs.filter(({ documentId }) =>
                    O.isNone(documentId) || 
                    pipe(
                      documentId,
                      O.exists((v) => v != action.documentId),
                    ),
                  ),
                ),
              ),
            );
            return [
              {
                ...model,
                requiredDocuments: updatingDeferred(updatedDocsList),
              },
              effectOfAsync(
                api.deleteDocument(model.applicationId, action.documentId),
                flow(Finished, DeleteDocument(action.documentId)),
              ),
            ];
          }
          case "Finished":
            return [
              {
                ...model,
                requiredDocuments: updatingDeferred(model.requiredDocuments),
              },
              noEffect,
            ];
        }
      }
      break;
    case "SkipDocumentChanged":
      return [
        {
          ...model,
          skippedDocuments: pipe(
            model.skippedDocuments,
            action.skip
              ? S.insert(EqRequirementId)(action.requirementId)
              : S.remove(EqRequirementId)(action.requirementId),
          ),
        },
        noEffect,
      ];
  }
};
