import { UsersTable } from "./UsersTable";
import { UsersTableProps } from "./types";

export type Props = /* ChildProps<Model, Action> & */ UsersTableProps;

export function View(props: Props): JSX.Element {

    const { users, onSelectUser, onDeleteUserInitiated, onEditUserInitiated} = props;
    return <UsersTable
       users={users}
       onSelectUser={onSelectUser}
       onDeleteUserInitiated={onDeleteUserInitiated}
       onEditUserInitiated={onEditUserInitiated}
     />
}