import * as AccountSettings from "@/components/AccountSettings";
import * as ApplicationsList from "@/components/ApplicationsList";
import * as BorrowerNotificationSettings from "@/components/BorrowerNotificationSettings";
import * as Dashboard from "@/components/Dashboard";
import * as HomePage from "@/components/HomePage";
import * as InactiveBorrower from "@/components/InactiveBorrower";
import * as Invite from "@/components/Invite";
import * as LoInvite from "@/components/LoInvite";
import * as Signup from "@/components/SignUp";
import * as SignUp from "@/components/SignUp";
import * as Summary from "@/components/Summary";
import * as UserManagement from "@/components/UserManagement";
import * as Wizard from "@/components/Wizard";
import * as UnFinishedApplicationExit from "@/components/UnFinishedApplication";
import { ClientStatusResponse, User } from "@/data/client";
import {
  Application,
  ApplicationId,
  CreateClientPayload,
  saveSurveyToLocalStorage,
  SurveyPayload,
} from "@/data/payload";
import {
  loadFromLocalStorage,
  SurveyFlowPayload,
} from "@/data/surveyFlowPayload";
import { Api, LoginTriggerer } from "@/utils/api";
import { Finished, Started } from "@/utils/asyncOperationStatus";
import { dateTimeNow } from "@/utils/codecs";
import {
  LocalStoragetKeys,
  removeFromLocalStorage,
} from "@/utils/localstorage";
import { PageTypes } from "@/utils/page-types";
import {
  Effect,
  effectOfAction,
  effectOfAsync,
  effectOfFunc,
  effectOfFunc_,
  effectsBatch,
  mapEffect,
  noEffect,
} from "@/utils/reducerWithEffect";
import {
  ApiError,
  ApiResult,
  AuthenticationError,
  showApiError,
} from "@/utils/request";
import * as E from "fp-ts/Either";
import { constant, constFalse, flow, identity, pipe } from "fp-ts/lib/function";
import { Option } from "fp-ts/lib/Option";
import { Task } from "fp-ts/lib/Task";
import * as O from "fp-ts/Option";
import * as TE from "fp-ts/TaskEither";
import * as Tup from "fp-ts/Tuple";
import { DateTime } from "luxon";
import Navigo from "navigo";
import { v4 as uuidv4 } from "uuid";
import {
  BorrowerNotificationsDialog,
  CompleteApplicationDialog,
  FlashType,
  Model,
  UnFinishedApplicationExitDialog,
} from "./model";
export type Action =
  | { type: "Initialized"; model: Model; effect: Effect<Action> }
  | { type: "InitializationFailed"; error: ApiError }
  | { type: "LoginInitiated" }
  | { type: "LoanOfficerSignup"; emailAddress: string; phoneNo: string }
  | { type: "UserLoginRedirected" }
  | { type: "LogoutInitiated"; logoutTask: Task<void> }
  | { type: "LogoutCompleted" }
  | {
      type: "HomepageRequested";
      model: O.Option<Model>;
      effect: O.Option<Effect<Action>>;
    }
  | { type: "SurveyInitialized"; payload: SurveyFlowPayload }
  | { type: "WizardAction"; action: Wizard.Action }
  | {
      type: "InviteSelected";
      model: Invite.Model;
      effect: Effect<Invite.Action>;
    }
  | { type: "InviteAction"; action: Invite.Action }
  | { type: "LoInviteAction"; action: LoInvite.Action }
  | { type: "SummaryAction"; action: Summary.Action }
  | { type: "ApplicationsListAction"; action: ApplicationsList.Action }
  | { type: "DashboardAction"; action: Dashboard.Action }
  | { type: "UserManagementAction"; action: UserManagement.Action }
  | { type: "AccountSettingsAction"; action: AccountSettings.Action }
  | {
      type: "SaveSurveyRequested";
      payload: SurveyPayload;
    }
  | {
      type: "SaveSurveyCompleted";
      application: ApiResult<Application>;
    }
  | { type: "SubmitApplicationRequested"; applicationId: ApplicationId }
  | { type: "SubmitApplicationCompleted"; result: ApiResult<Application> }
  | { type: "NewApplicationSelected" }
  | { type: "SetupPaymentsPage"; paymentSetupInfo: Signup.PaymentSetupInfo }
  | { type: "ApplicationEditClicked"; payload: Application }
  | { type: "ApplicationSelected"; applicationId: ApplicationId }
  | { type: "FlashMessageRemoved"; messageIndices: number[] }
  | { type: "FlashMessageAdded"; flashType: FlashType }
  | { type: "ApplicationLoaded"; application: Application }
  | { type: "ApplicationLoadFailed"; error: ApiError }
  | { type: "HomebuyerLandingSelected"; application: Option<Application> }
  | { type: "HomebuyerDoesNotHaveApplication" }
  | { type: "DashboardSelected"; date: DateTime }
  | {
      type: "UserManagementSelected";
      pendingEffects?: () => Effect<UserManagement.Action>;
    }
  | { type: "ApplicationsListSelected" }
  | { type: "RouteLoading"; pageType: Action["type"] }
  | { type: "AccountSettingsSelected"; user: User }
  | { type: "AccountCompanySettingsSelected"; user: User }
  | { type: "BillingSettingsSelected"; user: User }
  | { type: "ApplicationsListLoadFailed"; error: ApiError }
  | { type: "SignUpAction"; action: SignUp.Action }
  | { type: "HomePageAction"; action: HomePage.Action }
  | {
      type: "InactiveBorrrowerPage";
      forBorrower: boolean;
      status: ClientStatusResponse;
    }
  | { type: "BorrowerSettingsSelected"; user: User }
  | {
      type: "BorrowerSettingsAction";
      action: BorrowerNotificationSettings.Action;
    }
  | { type: "BorrowerSettingsClosed" }
  | {
      type: "UnFinishedApplicationExitInitiated";
      targetRouteUrl: O.Option<string>;
    }
  | { type: "OnSummaryPageResume" }
  | {
      type: "UnFinishedApplicationExitAction";
      action: UnFinishedApplicationExit.Action;
    }
  | {
      type: "UnFinishedApplicationExitCompleted";
      targetRouteUrl: O.Option<string>;
    };

export type GlobalAction = Action;
export const Initialized =
  (effect: Effect<Action>) =>
  (model: Model): Action => ({ type: "Initialized", model, effect });

export const InitializationFailed = (error: ApiError): Action => ({
  type: "InitializationFailed",
  error,
});

export const LoginInitiated = (): Action => ({ type: "LoginInitiated" });
export const UserLoginRedirected = (): Action => ({
  type: "UserLoginRedirected",
});

export const LogoutInitiated = (logoutTask: Task<void>): Action => ({
  type: "LogoutInitiated",
  logoutTask,
});

export const LogoutCompleted = (): Action => ({ type: "LogoutCompleted" });
export const HomepageRequested =
  (effect: Effect<Action>) =>
  (model: Model): Action => ({
    type: "HomepageRequested",
    model: O.some(model),
    effect: O.some(effect),
  });
export const BlankHomepageRequested = (): Action => ({
  type: "HomepageRequested",
  model: O.none,
  effect: O.none,
});
export const LoanOfficerSignup =
  (emailAddress: string, phoneNo: string) => (): Action => ({
    type: "LoanOfficerSignup",
    emailAddress,
    phoneNo,
  });

export const SurveyInitialized = (payload: SurveyFlowPayload): Action => ({
  type: "SurveyInitialized",
  payload,
});

export const SummaryAction = (action: Summary.Action): Action => ({
  action,
  type: "SummaryAction",
});

export const WizardAction = (action: Wizard.Action): Action => ({
  type: "WizardAction",
  action,
});

export const ApplicationsListAction = (
  action: ApplicationsList.Action,
): Action => ({
  type: "ApplicationsListAction",
  action,
});

export const InviteSelected = (
  model: Invite.Model,
  effect: Effect<Invite.Action>,
): Action => ({
  type: "InviteSelected",
  model,
  effect,
});

export const InviteAction = (action: Invite.Action): Action => ({
  type: "InviteAction",
  action,
});

export const LoInviteAction = (action: LoInvite.Action): Action => ({
  type: "LoInviteAction",
  action,
});

export const DashboardAction = (action: Dashboard.Action): Action => ({
  type: "DashboardAction",
  action,
});

export const UserManagementAction = (
  action: UserManagement.Action,
): Action => ({
  type: "UserManagementAction",
  action,
});

export const AccountSettingsAction = (
  action: AccountSettings.Action,
): Action => ({
  type: "AccountSettingsAction",
  action,
});

export const SaveSurveyRequested = (payload: SurveyPayload): Action => ({
  type: "SaveSurveyRequested",
  payload,
});

export const SaveSurveyCompleted = (
  application: ApiResult<Application>,
): Action => ({
  type: "SaveSurveyCompleted",
  application,
});

export const SubmitApplicationRequested = (
  applicationId: ApplicationId,
): Action => ({
  type: "SubmitApplicationRequested",
  applicationId,
});

export const SubmitApplicationCompleted = (
  result: ApiResult<Application>,
): Action => ({
  type: "SubmitApplicationCompleted",
  result,
});

export const HomebuyerLandingSelected = (
  application: Option<Application>,
): Action => ({
  type: "HomebuyerLandingSelected",
  application,
});

export const HomebuyerDoesNotHaveApplication = (): Action => ({
  type: "HomebuyerDoesNotHaveApplication",
});

export const NewApplicationSelected = (): Action => ({
  type: "NewApplicationSelected",
});

export const SetupPaymentsPage = (
  paymentSetupInfo: Signup.PaymentSetupInfo,
): Action => ({
  type: "SetupPaymentsPage",
  paymentSetupInfo,
});

export const ApplicationEditClicked = (payload: Application): Action => ({
  type: "ApplicationEditClicked",
  payload,
});

export const ApplicationSelected = (applicationId: ApplicationId): Action => ({
  type: "ApplicationSelected",
  applicationId,
});

export const FlashMessageRemoved = (messageIndices: number[]): Action => ({
  type: "FlashMessageRemoved",
  messageIndices,
});

export const FlashMessageAdded = (flashType: FlashType): Action => ({
  type: "FlashMessageAdded",
  flashType,
});

export const AccountSettingsSelected = (user: User): Action => ({
  type: "AccountSettingsSelected",
  user,
});

export const AccountCompanySettingsSelected = (user: User): Action => ({
  type: "AccountCompanySettingsSelected",
  user,
});

export const BillingSettingsSelected = (user: User): Action => ({
  type: "BillingSettingsSelected",
  user,
});

export const ApplicationLoaded = (application: Application): Action => ({
  type: "ApplicationLoaded",
  application,
});

export const ApplicationLoadFailed = (error: ApiError): Action => ({
  type: "ApplicationLoadFailed",
  error,
});

export const DashboardSelected = (date: DateTime): Action => ({
  type: "DashboardSelected",
  date,
});

export const UserManagementSelected = (
  pendingEffects?: () => Effect<UserManagement.Action>,
): Action => ({
  type: "UserManagementSelected",
  pendingEffects,
});

export const ApplicationsListSelected = (): Action => ({
  type: "ApplicationsListSelected",
});

export const RouteLoading = (pageType: Action["type"]): Action => ({
  type: "RouteLoading",
  pageType,
});

export const ApplicationsListLoadFailed = (error: ApiError): Action => ({
  type: "ApplicationsListLoadFailed",
  error,
});

export const SignUpAction = (action: SignUp.Action): Action => ({
  type: "SignUpAction",
  action,
});

export const HomePageAction = (action: HomePage.Action): Action => ({
  type: "HomePageAction",
  action,
});

export const InactiveBorrrowerPage = (
  forBorrower: boolean,
  status: ClientStatusResponse,
): Action => ({
  type: "InactiveBorrrowerPage",
  forBorrower,
  status,
});

export const BorrowerSettingsSelected = (user: User): Action => ({
  type: "BorrowerSettingsSelected",
  user,
});

export const BorrowerSettingsAction = (
  action: BorrowerNotificationSettings.Action,
): Action => ({
  type: "BorrowerSettingsAction",
  action,
});

export const BorrowerSettingsClosed = (): Action => ({
  type: "BorrowerSettingsClosed",
});

export const UnFinishedApplicationExitInitiated = (
  targetRouteUrl: O.Option<string>,
): Action => ({
  type: "UnFinishedApplicationExitInitiated",
  targetRouteUrl,
});

export const UnFinishedApplicationExitAction = (
  action: UnFinishedApplicationExit.Action,
): Action => ({
  type: "UnFinishedApplicationExitAction",
  action,
});

export const OnSummaryPageResume = (): Action => ({
  type: "OnSummaryPageResume",
});

export const UnFinishedApplicationExitCompleted = (
  targetRouteUrl: O.Option<string>,
): Action => ({
  type: "UnFinishedApplicationExitCompleted",
  targetRouteUrl,
});

export const update =
  (
    api: Api,
    performUserFlow: LoginTriggerer,
    setMessage: (s: O.Option<string>) => void,
    router: Navigo,
  ) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    console.log(action.type);

    switch (action.type) {
      case "Initialized":
        console.log(action.model);

        return [action.model, action.effect || noEffect];

      case "FlashMessageRemoved":
        return [
          {
            ...model,
            flashMessages: model.flashMessages.filter(
              (_, i) => !action.messageIndices.includes(i),
            ),
          },
          noEffect,
        ];

      case "FlashMessageAdded":
        return [
          {
            ...model,
            flashMessages: [...model.flashMessages, action.flashType],
          },
          noEffect,
        ];

      case "InitializationFailed":
        return [
          { ...model, page: { type: PageTypes.Login } },
          effectOfFunc_(
            () => console.log(`Failed to initialize: ${action.error}`),
            undefined,
          ),
        ];

      case "UserLoginRedirected":
        return [model, noEffect];

      case "LoginInitiated":
        return [model, noEffect];

      case "RouteLoading":
        console.log(`routeloading :${action.pageType}`);

        switch (action.pageType) {
          case "ApplicationSelected":
          case "SummaryAction":
            return [
              {
                ...model,
                page: {
                  type: "Summary",
                  summaryModel: O.none,
                },
              },
              noEffect,
            ];
          case "ApplicationsListSelected":
            return [
              {
                ...model,
                page: {
                  type: "ApplicationsList",
                  applicationsListModel: ApplicationsList.initLoading(),
                },
              },
              noEffect,
            ];

          case "LoginInitiated":
            return [
              {
                ...model,
                page: {
                  type: PageTypes.Login,
                },
              },
              noEffect,
            ];
          default:
            return [model, noEffect];
        }

      case "LoanOfficerSignup":
        {
          const nonce = uuidv4();
          return [
            model,
            effectsBatch([
              effectOfFunc_(
                () =>
                  localStorage.setItem(
                    LocalStoragetKeys.BackOfficeNonce,
                    nonce,
                  ),
                undefined,
              ),
              effectOfAsync(
                performUserFlow({
                  authority: import.meta.env.VITE_LO_SIGNUP_AUTHORITY,
                  state: { loginNonce: nonce },
                  loginHint: encodeURIComponent(
                    JSON.stringify({
                      email: action.emailAddress,
                      phoneNo: action.phoneNo,
                    }),
                  ),
                }),
                LogoutCompleted,
              ),
            ]),
          ];
        }
        break;
      case "LogoutInitiated":
        return [model, effectOfAsync(action.logoutTask, LogoutCompleted)];

      case "LogoutCompleted":
        return [
          { ...model, page: { type: PageTypes.Login }, user: O.none },
          noEffect,
        ];

      case "SurveyInitialized": {
        const [wizardModel, wizardEffect] = Wizard.init(dateTimeNow())(
          action.payload,
          model.user,
        );
        const currentMatch = router.getCurrentLocation();
        const refLoanOfficer = pipe(
          currentMatch?.params?.referringLoanOfficerId,
          O.fromNullable,
          O.map((v) => Number(v)),
          O.chain((v) => (isNaN(v) ? O.none : O.some(v))),
        );
        return [
          {
            ...model,
            page: { type: PageTypes.ApplyNow, wizardModel },
          },
          effectsBatch([
            mapEffect(WizardAction)(wizardEffect),
            pipe(
              wizardModel.isLoanOfficerInviting,
              O.fold(
                () => pipe(
                  refLoanOfficer,
                  O.fold(
                    () => noEffect,
                    (id) => effectOfAsync(
                      api.getReferringLoanOfficerInfo(id), (v) =>
                      pipe(
                        v,
                        (v) => E.isLeft(v) ? pipe(
                          model.clientStatus,
                          O.map((status) => ({
                            fullname: status.companyName,
                            email: status.email,
                            phoneNumber: status.phone
                          })
                        )) : O.some(v.right),
                        (v) =>
                          WizardAction(Wizard.UpdateReferringLoanOfficerInfo(v)),
                        )
                    )
                  )
                ),
                () => noEffect
              ),
            ),
          ]),
        ];
      }

      case "InviteSelected": {
        return [
          { ...model, page: { type: "Invite", inviteModel: action.model } },
          mapEffect(InviteAction)(action.effect),
        ];
      }

      case "InviteAction": {
        if (model.page.type !== "Invite") {
          return [model, noEffect];
        }

        return pipe(
          Invite.update(api)(model.page.inviteModel, action.action),
          Tup.bimap(mapEffect(InviteAction), (inviteModel) => ({
            ...model,
            page: { ...model.page, inviteModel },
          })),
        );
      }

      case "LoInviteAction": {
        if (model.page.type !== "LoInvite") {
          return [model, noEffect];
        }

        return pipe(
          LoInvite.update(api)(model.page.loInviteModel, action.action),
          Tup.bimap(mapEffect(LoInviteAction), (loInviteModel) => ({
            ...model,
            page: { ...model.page, loInviteModel },
          })),
        );
      }

      case "WizardAction": {
        if (model.page.type !== PageTypes.ApplyNow) {
          return [model, noEffect];
        }

        return pipe(
          Wizard.update(api)(model.page.wizardModel, action.action),
          Tup.bimap(mapEffect(WizardAction), (wizardModel) => ({
            ...model,
            page: { ...model.page, wizardModel },
          })),
        );
      }

      case "SummaryAction": {
        if (
          model.page.type !== "Summary" ||
          O.isNone(model.page.summaryModel)
        ) {
          return [model, noEffect];
        }

        const result = Summary.update(api)(
          model.page.summaryModel.value,
          action.action,
        );

        if (!result || result.length !== 2) {
          return [model, noEffect];
        }

        const [summaryModel, summaryEffect] = result;

        return [
          {
            ...model,
            page: { ...model.page, summaryModel: O.some(summaryModel) },
          },
          mapEffect(SummaryAction)(summaryEffect),
        ];
      }

      case "ApplicationsListAction": {
        if (model.page.type !== "ApplicationsList") {
          return [model, noEffect];
        }

        const [applicationsListModel, applicationsListEffect] =
          ApplicationsList.update(api)(
            model.page.applicationsListModel,
            action.action,
          );

        return [
          { ...model, page: { ...model.page, applicationsListModel } },
          mapEffect(ApplicationsListAction)(applicationsListEffect),
        ];
      }

      case "DashboardAction": {
        if (model.page.type !== "Dashboard") {
          return [model, noEffect];
        }

        const dashboardPage = model.page;
        return pipe(
          Dashboard.update(api)(model.page.dashboardModel, action.action),
          Tup.bimap(
            mapEffect(DashboardAction),
            (dashboardModel): Model => ({
              ...model,
              page: { ...dashboardPage, dashboardModel },
            }),
          ),
        );
      }

      case "UserManagementAction": {
        if (model.page.type !== "Branches") {
          return [model, noEffect];
        }

        return pipe(
          UserManagement.update(api)(model.page.branchesModel, action.action),
          Tup.bimap(
            mapEffect(UserManagementAction),
            (branchesModel): Model => ({
              ...model,
              page: { type: "Branches", branchesModel },
            }),
          ),
        );
      }

      case "AccountSettingsAction": {
        if (model.page.type !== "AccountSettings") {
          return [model, noEffect];
        }

        return pipe(
          AccountSettings.update(api)(action.action)(
            model.page.accountSettingsModel,
          ),
          Tup.bimap(
            mapEffect(AccountSettingsAction),
            (accountSettingsModel): Model => ({
              ...model,
              page: { type: "AccountSettings", accountSettingsModel },
            }),
          ),
        );
      }
      case "SaveSurveyRequested":
        return [
          { ...model, page: { type: "Initializing" } },
          effectOfAsync(
            pipe(
              () => api.tryGetUser(),
              TE.mapError((user) => {
                saveSurveyToLocalStorage(action.payload);
                return user;
              }),
              TE.chain((user) => {
                if (O.isNone(user)) {
                  saveSurveyToLocalStorage(action.payload);
                  return TE.left(AuthenticationError("USER_NONE"));
                }
                return TE.right(user);
              }),
              TE.chain(() => api.saveSurvey(action.payload)),
              TE.chain((v) => api.getApplication(v.applicationId)),
            ),
            SaveSurveyCompleted,
          ),
        ];

      case "SaveSurveyCompleted":
        return pipe(
          action.application,
          E.fold(
            (e): [Model, Effect<Action>] => {
              if (e.type == "AuthenticationError" && e.error == "USER_NONE") {
                const sid = uuidv4();
                return [
                  model,
                  effectsBatch([
                    effectOfFunc_(() => {
                      localStorage.setItem(
                        LocalStoragetKeys.BorrowerNonce,
                        sid,
                      );
                      localStorage.setItem(
                        LocalStoragetKeys.BorrowerSignupInProgress,
                        "yes",
                      );
                    }, undefined),
                    effectOfAsync(
                      performUserFlow({
                        authority: import.meta.env.VITE_BO_SIGNUP_AUTHORITY,
                        state: { loginNonce: sid },
                        loginHint: pipe(
                          loadFromLocalStorage().primaryApplicantPayload
                            .contactInformation,
                          O.fold(
                            () => "",
                            (v) =>
                              encodeURIComponent(
                                JSON.stringify({
                                  email: v.email,
                                  phoneNo: v.phoneNumber,
                                  logoUrl: pipe(
                                    model.clientStatus,
                                    O.map((v) => v.logoUrl),
                                    O.foldW(constant(null), identity),
                                  ),
                                }),
                              ),
                          ),
                        ),
                        redirectUri: "/homebuyer-landing",
                      }),
                      UserLoginRedirected,
                    ),
                  ]),
                ];
              }
              return [
                model,
                effectOfAction(
                  flow(
                    constant({
                      type: "error" as const,
                      message: "Failed to save survey",
                      pushed: DateTime.now(),
                    }),
                    FlashMessageAdded,
                  )(),
                ),
              ];
            },
            (application): [Model, Effect<Action>] => {
              const [summaryModel, summaryEffect] = Summary.init(
                application,
                model.clientStatus,
                model.user,
              );

              return [
                {
                  ...model,
                  page: { type: "Summary", summaryModel: O.some(summaryModel) },
                },
                effectsBatch([
                  mapEffect(SummaryAction)(summaryEffect),
                  effectOfFunc_(
                    () =>
                      router.navigate(`summary/${application.applicationId}`),
                    undefined,
                  ),
                ]),
              ];
            },
          ),
        );

      case "SubmitApplicationRequested":
        return [
          {
            ...model,
            dialog: O.some(CompleteApplicationDialog({ operation: Started() })),
          },
          effectOfAsync(
            pipe(
              api.submitApplication(action.applicationId),
              TE.chain(() => api.getApplication(action.applicationId)),
            ),
            SubmitApplicationCompleted,
          ),
        ];

      case "SubmitApplicationCompleted":
        return [
          {
            ...model,
            dialog: O.some(
              CompleteApplicationDialog({ operation: Finished(action.result) }),
            ),
          },
          noEffect,
        ];

      case "NewApplicationSelected": {
        if (model.page.type == PageTypes.ApplyNow) {
          return [model, noEffect];
        }
        return [
          { ...model, page: { type: "Initializing" } },
          effectOfFunc(loadFromLocalStorage, undefined, SurveyInitialized),
        ];
      }

      case "SetupPaymentsPage": {
        return [
          {
            ...model,
            page: {
              type: PageTypes.HomePage,
              homepageModel: HomePage.initHomePageModel(),
            },
          },
          effectsBatch([
            effectOfAction(HomePageAction(HomePage.SignupRequested())),
            effectOfAction(
              HomePageAction(
                HomePage.SignupAction(
                  Signup.CreateClient(
                    {} as CreateClientPayload,
                    (_: FlashType) => {},
                  )(Finished(E.right(action.paymentSetupInfo))),
                ),
              ),
            ),
          ]),
        ];
      }

      case "ApplicationEditClicked": {
        if (model.page.type !== "Application") {
          return [model, noEffect];
        }
        const [summaryModel, summaryEffect] = Summary.init(
          action.payload,
          model.clientStatus,
          model.user,
        );
        return [
          {
            ...model,
            page: {
              type: "Summary",
              summaryModel: O.some(summaryModel),
            },
          },
          mapEffect(SummaryAction)(summaryEffect),
        ];
      }

      case "ApplicationSelected":
        return [
          model,
          effectOfAsync(
            api.getApplication(action.applicationId),
            E.fold(ApplicationLoadFailed, ApplicationLoaded),
          ),
        ];

      case "ApplicationLoaded":
        return pipe(
          Summary.init(action.application, model.clientStatus, model.user),
          Tup.bimap(mapEffect(SummaryAction), (summaryModel) => ({
            ...model,
            page: { type: "Summary", summaryModel: O.some(summaryModel) },
          })),
        );

      case "ApplicationLoadFailed":
        return [
          model,
          effectsBatch([
            effectOfFunc_(() => {
              window.history.back();
            }, undefined),
            effectOfAction(
              flow(
                constant({
                  type: "error" as const,
                  message: "Unable to load the application",
                  pushed: DateTime.now(),
                }),
                FlashMessageAdded,
              )(),
            ),
          ]),
        ];

      case "HomebuyerDoesNotHaveApplication":
        return [
          {
            ...model,
            page: {
              type: "HomebuyerLanding",
              application: O.none,
              doesNotHaveApplication: true,
            },
          },
          noEffect,
        ];

      case "HomebuyerLandingSelected":
        return [
          {
            ...model,
            page: {
              type: "HomebuyerLanding",
              application: action.application,
              doesNotHaveApplication: false,
            },
          },
          O.isNone(action.application)
            ? effectOfAsync(pipe(api.getCurrentApplication), (v) =>
                pipe(v, O.fromEither, O.flatten, (v) =>
                  O.isNone(v)
                    ? HomebuyerDoesNotHaveApplication()
                    : HomebuyerLandingSelected(v),
                ),
              )
            : noEffect,
        ];

      case "DashboardSelected":
        {
          const location = new URL(window.location.href);
          const isFirstLook = pipe(
            location.searchParams.get("first_look"),
            O.fromNullable,
            O.map((v) => v == "true"),
            O.getOrElse(constFalse),
          );

          return pipe(
            Dashboard.init(action.date, isFirstLook, model.clientStatus),
            Tup.bimap(mapEffect(DashboardAction), (dashboardModel) => ({
              ...model,
              page: { type: "Dashboard", dashboardModel },
            })),
          );
        }
        break;

      case "UserManagementSelected":
        return pipe(
          UserManagement.init(model.clientStatus),
          Tup.bimap(mapEffect(UserManagementAction), (branchesModelValue) => {
            const branchesModel: UserManagement.Model = branchesModelValue;
            if (action.pendingEffects) {
              branchesModel.pendingEffects = action.pendingEffects;
            }

            return {
              ...model,
              page: { type: "Branches", branchesModel },
            };
          }),
        );

      case "AccountSettingsSelected":
        return [
          {
            ...model,
            page: {
              type: "AccountSettings",
              accountSettingsModel: AccountSettings.init(action.user),
            },
          },
          noEffect,
        ];
      case "AccountCompanySettingsSelected":
        return [
          {
            ...model,
            page: {
              type: "AccountSettings",
              accountSettingsModel: AccountSettings.init(action.user),
            },
          },
          effectOfAction(
            AccountSettingsAction(AccountSettings.CompanySettingsSelected()),
          ),
        ];

      case "BillingSettingsSelected":
        return [
          {
            ...model,
            page: {
              type: "AccountSettings",
              accountSettingsModel: AccountSettings.init(action.user),
            },
          },
          effectOfAction(
            AccountSettingsAction(AccountSettings.BillingSelected()),
          ),
        ];

      case "ApplicationsListSelected":
        return [
          {
            ...model,
            page: {
              type: "ApplicationsList",
              applicationsListModel: ApplicationsList.init(model.clientStatus),
            },
          },
          noEffect,
        ];

      case "HomepageRequested":
        if (O.isSome(action.model) && O.isSome(action.effect)) {
          return [action.model.value, action.effect.value];
        } else {
          return [
            {
              ...model,
              page: {
                type: PageTypes.HomePage,
                homepageModel: HomePage.initHomePageModel(),
              },
            },
            noEffect,
          ];
        }

      case "ApplicationsListLoadFailed":
        return [
          model,
          effectOfFunc_((e) => {
            setMessage(
              O.some("Failed to load applications list: \n" + showApiError(e)),
            );
          }, action.error),
        ];

      case "SignUpAction":
        if (model.page.type !== PageTypes.Signup) {
          return [model, noEffect];
        }

        return pipe(
          SignUp.update(api)(action.action)(model.page.signUpModel),
          Tup.bimap(
            mapEffect(SignUpAction),
            (signUpModel): Model => ({
              ...model,
              page: { type: PageTypes.Signup, signUpModel },
            }),
          ),
        );

      case "HomePageAction":
        if (model.page.type !== PageTypes.HomePage) {
          return [model, noEffect];
        }

        return pipe(
          HomePage.update(api)(
            model.page.homepageModel,
            action.action,
            model.user,
          ),
          Tup.bimap(
            mapEffect(HomePageAction),
            (homepageModel): Model => ({
              ...model,
              page: { type: PageTypes.HomePage, homepageModel },
            }),
          ),
        );
        break;
      case "InactiveBorrrowerPage":
        return [
          {
            ...model,
            page: {
              type: PageTypes.InactiveBorrower,
              inactiveBorrowerModel: InactiveBorrower.init(
                action.forBorrower,
                action.status,
              ),
            },
          },
          noEffect,
        ];

      case "BorrowerSettingsSelected": {
        return [
          {
            ...model,
            dialog: O.some(
              BorrowerNotificationsDialog(
                BorrowerNotificationSettings.initNotificationSettings(
                  action.user,
                ),
              ),
            ),
          },
          noEffect,
        ];
      }
      case "BorrowerSettingsAction": {
        if (
          O.isSome(model.dialog) &&
          model.dialog.value.type === "BorrowerSettings"
        ) {
          return pipe(
            BorrowerNotificationSettings.update(api)(action.action)(
              model.dialog.value.model,
            ),
            Tup.bimap(
              mapEffect(BorrowerSettingsAction),
              (settingsModel): Model => ({
                ...model,
                dialog: O.some({
                  type: "BorrowerSettings",
                  model: settingsModel,
                }),
              }),
            ),
          );
        }
        return [model, noEffect];
      }
      case "BorrowerSettingsClosed":
        return [
          {
            ...model,
            dialog: O.none,
          },
          noEffect,
        ];
      case "UnFinishedApplicationExitInitiated":
        return [
          {
            ...model,
            dialog: O.some(
              UnFinishedApplicationExitDialog(
                UnFinishedApplicationExit.init(action.targetRouteUrl),
              ),
            ),
          },
          noEffect,
        ];
      case "UnFinishedApplicationExitAction": {
        if (
          O.isSome(model.dialog) &&
          model.dialog.value.type === "UnFinishedApplicationExit"
        ) {
          return pipe(
            UnFinishedApplicationExit.update(api)(action.action)(
              model.dialog.value.model,
            ),
            Tup.bimap(
              mapEffect(UnFinishedApplicationExitAction),
              (dialogModel): Model => ({
                ...model,
                dialog: O.some({
                  type: "UnFinishedApplicationExit",
                  model: dialogModel,
                }),
              }),
            ),
          );
        }
        return [model, noEffect];
      }

      case "OnSummaryPageResume": {
        if (
          O.isSome(model.dialog) &&
          model.dialog.value.type === "UnFinishedApplicationExit"
        ) {
          if (model.dialog.value.model.dialogApiResult.status === "Resolved") {
            return [
              {
                ...model,
                dialog: O.none,
              },
              effectOfAction(
                flow(
                  constant(model.dialog.value.model.targetRouteUrl),
                  UnFinishedApplicationExitCompleted,
                )(),
              ),
            ];
          }
        }

        return [
          {
            ...model,
            dialog: O.none,
          },
          noEffect,
        ];
      }
      case "UnFinishedApplicationExitCompleted": {
        return [
          {
            ...model,
            dialog: O.none,
          },
          effectOfFunc_(
            () =>
              pipe(
                action.targetRouteUrl,
                O.fold(
                  () => {
                    removeFromLocalStorage(
                      LocalStoragetKeys.ApplicationModifyStatus,
                    );
                  },
                  (url) => {
                    removeFromLocalStorage(
                      LocalStoragetKeys.ApplicationModifyStatus,
                    );
                    return router.navigate(url);
                  },
                ),
              ),
            undefined,
          ),
        ];
      }
    }
  };
