import { ApplicationType } from "@/data/payload";

export type Model =
  | {
      page: "ApplicationType";
    }
  | {
      page: "PropertyType";
      selectedApplicationType: ApplicationType;
    };

export const init = (): Model => ({
  page: "ApplicationType",
});
