import * as t from "io-ts";

// represents a nullable empty string
export const NullFromEmptyString = new t.Type<string, null, unknown>(
  "NullFromEmptyString",
  (u): u is string => (u === null) || (typeof u == "string") && (u as string).trim().length ==0,
  (u, c) => (u === null) || (u as string).trim().length ==0 ? t.success("") : t.failure(u,c),
  () => null,
);


export type NullFromEmptyStringType = t.TypeOf<typeof NullFromEmptyString>;