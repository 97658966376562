import * as EditTeam from "@/components/UserManagement/pages/BranchManagement/EditTeam";
import { Team, TeamId } from "@/data/client";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import {
  DeleteTeamDialog,
  EditTeamDialog,
  Model,
  NewTeamDialog,
} from "./model";

export type Action =
  | {
      type: "NewTeamInitiated";
    }
  | {
      type: "EditTeamInitiated";
      team: Team;
    }
  | {
      type: "DeleteTeamInitiated";
      teamId: TeamId;
    }
  | {
      type: "DialogClosed";
    }
  | {
      type: "EditTeamAction";
      action: EditTeam.Action;
    };

export const NewTeamInitiated = (): Action => ({
  type: "NewTeamInitiated",
});

export const EditTeamInitiated = (team: Team): Action => ({
  type: "EditTeamInitiated",
  team,
});

export const DeleteTeamInitiated = (teamId: TeamId): Action => ({
  type: "DeleteTeamInitiated",
  teamId,
});

export const DialogClosed = (): Action => ({
  type: "DialogClosed",
});

export const EditTeamAction = (action: EditTeam.Action): Action => ({
  type: "EditTeamAction",
  action,
});

export const update = (model: Model, action: Action): Model => {
  switch (action.type) {
    case "NewTeamInitiated":
      return {
        ...model,
        dialog: pipe(model.branchId, EditTeam.init, NewTeamDialog, O.some),
      };
    case "EditTeamInitiated":
      return {
        ...model,
        dialog: pipe(
          EditTeam.initEdit(action.team),
          EditTeamDialog(action.team.teamId),
          O.some,
        ),
      };
    case "DeleteTeamInitiated":
      return {
        ...model,
        dialog: O.some(DeleteTeamDialog(action.teamId)),
      };
    case "DialogClosed":
      return {
        ...model,
        dialog: O.none,
      };
    case "EditTeamAction":
      if (
        O.isNone(model.dialog) ||
        model.dialog.value.type == "DeleteTeamDialog"
      ) {
        return model;
      }
      return {
        ...model,
        dialog: O.some({
          ...model.dialog.value,
          dialogModel: EditTeam.update(action.action)(
            model.dialog.value.dialogModel,
          ),
        }),
      };
  }
};
