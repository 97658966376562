import { ChildProps } from "@/utils/reducerWithEffect";
import * as A from "fp-ts/lib/Array";
import * as NEA from "fp-ts/lib/NonEmptyArray";
import * as O from "fp-ts/lib/Option";
import { defaultLogo, Model, result } from "./model";

import {
  Button,
  Col,
  Icon,
  InputField,
  PhoneInputField,
  Row,
} from "@/components/basic";
import { ClientSettingsPayload } from "@/data/payload";
import { constant, flow, pipe } from "fp-ts/lib/function";
import {
  Action,
  CompanyNameChanged,
  LogoSelected,
  NmlsNumberChanged,
  PhoneChanged,
  WebsiteChanged,
} from "./action";

import {
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";

export type Props = ChildProps<Model, Action> & {
  onSave: (payload: ClientSettingsPayload) => void;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const ref = useRef<HTMLImageElement>(null);

  const [uploadedLogo, setUploadedLogo] = useState<O.Option<File>>(O.none);

  useEffect(() => {
    const _defaultLogo = defaultLogo(model);
    if (defaultLogo.length > 0 && ref.current) {
      ref.current.src = _defaultLogo;
    }
  }, [model]);

  const save = useMemo(() => {
    const savedModel = result(model);

    return pipe(
      savedModel[0],
      O.fromEither,
      O.chain((v) =>
        savedModel[1] || O.isSome(uploadedLogo) ? O.some(v) : O.none,
      ),
      O.map(
        (form) => () =>
          props.onSave({
            form,
            files: pipe(
              uploadedLogo,
              O.fold(constant([]), (file) => [file]),
            ),
          }),
      ),
    );
  }, [model, props, uploadedLogo]);

  return (
    <Col>
      <div className="grid-2 gap-xs">
        <InputField
          label="Company name"
          field={model.companyName}
          onChange={flow(CompanyNameChanged, dispatch)}
        />
        <InputField
          label="NMLS Company number"
          field={model.nmlsNumber}
          onChange={flow(NmlsNumberChanged, dispatch)}
        />
        <PhoneInputField
          label="Phone"
          field={model.phone}
          onChange={flow(PhoneChanged, dispatch)}
        />
        <InputField
          label="Company website"
          field={model.website}
          onChange={flow(WebsiteChanged, dispatch)}
        />
        <InputField
          label="Your AppCatcher URL"
          suffix=".appcatcher.com"
          placeholder="Please fill in"
          field={model.subdomain}
          readonly={true}
          hint="This cannot be changed in the future."
          onChange={() => {}}
        />

        <div className="grid-item-2">
          <div className="grid-2">
            <Row alignHorizontal="center" alignVertical="center">
              <img ref={ref} src={defaultLogo(model)} className="logo-image"/>
            </Row>
            <DocumentsUpload
              {...{
                ...props,
                onFileSelect: (file: O.Option<File>) => {
                  return pipe(
                    file,
                    O.map((fileWithContent) => {
                      const fr = new FileReader();

                      fr.onload = function () {
                        if (ref?.current && fr.result) {
                          ref.current.src = fr.result as string;
                        }
                      };
                      setUploadedLogo(file);
                      fr.readAsDataURL(fileWithContent);
                      return flow(LogoSelected(file), dispatch);
                    }),
                    O.getOrElse(() => () => {
                      if (ref?.current) {
                        ref.current.src = defaultLogo(model);
                      }
                    }),
                  );
                },
              }}
            />
          </div>
        </div>
      </div>
      <Row padding="xs" gap="xs" grow={1}>
        <Button type="primary" onClick={save}>
          Save
        </Button>
        <Button type="primary" onClick={O.none}>
          Cancel
        </Button>
      </Row>
    </Col>
  );
}

function DocumentsUpload(
  props: Props & { onFileSelect: (file: O.Option<File>) => () => void },
): JSX.Element {
  const uploadId = useId();

  const dragOverHandler = useCallback((evt: React.DragEvent) => {
    const allowedFileTypes = ["image/png", "image/jpeg", "image/gif"];

    const files = evt.dataTransfer.items;

    if (!allowedFileTypes.includes(files[0].type)) {
      evt.dataTransfer.effectAllowed = "none";
      evt.dataTransfer.dropEffect = "none";
    }
    evt.preventDefault();
  }, []);

  const dropHandler = useCallback(
    (evt: React.DragEvent) => {
      evt.preventDefault();
      const file = pipe(
        O.fromNullable(evt.dataTransfer.items),
        O.chain((itemsList) =>
          pipe(
            Array.from(itemsList),
            A.filterMap((item) => O.fromNullable(item.getAsFile())),
            NEA.fromArray,
          ),
        ),
        O.alt(() => pipe(Array.from(evt.dataTransfer.files), NEA.fromArray)),
        O.map(NEA.head),
      );

      if (O.isSome(file)) {
        props.onFileSelect(file)();
      }
    },
    [props],
  );

  const filesChangedHandler = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      pipe(
        evt.target.files,
        O.fromNullable,
        O.chain((fileList) => pipe(Array.from(fileList), NEA.fromArray)),
        O.map((files) => pipe(files, NEA.head)),
        (file) => props.onFileSelect(file)(),
      );
    },
    [props],
  );

  return (
    <div
      className="drag-n-drop-area pointer"
      onDragOver={dragOverHandler}
      onDrop={dropHandler}
    >
      <Col
        gap="xxs"
        padding="xs"
        alignVertical="center"
        alignHorizontal="center"
        grow={1}
      >
        <span className="text-lg">
          <Icon type="upload"></Icon>
        </span>
        <label htmlFor={uploadId} className="upload-label text-sm text-bold">
          Click here to upload
        </label>{" "}
        <div className="drag-drop-message-header">or drag and drop.</div>
        <div className="drag-drop-message-content text-sm">
          SVG, PNG, JPEG or GIF (max 800x400px).
        </div>
        <input
          id={uploadId}
          type="file"
          accept=".png, .jpg, .jpeg, .svg"
          className="upload-input"
          multiple
          onChange={filesChangedHandler}
        />
      </Col>
    </div>
  );
}
