import {
  ApplicationListingResponse,
  ApplicationListItem,
  ApplicationsFilterPayload,
  EqApplicationStatus,
  OrdApplicationStatus,
} from "@/data/applicationsList";
import { Ord } from "fp-ts/lib/Ord";

import { EqUserId, UserId } from "@/data/client";
import {
  ApplicationId,
  ApplicationStatusType,
  ApplicationType,
  EqApplicationType,
  OrdApplicationType,
} from "@/data/payload";
import { Api } from "@/utils/api";
import {
  AsyncOperationStatus,
  Finished,
  Started,
} from "@/utils/asyncOperationStatus";
import {
  InProgress,
  isLoading,
  NotStarted,
  Resolved,
  updatingDeferred,
} from "@/utils/deferred";
import {
  Effect,
  effectOfAction,
  effectOfAsync,
  mapEffect,
  noEffect,
} from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import * as Tup from "fp-ts/Tuple";
import { Eq } from "fp-ts/lib/Eq";
import * as O from "fp-ts/lib/Option";
import * as S from "fp-ts/lib/Set";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import * as AssignUser from "./AssignUser/index";
import { getSearchPayload, Model } from "./model";
import {
  CreditCheckConsentFilterOption,
  dateRanges,
  noneIfEmpty,
  SubmissionDateFilterOption,
} from "./types";
import { isEqual } from "lodash-es";

export type Action =
  | {
      type: "SearchChanged";
      search: string;
    }
  | {
      type: "StatusFilterAllSelected";
    }
  | {
      type: "StatusFilterToggled";
      status: ApplicationStatusType;
    }
  | {
      type: "MortgageTypeFilterAllSelected";
    }
  | {
      type: "MortgageTypeFilterToggled";
      mortgageType: ApplicationType;
    }
  | {
      type: "SubmissionDateFilterAllSelected";
    }
  | {
      type: "CreditCheckConsentFilterAllSelected";
    }
  | {
      type: "SubmissionDateFilterSelected";
      submissionDateFilter: SubmissionDateFilterOption;
    }
  | {
      type: "CreditCheckConsentFilterSelected";
      creditCheckConsentFilter: CreditCheckConsentFilterOption;
    }
  | {
      type: "AssigneeFilterAllSelected";
    }
  | {
      type: "AssignUserAction";
      action: AssignUser.Action;
    }
  | {
      type: "AssignUserSelected";
      application: ApplicationListItem;
    }
  | {
      type: "CancelUserSelected";
      actionDone: boolean;
    }
  | {
      type: "AssigneeFilterToggled";
      userId: UserId;
    }
  | {
      type: "CreditCheckFilterToggled";
      showAuthorized: CreditCheckConsentFilterOption;
    }
  | {
      type: "ArchiveApplication";
      applicationId: ApplicationId;
      operation: AsyncOperationStatus<ApiResult<unknown>>;
    }
  | {
      type: "ExportDocuments";
      applicationId: ApplicationId;
      operation: AsyncOperationStatus<ApiResult<string>>;
    }
  | {
      type: "ResetExportStatus";
    }
  | {
      type: "ShowExportWarning";
      applicationId: ApplicationId;
    }
  | {
      type: "ArchiveApplication";
      applicationId: ApplicationId;
      operation: AsyncOperationStatus<ApiResult<unknown>>;
    }
  | {
      type: "AcceptExportWarning";
    }
  | {
      type: "RefreshPageAction";
      payload: ApplicationsFilterPayload;
      operation: AsyncOperationStatus<ApiResult<ApplicationListingResponse>>;
    };

export const SearchChanged = (search: string): Action => ({
  type: "SearchChanged",
  search,
});

export const StatusFilterAllSelected = (): Action => ({
  type: "StatusFilterAllSelected",
});

export const StatusFilterToggled = (status: ApplicationStatusType): Action => ({
  type: "StatusFilterToggled",
  status,
});

export const MortgageTypeFilterAllSelected = (): Action => ({
  type: "MortgageTypeFilterAllSelected",
});

export const MortgageTypeFilterToggled = (
  mortgageType: ApplicationType,
): Action => ({
  type: "MortgageTypeFilterToggled",
  mortgageType,
});

export const SubmissionDateFilterAllSelected = (): Action => ({
  type: "SubmissionDateFilterAllSelected",
});

export const SubmissionDateFilterSelected = (
  submissionDateFilter: SubmissionDateFilterOption,
): Action => ({
  type: "SubmissionDateFilterSelected",
  submissionDateFilter,
});

export const CreditCheckConsentFilterAllSelected = (): Action => ({
  type: "CreditCheckConsentFilterAllSelected",
});

export const CreditCheckConsentFilterSelected = (
  creditCheckConsentFilter: CreditCheckConsentFilterOption,
): Action => ({
  type: "CreditCheckConsentFilterSelected",
  creditCheckConsentFilter,
});

export const AssigneeFilterAllSelected = (): Action => ({
  type: "AssigneeFilterAllSelected",
});

export const AssignUserSelected = (
  application: ApplicationListItem,
): Action => ({
  type: "AssignUserSelected",
  application,
});

export const CancelUserSelected = (actionDone: boolean): Action => ({
  type: "CancelUserSelected",
  actionDone,
});

export const AssignUserAction = (action: AssignUser.Action): Action => ({
  type: "AssignUserAction",
  action,
});

export const AssigneeFilterToggled = (userId: UserId): Action => ({
  type: "AssigneeFilterToggled",
  userId,
});

export const CreditCheckFilterToggled = (
  showAuthorized: CreditCheckConsentFilterOption,
): Action => ({
  type: "CreditCheckFilterToggled",
  showAuthorized,
});

export const ArchiveApplication =
  (applicationId: ApplicationId) =>
  (operation: AsyncOperationStatus<ApiResult<unknown>>): Action => ({
    type: "ArchiveApplication",
    applicationId,
    operation,
  });

export const ExportDocuments =
  (applicationId: ApplicationId) =>
  (operation: AsyncOperationStatus<ApiResult<string>>): Action => ({
    type: "ExportDocuments",
    applicationId,
    operation,
  });

export const ResetExportStatus = (): Action => ({
  type: "ResetExportStatus",
});

export const ShowExportWarning =
  (applicationId: ApplicationId) => (): Action => ({
    type: "ShowExportWarning",
    applicationId,
  });

export const RefreshPageAction =
  (payload: ApplicationsFilterPayload) =>
  (
    operation: AsyncOperationStatus<ApiResult<ApplicationListingResponse>>,
  ): Action => ({
    type: "RefreshPageAction",
    payload,
    operation,
  });
export const AcceptExportWarning = (): Action => ({
  type: "AcceptExportWarning",
});

const filterUpdater = <T>(
  newStatus: T,
  eq: Eq<T>,
  ord: Ord<T>,
  existing: O.Option<Set<T>>,
): [O.Option<Set<T>>, T[]] => {
  const statusFilter = pipe(
    existing,
    O.fold(
      () => O.some(S.singleton(newStatus)),
      flow(S.toggle(eq)(newStatus), noneIfEmpty),
    ),
  );

  const statusAsArray = pipe(
    statusFilter,
    O.map((v) => S.toArray(ord)(v)),
    O.fold(() => [], identity),
  );

  return [statusFilter, statusAsArray];
};

const refreshSearch = (
  model: Model,
  args: Partial<ApplicationsFilterPayload>,
) =>
  effectOfAction(
    flow(
      Started,
      flow(constant(args), getSearchPayload(model), RefreshPageAction)(),
    )(),
  );

export const update =
  (api: Api) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    switch (action.type) {
      case "SearchChanged": {
        return [{ ...model, search: action.search }, noEffect];
      }

      case "StatusFilterAllSelected":
        return [
          { ...model, statusFilter: O.none },
          refreshSearch(model, { statusesToInclude: [] }),
        ];

      case "StatusFilterToggled":
        {
          const [modelValue, payloadArray] = filterUpdater(
            action.status,
            EqApplicationStatus,
            OrdApplicationStatus,
            model.statusFilter,
          );

          return [
            {
              ...model,
              statusFilter: modelValue,
            },
            refreshSearch(model, { statusesToInclude: payloadArray }),
          ];
        }
        break;

      case "MortgageTypeFilterAllSelected":
        return [
          { ...model, mortgageTypeFilter: O.none },
          refreshSearch(model, { mortgageTypesToInclude: [] }),
        ];

      case "MortgageTypeFilterToggled":
        {
          const [modelValue, payloadArray] = filterUpdater(
            action.mortgageType,
            EqApplicationType,
            OrdApplicationType,
            model.mortgageTypeFilter,
          );
          return [
            {
              ...model,
              mortgageTypeFilter: modelValue,
            },
            refreshSearch(model, { mortgageTypesToInclude: payloadArray }),
          ];
        }
        break;

      case "SubmissionDateFilterAllSelected":
        return [
          { ...model, submissionDateFilter: O.none },
          refreshSearch(model, { startDate: null, endDate: null }),
        ];

      case "SubmissionDateFilterSelected":
        return [
          {
            ...model,
            submissionDateFilter: O.some(action.submissionDateFilter),
          },
          refreshSearch(model, dateRanges(action.submissionDateFilter)),
        ];

      case "AssigneeFilterAllSelected":
        return [
          { ...model, assigneeFilter: O.none },
          refreshSearch(model, { assignedToUserId: [] }),
        ];

      case "AssignUserAction": {
        const { assingmentModel } = model;
        if (O.isNone(assingmentModel)) {
          return [model, noEffect];
        }
        return pipe(
          AssignUser.update(api)(assingmentModel.value, action.action),
          Tup.bimap(mapEffect(AssignUserAction), (assingmentModel) => ({
            ...model,
            assingmentModel: O.some(assingmentModel),
          })),
        );
      }

      case "CancelUserSelected":
        return [
          { ...model, assingmentModel: O.none },
          action.actionDone ? refreshSearch(model, {}) : noEffect,
        ];

      case "AssignUserSelected":
        return pipe(
          AssignUser.init(action.application),
          Tup.bimap(
            mapEffect(AssignUserAction),
            (assingmentModel): Model => ({
              ...model,
              assingmentModel: O.some(assingmentModel),
            }),
          ),
        );

      case "AssigneeFilterToggled":
        return [
          {
            ...model,
            assigneeFilter: pipe(
              model.assigneeFilter,
              O.fold(
                () => O.some(S.singleton(action.userId)),
                flow(S.toggle(EqUserId)(action.userId), noneIfEmpty),
              ),
            ),
          },
          refreshSearch(model, { assignedToUserId: [action.userId] }),
        ];

      case "CreditCheckFilterToggled":
        {
          return [
            {
              ...model,
              creditCheckFilter: O.some(action.showAuthorized),
            },
            refreshSearch(model, {
              isCreditCheckAuthorized: pipe(action.showAuthorized, (v) => {
                switch (v) {
                  case "Authorized":
                    return true;
                  case "Not Authorized":
                    return false;
                }
              }),
            }),
          ];
        }
        break;

      case "ArchiveApplication":
        {
          switch (action.operation.status) {
            case "Started":
              {
                const apiCallerAction = effectOfAsync(
                  api.archiveApplication(action.applicationId),
                  flow(Finished, ArchiveApplication(action.applicationId)),
                );

                return [
                  {
                    ...model,
                  },
                  apiCallerAction,
                ];
              }
              break;

            case "Finished":
              return [{ ...model }, refreshSearch(model, {})];
          }
          break;
        }
        break;
      case "ExportDocuments":
        {
          switch (action.operation.status) {
            case "Started":
              {
                const apiCallerAction = effectOfAsync(
                  api.exportDocuments(action.applicationId),
                  flow(Finished, ExportDocuments(action.applicationId)),
                );

                return [
                  {
                    ...model,
                    exportWarningShown: O.none,
                    exportStatus: InProgress(),
                  },
                  pipe(
                    model.exportWarningShown,
                    O.chain(([v]) => O.fromPredicate(identity<boolean>)(v)),
                    O.map((v) => (v ? apiCallerAction : noEffect)),
                    O.getOrElse(() => apiCallerAction),
                  ),
                ];
              }
              break;

            case "Finished":
              return [
                {
                  ...model,
                  exportStatus: Resolved(action.operation.result),
                },
                noEffect,
              ];
          }
        }
        break;

      case "RefreshPageAction":
        {
          switch (action.operation.status) {
            case "Started":
              {
                const newPayload = action.payload;
                if (
                  isLoading(model.applicationListReponse) &&
                  isEqual(model.searchParams, action.payload)
                ) {
                  return [model, noEffect];
                }
                return [
                  {
                    ...model,
                    searchParams: newPayload,
                    applicationListReponse: updatingDeferred(
                      model.applicationListReponse,
                    ),
                  },
                  effectOfAsync(
                    api.postApplicationsList(newPayload),
                    flow(Finished, RefreshPageAction(newPayload)),
                  ),
                ];
              }
              break;

            case "Finished":
              return [
                {
                  ...model,
                  applicationListReponse: Resolved(action.operation.result),
                },
                noEffect,
              ];
          }
        }
        break;

      case "ResetExportStatus": {
        return [
          {
            ...model,
            exportStatus: NotStarted(),
          },
          noEffect,
        ];
      }

      case "ShowExportWarning":
        {
          return [
            {
              ...model,
              exportWarningShown: O.some([false, action.applicationId]),
            },
            noEffect,
          ];
        }
        break;
      case "AcceptExportWarning":
        {
          const [_, applicationId] = pipe(
            model.exportWarningShown,
            O.fold(
              () => [false, NaN] as [boolean, ApplicationId],
              (v) => v,
            ),
          );
          return [
            { ...model, exportWarningShown: O.some([true, applicationId]) },
            effectOfAction(flow(Started, ExportDocuments(applicationId))()),
          ];
        }
        break;

      case "CreditCheckConsentFilterAllSelected":
        return [
          { ...model, creditCheckFilter: O.none },
          refreshSearch(model, { isCreditCheckAuthorized: null }),
        ];
      case "CreditCheckConsentFilterSelected":
        return [
          {
            ...model,
            creditCheckFilter: O.some(action.creditCheckConsentFilter),
          },
          refreshSearch(model, {
            isCreditCheckAuthorized: pipe(
              action.creditCheckConsentFilter,
              (v) => {
                switch (v) {
                  case "Authorized":
                    return true;
                  case "Not Authorized":
                    return false;
                }
              },
            ),
          }),
        ];
    }
  };
