import { useMediaQuery, useOrientation } from "@uidotdev/usehooks";

export enum DeviceType {
  Tablet = "Tablet",
  Desktop = "Desktop",
  Mobile = "Mobile",
  Unknown = "Unknown",
}

export enum OrientationType {
  Portrait = "Portrait",
  Landscape = "Landscape",
}

export type DeviceOrientation = `${DeviceType}-${OrientationType}` | "Default";

export type ResolvedValue<T> = Array<T>;
export interface ViewResolverProps<T> {
  viewModes: DeviceOrientation[][];
  resolvedContent: Array<T>;
}

export function GetDeviceInfo() {
  const tabletMediaQuery =
    "screen and (min-width: 768px) and (max-width: 1024px)";
  const mobileMediaQuery = "screen and (max-width: 767px)";
  const desktopMediaQuery = "screen and (min-width: 1025px)";
  const isTablet = useMediaQuery(tabletMediaQuery);
  const isMobile = useMediaQuery(mobileMediaQuery);
  const isDesktop = useMediaQuery(desktopMediaQuery);
  const orientationDetails = useOrientation();
  const deviceType = isMobile
    ? "Mobile"
    : isTablet
      ? "Tablet"
      : isDesktop
        ? "Desktop"
        : "Unknown";
  const orientation =
    orientationDetails.type == "portrait-primary" ||
    orientationDetails.type == "portrait-secondary"
      ? "Portrait"
      : "Landscape";
  return [deviceType, orientation].join("-");
}

export function GetDeviceOrientation(): DeviceOrientation {
  const tabletMediaQuery =
    "screen and (min-width: 768px) and (max-width: 1024px)";
  const mobileMediaQuery = "screen and (max-width: 767px)";
  const desktopMediaQuery = "screen and (min-width: 1025px)";
  const isTablet = useMediaQuery(tabletMediaQuery);
  const isMobile = useMediaQuery(mobileMediaQuery);
  const isDesktop = useMediaQuery(desktopMediaQuery);
  const orientationDetails = useOrientation();
  const deviceType = isMobile
    ? DeviceType.Mobile
    : isTablet
      ? DeviceType.Tablet
      : isDesktop
        ? DeviceType.Desktop
        : DeviceType.Unknown;
  const orientation =
    orientationDetails.type == "portrait-primary" ||
    orientationDetails.type == "portrait-secondary"
      ? OrientationType.Portrait
      : OrientationType.Landscape;
  return `${deviceType}-${orientation}`;
}

export function ViewResolver<O>({
  viewModes: deviceGroup,
  resolvedContent: resolvedValue,
}: ViewResolverProps<O>) {
  const deviceInfo = GetDeviceOrientation();
  const resolvedObj = zip(deviceGroup, resolvedValue);
  return Object.keys(resolvedObj).includes(deviceInfo)
    ? resolvedObj[
        deviceInfo]
    : resolvedObj.Default;
}

export function zip<K>(group: DeviceOrientation[][], mapValue: Array<K>) {
  const mapped = group.map((ele, index) => {
    const mapperIndex = index < mapValue.length ? index : 0;
    return zipWith(ele, [mapValue[mapperIndex]]);
  });

  return (mapped.flat() as { [k in DeviceOrientation]: K }[]).reduce(
    (accum, flattenObj) => {
      return { ...accum, ...flattenObj };
    },
    {} as { [key in DeviceOrientation]: K },
  );
}

export function zipWith<K>(
  group: Array<DeviceOrientation>,
  mapValue: Array<K>,
) {
  const mapped = group.map((ele, index) => {
    const mapperIndex = index < mapValue.length ? index : 0;
    return {
      [`${ele}`]: mapValue[mapperIndex],
    };
  });

  return mapped;
}
