// HomePage/action.ts
import * as O from "fp-ts/Option";
import * as Tup from "fp-ts/Tuple";
import * as Signup from "@/components/SignUp";
import { Model } from "./model";
import { Api } from "@/utils/api";
import { Effect, mapEffect, noEffect } from "@/utils/reducerWithEffect";
import { pipe } from "fp-ts/lib/function";
import { User } from "@/data/client";

export type Action =
  | { type: "GoToDashboard" }
  | { type: "GoToApplicationsList" }
  | { type: "SignupRequested" }
  | { type: "CancelSignup" }
  | { type: "UpdateTitle"; title: string }
  | { type: "SignupAction"; action: Signup.Action };

export const GoToDashboard = (): Action => ({ type: "GoToDashboard" });
export const CancelSignup = (): Action => ({ type: "CancelSignup" });
export const SignupRequested = (): Action => ({ type: "SignupRequested" });
export const goToApplicationsList = (): Action => ({
  type: "GoToApplicationsList",
});
export const updateTitle = (title: string): Action => ({
  type: "UpdateTitle",
  title,
});

export const SignupAction = (action: Signup.Action): Action => ({
  type: "SignupAction",
  action,
});

export const update =
  (api: Api) =>
  (
    model: Model,
    action: Action,
    user: O.Option<User>,
  ): [Model, Effect<Action>] => {
    switch (action.type) {
      case "SignupRequested":
        return [{ ...model, signupModel: O.some(Signup.init(user)) }, noEffect];

      case "SignupAction":
        if (O.isNone(model.signupModel)) {
          return [model, noEffect];
        }
        return pipe(
          Signup.update(api)(action.action)(model.signupModel.value),
          Tup.bimap(
            mapEffect(SignupAction),
            (signUpModel): Model => ({
              ...model,
              signupModel: O.some(signUpModel),
            }),
          ),
        );

      case "CancelSignup":
        return [{ ...model, signupModel: O.none }, noEffect];
      default:
        return [model, noEffect];
    }
  };
