import {
  Button,
  Col,
  InputField,
  Label,
  PhoneInputField,
} from "@/components/basic";
import { Started } from "@/utils/asyncOperationStatus";
import { LocalStoragetKeys, saveToLocalStorage } from "@/utils/localstorage";
import { ChildProps } from "@/utils/reducerWithEffect";
import { Elements, PaymentElement, useElements } from "@stripe/react-stripe-js";
import * as E from "fp-ts/Either";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { useMemo } from "react";
import {
  Action,
  CompanyNameChanged,
  CompanyNMLSChanged,
  CompanyTypeChanged,
  CreateClient,
  EmailChanged,
  FirstNameChanged,
  HeadCountChanged,
  LastNameChanged,
  LoanOfficerNMLSChanged,
  PhoneChanged,
  SubdomainChanged,
  WebsiteUrlChanged,
} from "./action";
import { Model, PaymentSetupInfo, result } from "./model";
import realEstateAgentImage from "@/assets/images/RealEstateAgent.png";
import "@/assets/styles/SignUp/styles.css";
import { FlashAdder } from "@/utils/page-types";

export type Props = ChildProps<Model, Action> & {
  onSignup: (email: string, phoneNo: string) => void;
  flashAdder: FlashAdder
};

export function View(props: Props): JSX.Element {
  const { model } = props;
  switch (model.createClientResponse.status) {
    case "NotStarted":
      return <ClientForm {...props} />;
    case "InProgress":
    case "Updating":
      return <div>Creating client...</div>;
    case "Resolved":
      return pipe(
        model.createClientResponse.value,
        E.fold(
          () => <Label>Failed to create client</Label>,
          (response) => (
            <div className="grid-2 signup-banner">
              <Col grow={0} gap="xs" alignVertical="center">
                <div className="text-lg">Free to signup and use</div>
                <img className="" src={realEstateAgentImage} />

                <div>
                  <span> Pricing: </span>{" "}
                  <span className="text-bold text-md">
                    $49.95 per Application
                  </span>
                </div>
                <div>No monthly or annual fee obligations</div>
                <div>
                  The application fee will only be charged after:
                  <ul>
                    <li>Borrower or loan officer has completed the survey.</li>
                    <li>Borrower has provided consent to proceed.</li>
                    <li>Borrower has set up their user account.</li>
                  </ul>
                </div>
                <div className="text-bold text-sm">
                  Fees are charged to your card on the day they are incurred.
                </div>
              </Col>

              <Col alignVertical="center">
                <Payment
                  stripe={response.stripe}
                  clientSecret={response.clientSecret}
                />
              </Col>
            </div>
          ),
        ),
      );
  }
}

function ClientForm(props: Props): JSX.Element {
  const { model, dispatch } = props;
  const saveClient = useMemo(
    () =>
      pipe(
        result(model),
        O.fromEither,
        O.map((payload) => {
          return pipe(
            model.user,
            O.fold(
              () =>
                flow(
                  () => {
                    saveToLocalStorage(
                      LocalStoragetKeys.BackOfficeSignup,
                      JSON.stringify(payload),
                    );
                  },
                  () => props.onSignup(payload.email, payload.phone),
                ),
              () => flow(Started, CreateClient(payload, props.flashAdder), dispatch),
            ),
          );
        }),
      ),
    [model, dispatch, props],
  );

  return (
    <Col padding="sm" gap="sm">
      <div className="grid-2 gap-xs">
        <InputField
          label="First Name *"
          field={model.firstName}
          onChange={flow(FirstNameChanged, dispatch)}
        />
        <InputField
          label="Last Name *"
          field={model.lastName}
          onChange={flow(LastNameChanged, dispatch)}
        />
        <InputField
          label="Email address *"
          field={model.email}
          onChange={flow(EmailChanged, dispatch)}
        />
        <PhoneInputField
          label="Telephone *"
          field={model.phone}
          onChange={flow(PhoneChanged, dispatch)}
        />
        <InputField
          label="Company Name *"
          field={model.companyName}
          onChange={flow(CompanyNameChanged, dispatch)}
        />
        <InputField
          label="Company website"
          field={model.websiteUrl}
          onChange={flow(WebsiteUrlChanged, dispatch)}
        />
        <InputField
          label="Your NMLS"
          field={model.loanOfficerNMLS}
          onChange={flow(LoanOfficerNMLSChanged, dispatch)}
        />
        <InputField
          label="Company NMLS"
          field={model.companyNMLS}
          onChange={flow(CompanyNMLSChanged, dispatch)}
        />
        <InputField
          label="Company Type"
          field={model.companyType}
          onChange={flow(CompanyTypeChanged, dispatch)}
        />
        <InputField
          label="Head Count"
          field={model.headCount}
          onChange={flow(HeadCountChanged, dispatch)}
        />
        <InputField
          label="Your AppCatcher URL"
          suffix=".appcatcher.com"
          placeholder="Please fill in"
          required={true}
          field={model.subdomain}
          hint="This cannot be changed in the future."
          onChange={flow(SubdomainChanged, dispatch)}
        />
      </div>
      <Col>
        <Button type="primary" onClick={saveClient}>
          Submit
        </Button>
      </Col>
    </Col>
  );
}

type PaymentProps = PaymentSetupInfo;

function Payment(props: PaymentProps): JSX.Element {
  return (
    <Elements
      stripe={props.stripe}
      options={{ clientSecret: props.clientSecret }}
    >
      <PaymentForm {...props} />
    </Elements>
  );
}

function PaymentForm(props: PaymentProps): JSX.Element {
  const elementsRef = useElements();
  const { stripe } = props;
  const confirm = useMemo(() => {
    return pipe(
      O.fromNullable(elementsRef),
      O.map(
        (elements) => () =>
          stripe.confirmSetup({
            elements,
            confirmParams: {
              return_url: `${window.location.origin}/dashboard?first_look=true`,
            },
          }),
      ),
    );
  }, [elementsRef, stripe]);

  return (
    <Col padding="lg" gap="sm">
      <PaymentElement />
      <Button type="primary" onClick={confirm}>
        Submit
      </Button>
    </Col>
  );
}
