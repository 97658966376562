import * as t from "io-ts";
import * as tt from "io-ts-types";

// must be a 10 digit number
// The first digit can't be 0.
const phoneNumberRegex = /^[1-9]\d{9}$/;

interface ValidPhoneNumberBrand {
  readonly ValidPhoneNumber: unique symbol;
}

export const phoneNumberCodec = t.brand(
  tt.NonEmptyString,
  (s): s is t.Branded<tt.NonEmptyString, ValidPhoneNumberBrand> =>
    phoneNumberRegex.test(s),
  "ValidPhoneNumber",
);

export const phoneNumberCodecWithMessage = (errorMessage: string) => tt.withMessage(
  phoneNumberCodec,
  () => errorMessage
);

export type PhoneNumber = t.TypeOf<typeof phoneNumberCodec>;
