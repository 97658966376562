import { constant, flow } from "fp-ts/lib/function";
import {
  BankruptcyStatusType,
  EqRetirementAccount,
  retirementAccountTypes,
  showBankruptcyStatusType,
  showRetirementAccountType,
} from "@/data/payload";
import { ChildProps } from "@/utils/reducerWithEffect";
import {
  Action,
  AlimonyOrChildSupportChanged,
  BankruptcyStatusAction,
  BankruptcyStatusChanged,
  ForeclosureOrShortSaleChanged,
  IncomeInformationAction,
  RetirementAccountToggled,
} from "./action";
import { Model } from "./model";
import * as O from "fp-ts/Option";
import { Eq as EqString } from "fp-ts/string";
import * as S from "fp-ts/Set";
import { Col, Label, Select, Checkbox, Row } from "@/components/basic";
import * as BankruptcyStatus from "@/components/BankruptcyStatus";
import * as IncomeInformation from "@/components/IncomeInformation";
import { ViewResolver } from "@/utils/viewResolver";

const bankruptcyStatusTypes: BankruptcyStatusType[] = [
  "None",
  "Chapter7",
  "Chapter13",
];

export type Props = ChildProps<Model, Action>;

export function View({ model, dispatch }: Props): JSX.Element {
  return (
    <>
      <IncomeInformation.View
        model={model.incomeInformationModel}
        dispatch={flow(IncomeInformationAction, dispatch)}
      />
      <hr className="grid-item-2 line"></hr>
      <Row
        gap="xs"
        className="text-smd grid-item-3 padding-vertical-xs color-act-70 text-mbold"
      >
        <span>Additional Financial Information</span>
      </Row>
      <div className="grid-2 gap-xs collapse-gap-md">
        <Col gap="xs" className="column-span-2">
          <Label>Have you ever declared bankruptcy?</Label>
          <Select
            options={bankruptcyStatusTypes}
            selected={O.some(model.bankruptcyStatus.type)}
            valueEq={EqString}
            renderLabel={showBankruptcyStatusType}
            onChange={flow(BankruptcyStatusChanged, dispatch)}
          />
        </Col>
        <Col gap="sm" className={ViewResolver({
          viewModes: [["Default"], ["Mobile-Portrait"]],
          resolvedContent: ['', "column-span-2"]
        })}>
          <Label>Do any of the following apply to you?</Label>

          <Checkbox
            label="Foreclosure or short sale"
            checked={model.foreclosureOrShortSale}
            onChange={flow(ForeclosureOrShortSaleChanged, dispatch)}
          />

          <BankruptcyStatus.View
            model={model.bankruptcyStatus}
            dispatch={flow(BankruptcyStatusAction, dispatch)}
          />

          <Checkbox
            label="Obligation to pay Alimony and/or Child Support"
            checked={model.alimonyOrChildSupport}
            onChange={flow(AlimonyOrChildSupportChanged, dispatch)}
          />
        </Col>

        <Col gap="xs">
          <Label>Which if any retirement accounts do you have?</Label>
          {retirementAccountTypes.map((retirementAccount) => (
            <Checkbox
              key={retirementAccount}
              label={showRetirementAccountType(retirementAccount)}
              checked={S.elem(EqRetirementAccount)(retirementAccount)(
                model.retirementAccounts,
              )}
              onChange={flow(
                constant(retirementAccount),
                RetirementAccountToggled,
                dispatch,
              )}
            />
          ))}
        </Col>
      </div>
    </>
  );
}
