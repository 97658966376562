import "@/assets/styles/basic/Input/input.css";
import { constUndefined, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";

export type InputType =
  | "text"
  | "email"
  | "tel"
  | "password"
  | "number"
  | "date"
  | "textarea";

export type InputProps = {
  value: string;
  type?: InputType;
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  onChange: (raw: string) => void;
  className?: string;
  onSubmit?: () => void;
};

export function Input(props: InputProps): JSX.Element {
  const handleEnterKey = pipe(props.onSubmit, O.fromNullable);
  return (
    <input
      className={`input text-md ${props.invalid ? "invalid" : ""} ${props?.className ? props.className : ""}`}
      type={props.type || "text"}
      value={props.value}
      placeholder={props.placeholder}
      disabled={props.disabled}
      onKeyDown={(e) =>
        e.key === "Enter" ? pipe(handleEnterKey, O.flap(e)) : constUndefined
      }
      onChange={(e) => props.onChange(e.target.value)}
    />
  );
}
