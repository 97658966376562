import * as Signup from "@/components/SignUp";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import React from "react";
import { Col, Modal } from "../basic";
import { CancelSignup, SignupAction, SignupRequested } from "./action";
import { HomePageProps } from "./model";
import { FooterSection, HeaderSection, SectionFive, SectionFour, SectionOne, SectionSeven, SectionSix, SectionThree, SectionTwo } from "./sections";

export const View: React.FC<HomePageProps> = (props) => {
  const SignUpFlow = () => flow(SignupRequested, props.dispatch);
  return (
    <>
      {pipe(
        props.model.signupModel,
        O.fold(
          () => <></>,
          (signupModel) => (
            <Modal
              onClose={O.some(flow(CancelSignup, props.dispatch))}
              title={O.some("Create your account")}
            >
              <Signup.View
                model={signupModel}
                dispatch={flow(SignupAction, props.dispatch)}
                onSignup={props.onSignup}
                flashAdder={props.flashAdder}
              />
            </Modal>
          ),
        ),
      )}
      <Col className="app-container">
        <HeaderSection onSignup={SignUpFlow()} onSignin={props.onSignin}></HeaderSection>
        <SectionOne onSignup={SignUpFlow()} ></SectionOne>
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <SectionFive />
        <SectionSix />
        <SectionSeven onSignup={SignUpFlow()}></SectionSeven>
        <FooterSection />
        {/* <FeaturePage />
        <BenefitsPage />
        <Benefit1Section />
        <LpFeaturesPage />
        <TestimonialSection />
        <CtaPage />
        <Footer /> */}
      </Col>
    </>
  );
};
