import {
  BankruptcyStatusType,
  EqRetirementAccount,
  RetirementAccount,
} from "@/data/payload";
import * as BankruptcyStatus from "@/components/BankruptcyStatus";
import * as S from "fp-ts/Set";
import { Model } from "./model";

export type Action =
  | {
      type: "RetirementAccountToggled";
      retirementAccount: RetirementAccount;
    }
  | {
      type: "BankruptcyStatusChanged";
      bankruptcyStatus: BankruptcyStatusType;
    }
  | {
      type: "BankruptcyStatusAction";
      action: BankruptcyStatus.Action;
    }
  | {
      type: "ForeclosureOrShortSaleChanged";
      foreclosureOrShortSale: boolean;
    }
  | {
      type: "AlimonyOrChildSupportChanged";
      alimonyOrChildSupport: boolean;
    };

export const RetirementAccountToggled = (
  retirementAccount: RetirementAccount,
): Action => ({
  type: "RetirementAccountToggled",
  retirementAccount,
});

export const BankruptcyStatusChanged = (
  bankruptcyStatus: BankruptcyStatusType,
): Action => ({
  type: "BankruptcyStatusChanged",
  bankruptcyStatus,
});

export const BankruptcyStatusAction = (
  action: BankruptcyStatus.Action,
): Action => ({
  type: "BankruptcyStatusAction",
  action,
});

export const ForeclosureOrShortSaleChanged = (
  foreclosureOrShortSale: boolean,
): Action => ({
  type: "ForeclosureOrShortSaleChanged",
  foreclosureOrShortSale,
});

export const AlimonyOrChildSupportChanged = (
  alimonyOrChildSupport: boolean,
): Action => ({
  type: "AlimonyOrChildSupportChanged",
  alimonyOrChildSupport,
});

export const update = (model: Model, action: Action): Model => {
  switch (action.type) {
    case "RetirementAccountToggled":
      return {
        ...model,
        retirementAccounts: S.toggle(EqRetirementAccount)(
          action.retirementAccount,
        )(model.retirementAccounts),
      };
    case "BankruptcyStatusChanged":
      return {
        ...model,
        bankruptcyStatus: BankruptcyStatus.init(action.bankruptcyStatus),
      };
    case "BankruptcyStatusAction":
      return {
        ...model,
        bankruptcyStatus: BankruptcyStatus.update(
          model.bankruptcyStatus,
          action.action,
        ),
      };
    case "ForeclosureOrShortSaleChanged":
      return {
        ...model,
        foreclosureOrShortSale: action.foreclosureOrShortSale,
      };
    case "AlimonyOrChildSupportChanged":
      return {
        ...model,
        alimonyOrChildSupport: action.alimonyOrChildSupport,
      };
  }
};
