import {
  EqSourceOfFunds,
  JointApplicantType,
  jointApplicantTypeCodec,
  SourceOfFunds,
  sourceOfFundsCodec,
} from "@/data/payload";
import { FundsAndCoApplicantPayload } from "@/data/surveyFlowPayload";
import { FormField, initFormField } from "@/utils/formField";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import * as A from "fp-ts/Array";
import * as t from "io-ts";
import * as NEA from "fp-ts/NonEmptyArray";
import * as S from "fp-ts/Set";
import { sequenceS } from "fp-ts/lib/Apply";

export type Model = {
  // separate selected sources of funds into a required first source
  // and additional sources to correctly represent the validation logic
  sourceOfFunds: FormField<SourceOfFunds>;
  additionalSourcesOfFunds: SourceOfFunds[];
  jointApplicantSelected: boolean;
  jointApplicantType: FormField<JointApplicantType>;
};

export const init = (payload: Option<FundsAndCoApplicantPayload>): Model => ({
  sourceOfFunds: pipe(
    payload,
    O.fold(
      () => "",
      ({ sourceOfFunds }) => NEA.head(sourceOfFunds),
    ),
    initFormField(t.string.pipe(sourceOfFundsCodec).decode),
  ),
  additionalSourcesOfFunds: pipe(
    payload,
    O.fold(
      () => [],
      ({ sourceOfFunds }) => NEA.tail(sourceOfFunds),
    ),
  ),
  jointApplicantSelected: pipe(
    payload,
    O.chain(({ jointApplicantType }) => jointApplicantType),
    O.isSome,
  ),
  jointApplicantType: pipe(
    payload,
    O.chain(({ jointApplicantType }) => jointApplicantType),
    O.getOrElse(() => ""),
    initFormField(t.string.pipe(jointApplicantTypeCodec).decode),
  ),
});

export const result = (
  model: Model,
): t.Validation<FundsAndCoApplicantPayload> =>
  sequenceS(E.Apply)({
    sourceOfFunds: pipe(
      model.sourceOfFunds.val,
      E.map((s) => A.prepend(s)(model.additionalSourcesOfFunds)),
    ),
    jointApplicantType: model.jointApplicantSelected
      ? pipe(model.jointApplicantType.val, E.map(O.some))
      : E.right(O.none),
  });

// a set structure for selected sources of funds
// to take care of the ordering and uniqueness implicitly
export const selectedSourcesOfFunds = (model: Model): Set<SourceOfFunds> =>
  pipe(
    model.sourceOfFunds.val,
    E.fold(() => S.empty, S.singleton),
    S.union(EqSourceOfFunds)(
      S.fromArray(EqSourceOfFunds)(model.additionalSourcesOfFunds),
    ),
  );
