import { Button, Col, Label, Modal, Row, Select } from "@/components/basic";
import { ApiError } from "@/components/basic/ApiError";
import { User } from "@/data/client";
import { Started } from "@/utils/asyncOperationStatus";
import {
    deferredToOption,
    isApiSuccess,
    isLoading,
    mapDeferred,
} from "@/utils/deferred";
import { ChildProps } from "@/utils/reducerWithEffect";
import { UserContext } from "@/utils/router-context";
import { useApiResult } from "@/utils/useApiResult";
import {
    getFullName,
    getLoanOfficers,
    getSupportUsers,
    isSuperUser
} from "@/utils/user";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import { useContext, useEffect, useMemo, useRef } from "react";
import {
    Action,
    ChooseLoanOfficer,
    ChooseSupportPartner,
    Model,
    PerformAssignments,
    result,
} from "./index";

type Props = ChildProps<Model, Action> & {
  onClose: (done: boolean) => void;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch, onClose } = props;
  const ref = useRef<HTMLDivElement>(null);

  const { user } = useContext(UserContext);

  const isUserSuperUser = useMemo(
    () => pipe(user, O.map(isSuperUser), O.fold(constant(false), identity)),
    [user],
  );

  const payload = useMemo(() => {
    return result(model, isUserSuperUser);
  }, [model, isUserSuperUser]);

  const loanOfficers = useMemo(
    () =>
      pipe(
        model.client,
        mapDeferred(E.map(getLoanOfficers)),
        deferredToOption,
        O.chain(O.fromEither),
      ),
    [model.client],
  );

  const supportUsers = useMemo(
    () =>
      pipe(
        model.client,
        mapDeferred(E.map(getSupportUsers)),
        deferredToOption,
        O.chain(O.fromEither),
      ),
    [model.client],
  );
  const isSubmitting = useMemo(
    () => pipe(model.assignmentStatus, isLoading),
    [model.assignmentStatus],
  );

  const [apiError, hasResolved] = useApiResult(model.assignmentStatus);

  const submitAction = useMemo(
    () =>
      pipe(
        isSubmitting,
        (isSubmitting) => (isSubmitting ? O.none : O.some(null)), //no when submitting
        O.chain(() => {
          //Disable when error
          if (hasResolved && O.isSome(apiError)) {
            return O.none;
          } else {
            return O.some(null);
          }
        }),
        O.chain(() => pipe(payload, O.fromEither)),
        O.map((v) => flow(Started, PerformAssignments(v), dispatch)),
      ),
    [isSubmitting, payload, dispatch, apiError, hasResolved],
  );

  useEffect(() => {
    if (isApiSuccess(model.assignmentStatus)) {
      onClose(true);
    }
  }, [model.assignmentStatus, onClose]);

  const cancelHandler = useMemo(
    () => O.some(() => pipe(O.some(onClose), O.flap(false))),
    [onClose],
  );

  return (
    <Modal
      ref={ref}
      onClose={cancelHandler}
      title={O.some("Select Loan Office/ Support Partner for this application")}
    >
      <Col padding="sm" gap="sm">
        {isUserSuperUser && (
          <>
            <Label>
              Select a loan officer to work on{" "}
              {model.application.applicantsName}'s application
            </Label>
            <Select
              placeholder={
                O.isNone(loanOfficers)
                  ? "Fetching users"
                  : "Select Loan Officer"
              }
              options={pipe(
                loanOfficers,
                O.fold(() => [] as User[], identity),
              )}
              container={ref.current}
              selected={pipe(model.selectedLoanOfficer)}
              valueEq={{ equals: (a, b) => a.userId == b.userId }}
              renderLabel={getFullName}
              onChange={flow(ChooseLoanOfficer, dispatch)}
            />
          </>
        )}
      </Col>

      <Col padding="sm" gap="sm">
        <Label>
          Select a support partner to work on {model.application.applicantsName}'s application (Optional)
        </Label>
        <Select
          placeholder={
            O.isNone(loanOfficers)
              ? "Fetching support partners..."
              : "Select Support Partner"
          }
          options={pipe(
            supportUsers,
            O.fold(() => [] as User[], identity),
          )}
          container={ref.current}
          selected={pipe(model.selectedSupportPartner)}
          valueEq={{ equals: (a, b) => a.userId == b.userId }}
          renderLabel={getFullName}
          onChange={flow(ChooseSupportPartner, dispatch)}
        />
      </Col>
      <Col padding="sm" gap="sm">
        <Row alignHorizontal="left" gap="xs">
          <Button onClick={submitAction} type="primary">
            {isSubmitting ? "Saving..." : "Save Updates"}
          </Button>
          <Button onClick={cancelHandler} type="secondary">
            Cancel
          </Button>
        </Row>
      </Col>
      {isSubmitting && <p> Performing Assigments...</p>}
      <ApiError apiResult={model.assignmentStatus} />
    </Modal>
  );
}
