import { Col, Label, Row } from "@/components/basic";
import "@/assets/styles/SurveyProgress/surveyProgress.css";
import { ViewResolver } from "@/utils/viewResolver";
export type SurveyStepProgress = "Empty" | "InProgress" | "Completed";

export type SurveyProgressProps = {
  personal: SurveyStepProgress;
  financial: SurveyStepProgress;
  mortgage: SurveyStepProgress;
  verification: SurveyStepProgress;
  documentation: SurveyStepProgress;
};

type SuveryProgressOrder = {
  prop: keyof SurveyProgressProps,
  order: number
}

const suveryProgressOrder: SuveryProgressOrder[] = [
  {
    prop: 'personal',
    order: 0,
  },
  {
    prop: 'financial',
    order: 1,
  },
  {
    prop: 'mortgage',
    order: 2,
  },
  {
    prop: 'verification',
    order: 3,
  },
  {
    prop: 'documentation',
    order: 4,
  }
];

const showStepProgress = (stepProgress: SurveyStepProgress): string => {
  switch (stepProgress) {
    case "Empty":
      return "0%";
    case "InProgress":
      return "50%";
    case "Completed":
      return "100%";
  }
};

const getActiveSurvey = (props: SurveyProgressProps): SuveryProgressOrder  => {
 const activeSurvey = suveryProgressOrder.find((survey)=> {
    if((props[survey.prop] === 'Empty' ||  props[survey.prop] === 'InProgress')) {
      return survey;
    };
  })
  return activeSurvey ?? suveryProgressOrder[0]; // default to Personal Survey;
}


export function SurveyProgress(props: SurveyProgressProps): JSX.Element {

  const activeSurvey = getActiveSurvey(props);

  const normalView = (
    <Row gap="sm">
      <Col gap="sm" grow={1}>
        <Row gap="xxxs" alignVertical="center" className="survey-progress-tab">
          <CompleteIconView status={props.personal}></CompleteIconView>
          <Label className="survey-progress-label">Personal</Label>
        </Row>
        <div className="progress-item">
          <div
            className={`progress-bar ${props.personal === 'Completed' ? 'complete' : ''}`}
            style={{ width: showStepProgress(props.personal) }}
          ></div>
        </div>
      </Col>
      <Col gap="sm" grow={1}>
        <Row gap="xxxs" alignVertical="center" className="survey-progress-tab">
          <CompleteIconView status={props.financial}></CompleteIconView>
          <Label className="survey-progress-label">Financial</Label>
        </Row>
        <div className="progress-item">
          <div
            className={`progress-bar ${props.financial === 'Completed' ? 'complete' : ''}`}
            style={{ width: showStepProgress(props.financial) }}
          ></div>
        </div>
      </Col>
      <Col gap="sm" grow={1}>
        <Row gap="xxxs" alignVertical="center" className="survey-progress-tab">
          <CompleteIconView status={props.mortgage}></CompleteIconView>
          <Label className="survey-progress-label">Mortgage</Label>
        </Row>
        <div className="progress-item">
          <div
            className={`progress-bar ${props.mortgage === 'Completed' ? 'complete' : ''}`}
            style={{ width: showStepProgress(props.mortgage) }}
          ></div>
        </div>
      </Col>
      <Col gap="sm" grow={1}>
        <Row gap="xxxs" alignVertical="center" className="survey-progress-tab">
          <CompleteIconView status={props.verification}></CompleteIconView>
          <Label className="survey-progress-label">Verification</Label>
        </Row>
        <div className="progress-item">
          <div
            className={`progress-bar ${props.verification === 'Completed' ? 'complete' : ''}`}
            style={{ width: showStepProgress(props.verification) }}
          ></div>
        </div>
      </Col>
      <Col gap="sm" grow={1}>
        <Row gap="xxxs" alignVertical="center" className="survey-progress-tab">
          <CompleteIconView status={props.documentation}></CompleteIconView>
          <Label className="survey-progress-label">Documentation</Label>
        </Row>
        <div className="progress-item">
          <div
            className={`progress-bar ${props.documentation === 'Completed' ? 'complete' : ''}`}
            style={{ width: showStepProgress(props.documentation) }}
          ></div>
        </div>
      </Col>
    </Row>
  );


  const collapsedView = (
    <Col gap="xs" className="survey-progress-view">
    <Row alignHorizontal="center" alignVertical="center" gap="xxxs">
      <Label className="survey-progress-label collapse-view">{activeSurvey.order + 1}. {activeSurvey.prop}
      </Label>
    </Row>
    <Row gap="sm">
      <Col gap="sm" grow={activeSurvey.prop === 'personal' ? 2 : 1}>
        <div className="progress-item">
          <div
            className={`progress-bar ${props.personal === 'Completed' ? 'complete' : ''}`}
            style={{ width: showStepProgress(props.personal) }}
          ></div>
        </div>
      </Col>
      <Col gap="sm" grow={activeSurvey.prop === 'financial' ? 2 : 1}>
        <div className="progress-item">
          <div
            className={`progress-bar ${props.financial === 'Completed' ? 'complete' : ''}`}
            style={{ width: showStepProgress(props.financial) }}
          ></div>
        </div>
      </Col>
      <Col gap="sm" grow={activeSurvey.prop === 'mortgage' ? 2 : 1}>
        <div className="progress-item">
          <div
            className={`progress-bar ${props.mortgage === 'Completed' ? 'complete' : ''}`}
            style={{ width: showStepProgress(props.mortgage) }}
          ></div>
        </div>
      </Col>
      <Col gap="sm" grow={activeSurvey.prop === 'verification' ? 2 : 1}>
        <div className="progress-item">
          <div
            className={`progress-bar ${props.verification === 'Completed' ? 'complete' : ''}`}
            style={{ width: showStepProgress(props.verification) }}
          ></div>
        </div>
      </Col>
      <Col gap="sm" grow={activeSurvey.prop === 'documentation' ? 2 : 1}>
        <div className="progress-item">
          <div
            className={`progress-bar ${props.documentation === 'Completed' ? 'complete' : ''}`}
            style={{ width: showStepProgress(props.documentation) }}
          ></div>
        </div>
      </Col>
    </Row>
    </Col>
  );

  const SurveyProgressViewWrapper = ViewResolver({
    viewModes: [["Default"], ["Mobile-Portrait", "Tablet-Portrait", "Mobile-Landscape"]],
    resolvedContent: [normalView, collapsedView]
  })
  
  return (
    SurveyProgressViewWrapper
  );
}

type CompleteIconViewProps = {
  status: SurveyStepProgress
}

export function CompleteIconView(props: CompleteIconViewProps): JSX.Element {

  const view = (
    <>
      {props.status === 'Completed' ?<i className="fa-solid fa-check"></i> : <></>}
    </>
  )
  const viewWrapper = ViewResolver({
    viewModes: [["Default"], ["Tablet-Landscape"]],
    resolvedContent: [view, <></>]
  })
  return (
    viewWrapper
  )
}

