import * as ClientsListing from "@/components/ClientsListing";
import * as BranchManagement from "@/components/UserManagement/pages/BranchManagement";
import * as ClientManagement from "@/components/UserManagement/pages/ClientManagement";
import * as EditUser from "@/components/UserManagement/UsersListing/modals/EditUser";
import * as TeamManagement from "@/components/UserManagement/UsersListing/modals/TeamManagement";

import {
  ApplicationListingResponse,
  emptyApplicationFilter,
} from "@/data/applicationsList";
import {
  Branch,
  BranchId,
  Client,
  ClientId,
  ClientsReassignment,
  Team,
  TeamId,
  User,
  UserId,
} from "@/data/client";
import { BranchPayload, TeamPayload, UserPayload } from "@/data/payload";
import { Api } from "@/utils/api";
import { AsyncOperationStatus, Finished } from "@/utils/asyncOperationStatus";
import {
  deferredToOption,
  InProgress,
  mapDeferred,
  NotStarted,
  Resolved,
  updatingDeferred,
} from "@/utils/deferred";
import {
  Effect,
  effectOfAction,
  effectOfAsync,
  effectOfFunc_,
  mapEffect,
  noEffect,
} from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { constant, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import * as TE from "fp-ts/TaskEither";
import {
  DeleteUserDialog,
  EditUserDialog,
  Model,
  NewUserDialog,
  reinitPage,
} from "./model";
export type Action =
  | {
      type: "GetCurrentClient";
      operation: AsyncOperationStatus<ApiResult<Client>>;
    }
  | {
      type: "BranchSelected";
      branchId: BranchId;
    }
  | {
      type: "TeamSelected";
      teamId: TeamId;
    }
  | {
      type: "UserSelected";
      userId: UserId;
    }
  | {
      type: "BranchPayloadPrepared";
      clientId: ClientId;
      branchId: Option<BranchId>;
      payload: BranchPayload;
      operation: AsyncOperationStatus<ApiResult<Client>>;
    }
  | {
      type: "TeamPayloadPrepared";
      teamId: Option<TeamId>;
      payload: TeamPayload;
      operation: AsyncOperationStatus<ApiResult<Client>>;
    }
  | {
      type: "UserPayloadPrepared";
      userId: Option<UserId>;
      payload: UserPayload;
      operation: AsyncOperationStatus<ApiResult<Client>>;
    }
  | {
      type: "DeleteTeamConfirmed";
      teamId: TeamId;
      operation: AsyncOperationStatus<ApiResult<Client>>;
    }
  | {
      type: "DeleteUserConfirmed";
      userId: UserId;
      reassignment: ClientsReassignment;
      operation: AsyncOperationStatus<ApiResult<Client>>;
    }
  | {
      type: "DeleteBranchConfirmed";
      branchId: BranchId;
      operation: AsyncOperationStatus<ApiResult<Client>>;
    }
  | {
      type: "BranchManagementAction";
      action: BranchManagement.Action;
    }
  | {
      type: "TeamManagementAction";
      action: TeamManagement.Action;
    }
  | {
      type: "ClientManagementAction";
      action: ClientManagement.Action;
    }
  | {
      type: "NewUserInitiated";
      teamId: Option<TeamId>;
      branchId: Option<BranchId>;
    }
  | {
      type: "EditUserInitiated";
      user: User;
    }
  | {
      type: "DeleteUserInitiated";
      userId: UserId;
      operation: AsyncOperationStatus<ApiResult<ApplicationListingResponse>>;
    }
  | {
      type: "ConfirmReassignedUser";
    }
  | {
      type: "ReassignToChanged";
      userId: UserId;
    }
  | {
      type: "DialogClosed";
    }
  | {
      type: "EditUserAction";
      action: EditUser.Action;
    }
  | {
      type: "ClientsListingAction";
      action: ClientsListing.Action;
    };

export const GetCurrentClient = (
  operation: AsyncOperationStatus<ApiResult<Client>>,
): Action => ({
  type: "GetCurrentClient",
  operation,
});

export const BranchSelected = (branchId: BranchId): Action => ({
  type: "BranchSelected",
  branchId,
});

export const TeamSelected = (teamId: TeamId): Action => ({
  type: "TeamSelected",
  teamId,
});

export const UserSelected = (userId: UserId): Action => ({
  type: "UserSelected",
  userId,
});

export const BranchPayloadPrepared =
  (clientId: ClientId) =>
  (branchId: Option<BranchId>, payload: BranchPayload) =>
  (operation: AsyncOperationStatus<ApiResult<Client>>): Action => ({
    type: "BranchPayloadPrepared",
    branchId,
    clientId,
    payload,
    operation,
  });

export const TeamPayloadPrepared =
  (teamId: Option<TeamId>, payload: TeamPayload) =>
  (operation: AsyncOperationStatus<ApiResult<Client>>): Action => ({
    type: "TeamPayloadPrepared",
    teamId,
    payload,
    operation,
  });

export const UserPayloadPrepared =
  (userId: Option<UserId>, payload: UserPayload) =>
  (operation: AsyncOperationStatus<ApiResult<Client>>): Action => ({
    type: "UserPayloadPrepared",
    userId,
    payload,
    operation,
  });

export const DeleteTeamConfirmed =
  (teamId: TeamId) =>
  (operation: AsyncOperationStatus<ApiResult<Client>>): Action => ({
    type: "DeleteTeamConfirmed",
    teamId,
    operation,
  });

export const DeleteUserConfirmed =
  (userId: UserId, reassignment: ClientsReassignment) =>
  (operation: AsyncOperationStatus<ApiResult<Client>>): Action => ({
    type: "DeleteUserConfirmed",
    userId,
    reassignment,
    operation,
  });

export const DeleteBranchConfirmed =
  (branchId: BranchId) =>
  (operation: AsyncOperationStatus<ApiResult<Client>>): Action => ({
    type: "DeleteBranchConfirmed",
    branchId,
    operation,
  });

export const BranchManagementAction = (
  action: BranchManagement.Action,
): Action => ({
  type: "BranchManagementAction",
  action,
});

export const TeamManagementAction = (
  action: TeamManagement.Action,
): Action => ({
  type: "TeamManagementAction",
  action,
});

export const ClientManagementAction = (
  action: ClientManagement.Action,
): Action => ({
  type: "ClientManagementAction",
  action,
});

export const NewUserInitiated = (
  branchId: Option<BranchId>,
  teamId: Option<TeamId>,
): Action => ({
  type: "NewUserInitiated",
  branchId,
  teamId,
});

export const EditUserInitiated = (user: User): Action => ({
  type: "EditUserInitiated",
  user,
});

export const DeleteUserInitiated =
  (userId: UserId) =>
  (
    operation: AsyncOperationStatus<ApiResult<ApplicationListingResponse>>,
  ): Action => ({
    type: "DeleteUserInitiated",
    userId,
    operation,
  });

export const ConfirmReassignedUser = (): Action => ({
  type: "ConfirmReassignedUser",
});

export const ReassignToChanged = (userId: UserId): Action => ({
  type: "ReassignToChanged",
  userId,
});

export const DialogClosed = (): Action => ({
  type: "DialogClosed",
});

export const EditUserAction = (action: EditUser.Action): Action => ({
  type: "EditUserAction",
  action,
});

const findBranch =
  (branchId: BranchId) =>
  (client: Client): Option<Branch> =>
    pipe(
      client.branches,
      A.findFirst((branch) => branch.branchId === branchId),
    );

const findTeam =
  (teamId: TeamId) =>
  (branches: Branch[]): Option<Team> =>
    pipe(
      branches,
      A.findFirstMap((branch) =>
        pipe(
          branch.teams,
          A.findFirst((team) => team.teamId === teamId),
        ),
      ),
    );

const allBranchUsers = (branch: Branch): User[] =>
  pipe(
    branch.teams,
    A.chain((team) => team.users),
    A.concat(branch.users),
  );

export const ClientsListingAction = (
  action: ClientsListing.Action,
): Action => ({
  type: "ClientsListingAction",
  action,
});

export const update =
  (api: Api) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    switch (action.type) {
      case "GetCurrentClient":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                { ...model, client: updatingDeferred(model.client) },
                effectOfAsync(
                  api.getCurrentClient,
                  flow(Finished, GetCurrentClient),
                ),
              ];

            case "Finished":
              return [
                {
                  ...model,
                  client: Resolved(action.operation.result),
                  page: reinitPage(model.page),
                },
                pipe(
                  action.operation.result,
                  E.fold(
                    (err) => effectOfFunc_(() => console.log(err), undefined),
                    (_) => {
                      if (model.pendingEffects) {
                        return model.pendingEffects();
                      } else {
                        return noEffect;
                      }
                    },
                  ),
                ),
              ];
          }
        }
        break;

      case "BranchSelected": {
        const branch: Option<Branch> = pipe(
          model.client,
          deferredToOption,
          O.chain(O.fromEither),
          O.chain((v) => findBranch(action.branchId)(v)),
        );

        return [
          pipe(
            branch,
            O.fold(
              () => model,
              ({ branchId }) => ({
                ...model,
                page: {
                  type: "Branch",
                  model: BranchManagement.init(branchId),
                },
              }),
            ),
          ),
          noEffect,
        ];
      }

      case "TeamSelected": {
        const team: Option<Team> = pipe(
          model.client,
          deferredToOption,
          O.chain(O.fromEither),
          O.chain(({ branches }) => findTeam(action.teamId)(branches)),
        );

        return [
          pipe(
            team,
            O.fold(
              () => model,
              ({ teamId }): Model => ({
                ...model,
                page: { type: "Team", model: TeamManagement.init(teamId) },
              }),
            ),
          ),
          noEffect,
        ];
      }

      case "UserSelected": {
        const user: Option<User> = pipe(
          model.client,
          deferredToOption,
          O.chain(O.fromEither),
          O.chain((client) =>
            pipe(
              client.branches,
              A.chain(allBranchUsers),
              A.concat(client.users),
              A.findFirst((user) => user.userId === action.userId),
            ),
          ),
        );
        const newModel = ClientsListing.withAssignee(
          action.userId,
          model.clientsListing,
        );
        return [
          pipe(
            user,
            O.fold(
              () => model,
              (u) => ({
                ...model,
                clientsListing: newModel,
                page: { type: "User", user: u, applications: InProgress() },
              }),
            ),
          ),
          noEffect,
        ];
      }

      case "BranchPayloadPrepared":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                {
                  ...model,
                  dialogApiResult: InProgress(),
                },
                effectOfAsync(
                  pipe(
                    action.branchId,
                    O.fold(
                      () => api.createNewBranch(action.payload),
                      (branchId) =>
                        api.updateBranch(
                          action.clientId,
                          branchId,
                        )(action.payload),
                    ),
                    TE.chain((_) => api.getCurrentClient),
                  ),
                  flow(
                    Finished,
                    BranchPayloadPrepared(action.clientId)(
                      action.branchId,
                      action.payload,
                    ),
                  ),
                ),
              ];
            case "Finished":
              return [
                {
                  ...model,
                  client: Resolved(action.operation.result),
                  dialogApiResult: Resolved(action.operation.result),
                },
                noEffect,
              ];
          }
        }
        break;

      case "TeamPayloadPrepared":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                {
                  ...model,
                  dialogApiResult: InProgress(),
                },
                effectOfAsync(
                  pipe(
                    action.teamId,
                    O.fold(
                      () => api.createNewTeam(action.payload),
                      (teamId) => api.updateTeam(teamId)(action.payload),
                    ),
                    TE.chain((_) => api.getCurrentClient),
                  ),
                  flow(
                    Finished,
                    TeamPayloadPrepared(action.teamId, action.payload),
                  ),
                ),
              ];
            case "Finished":
              return [
                {
                  ...model,
                  client: Resolved(action.operation.result),
                  dialogApiResult: Resolved(action.operation.result),
                },
                noEffect,
              ];
          }
        }
        break;

      case "UserPayloadPrepared":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                {
                  ...model,
                  dialogApiResult: InProgress(),
                },
                effectOfAsync(
                  pipe(
                    action.userId,
                    O.fold(
                      () => api.createNewUser(action.payload),
                      (userId) => api.updateUser(userId)(action.payload),
                    ),
                    TE.chain((_) => api.getCurrentClient),
                  ),
                  flow(
                    Finished,
                    UserPayloadPrepared(action.userId, action.payload),
                  ),
                ),
              ];
            case "Finished":
              return [
                {
                  ...model,
                  client: Resolved(action.operation.result),
                  dialogApiResult: Resolved(action.operation.result),
                },
                noEffect,
              ];
          }
        }
        break;

      case "DeleteTeamConfirmed":
        switch (action.operation.status) {
          case "Started":
            return [
              {
                ...model,
                dialogApiResult: InProgress(),
              },
              effectOfAsync(
                pipe(
                  api.deleteTeam(action.teamId),
                  TE.chain((_) => api.getCurrentClient),
                ),
                flow(Finished, DeleteTeamConfirmed(action.teamId)),
              ),
            ];
          case "Finished":
            return [
              {
                ...model,
                client: Resolved(action.operation.result),
                dialogApiResult: Resolved(action.operation.result),
              },
              noEffect,
            ];
        }
        break;

      case "DeleteUserConfirmed": {
        switch (action.operation.status) {
          case "Started":
            return [
              {
                ...model,
                dialogApiResult: InProgress(),
              },
              effectOfAsync(
                pipe(
                  action.reassignment.reassignTo
                    ? api.deleteUserWithReassignment(
                        action.userId,
                        action.reassignment,
                      )
                    : api.deleteUser(action.userId),
                  TE.chain((_) => api.getCurrentClient),
                ),
                flow(
                  Finished,
                  DeleteUserConfirmed(action.userId, action.reassignment),
                ),
              ),
            ];
          case "Finished": {
            const userManagementDialog = model.userManagementDialog;
            if (
              O.isNone(userManagementDialog) ||
              userManagementDialog.value.type != "DeleteUserDialog"
            ) {
              return [model, noEffect];
            }
            return [
              {
                ...model,
                client: Resolved(action.operation.result),
                userManagementDialog: O.some({
                  ...userManagementDialog.value,
                  deleteDone: true,
                }),
              },
              noEffect,
            ];
          }
        }
        break;
      }

      case "DeleteBranchConfirmed":
        switch (action.operation.status) {
          case "Started":
            return [
              {
                ...model,
                // client: updatingDeferred(model.client),
                dialogApiResult: InProgress(),
              },
              effectOfAsync(
                pipe(
                  api.deleteBranch(action.branchId),
                  TE.chain((_) => api.getCurrentClient),
                ),
                flow(Finished, DeleteBranchConfirmed(action.branchId)),
              ),
            ];
          case "Finished":
            return [
              {
                ...model,
                client: Resolved(action.operation.result),
                dialogApiResult: Resolved(action.operation.result),
              },
              noEffect,
            ];
        }
        break;

      case "BranchManagementAction":
        if (model.page.type !== "Branch") {
          return [model, noEffect];
        }

        return [
          {
            ...model,
            page: {
              ...model.page,
              model: BranchManagement.update(model.page.model, action.action),
            },
          },
          noEffect,
        ];

      case "TeamManagementAction":
        if (model.page.type !== "Team") {
          return [model, noEffect];
        }

        return [
          {
            ...model,
            page: {
              ...model.page,
              model: TeamManagement.update(model.page.model, action.action),
            },
          },
          noEffect,
        ];

      case "ClientManagementAction":
        if (model.page.type !== "Client") {
          return [model, noEffect];
        }

        return [
          {
            ...model,
            page: {
              ...model.page,
              model: ClientManagement.update(model.page.model, action.action),
            },
          },
          noEffect,
        ];

      case "NewUserInitiated":
        return [
          {
            ...model,
            userManagementDialog: pipe(
              EditUser.init(action.branchId)(action.teamId),
              NewUserDialog,
              O.some,
            ),
          },
          noEffect,
        ];

      case "EditUserInitiated":
        return [
          {
            ...model,
            userManagementDialog: pipe(
              EditUser.initEdit(action.user),
              EditUserDialog(action.user.userId),
              O.some,
            ),
          },
          noEffect,
        ];

      case "DeleteUserInitiated":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                {
                  ...model,
                  userManagementDialog: O.some({
                    ...DeleteUserDialog(action.userId, O.none),
                    assignedLoans: InProgress(),
                  }),
                },
                effectOfAsync(
                  api.postApplicationsList({
                    ...emptyApplicationFilter,
                    assignedToUserId: [action.userId],
                  }),
                  flow(Finished, DeleteUserInitiated(action.userId)),
                ),
              ];

            case "Finished": {
              const assignedLoans = Resolved(action.operation.result);
              const totalApplications = pipe(
                assignedLoans,
                mapDeferred(E.map((v) => v.totalCount)),
                deferredToOption,
                O.chain(O.fromEither),
                O.getOrElse(constant(0)),
              );

              return [
                {
                  ...model,
                  userManagementDialog: O.some({
                    ...DeleteUserDialog(action.userId, O.none),
                    assignedLoans,
                  }),
                },
                totalApplications == 0
                  ? effectOfAction(flow(ConfirmReassignedUser)())
                  : noEffect,
              ];
            }
          }
        }
        break;

      case "ReassignToChanged":
        if (
          O.isNone(model.userManagementDialog) ||
          model.userManagementDialog.value.type !== "DeleteUserDialog"
        ) {
          return [model, noEffect];
        }
        return [
          {
            ...model,
            userManagementDialog: O.some({
              ...model.userManagementDialog.value,
              reassignTo: O.some(action.userId),
            }),
          },
          noEffect,
        ];

      case "DialogClosed":
        return [
          {
            ...model,
            userManagementDialog: O.none,
            dialogApiResult: NotStarted(),
          },
          noEffect,
        ];

      case "EditUserAction":
        if (
          O.isNone(model.userManagementDialog) ||
          model.userManagementDialog.value.type === "DeleteUserDialog"
        ) {
          return [model, noEffect];
        }

        return [
          {
            ...model,
            userManagementDialog: O.some({
              ...model.userManagementDialog.value,
              dialogModel: EditUser.update(action.action)(
                model.userManagementDialog.value.dialogModel,
              ),
            }),
          },
          noEffect,
        ];

      case "ClientsListingAction": {
        const [clientsListing, clientEffect] = pipe(
          model.clientsListing,
          (model) => ClientsListing.update(api)(model, action.action),
        );
        return [
          { ...model, clientsListing },
          mapEffect(ClientsListingAction)(clientEffect),
        ];
      }

      case "ConfirmReassignedUser": {
        const userManagementDialog = model.userManagementDialog;
        if (
          O.isNone(userManagementDialog) ||
          userManagementDialog.value.type != "DeleteUserDialog"
        ) {
          return [model, noEffect];
        } else {
          return [
            {
              ...model,
              userManagementDialog: O.some({
                ...userManagementDialog.value,
                reassignmentConfirmed: true,
              }),
            },
            noEffect,
          ];
        }
      }
    }
  };
