import { Api } from "@/utils/api";
import {
  Effect,
  noEffect
} from "@/utils/reducerWithEffect";
import { Model } from "./model";

export type Action = {
  type: "None";
};

export const None = (): Action => ({
  type: "None",
});

export const update =
  (_: Api) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    switch (action.type) {
      case "None":
        {
          return [model, noEffect];
        }
        break;
    }
  };
