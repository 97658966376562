import * as FinancialInformation from "@/components/FinancialInformation";
import * as PersonalInformation from "@/components/PersonalInformation";
import * as SurveyInitiation from "@/components/SurveyInitiation";
import { ViewResolver } from "@/utils/viewResolver";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { Button, Col, Icon, Label, Row, TogglePanel } from "../basic";
import {
  FinancialInformationAction,
  PersonalInformationAction,
  SurveyInitiationAction,
} from "./action";
import { result } from "./model";
import { ContentProps, SubPageProps } from "./view";


function scrollIntoView(id: string) {
  const targetElement = document.getElementById(id);
  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}

import * as Documents from "@/components/Documents";
import { User } from "@/data/client";
import { ApplicationStatusType } from "@/data/payload";
import { Started } from "@/utils/asyncOperationStatus";
import { deferredToOption } from "@/utils/deferred";
import { useRights } from "@/utils/use-rights";
import * as A from "fp-ts/lib/Array";
import { constant, flow, pipe } from "fp-ts/lib/function";
import * as NEA from "fp-ts/lib/NonEmptyArray";
import { fold, Option } from "fp-ts/lib/Option";
import { identity, startCase } from "lodash-es";
import { DateTime } from "luxon";
import { MouseEventHandler, useMemo, useState } from "react";
import { Tag } from "../basic/Tag";
import { SaveSurvey } from "./action";

function relativeDate(date: Option<DateTime>) {
  return fold(
    () => "Today",
    (exportedDate: DateTime) => {
      const now = DateTime.local();
      const diffInDays = now.diff(exportedDate, "days").days;

      if (diffInDays < 1) {
        return "Today";
      } else if (diffInDays < 2) {
        return "1 day ago";
      } else {
        return `${Math.floor(diffInDays)} days ago`;
      }
    },
  )(date);
}

export function EditApplication(
  props: ContentProps & SubPageProps,
): JSX.Element {
  const { model, dispatch, children } = props;
  const surveyValidationResult = useMemo(() => result(model), [model]);
  const { isBackOfficeUser } = useRights();
  const status = model.application.status;
  const canSaveApplication = (aStatus: ApplicationStatusType) =>
    ![
      ApplicationStatusType.Complete,
      ApplicationStatusType.Exported,
      ApplicationStatusType.Archived,
    ].includes(aStatus);
  const [selectedSection, setSelectedSection] = useState<string>("primary-applicant-personal-info");
  const submitSurvey = useMemo(
    () =>
      pipe(
        O.fromPredicate(() => canSaveApplication(status)),
        () => surveyValidationResult,
        O.fromEither,
        O.map((payload) =>
          flow(Started, SaveSurvey(payload, props.onFlashAdded), dispatch),
        ),
      ),
    [status, surveyValidationResult, props.onFlashAdded, dispatch],
  );

  const { completeApplication: completeApplicationHandler } = props;
  const completeApplication = useMemo(
    () =>
      pipe(
        surveyValidationResult,
        O.fromEither,
        O.filter(() => Documents.allFilesUploaded(model.documents)),
        O.map(() => () => completeApplicationHandler(model.applicationId)),
      ),
    [
      surveyValidationResult,
      model.documents,
      model.applicationId,
      completeApplicationHandler,
    ],
  );

  const editActions = useMemo(
    () => (
      <>
        <Button
          only={isBackOfficeUser}
          type="secondary"
          onClick={props.onExportApplication}
          disabledTooltip="Restricted mode"
        >
          Export
        </Button>

        <Button
          type="secondary"
          onClick={submitSurvey}
          only={O.some(canSaveApplication(model.application.status))}
        >
          Save
        </Button>

        <Button type="primary" onClick={completeApplication}>
          Submit
        </Button>
      </>
    ),
    [
      completeApplication,
      isBackOfficeUser,
      model.application.status,
      props.onExportApplication,
      submitSurvey,
    ],
  );

  const commonText = useMemo(
    () => (
      <Row className="text-md" alignVertical="baseline">
        <div>
          In order to submit your application, fill in all of your information
          and{" "}
          <span
            className="link"
            onClick={O.fold(
              () => undefined,
              (onClick) => onClick as MouseEventHandler<HTMLSpanElement>,
            )(props.onScrollToDocuments)}
          >
            upload all relevant documents
          </span>
        </div>
      </Row>
    ),
    [props.onScrollToDocuments],
  );

  const editActionsMobile = (
    <Col gap="sm" alignHorizontal="space-between">
      <Row
        className="text-md"
        gap="sm"
        alignVertical="baseline"
        alignHorizontal="right"
      >
        {editActions}
      </Row>
    </Col>
  );

  const editActionsDesktop = useMemo(
    () => (
      <Row gap="xs" alignHorizontal="right">
        <Row gap="xs" alignHorizontal="right">
          {editActions}
        </Row>
      </Row>
    ),
    [editActions],
  );

  const SummaryContent = useMemo(
    () => (
      <>
        {commonText}
        <ApplicationSummary {...props} />
        <Col className="notes-container" grow={1} gap="xs">
          <Row>
            {" "}
            <span>Loan Officer Notes</span>
            {pipe(
              props.model.application.survey.loanOfficerNote,
              O.map((v) => v != model.loNotesFormField.raw),
              O.fold(() => model.loNotesFormField.raw?.length > 0, identity),
            ) ? (
              <Button type="flat" onClick={O.some(props.onSaveLoNotes)}>
                Save Notes
              </Button>
            ) : (
              <></>
            )}
          </Row>
          <textarea
            className="notes-text-area text-md"
            value={props.loNotesFormField.raw}
            onChange={(e) => props.onLoNotesChanged(e.target.value)}
            required={true}
          />
        </Col>
        <Col
          padding="md"
          background="white"
          className="survey-initiation-wrapper"
        >
          <SurveyInitiation.View
            model={model.surveyInitiation}
            dispatch={flow(SurveyInitiationAction, dispatch)}
          />
        </Col>

        <TogglePanel
          title={model.primaryApplicant.personalInformation.fullName.raw}
          subTitle="Primary"
        >
          <Row
            gap="xs"
            className="text-smd grid-item-3 padding-vertical-xs color-act-70 text-mbold"
          >
            <Icon type="user" />
            <span id="primary-applicant-personal-info">
              Personal Information
            </span>
          </Row>
          <Col gap="xxs">
            <Col>
              <PersonalInformation.View
                model={model.primaryApplicant.personalInformation}
                dispatch={flow(
                  PersonalInformationAction({ type: "Primary" }),
                  dispatch,
                )}
              />
            </Col>
            <Col>
              <hr className="grid-item-2 line"></hr>
            </Col>
            <Col id="primary-applicant-financial-info">
              <Row
                gap="xs"
                className="text-smd grid-item-3 padding-vertical-xs color-act-70 text-mbold"
              >
                <Icon type="piggy-bank" />
                <span>Financial Information</span>
              </Row>
              <FinancialInformation.View
                model={model.primaryApplicant.financialInformation}
                dispatch={flow(
                  FinancialInformationAction({ type: "Primary" }),
                  dispatch,
                )}
              />
            </Col>
          </Col>
        </TogglePanel>

        {Array.isArray(model.mortgageInformation.coApplicants) &&
          model.mortgageInformation.coApplicants.map((coApplicant, index) => (
            <TogglePanel
              title={coApplicant.personalInformation.fullName.raw}
              key={index}
              subTitle="Co-applicant"
            >
              <Row
                gap="xs"
                className="text-smd grid-item-3 padding-vertical-xs color-act-70 text-mbold"
              >
                <Icon type="user" />
                <span id={`co-applicant-${index}-personal-info`}>
                  Personal Information
                </span>
              </Row>
              <Col gap="xxs">
                <Col>
                  <PersonalInformation.View
                    model={coApplicant.personalInformation}
                    dispatch={flow(
                      PersonalInformationAction({
                        type: "CoApplicant",
                        index: coApplicant.personalInformation.applicantId,
                      }),
                      dispatch,
                    )}
                  />
                </Col>

                <Col>
                  <hr className="grid-item-2 line"></hr>
                </Col>

                <Col id={`co-applicant-${index}-financial-info`}>
                  <Row
                    gap="xs"
                    className="text-smd grid-item-3 padding-vertical-xs color-act-70 text-mbold"
                  >
                    <Icon type="piggy-bank" />
                    <span>Financial Information</span>
                  </Row>
                  <FinancialInformation.View
                    model={coApplicant.financialInformation}
                    dispatch={flow(
                      FinancialInformationAction({
                        type: "CoApplicant",
                        index: coApplicant.personalInformation.applicantId,
                      }),
                      dispatch,
                    )}
                  />
                </Col>
              </Col>
            </TogglePanel>
          ))}

        <div style={{ order: "2" }}>
          <TogglePanel id="verification-section" title="Verification">
            <div>
              {pipe(
                model.primaryApplicant.consents,
                NEA.fromArray,
                O.fold(
                  () => (
                    <Col>
                      <p> Consents are not provided yet by the applicant</p>
                    </Col>
                  ),
                  (array) => (
                    <>
                      {array.map((consent, index) => {
                        const matchingConsentItem = pipe(
                          model.constentMeta,
                          deferredToOption,
                          O.chain(O.fromEither),
                          O.chain((meta) =>
                            pipe(
                              meta,
                              A.findFirst(
                                (m) =>
                                  m.consentVersionId ==
                                  consent.consentVersionId,
                              ),
                            ),
                          ),
                        );
                        return pipe(
                          matchingConsentItem,
                          O.fold(constant(<></>), (matchingConsent) => (
                            <Row
                              alignVertical="center"
                              gap="xs"
                              padding="xs"
                              key={index}
                            >
                              <Icon type="check" />
                              <Col padding="sm">
                                <>
                                  <span> {matchingConsent.title}</span>
                                  <span className="text-bold">
                                    {startCase(
                                      pipe(
                                        consent.method,
                                        O.fromNullable,
                                        O.fold(constant("None"), identity),
                                      ),
                                    )}
                                  </span>
                                  <span>
                                    {consent.consentProvided ? "Yes" : "No"}
                                  </span>
                                </>
                              </Col>
                            </Row>
                          )),
                        );
                      })}
                    </>
                  ),
                ),
              )}
            </div>
          </TogglePanel>
        </div>

        {children}
      </>
    ),
    [
      commonText,
      props,
      children,
      model.surveyInitiation,
      model.primaryApplicant.personalInformation,
      model.primaryApplicant.financialInformation,
      model.primaryApplicant.consents,
      model.mortgageInformation.coApplicants,
      model.loNotesFormField.raw,
      model.constentMeta,
      dispatch,
    ],
  );

  const coApplicantQuickAccess = useMemo(
    () =>
      model.mortgageInformation.coApplicants.map((coApplicant, index) => {
        return (
          <ApplicantSummaryItem
            key={index}
            coApplicantIndex={index}
            personalInformation={coApplicant.personalInformation || {}}
            financialInformation={coApplicant.financialInformation || {}}
            user={model.user}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
          />
        );
      }),
    [model.mortgageInformation.coApplicants, model.user, selectedSection, setSelectedSection],
  );

  const DesktopView = useMemo(
    () => (
      <Col gap="sm" className="app-content">
        {editActionsDesktop}
        <Row gap="sm">
          <Col padding="sm" className="summary-navigation w-25">
            <Col className="padding-horizontal-xs">
              <Col gap="sm">
                <ApplicantSummaryItem
                  personalInformation={
                    model.primaryApplicant.personalInformation
                  }
                  financialInformation={
                    model.primaryApplicant.financialInformation
                  }
                  user={model.user}
                  selectedSection={selectedSection}
                  setSelectedSection={setSelectedSection}
                />

                {coApplicantQuickAccess}
                <Col gap="xxs">
                  <Row
                    gap="xs"
                    padding="sm"
                    onClick={() => {
                      scrollIntoView("documents-section")
                      setSelectedSection("documents-section")
                    }}
                    className={`text-md pointer app 
                      ${selectedSection === "documents-section"
                        ? "highlight-selected-menu" : ""
                      }`}
                  >
                    <Icon type="file-lines" />
                    <span className="flex-grow-1">Upload Documentation</span>
                    <Icon
                      type={
                        Documents.isAnyDocumentError(model.documents)
                          ? "triangle-exclamation"
                          : Documents.allFilesUploaded(model.documents)
                            ? "circle-check"
                            : Documents.isAnyDocumentValidated(model.documents)
                              ? "spinner"
                              : "circle"
                      }
                    />
                  </Row>
                  <Row
                    gap="xs"
                    padding="sm"
                    onClick={() => {
                      scrollIntoView("verification-section")
                      setSelectedSection("verification-section")
                    }}
                    className={`text-md pointer 
                      ${selectedSection === "verification-section"
                        ? "highlight-selected-menu" : ""
                      }`}
                  >
                    <Icon type="search" />
                    <span className="flex-grow-1">Verification</span>
                    <Icon
                      type={
                        model.primaryApplicant.consents.length > 0 &&
                        model.primaryApplicant.consents.every(
                          (consent) => consent.consentProvided,
                        )
                          ? "circle-check"
                          : "circle"
                      }
                    />
                  </Row>
                </Col>
              </Col>
            </Col>
          </Col>
          <Col gap="lg" grow={1} padding="sm" className="summary-content w-100">
            {SummaryContent}
          </Col>
        </Row>
      </Col>
    ),
    [
      SummaryContent,
      coApplicantQuickAccess,
      editActionsDesktop,
      model.documents,
      model.primaryApplicant.consents,
      model.primaryApplicant.financialInformation,
      model.primaryApplicant.personalInformation,
      model.user,
      selectedSection,
      setSelectedSection
    ],
  );

  const MobileView = (
    <Row className="app-content" background="grey10">
      <Col gap="md" grow={1} className="summary-content">
        {editActionsMobile}
        {SummaryContent}
      </Col>
    </Row>
  );

  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, MobileView],
  });

  return (
    <Col className="w-100" gap="sm">
      {ViewWrapper}
    </Col>
  );
}

function ApplicationSummary(props: ContentProps & SubPageProps) {
  return (
    <>
      <div className="application-section">
        <Row
          gap="xs"
          padding="fallback"
          className="text-smd grid-item-3 color-act-70 text-mbold applicant-name"
        >
          <span>
            {props.model.primaryApplicant.personalInformation.fullName.raw}
          </span>
        </Row>
        <Row
          gap="md"
          alignVertical="center"
          className={ViewResolver({
            viewModes: [
              ["Default"],
              ["Tablet-Portrait", "Tablet-Landscape"],
              ["Mobile-Portrait", "Mobile-Landscape"],
            ],
            resolvedContent: ["flex-wrap", "grid-3", "grid"],
          })}
          switchToGrid={ViewResolver({
            viewModes: [["Default"], ["Desktop-Portrait", "Desktop-Landscape"]],
            resolvedContent: [true, false],
          })}
          padding="md"
          alignHorizontal="space-between"
        >
          <Col gap="xs">
            <b>Application Number</b>
            <span className="padding-vertical-xxxs">
              {props.model.application.applicationId
                ? props.model.application.applicationId
                : "-"}
            </span>
          </Col>
          <Col gap="xs">
            <b>Status</b>
            <Tag
              type="neutral"
              iconAlign="none"
              icon={O.some("info")}
              className="text-sm font-weight-500"
            >
              {props.model.application.status}
            </Tag>
          </Col>
          <Col gap="xs">
            <b>Credit Check</b>
            <span className="padding-vertical-xxxs">
              {props.isCreditCheckAuthorized ? (
                <Tag
                  iconAlign="right"
                  icon={O.some("check")}
                  className="text-sm font-weight-500"
                  type="neutral"
                >
                  {" "}
                  Authorized
                </Tag>
              ) : (
                <Tag
                  iconAlign="right"
                  icon={O.some("xmark")}
                  className="text-sm font-weight-500"
                  type="neutral"
                >
                  {" "}
                  Not Authorized
                </Tag>
              )}
            </span>
          </Col>
          <Col gap="xs">
            <b>Type</b>
            <span className="padding-vertical-xxxs">
              {props.model.application.survey.applicationType}
            </span>
          </Col>
          <Col gap="xs">
            <b>Submitted</b>
            <Tag
              type="neutral"
              iconAlign="none"
              icon={O.some("info")}
              className="text-sm font-weight-500"
            >
              {relativeDate(props.model.application.submittedDateUtc)}
            </Tag>
          </Col>
          <Col gap="xs">
            <b>Assigned to</b>
            <span className="padding-vertical-xxxs">
              <i className="fa-regular fa-user ml-right-xxs"></i>
              {pipe(
                props.model.application.loanOfficer,
                O.fold(
                  () => "-",
                  (info) =>
                    info.fullName
                      ? info.fullName
                      : info.email
                        ? info.email
                        : "-",
                ),
              )}
            </span>
          </Col>
        </Row>
      </div>
    </>
  );
}

type ApplicantSummaryItemProps = {
  personalInformation: PersonalInformation.Model;
  financialInformation: FinancialInformation.Model;
  coApplicantIndex?: number;
  user: O.Option<User>;
  selectedSection: string;
  setSelectedSection: React.Dispatch<React.SetStateAction<string>>;
};

function ApplicantSummaryItem(props: ApplicantSummaryItemProps): JSX.Element {
  const personalInformationComplete = useMemo(
    () =>
      pipe(props.financialInformation, FinancialInformation.result, E.isRight),
    [props.financialInformation],
  );

  const financialInformationComplete = useMemo(
    () =>
      pipe(props.personalInformation, PersonalInformation.result, E.isRight),
    [props.personalInformation],
  );

  return (
    <Col>
      <Col gap="xxs">
        <Label className="text-md text-mbold action-70 mb-sm">
          {props.personalInformation.fullName.raw}
        </Label>
        <Row
          gap="xs"
          padding="sm"
          onClick={() => {
            const id =
              props.coApplicantIndex !== undefined &&
              props.coApplicantIndex !== null
                ? `co-applicant-${props.coApplicantIndex}-personal-info`
                : "primary-applicant-personal-info";
            props.setSelectedSection(id)
            scrollIntoView(id);
          }}
          className={`text-md pointer 
            ${(props.selectedSection === `co-applicant-${props.coApplicantIndex}-personal-info` && props.coApplicantIndex !== undefined && props.coApplicantIndex !== null) ||
              (props.selectedSection === "primary-applicant-personal-info" && props.coApplicantIndex === undefined || props.coApplicantIndex === null)
              ? "highlight-selected-menu" : ""
            }`}
        >
          <Icon type="user" />
          <span className="flex-grow-1">Personal Information</span>
          <Icon
            type={personalInformationComplete ? "circle-check" : "circle"}
          />
        </Row>
        <Row
          gap="xs"
          padding="sm"
          onClick={() => {
            const id =
              props.coApplicantIndex !== undefined &&
              props.coApplicantIndex !== null
                ? `co-applicant-${props.coApplicantIndex}-financial-info`
                : "primary-applicant-financial-info";
            props.setSelectedSection(id)
            scrollIntoView(id);
          }}
          className={`text-md pointer 
            ${(props.selectedSection === `co-applicant-${props.coApplicantIndex}-financial-info` && props.coApplicantIndex !== undefined && props.coApplicantIndex !== null) ||
              (props.selectedSection === "primary-applicant-financial-info" && props.coApplicantIndex === undefined || props.coApplicantIndex === null)
              ? "highlight-selected-menu" : ""
            }`}

        >
          <Icon type="piggy-bank" />
          <span className="flex-grow-1">Financial Information</span>
          <Icon
            type={financialInformationComplete ? "circle-check" : "circle"}
          />
        </Row>
      </Col>
    </Col >
  );
}
