import { RouterContext } from "@/utils/router-context";
import * as O from "fp-ts/Option";
import { useContext } from "react";
import { Button } from "../basic";
import { Card } from "../basic/Card";
import { ViewResolver } from "@/utils/viewResolver";

type Props = {
  usersCount: number;
  applicationsCount: number;
};

export function WelcomeView(props: Props): JSX.Element {
  const {router} = useContext(RouterContext)
  return (
    <div className={
      ViewResolver({
        viewModes: [["Default"], ["Tablet-Portrait", "Mobile-Portrait", "Mobile-Landscape", "Tablet-Landscape"]],
        resolvedContent: ['grid-3 gap-md', 'grid gap-md']
      })
    }>
      {props.applicationsCount == 0 && (
        <Card title="Create an application" className="welcome-card" subContent="Create an application for your clients. Creating an application will
          send an AppCatcher Invite to the borrower. After registering
          themselves, they can upload the necessary documents">
          
          <Button className="mg-top-auto" type="secondary" onClick={O.some(() => router.navigate("applynow"))}>
            Get Started
          </Button>
        </Card>
      )}
      <Card className="welcome-card" title="Update your settings" subContent="
        Update notificaiton settings, view Billing Information and edit your profile.">
        <Button className="mg-top-auto" type="secondary" onClick={O.some(() => router.navigate("settings/notifications"))}>
          Get Started
        </Button>
      </Card>
      {props.usersCount == 1 && (
        <Card className="welcome-card" title="Invite Team" subContent="Invite your loan officers on this screen. You can group your officers by branch and/or by teams.">
          
          <Button className="mg-top-auto" type="secondary" onClick={O.some(() => router.navigate("org/branches"))}>
            Get Started
          </Button>
        </Card>
      )}
    </div>
  );
}
