import { FlashType } from "@/app/model";

export enum PageTypes {
  Login = "Login",
  Signup = "Signup",
  ApplyNow = "ApplyNow",
  HomePage = "HomePage",
  InactiveBorrower = "InactiveBorrower",
  LoginRedirection = "LoginRedirection",
  //TODO: Remove after Loader Dev
  Loader = "Loader",
  InvitePage = "Invite"
}

export type FlashAdder = (flash: FlashType) => void;
