import { Col, IconType, Label, Row, Table } from "@/components/basic";
import { RouterContext } from "@/utils/router-context";
import { getFullName, getProfileId } from "@/utils/user";
import { constant, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { useContext } from "react";
import { OptionMenu } from "../../basic/Menu/OptionMenu";
import { UsersTableProps } from "./types";
import { UserRoles } from "./UserRoles";
import { ViewResolver } from "@/utils/viewResolver";
import Navigo from "navigo";
import { User } from "@/data/client";

export function UsersTable(props: UsersTableProps): JSX.Element {
  const { router } = useContext(RouterContext);

  return (
    <Table
      pageInfo={O.none}
      isLoading={false}
      className="data-table users-table"
      itemKey={(user) => user.userId}
      data={props.users}
      columns={ViewResolver({
        viewModes: [
          ["Mobile-Portrait", "Mobile-Landscape"],
          ["Default"]
        ],
        resolvedContent: [
          getMobileColumns(router),
          getDesktopColumns(router, props)
        ]
      })}
    />
  );
}
function getMobileColumns(router: Navigo) {
  return [
    {
      columnKey: "name",
      header: (
        <Row padding="sm">
          <Label className="table-header-label">User</Label>
        </Row>
      ),
      view: (user: User) => (
        <Row
          padding="sm"
          gap="xs"
          alignVertical="center"
          className="data-table-cell mobile-column"
          onClick={flow(
            () =>
              pipe(
                [user.branchId, user.teamId, O.some(user.userId)],
                O.traverseArray(
                  (v): O.Option<string> =>
                    O.isNone(v) ? O.some("") : O.some<string>(`${v.value.toString()}`)
                ),
                O.fold(
                  () => "/org/branches",
                  ([branchId, teamId, userId]) =>
                    `/org/branches/${branchId}/teams/${teamId}/users/${userId}`
                )
              ),
            router.navigate
          )}
        >
          <Col grow={1}>
            <Label className="user-name cp table-header-label">{getFullName(user)}</Label>
            <Label>ID - {user.userId}</Label>
            <UserRoles user={user} />
          </Col>
          <Col
            className="data-table-cell"
            alignHorizontal="right"
            alignVertical="center"
            
          >
            <i className="text-smd fa-solid fa-arrow-right padding-horizontal-md" />
          </Col>
        </Row>
      ),
    },
  ];
}

function getDesktopColumns(router: Navigo, props: UsersTableProps) {
  return [
    {
      columnKey: "name",
      header: (
        <Row padding="sm">
          <Label className="table-header-label">User</Label>
        </Row>
      ),
      view: (user: User) => (
        <Row
          padding="sm"
          gap="xs"
          alignVertical="center"
          className="data-table-cell cell-first user-column"
          onClick={flow(
            () =>
              pipe(
                [user.branchId, user.teamId, O.some(user.userId)],
                O.traverseArray(
                  (v): O.Option<string> =>
                    O.isNone(v) ? O.some("") : O.some<string>(`${v.value.toString()}`)
                ),
                O.fold(
                  () => "/org/branches",
                  ([branchId, teamId, userId]) =>
                    `/org/branches/${branchId}/teams/${teamId}/users/${userId}`
                )
              ),
            router.navigate
          )}
        >
          <Col>
            <div className="user-id">
              {pipe(getFullName(user), getProfileId)}
            </div>
          </Col>
          <Col>
            <Row>
              <Label className="user-name cp">{getFullName(user)}</Label>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      columnKey: "roles",
      header: (
        <Row padding="sm">
          <Label className="table-header-label">Roles</Label>
        </Row>
      ),
      view: (user: User) => (
        <Row padding="sm" alignVertical="center" className="data-table-cell">
          <UserRoles user={user} />
        </Row>
      ),
    },
    {
      columnKey: "userId",
      header: (
        <Row padding="sm">
          <Label className="table-header-label">ID</Label>
        </Row>
      ),
      view: (user: User) => (
        <Row padding="sm" className="data-table-cell" alignVertical="center">
          <Label className="black">{user.userId}</Label>
        </Row>
      ),
    },
    {
      columnKey: "actions",
      header: <Row>""</Row>,
      view: (user: User) => (
        <Col
          className="data-table-cell cell-last actions-cell"
          alignHorizontal="right"
          alignVertical="center"
        >
          <OptionMenu
            menuItems={[
              {
                icon: "pen-to-square" as IconType,
                label: "Edit",
                onClick: O.some(
                  flow(constant(user), props.onEditUserInitiated)
                ),
              },
              {
                icon: "trash-can" as IconType,
                label: "Delete",
                onClick: O.some(
                  flow(constant(user.userId), props.onDeleteUserInitiated)
                ),
              },
            ]}
          ></OptionMenu>
        </Col>
      ),
    },
  ];
}




