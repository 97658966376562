import { updateFormField } from "@/utils/formField";
import { Model } from "./model";
import * as t from "io-ts";
import { referralSourceCodec } from "@/data/payload";
import { nonEmptyStringCodec } from "@/utils/codecs";
import { phoneNumberCodecWithMessage } from "@/utils/codecs/phoneNumber";
import { ValidErrorMessage } from "../basic";

export type Action =
  | {
      type: "ReferralSourceChanged";
      referralSource: string;
    }
  | {
      type: "WorkingWithRealEstateAgentChanged";
      workingWithRealEstateAgent: boolean;
    }
  | {
      type: "RealEstateAgentNameChanged";
      realEstateAgentName: string;
    }
  | {
      type: "RealEstateAgentPhoneChanged";
      realEstateAgentPhone: string;
    };

export const ReferralSourceChanged = (referralSource: string): Action => ({
  type: "ReferralSourceChanged",
  referralSource,
});

export const WorkingWithRealEstateAgentChanged = (
  workingWithRealEstateAgent: boolean,
): Action => ({
  type: "WorkingWithRealEstateAgentChanged",
  workingWithRealEstateAgent,
});

export const RealEstateAgentNameChanged = (
  realEstateAgentName: string,
): Action => ({
  type: "RealEstateAgentNameChanged",
  realEstateAgentName,
});

export const RealEstateAgentPhoneChanged = (
  realEstateAgentPhone: string,
): Action => ({
  type: "RealEstateAgentPhoneChanged",
  realEstateAgentPhone,
});

export const update = (model: Model, action: Action): Model => {
  switch (action.type) {
    case "ReferralSourceChanged":
      return {
        ...model,
        referralSource: updateFormField(
          t.string.pipe(referralSourceCodec).decode,
        )(action.referralSource),
      };
    case "WorkingWithRealEstateAgentChanged":
      return {
        ...model,
        isWorkingWithAgent: action.workingWithRealEstateAgent,
      };
    case "RealEstateAgentNameChanged":
      return {
        ...model,
        realEstateAgentName: updateFormField(nonEmptyStringCodec(false, ValidErrorMessage('name')).decode)(
          action.realEstateAgentName,
        ),
      };
    case "RealEstateAgentPhoneChanged":
      return {
        ...model,
        realEstateAgentPhoneNumber: updateFormField(phoneNumberCodecWithMessage(ValidErrorMessage('phone number')).decode)(
          action.realEstateAgentPhone,
        ),
      };
  }
};
