import { Button, Col, Input, Label, Row } from "@/components/basic";
import { ApiCallingModal } from "@/components/basic/Modal/ApiCallingModal";
import * as EditBranch from "@/components/UserManagement/pages/ClientManagement/EditBranch";
import { BranchId, Client, TeamId } from "@/data/client";
import { BranchPayload } from "@/data/payload";
import { Deferred } from "@/utils/deferred";
import { ChildProps } from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import { getAllTeams } from "@/utils/user";
import * as A from "fp-ts/lib/Array";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import { useMemo } from "react";
import { BranchesTable } from "../BranchesTable";
import { TeamsTable } from "../TeamsTable";
import {
  Action,
  DeleteBranchInitiated,
  DialogClosed,
  EditBranchAction,
  EditBranchInitiated,
  NewBranchInitiated,
  SearchChanged,
} from "./action";
import { DialogModel, Model } from "./model";
import { ViewResolver } from "@/utils/viewResolver";

export type Props = ChildProps<Model, Action> & {
  client: Client;
  onSelectBranch: (branchId: BranchId) => void;
  onSaveBranch: (branchId: Option<BranchId>, payload: BranchPayload) => void;
  onDeleteBranch: (branchId: BranchId) => void;
  onSelectTeam: (teamId: TeamId) => void;
  onNewUser: (branchId: Option<BranchId>, teamId: Option<TeamId>) => void;
  dialogApiResult: Deferred<ApiResult<unknown>>;
  onCloseModal: () => void;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const trimmedSearch = useMemo(
    () => model.search.trim().toLowerCase(),
    [model.search],
  );

  const allTeams = useMemo(() => getAllTeams(props.client), [props.client]);

  const foundBranches = useMemo(
    () =>
      trimmedSearch.length > 0
        ? pipe(
          props.client.branches,
          A.filter((branch) =>
            branch.name.toLowerCase().includes(trimmedSearch),
          ),
        )
        : [],
    [trimmedSearch, props.client.branches],
  );

  const foundTeams = useMemo(
    () =>
      trimmedSearch.length > 0
        ? pipe(
          allTeams,
          A.filter((team) => team.name.toLowerCase().includes(trimmedSearch)),
        )
        : [],
    [trimmedSearch, allTeams],
  );

  const modalTitle = pipe(
    model.dialog,
    O.map((v) => {
      const branchName = (v: BranchId) =>
        `${props.client.branches.find((b) => b.branchId == v)?.name}`;
      switch (v.type) {
        case "DeleteBranchDialog":
          return `Delete Branch: ${branchName(v.branchId)}`;
        case "EditBranchDialog":
          return `Edit Branch: ${branchName(v.branchId)}`;
        case "NewBranchDialog":
          return `New Branch`;
      }
    }),
  );

  return (
    <Col gap="md">
      <Row alignVertical="center" alignHorizontal="space-between" wrap={true} gap="sm">
        <Row gap="xxxs" alignVertical="center">
          <Label className="text-smd action-70 text-mbold cp">
            {props.client.name}
          </Label>
          <i className="fa-regular fa-pen-to-square"></i>
        </Row>
        <Row
          alignHorizontal="left"
          gap="xs"
          wrap={true}
          className={ViewResolver({
            viewModes: [
              ["Default"],
              ["Mobile-Landscape", "Mobile-Portrait"]
            ],
            resolvedContent: [
              "", "user-management-mb-buttons"
            ]
          })}
        >
          <Button
            type="secondary"
            onClick={O.some(() => props.onNewUser(O.none, O.none))}
          >
            New User
          </Button>
          <Button
            type="secondary"
            onClick={O.some(flow(NewBranchInitiated, dispatch))}
          >
            Create a new branch
          </Button>
        </Row>
      </Row>
      <Col alignVertical="stretch" className="pos-rel" grow={1}>
        <i className="fa-solid fa fa-search ac-search-icon"></i>
        <Input
          placeholder="Search"
          onChange={flow(SearchChanged, dispatch)}
          value={model.search}
          className="ac-search-box"
        />
        {pipe(
          model.search.trim(),
          O.fromPredicate((v) => v.length > 0),
          O.fold(
            () => <></>,
            () => <i
              onClick={() => flow(constant(""), SearchChanged, dispatch)()}
              className="fa-solid fa fa-circle-xmark ac-search-clear-icon"
            ></i>
          )
        )}
      </Col>

      <Col>
        <Label className="black text-mbold">Branches</Label>
        <hr />
        <BranchesTable
          branches={
            foundBranches.length > 0 ? foundBranches : props.client.branches
          }
          onSelectBranch={props.onSelectBranch}
          onEditBranch={flow(EditBranchInitiated, dispatch)}
          onDeleteBranch={flow(DeleteBranchInitiated, dispatch)}
        />
      </Col>

      {foundTeams.length > 0 && (
        <Col>
          <Label className="black text-mbold">Teams</Label>
          <hr />
          <TeamsTable
            teams={foundTeams}
            onSelectTeam={props.onSelectTeam}
            onEditTeam={O.none}
            onDeleteTeam={O.none}
          />
        </Col>
      )}

      {O.isSome(model.dialog) && (
        <DialogView
          dialog={model.dialog.value}
          title={modalTitle}
          dispatch={dispatch}
          onSaveBranch={(branchId) => (payload) =>
            props.onSaveBranch(branchId, payload)
          }
          onCloseModal={() => {
            flow(DialogClosed, dispatch)();
            props.onCloseModal();
          }}
          onDeleteBranch={props.onDeleteBranch}
          dialogApiResult={props.dialogApiResult}
        />
      )}
    </Col>
  );
}

type DialogViewProps = {
  dialog: DialogModel;
  dispatch: (action: Action) => void;
  onSaveBranch: (
    branchId: Option<BranchId>,
  ) => (payload: BranchPayload) => void;
  onDeleteBranch: (branchId: BranchId) => void;
  title: O.Option<string>;
  dialogApiResult: Deferred<ApiResult<unknown>>;
  onCloseModal: () => void;
};

function DialogView(props: DialogViewProps): JSX.Element {
  const cancelHandler = O.some(() => props.onCloseModal());
  const title = O.some(
    pipe(props.title, O.fold(constant("Manage Users"), identity)),
  );

  switch (props.dialog.type) {
    case "NewBranchDialog":
    case "EditBranchDialog":
      {
        const branchIdtoBeSaved =
          props.dialog.type == "NewBranchDialog"
            ? O.none
            : O.some(props.dialog.branchId);
        const model = props.dialog.dialogModel;
        const validatedModel = pipe(EditBranch.result(model), O.fromEither);
        return (
          <ApiCallingModal
            actionTitle={
              props.dialog.type == "NewBranchDialog"
                ? "Create New Branch"
                : "Save"
            }
            onClose={cancelHandler}
            title={title}
            dialogApiResult={props.dialogApiResult}
            actionHander={O.some(() =>
              pipe(
                validatedModel,
                O.map((payload) =>
                  props.onSaveBranch(branchIdtoBeSaved)(payload),
                ),
              ),
            )}
            cancelHandler={cancelHandler}
          >
            <EditBranch.View
              model={model}
              dispatch={flow(EditBranchAction, props.dispatch)}
            />
          </ApiCallingModal>
        );
      }
      break;

    case "DeleteBranchDialog":
      return (
        <ApiCallingModal
          actionTitle="Delete"
          onClose={cancelHandler}
          title={title}
          dialogApiResult={props.dialogApiResult}
          actionHander={O.some(
            flow(constant(props.dialog.branchId), props.onDeleteBranch),
          )}
          cancelHandler={cancelHandler}
        >
          <Col>
            <Label>Are you sure you want to delete this branch?</Label>
          </Col>
        </ApiCallingModal>
      );
  }
}
