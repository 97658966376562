import { flow, pipe } from "fp-ts/lib/function";
import { Props } from "./types";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import { Modal } from "../basic";
import { ResetExportStatus } from "./action";
import { showApiError } from "@/utils/request";

export function ExportModal(props: Props): JSX.Element {
  const { model, dispatch } = props;
  return pipe(
    model.exportStatus,
    O.fromPredicate((exportStatus) =>
      ["InProgress", "Resolved"].includes(exportStatus.status),
    ),
    O.map((exportStatus) => {
      switch (exportStatus.status) {
        case "InProgress":
          return (
            <Modal onClose={O.none} title={O.some("Export Status")}>
              Please wait while we are exporting your application.
            </Modal>
          );
        case "Resolved": {
          const { value } = exportStatus;
          if (E.isLeft(value)) {
            return (
              <Modal
                onClose={O.some(flow(ResetExportStatus, dispatch))}
                title={O.some("Export Status")}
              >
                There was an error exporting the documents.
                {showApiError(value.left)}
              </Modal>
            );
          } else {
            return (
              <Modal
                onClose={O.some(flow(ResetExportStatus, dispatch))}
                title={O.some("Export Status")}
              >
                Successfuly exported the application!
              </Modal>
            );
          }
          return <></>;
        }
        default:
          return <></>;
      }
    }),
    O.getOrElse(() => <></>),
  );
}
