import { Col } from "@/components/basic";

export function ContactLoanOfficer(): JSX.Element {
    return <Col gap="xs" className="container error" padding="xs">
    <div className="text-lg">Contact your loan officer</div>
    <div>
      Seems like your having issues with providing the details below.
      Please contact Daryle Messina.
    </div>
  </Col>
}