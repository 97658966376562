import { pipe } from "fp-ts/lib/function";
import { LivingArrangementType } from "@/data/payload";
import { nonEmptyStringCodec } from "@/utils/codecs/nonEmptyString";
import { updateFormField } from "@/utils/formField";
import { Model, monthsAtAddressCodec, rentAmountCodec } from "./model";
import * as O from "fp-ts/lib/Option";
import { zipCodeCodecWithMessage } from "@/utils/codecs/zipCode";
import { stateCodec } from "@/data/states";
import { ValidErrorMessage } from "../basic/Error";

export type Action =
  | {
      type: "StreetChanged";
      street: string;
    }
  | {
      type: "UnitChanged";
      unit: string;
    }
  | {
      type: "CityChanged";
      city: string;
    }
  | {
      type: "StateChanged";
      state: string;
    }
  | {
      type: "ZipChanged";
      zip: string;
    }
  | {
      type: "MonthsAtAddressChanged";
      monthsAtAddress: string;
    }
  | {
      type: "LivingArrangementTypeChanged";
      livingArrangementType: LivingArrangementType;
    }
  | {
      type: "RentAmountChanged";
      rentAmount: string;
    };

export const StreetChanged = (street: string): Action => ({
  type: "StreetChanged",
  street,
});

export const UnitChanged = (unit: string): Action => ({
  type: "UnitChanged",
  unit,
});

export const CityChanged = (city: string): Action => ({
  type: "CityChanged",
  city,
});

export const StateChanged = (state: string): Action => ({
  type: "StateChanged",
  state,
});

export const ZipChanged = (zip: string): Action => ({
  type: "ZipChanged",
  zip,
});

export const MonthsAtAddressChanged = (monthsAtAddress: string): Action => ({
  type: "MonthsAtAddressChanged",
  monthsAtAddress,
});

export const LivingArrangementTypeChanged = (
  livingArrangementType: LivingArrangementType,
): Action => ({
  type: "LivingArrangementTypeChanged",
  livingArrangementType,
});

export const RentAmountChanged = (rentAmount: string): Action => ({
  type: "RentAmountChanged",
  rentAmount,
});

export const update =
  (action: Action) =>
  (model: Model): Model => {
    switch (action.type) {
      case "StreetChanged":
        return {
          ...model,
          street: updateFormField(nonEmptyStringCodec(false, ValidErrorMessage('street address')).decode)(action.street),
        };

      case "UnitChanged":
        return {
          ...model,
          unit: pipe(action.unit, nonEmptyStringCodec().decode, O.fromEither),
        };

      case "CityChanged":
        return {
          ...model,
          city: updateFormField(nonEmptyStringCodec(false, ValidErrorMessage('city')).decode)(action.city),
        };

      case "StateChanged":
        return {
          ...model,
          state: updateFormField(stateCodec.decode)(action.state),
        };

      case "ZipChanged":
        return {
          ...model,
          zip: updateFormField(zipCodeCodecWithMessage(ValidErrorMessage('zip code')).decode)(action.zip),
        };

      case "MonthsAtAddressChanged":
        return {
          ...model,
          monthsAtAddress: updateFormField(monthsAtAddressCodec.decode)(
            action.monthsAtAddress,
          ),
        };

      case "LivingArrangementTypeChanged":
        return {
          ...model,
          livingArrangementType: action.livingArrangementType,
        };

      case "RentAmountChanged": {
        if (model.livingArrangementType !== "Rent") {
          return model;
        }

        return {
          ...model,
          monthlyRent: updateFormField(rentAmountCodec.decode)(
            action.rentAmount,
          ),
        };
      }
    }
  };
