import { pipe } from "fp-ts/lib/function";
import * as t from "io-ts";
import * as tt from "io-ts-types";
import { failure } from "io-ts/PathReporter";

export const showValidationError = (e: t.Errors): string =>
  pipe(failure(e), errors => errors.join("\n"));

export const withMessage =
  <C extends t.Any>(msg: (i: t.InputOf<C>, c: t.Context) => string) =>
  (codec: C) =>
    tt.withMessage(codec, msg);


type LiteralNES<S extends string> = S extends "" ? never : S;

/**
 * SHOULD ONLY BE USED WITH STRING LITERALS
 * - use for testing or hardcoded data only
 *
 * eg.
 * literalNES("") will fail the type check
 * literalNES("something") will produce NonEmptyString
 *
 * DON'T DO THIS
 * literalNES(s)
 * if s === "", this will throw an exception at runtime
 */
export function literalNES<S extends string>(
  s: LiteralNES<S>
): tt.NonEmptyString {
  if (s === "") {
    throw new Error("SHOULD ONLY BE USED WITH STRING LITERALS");
  }

  return s as string as tt.NonEmptyString;
}
