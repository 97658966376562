import { ChildProps } from "@/utils/reducerWithEffect";
import { Model } from "./model";
import { Action, DischargedToggled, PaymentPlanCompletedToggled } from "./action";
import { flow } from "fp-ts/lib/function";
import { Checkbox } from "@/components/basic";

export type Props = ChildProps<Model, Action>;

export function View({ model, dispatch }: Props): JSX.Element {
  switch (model.type) {
    case 'None':
      return <></>;

    case 'Chapter7':
      return (
        <Checkbox
          label="Discharged"
          checked={model.discharged}
          onChange={flow(DischargedToggled, dispatch)}
        />
      )

    case 'Chapter13':
      return (
        <Checkbox
          label="Payment plan completed"
          checked={model.paymentPlanCompleted}
          onChange={flow(PaymentPlanCompletedToggled, dispatch)}
        />
      );
  }
}