import { Col } from "@/components/basic";
import * as ClientsListing from "@/components/ClientsListing";
import { ApplicationId } from "@/data/payload";
import { ChildProps } from "@/utils/reducerWithEffect";
import { constant, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Action, ClientsListingAction } from "./action";
import { Model } from "./model";

export type Props = ChildProps<Model, Action> & {
  onApplicationSelected: (applicationId: ApplicationId) => void;
};

export function View(props: Props): JSX.Element {
  const { dispatch, model } = props;

  return (
    <Col gap="md">
      {pipe(
        model.clientListing,
        O.fold(constant(<></>), (model) => {
          return (
            <ClientsListing.View
              model={model}
              showFilters={true}
              dispatch={flow(ClientsListingAction, dispatch)}
              onApplicationSelected={(id) => props.onApplicationSelected(id)} // added to check if the navigation will work - it didn't
            />
          );
        }),
      )}
    </Col>
  );
}
