import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import { useMemo } from "react";
import { Deferred } from "./deferred";
import { ApiResult, showApiError } from "./request";

/**
 * Hook to return an error from a deferred value
 * @param apiResult
 * @returns
 *  [O.none, true] incase of status  is Resolved and no error
 *  [O.none, false]  if status != resolved , meaning we should not use the return value.
 *  [O.some(string), true]  if status status is resolved and is an error
 */
export const useApiResult = <T>(
  apiResult: Deferred<ApiResult<T>>,
): [O.Option<string>, boolean] => {
  return useMemo(() => {
    const isResolved = apiResult.status == "Resolved";
    return [
      isResolved && E.isLeft(apiResult.value)
        ? O.some(showApiError(apiResult.value.left))
        : O.none,
      isResolved,
    ];
  }, [apiResult]);
};
