import "@/assets/styles/App.css";
import { Api } from "@/utils/api";
import { Effect, effectOfAction } from "@/utils/reducerWithEffect";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import { Action, InactiveBorrrowerPage } from "@/app/action";
import { init, Model } from "@/app/model";
import { ClientStatus } from "@/data/client";
import { LocalStoragetKeys } from "@/utils/localstorage";
import { ApiError } from "@/utils/request";
import { AuthenticationResult } from "@azure/msal-common";
import * as T from "fp-ts/Task";
import * as TE from "fp-ts/TaskEither";
import Navigo from "navigo";
import {
  initModel,
  toInactive,
  toLogin,
  tryCompleteBackOfficeSignin,
  tryCompleteSurvey,
  userInfoAndClientStatus,
} from "./navigation";

export const initTask = (
  api: Api,
  handleRedirectTask: TE.TaskEither<ApiError, AuthenticationResult | null>,
  router: Navigo,
): T.Task<[Model, Effect<Action>]> => {
  return pipe(
    handleRedirectTask,
    TE.fold(
      (_) => T.of<AuthenticationResult | null>(null),
      (v) => T.of(v),
    ),
    T.chain((result) => {
      switch (result?.authority.toUpperCase()) {
        case import.meta.env.VITE_BO_SIGNUP_AUTHORITY.toUpperCase():
          return tryCompleteSurvey(result, api, router);

        case import.meta.env.VITE_LO_SIGNUP_AUTHORITY.toUpperCase():
          return tryCompleteBackOfficeSignin(result, api, router);

        default:
          [
            LocalStoragetKeys.BorrowerSignupInProgress,
            LocalStoragetKeys.BorrowerNonce,
            LocalStoragetKeys.BorrowerSignup,
            LocalStoragetKeys.BackOfficeNonce,
            LocalStoragetKeys.BackOfficeStripeSecret,
            LocalStoragetKeys.BackOfficeSignup,
          ].forEach((v) => {
            window.localStorage.removeItem(v);
          });

          return userInfoAndClientStatus(api);
      }
    }),
    TE.fold(
      () => toLogin(router, O.none),
      (v) =>
        pipe(
          v,
          O.fold(
            () => toLogin(router, O.none),
            ([user, clientStatus]) => {
              if (clientStatus.status == ClientStatus.Inactive) {
                return toInactive(router, clientStatus);
              }
              if (clientStatus.status == ClientStatus.Deprecated) {
                const currentMatch = router.getCurrentLocation();
                if (
                  ["applynow", "invite"].some((slug) =>
                    (currentMatch.route.path as string).startsWith(slug),
                  )
                ) {
                  return T.of([
                    { ...init(), clientStatus: O.none },
                    effectOfAction(InactiveBorrrowerPage(true, clientStatus)),
                  ]);
                }
              }
              const value = O.isNone(user)
                ? toLogin(router, O.some(clientStatus))
                : T.of(initModel(user, O.some(clientStatus)));

              window.dispatchEvent(
                new CustomEvent("app-init", {
                  detail: { user, clientStatus: O.some(clientStatus) },
                }),
              );
              router.resolve();

              return value;
            },
          ),
        ),
    ),
  );
};
