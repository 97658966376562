import { sequenceS } from "fp-ts/lib/Apply";
import {
  ApplicationType,
  PropertyType,
  SurveyTypeInfo,
  applicationTypeCodec,
  propertyTypeCodec,
} from "../../data/payload";
import { FormField, initFormField } from "../../utils/formField";
import * as E from "fp-ts/lib/Either";
import * as t from "io-ts";
import { Either } from "fp-ts/lib/Either";
import { pipe, identity } from "fp-ts/lib/function";
import { DateTime } from "luxon";
import { validDateTimeCodec } from "@/utils/codecs";

export type Model = {
  propertyType: FormField<PropertyType>;
  applicationType: FormField<ApplicationType>;
  initiatedDateTime: DateTime;
};

export const reset = (model: Model): Model => ({
  ...model,
  propertyType: initFormField(propertyTypeCodec.decode)(""),
  applicationType: initFormField(applicationTypeCodec.decode)(""),
});

// here Either represents the fact that the survey
// needs either an existing survey info to initialize
// or a new initialization date
export const init = (
  surveyTypeInfo: Either<DateTime, SurveyTypeInfo>,
): Model => ({
  propertyType: pipe(
    surveyTypeInfo,
    E.fold(
      (_) => "",
      ({ propertyType }) => propertyType,
    ),
    initFormField(propertyTypeCodec.decode),
  ),
  applicationType: pipe(
    surveyTypeInfo,
    E.fold(
      (_) => "",
      ({ applicationType }) => applicationType,
    ),
    initFormField(applicationTypeCodec.decode),
  ),
  initiatedDateTime: pipe(
    surveyTypeInfo,
    E.fold(identity, ({ initiatedDateTimeUtc }) => initiatedDateTimeUtc),
  ),
});

export const result = (model: Model): t.Validation<SurveyTypeInfo> =>
  sequenceS(E.Apply)({
    propertyType: model.propertyType.val,
    applicationType: model.applicationType.val,
    initiatedDateTimeUtc: pipe(
      model.initiatedDateTime,
      validDateTimeCodec.decode,
    ),
  });
