import "@/assets/styles/basic/TogglePanel/togglePanel.css";
import propertyAgentIcon from "@/assets/images/property-agent-icon.svg";
import { Col, Label, Row } from "@/components/basic";
import { useState } from "react";
import * as O from "fp-ts/lib/Option";
import { useOnly } from "@/utils/new-only";
import { pipe } from "fp-ts/lib/function";

export type TogglePanelProps = {
  id?: string;
  children: React.ReactNode;
  title: string;
  subTitle?: string;
  only?: O.Option<boolean>;
  toggleHeaderClassName?: string;
  showIcon?: boolean;
};

export function TogglePanel(props: TogglePanelProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);
  const shouldDisplay = useOnly(props.only);

  return (
    <>
      {shouldDisplay && (
        <Col id={props.id}>
          <Row
            padding="sm"
            alignHorizontal="space-between"
            className={`toggle-panel-header cp ${props.toggleHeaderClassName ? props.toggleHeaderClassName : ""}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div
              className="w-100"
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "16px",
              }}
            >
              <Row gap="xs">
                {pipe(
                  props.showIcon ? (
                    <Col className="property-agent-icon-wrapper" background="white" padding="xxxs">
                      <img src={propertyAgentIcon} style={{width: "32px", height: "32px"}} />
                    </Col>
                  ) : (
                    <></>
                  ),
                )}
                <Label className="text-smd white">{props.title}</Label>
              </Row>
              {props.subTitle && (
                <span className="primary-badge">{props.subTitle}</span>
              )}
            </div>

            {isOpen ? (
              <i className="fa-solid fa-chevron-up"></i>
            ) : (
              <i className="fa-solid fa-chevron-down"></i>
            )}
          </Row>

          {isOpen && (
            <div className="toggle-panel-content">{props.children}</div>
          )}
        </Col>
      )}
    </>
  );
}
