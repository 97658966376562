import { Errors } from "io-ts";
import { Col } from "./Col";
import { pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";

export function Error(props: { errors: Errors }): JSX.Element {
  const messages = pipe(
    props.errors,
    A.filterMap((err) => O.fromNullable(err.message)),
  );

  return (
    <Col gap="xs" foreground="no50">
      {messages.length > 0 ? (
        messages.map((msg, i) => <span key={i}>{msg}</span>)
      ) : (
        <span>Invalid</span>
      )}
    </Col>
  );
}

export const ValidErrorMessage = (suffix: string) => `Please enter a valid ${suffix}` 
