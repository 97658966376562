import * as ClientsListing from "@/components/ClientsListing";
import * as BranchManagement from "@/components/UserManagement/pages/BranchManagement";
import * as ClientManagement from "@/components/UserManagement/pages/ClientManagement";
import * as TeamManagement from "@/components/UserManagement/UsersListing/modals/TeamManagement";
import { Branch, BranchId, Team, TeamId, User, UserId } from "@/data/client";
import { Started } from "@/utils/asyncOperationStatus";
import { Deferred } from "@/utils/deferred";
import { ApiResult } from "@/utils/request";
import { constant, flow } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import {
  BranchManagementAction,
  BranchPayloadPrepared,
  BranchSelected,
  ClientManagementAction,
  DeleteBranchConfirmed,
  DeleteTeamConfirmed,
  DialogClosed,
  NewUserInitiated,
  TeamPayloadPrepared,
  TeamSelected,
  UserSelected,
} from "./action";
import { Page } from "./model";
import { UserView } from "./pages/UserView";
import { PageViewProps } from "./types";

//PS: this is not a react component..
export function pageSections(
  page: Page,
  props: PageViewProps,
  branches: Map<BranchId, Branch>,
  teams: Map<TeamId, Team>,
  users: Map<UserId, User>,
  branchName: (branchId: BranchId) => O.Option<string>,
  teamName: (teamId: TeamId) => O.Option<string>,
  clientListing: ClientsListing.Model,
  dialogApiResult: Deferred<ApiResult<unknown>>,
) {
  let pageContent = <></>;

  const onNewUser = (branchId: O.Option<BranchId>, teamId: O.Option<TeamId>) =>
    props.dispatch(NewUserInitiated(branchId, teamId));
  switch (page.type) {
    case "Client":
      {
        pageContent = (
          <ClientManagement.View
            client={props.client}
            model={page.model}
            dispatch={flow(ClientManagementAction, props.dispatch)}
            onCloseModal={flow(DialogClosed, props.dispatch)}
            onSaveBranch={(branch, payload) =>
              flow(
                Started,
                BranchPayloadPrepared(props.client.clientId)(branch, payload),
                props.dispatch,
              )()
            }
            onNewUser={onNewUser}
            onDeleteBranch={(branchId) =>
              flow(Started, DeleteBranchConfirmed(branchId), props.dispatch)()
            }
            onSelectBranch={flow(BranchSelected, props.dispatch)}
            onSelectTeam={flow(TeamSelected, props.dispatch)}
            dialogApiResult={dialogApiResult}
          />
        );
      }
      break;
    case "Branch":
      {
        pageContent = (
          <BranchManagement.View
            client={props.client}
            branches={branches}
            model={page.model}
            teams={teams}
            users={users}
            onNewUser={onNewUser}
            dispatch={flow(BranchManagementAction, props.dispatch)}
            onSelectUser={flow(UserSelected, props.dispatch)}
            onSelectTeam={flow(TeamSelected, props.dispatch)}
            onSaveTeam={(teamId, payload) =>
              flow(
                Started,
                TeamPayloadPrepared(teamId, payload),
                props.dispatch,
              )()
            }
            onDeleteTeam={(teamId) =>
              flow(Started, DeleteTeamConfirmed(teamId), props.dispatch)()
            }
            dialogApiResult={dialogApiResult}
            onCloseModal={flow(DialogClosed, props.dispatch)}
          />
        );
      }
      break;
    case "Team":
      {
        pageContent = (
          <TeamManagement.View
            branches={branches}
            client={props.client}
            branchName={branchName}
            teams={teams}
            users={users}
            model={page.model}
            onNewUser={onNewUser}
            dispatch={constant}
            onSelectUser={flow(UserSelected, props.dispatch)}
          />
        );
      }
      break;
    case "User":
      {
        pageContent = (
          <UserView
            user={page.user}
            client={props.client}
            applications={page.applications}
            branchName={branchName}
            teamName={teamName}
            clientsListing={clientListing}
            dispatch={props.dispatch}
          />
        );
      }
      break;
  }
  return pageContent;
}
