import { Button, Modal, Table } from "@/components/basic";
import {
  ApplicationListingSortColumns,
  ApplicationListingSortDirection,
  ApplicationsFilterPayload,
  pageInfoFromList,
} from "@/data/applicationsList";
import { EqUserId, UserId } from "@/data/client";
import { nesHead } from "@/utils/codecs";
import { GetDeviceInfo } from "@/utils/viewResolver";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import * as Eq from "fp-ts/lib/Eq";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as Ord from "fp-ts/lib/Ord";
import { Ord as OrdString } from "fp-ts/string";
import { useCallback, useEffect, useMemo } from "react";
import {
  AcceptExportWarning,
  AssignUserAction,
  CancelUserSelected,
  RefreshPageAction,
} from "./action";

import { Started } from "@/utils/asyncOperationStatus";
import { deferredToOption, isLoading, mapDeferred } from "@/utils/deferred";
import * as AssignUser from "./AssignUser/index";
import { ClientTableFilters } from "./client-table-filters";
import { getTableColumns } from "./column-definitions";
import { ExportModal } from "./export-modal";
import { getSearchPayload } from "./model";
import { ApplicationAssignees, Props } from "./types";

export function View(props: Props): JSX.Element {
  const { model, dispatch, showFilters } = props;

  const pageInfo = useMemo(() => {
    return pipe(
      model.applicationListReponse,
      mapDeferred(E.map((v) => pageInfoFromList(v))),
      deferredToOption,
      O.chain(O.fromEither),
    );
  }, [model.applicationListReponse]);

  const applicationList = useMemo(() => {
    return pipe(
      model.applicationListReponse,
      mapDeferred(E.map((v) => v.items)),
      deferredToOption,
      O.chain(O.fromEither),
      O.fold(constant([]), identity),
    );
  }, [model.applicationListReponse]);

  const assignees: ApplicationAssignees = useMemo(
    () =>
      pipe(
        applicationList,
        A.chain((app): [UserId, string][] =>
          app.assignees.map(({ userId, firstName, lastName }) => [
            userId,
            `${nesHead(firstName)} ${lastName}`,
          ]),
        ),
        A.uniq(
          Eq.contramap(([userId, _name]: [UserId, string]) => userId)(EqUserId),
        ),
        A.sort(
          Ord.contramap(([_userId, name]: [UserId, string]) => name)(OrdString),
        ),
      ),
    [applicationList],
  );

  const deviceInfo = GetDeviceInfo();

  useEffect(() => {
    flow(Started, RefreshPageAction(model.searchParams), dispatch)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performSearch = useCallback(
    (args: Partial<ApplicationsFilterPayload>) => {
      return flow(
        Started,
        flow(constant(args), getSearchPayload(model), RefreshPageAction)(),
        dispatch,
      )();
    },
    [dispatch, model],
  );

  const _isDataLoading = useMemo(() => {
    return isLoading(model.applicationListReponse);
  }, [model.applicationListReponse]);

  return (
    <>
      {
        //show warning on 'incomplete' status exports
        pipe(
          model.exportWarningShown,
          O.chain((v) =>
            pipe(
              v,
              O.fromPredicate(([v]) => !v),
            ),
          ),
          O.map(() => (
            <Modal
              onClose={O.none}
              title={O.some(
                "This application is incomplete. You may continue with export, but much of the data will be missing from the exported data",
              )}
            >
              <Button
                type="primary"
                onClick={O.some(flow(AcceptExportWarning, dispatch))}
              >
                Ok
              </Button>
            </Modal>
          )),
          O.getOrElse(() => <></>),
        )
      }
      {pipe(
        model.assingmentModel,
        O.fold(constant(<></>), (model) => (
          <AssignUser.View
            model={model}
            dispatch={flow(AssignUserAction, dispatch)}
            onClose={(v: boolean) =>
              flow(constant(v), CancelUserSelected, dispatch)()
            }
          />
        )),
      )}
      {showFilters && (
        <ClientTableFilters
          {...{ ...props, deviceInfo, assignees, performSearch }}
        />
      )}
      <ExportModal {...props} />
      <Table
        className="data-table applications-table"
        data={applicationList}
        pageInfo={pageInfo}
        onPageChange={(page) => performSearch({ page })}
        isLoading={_isDataLoading}
        itemKey={(app) => app.applicationId}
        columns={getTableColumns(deviceInfo, props).map((v) =>
          ["assignees", "actions", "creditCheckOk"].includes(v.columnKey)
            ? v
            : { ...v, sortable: true },
        )}
        onSortedChanged={(column: string, dir) => {
          let sortColumn: ApplicationListingSortColumns;
          switch (column) {
            case "client":
              sortColumn = ApplicationListingSortColumns.ApplicantName;
              break;
            case "status":
              sortColumn = ApplicationListingSortColumns.Status;
              break;
            case "mortgageType":
              sortColumn = ApplicationListingSortColumns.MortgageType;
              break;
            default:
              sortColumn = ApplicationListingSortColumns.SubmittedDate;
          }
          performSearch({
            sortColumn,
            sortDirection:
              dir == "asc"
                ? ApplicationListingSortDirection.Ascending
                : ApplicationListingSortDirection.Descending,
          });
        }}
        noDataMessage="No application(s) found."
      />
    </>
  );
}
