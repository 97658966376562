import "@/assets/styles/Review/application.css";
import {
  Button,
  Col,
  Icon,
  Label,
  Row,
  TogglePanel
} from "@/components/basic";
import * as Documents from "@/components/Documents";
import { User } from "@/data/client";
import { ConsentItem } from "@/data/consents";
import {
  AddressPayload,
  ApplicantPayload,
  Application,
  ApplicationStatusType,
  EmployerInfoPayload,
  EmployersPayload,
  FullEmployerInfo,
  RetirementAccount,
  showApplicantType,
  showBankruptcyStatusType,
  showCitizenshipStatus,
  showIncomeSourceType,
  showJointApplicantType,
  showRetirementAccountType,
  showSourceOfFunds,
} from "@/data/payload";
import { RouteNames } from "@/routing/routes";
import { Deferred, deferredToOption } from "@/utils/deferred";
import { formatConfirmSsn } from "@/utils/formatters";
import { FormField } from "@/utils/formField";
import { ApiResult } from "@/utils/request";
import { RouterContext } from "@/utils/router-context";
import { useRights } from "@/utils/use-rights";
import { ViewResolver } from "@/utils/viewResolver";
import * as A from "fp-ts/lib/Array";
import { constant, pipe } from "fp-ts/lib/function";
import * as NEA from "fp-ts/lib/NonEmptyArray";
import * as O from "fp-ts/lib/Option";
import { Option, fold } from "fp-ts/lib/Option";
import { identity, startCase } from "lodash-es";
import { DateTime } from "luxon";
import {
  Fragment,
  MouseEventHandler,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";
import { Tag } from "../basic/Tag";


function scrollIntoView(id: string, section?: string, index?: number) {
  const targetId =
    section && index !== undefined ? `section-${section}-${index}` : id;
  const targetElement = document.getElementById(targetId);
  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}

function relativeDate(date: Option<DateTime>) {
  return fold(
    () => "Today",
    (exportedDate: DateTime) => {
      const now = DateTime.local();
      const diffInDays = now.diff(exportedDate, "days").days;

      if (diffInDays < 1) {
        return "Today";
      } else if (diffInDays < 2) {
        return "1 day ago";
      } else {
        return `${Math.floor(diffInDays)} days ago`;
      }
    },
  )(date);
}

export type Props = {
  payload: Application;
  onEdit: () => void;
  onEditLoNotes: () => void;
  isEditingLoNotes: boolean;
  loNotesFormField: FormField<O.Option<string>>;
  onLoNotesChanged: (o: string) => void;
  onSaveLoNotes: () => void;
  children: ReactNode;
  user: O.Option<User>;
  isCreditCheckAuthorized: boolean;
  document: Documents.Model;
  onScrollToDocuments: O.Option<() => void>;
  constentMeta: Deferred<ApiResult<ConsentItem[]>>;
  onExportApplication: O.Option<() => void>;
};

function ApplicationSummary(props: Props) {
  return (
    <>
      <div className="application-section">
        <Row
          gap="xs"
          padding="fallback"
          className="text-smd grid-item-3 color-act-70 text-mbold applicant-name"
        >
          <span>{props.payload.survey.primaryApplicant.fullName}</span>
        </Row>
        <Row
          gap="md"
          alignVertical="baseline"
          className={ViewResolver({
            viewModes: [
              ["Default"],
              ["Tablet-Portrait", "Tablet-Landscape"],
              ["Mobile-Portrait", "Mobile-Landscape"],
            ],
            resolvedContent: ["flex-wrap", "grid-3", "grid"],
          })}
          switchToGrid={ViewResolver({
            viewModes: [["Default"], ["Desktop-Portrait", "Desktop-Landscape"]],
            resolvedContent: [true, false],
          })}
          padding="md"
          alignHorizontal="space-between"
        >
          <Col gap="xs">
            <b>Application Number</b>
            <span className="padding-vertical-xxxs">
              {props.payload.applicationId ? props.payload.applicationId : "-"}
            </span>
          </Col>
          <Col gap="xs">
            <b>Status</b>
            <Tag
              type="neutral"
              iconAlign="none"
              icon={O.some("info")}
              className="text-sm font-weight-500"
            >
              {props.payload.status}
            </Tag>
          </Col>
          <Col gap="xs">
            <b>Credit Check</b>
            <span className="padding-vertical-xxxs">
              {props.isCreditCheckAuthorized ? (
                <Tag
                  iconAlign="right"
                  icon={O.some("check")}
                  className="text-sm font-weight-500"
                  type="neutral"
                >
                  {" "}
                  Authorized
                </Tag>
              ) : (
                <Tag
                  iconAlign="right"
                  icon={O.some("xmark")}
                  className="text-sm font-weight-500"
                  type="neutral"
                >
                  {" "}
                  Not Authorized
                </Tag>
              )}
              {/* <i className="fa-solid fa fa-check ml-left-xxs"></i> */}
            </span>
          </Col>
          <Col gap="xs">
            <b>Type</b>
            <span className="padding-vertical-xxxs">
              {props.payload.survey.applicationType}
            </span>
          </Col>
          <Col gap="xs">
            <b>Started</b>
            <Tag
              type="neutral"
              iconAlign="none"
              icon={O.some("info")}
              className="text-sm font-weight-500"
            >
              {relativeDate(
                props.payload.createdDateUtc
                  ? O.some(props.payload.createdDateUtc)
                  : O.none,
              )}
            </Tag>
          </Col>
          <Col gap="xs">
            <b>Assigned to</b>
            <span className="padding-vertical-xxxs">
              <i className="fa-regular fa-user ml-right-xxs"></i>
              {pipe(
                props.payload.loanOfficer,
                O.fold(
                  () => "-",
                  (info) =>
                    info.fullName
                      ? info.fullName
                      : info.email
                        ? info.email
                        : "-",
                ),
              )}
            </span>
          </Col>
        </Row>
      </div>
    </>
  );
}

function RenderCommonContent(props: Props) {
  const { isBackOfficeUser } = useRights();
  const commonText = useMemo(
    () => (
      <Row className="text-md" alignVertical="baseline">
        <div>
          In order to submit your application, fill in all of your information
          and{" "}
          <span
            className="link"
            onClick={O.fold(
              () => undefined,
              (onClick) => onClick as MouseEventHandler<HTMLSpanElement>,
            )(props.onScrollToDocuments)}
          >
            upload all relevant documents
          </span>
        </div>
      </Row>
    ),
    [props.onScrollToDocuments],
  );
  return (
    <Col gap="lg" padding="xs" className="w-100">
      <Row>{commonText}</Row>

      <ApplicationSummary {...props} />
      <Col className="application-section" only={isBackOfficeUser}>
        <Row
          gap="xs"
          padding="fallback"
          className="text-smd grid-item-3 color-act-70 text-mbold applicant-name"
        >
          <span>Loan Officer Notes</span>
          {!props.isEditingLoNotes ? (
            <Button onClick={O.some(props.onEditLoNotes)} type="flat">
              Edit Note
            </Button>
          ) : (
            <></>
          )}
        </Row>
        <Row gap="md" padding="md">
          {props.isEditingLoNotes ? (
            <Col className="notes-container" grow={1} gap="xs">
              <textarea
                className="notes-text-area text-md"
                value={props.loNotesFormField.raw}
                onChange={(e) => props.onLoNotesChanged(e.target.value)}
                required={true}
              />
              <Button type="primary" onClick={O.some(props.onSaveLoNotes)}>
                Save
              </Button>
            </Col>
          ) : (
            <>
              {pipe(
                props.payload.survey.loanOfficerNote,
                O.fold(
                  constant(
                    <>
                      <Col gap="xs">
                        <span className="text-grey">
                          There are no notes. You can add notes about the
                          application. This won't be visible to the borrower .{" "}
                        </span>
                        <Button
                          onClick={O.some(props.onEditLoNotes)}
                          type="secondary"
                        >
                          Add Note
                        </Button>
                      </Col>
                    </>,
                  ),
                  identity,
                ),
              )}
            </>
          )}
        </Row>
      </Col>

      <ApplicantView
        index={0}
        {...{ ...props.payload, constentMeta: props.constentMeta }}
      />
      {props.children}
    </Col>
  );
}

export function View(props: Props & { children: ReactNode }): JSX.Element {
  const { isBackOfficeUser } = useRights();
  const [selectedSection, setSelectedSection] = useState<string>("personal-0");

  const DesktopView = (
    <Col className="w-100" gap="sm">
      <ActionButtons {...props} />
      <Row gap="sm">
        <Col padding="xs" className="summary-navigation w-25">
          <Col gap="sm">
            <ApplicantSummaryItem
              index={0}
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
              {...props.payload.survey.primaryApplicant}
            />
            {props.payload.survey.jointApplicants.map((coApplicant, index) => (
              <ApplicantSummaryItem
                key={index}
                index={index + 1}
                selectedSection={selectedSection}
                setSelectedSection={setSelectedSection}
                {...coApplicant.applicant}
              />
            ))}
            <Col gap="xxs">
              <Row
                gap="xs"
                padding="sm"
                className={`text-md pointer 
                  ${(selectedSection === "documents-section")
                    ? "highlight-selected-menu" : ""
                  }`}
                onClick={() => {
                  setSelectedSection("documents-section")
                  scrollIntoView("documents-section");
                }}
              >
                <Icon type="file-lines" />
                <span className="flex-grow-1">Upload Documentation</span>
                <Icon
                  type={
                    Documents.isAnyDocumentError(props.document)
                      ? "triangle-exclamation"
                      : Documents.allFilesUploaded(props.document)
                        ? "circle-check"
                        : Documents.isAnyDocumentValidated(props.document)
                          ? "spinner"
                          : "circle"
                  }
                />
              </Row>
              <Row
                gap="xs"
                padding="sm"
                className={`text-md pointer 
                  ${(selectedSection === "verification-section")
                    ? "highlight-selected-menu" : ""
                  }`}
                onClick={() => {
                  setSelectedSection("verification-section")
                  scrollIntoView("verification-section");
                }}
                only={isBackOfficeUser}
              >
                <Icon type="search" />
                <span className="flex-grow-1">Verification</span>
                <Icon
                  type={
                    props.payload.survey.primaryApplicant.consents.length > 0 &&
                    props.payload.survey.primaryApplicant.consents.every(
                      (consent) => consent.consentProvided,
                    )
                      ? "circle-check"
                      : "circle"
                  }
                />
              </Row>
            </Col>
          </Col>
        </Col>
        <RenderCommonContent {...props} />
      </Row>
    </Col>
  );

  const MobileView = (
    <Col gap="sm" className="w-100">
      <ActionButtons {...props} />
      <RenderCommonContent {...props} />
    </Col>
  );

  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, MobileView],
  });

  return <>{ViewWrapper}</>;
}

function formatAddress(address: AddressPayload, isCurrent: boolean): string {
  return isCurrent
    ? `${address.street},<br />${address.city},<br />${address.state}`
    : `${address.street},<br />${address.city},<br />${address.state}`;
}

function ApplicantView(
  props: Application & {
    index: number;
    constentMeta: Deferred<ApiResult<ConsentItem[]>>;
  },
): JSX.Element {
  const { index, survey } = props;
  const { isBackOfficeUser } = useRights();


  const applicants = [
    { ...survey.primaryApplicant, index, applicantType: "Primary" },
    ...survey.jointApplicants.map((jointApplicant, jointIndex) => ({
      ...jointApplicant.applicant,
      index: jointIndex,
      applicantType: "Co-applicant",
    })),
  ];

  return (
    <>
      {applicants.map((applicant, applicantIndex) => (
        <TogglePanel
          key={applicantIndex}
          title={applicant.fullName}
          subTitle={applicant.applicantType}
        >
          <div
            className={ViewResolver({
              viewModes: [["Default"], ["Tablet-Portrait", "Tablet-Landscape"]],
              resolvedContent: ["grid-3", "grid-2"],
            })}
          >
            <Row
              gap="xs"
              className="text-smd grid-item-3 padding-xs color-act-70 text-mbold"
            >
              <Icon type="user" />
              <span id={`section-personal-${applicantIndex}`}>
                Personal Information
              </span>
            </Row>

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Full Name</b>
              <span>{applicant.fullName ?? " - "}</span>
            </Col>

            <Col
              gap="xs"
              padding="sm"
              className={ViewResolver({
                viewModes: [
                  ["Default"],
                  ["Tablet-Portrait", "Tablet-Landscape"],
                ],
                resolvedContent: ["grid-item-1", "column-span-2"],
              })}
            >
              <b>Date of Birth</b>
              <span>
                {applicant.dateOfBirth
                  ? applicant.dateOfBirth.toLocaleString(DateTime.DATE_SHORT)
                  : " - "}
              </span>
            </Col>

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Social Security Number</b>
              <span>
                {applicant.ssn ? formatConfirmSsn(applicant.ssn) : "-"}
              </span>
            </Col>

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Email</b>
              <span className="wb-bw">{applicant.email ?? " - "}</span>
            </Col>

            <Col
              gap="xs"
              padding="sm"
              className={ViewResolver({
                viewModes: [
                  ["Default"],
                  ["Tablet-Portrait", "Tablet-Landscape"],
                ],
                resolvedContent: ["grid-item-1", "column-span-2"],
              })}
            >
              <b>Cell Number</b>
              <span>{applicant.phone ?? " - "}</span>
            </Col>

            <hr className="grid-item-3" />
            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Current Address</b>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    applicant.addresses.length > 0
                      ? formatAddress(applicant.addresses[0], true)
                      : " - ",
                }}
              ></span>
            </Col>

            <Col
              gap="xs"
              padding="sm"
              className={ViewResolver({
                viewModes: [
                  ["Default"],
                  ["Tablet-Portrait", "Tablet-Landscape"],
                ],
                resolvedContent: ["grid-item-1", "column-span-2"],
              })}
            >
              <b>Previous Addresses</b>
              {applicant.addresses.length > 1 ? (
                applicant.addresses.slice(1).map((address, index) => (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: formatAddress(address, false),
                    }}
                  ></div>
                ))
              ) : (
                <span> - </span>
              )}
            </Col>
            <hr className="grid-item-3" />

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Marital Status</b>
              <span>{applicant.maritalStatus ?? " - "}</span>
            </Col>

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Previously Divorced</b>
              <span>{applicant.divorced ? "Yes" : "No"}</span>
            </Col>

            <hr className="grid-item-3" />

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Citizenship</b>
              <span>
                {showCitizenshipStatus(applicant.citizenshipStatus) ?? " - "}
              </span>
            </Col>

            <hr className="grid-item-3" />

            <Row
              gap="xs"
              className="text-smd grid-item-3 padding-xs color-act-70 text-mbold"
            >
              <Icon type="house" />
              <span id={`section-mortgage-${applicantIndex}`}>
                Mortgage Information
              </span>
            </Row>

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Source of Funds</b>
              {props.survey.sourceOfFunds.map((source) => (
                <span key={source}>{showSourceOfFunds(source) ?? " - "}</span>
              ))}
            </Col>

            <hr className="grid-item-3" />

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Application</b>
              <span>
                {showApplicantType(
                  A.isNonEmpty(props.survey.jointApplicants) ?? "-",
                )}
              </span>
            </Col>

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Relationship</b>
              {A.isNonEmpty(props.survey.jointApplicants)
                ? props.survey.jointApplicants.map((relation) => (
                    <span className="wb-bw" key={relation.applicantType}>
                      {showJointApplicantType(relation.applicantType) ?? " - "}
                    </span>
                  ))
                : "-"}
            </Col>

            <Col
              gap="xs"
              padding="sm"
              className={ViewResolver({
                viewModes: [
                  ["Default"],
                  ["Tablet-Portrait", "Tablet-Landscape"],
                ],
                resolvedContent: ["grid-item-1", "column-span-2"],
              })}
            >
              <b>Full Name</b>
              {A.isNonEmpty(props.survey.jointApplicants)
                ? props.survey.jointApplicants.map((name) => (
                    <span key={name.applicant.fullName}>
                      {name.applicant.fullName ?? " - "}
                    </span>
                  ))
                : "-"}
            </Col>

            <hr className="grid-item-3" />

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Real Estate Agent</b>
              <span>
                {props.survey.workingWithRealEstateAgent ? "Yes" : "No"}
              </span>
            </Col>

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Primary Contact</b>
              <span>
                {pipe(
                  props.survey.realEstateAgentName,
                  O.fold(
                    () => " - ",
                    (name) => name,
                  ),
                )}
              </span>
            </Col>

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Contact number</b>
              <span>
                {pipe(
                  props.survey.realEstateAgentPhone,
                  O.fold(
                    () => " - ",
                    (phone) => phone,
                  ),
                )}
              </span>
            </Col>

            <hr className="grid-item-3" />

            <Row
              gap="xs"
              className="text-smd grid-item-3 padding-xs color-act-70 text-mbold"
            >
              <Icon type="piggy-bank" />
              <span id={`section-financial-${applicantIndex}`}>
                Financial Information
              </span>
            </Row>

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Current Sources of Income</b>
              {A.isNonEmpty(applicant.incomeSources)
                ? applicant.incomeSources
                    .filter(
                      (incomeSource) =>
                        incomeSource.type !== "Other_Alimony_ChildSupport",
                    )
                    .map((source) => (
                      <span key={source.type}>
                        {showIncomeSourceType(source.type) ?? " - "}
                      </span>
                    ))
                : "-"}
            </Col>

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Type of work</b>
              <span>
                {showBankruptcyStatusType(applicant.bankruptcyStatus.type) ??
                  " - "}
              </span>
            </Col>
            {/* TODO: Render only sources with type "Other"*/}
            <Col
              gap="xs"
              padding="sm"
              className={ViewResolver({
                viewModes: [
                  ["Default"],
                  ["Tablet-Portrait", "Tablet-Landscape"],
                ],
                resolvedContent: ["grid-item-1", "column-span-2"],
              })}
            >
              <b>Other Sources of Income</b>
              {A.isNonEmpty(applicant.incomeSources)
                ? applicant.incomeSources
                    .filter(
                      (incomeSource) =>
                        incomeSource.type === "Other_Alimony_ChildSupport",
                    )
                    .map((source) => (
                      <span key={source.type}>
                        {showIncomeSourceType(source.type) ?? " - "}
                      </span>
                    ))
                : "-"}
            </Col>

            <hr className="grid-item-3" />

            <Row
              gap="xs"
              className="text-smd grid-item-3 padding-xs color-act-70 text-mbold"
            >
              <span>Additional Financial Information</span>
            </Row>

            <Col gap="xs" padding="sm" className="grid-item-1">
              <b>Financial History</b>
              <span>
                {props.survey.primaryApplicant.foreclosureOrShortSale
                  ? "Yes"
                  : "No"}
              </span>
            </Col>

            <Col
              gap="xs"
              padding="sm"
              className={ViewResolver({
                viewModes: [
                  ["Default"],
                  ["Tablet-Portrait", "Tablet-Landscape"],
                ],
                resolvedContent: ["grid-item-1", "column-span-2"],
              })}
            >
              <b>Retirement Accounts Held</b>
              {applicant.retirementAccounts &&
              applicant.retirementAccounts.size > 0 ? (
                Array.from(applicant.retirementAccounts).map(
                  (account: RetirementAccount, index: number) => (
                    <span key={index}>
                      {showRetirementAccountType(account) ?? " - "}
                    </span>
                  ),
                )
              ) : (
                <span> - </span>
              )}
            </Col>

            {/* Employer Section*/}
            {A.isNonEmpty(applicant.employers) && (
              <>
                <hr className="grid-item-3" />
                <Row
                  gap="xs"
                  className="text-smd grid-item-3 padding-xs color-act-70 text-mbold"
                >
                  <Icon type="user-tie" />
                  <span id={`section-employer-${applicantIndex}`}>
                    Employer Information
                  </span>
                </Row>

                <EmployerInformationView employers={applicant.employers} />
              </>
            )}
          </div>
        </TogglePanel>
      ))}
      <div style={{ order: "2" }}>
        <TogglePanel
          id="verification-section"
          title="Verification"
          only={isBackOfficeUser}
        >
          <div>
            {pipe(
              props.survey.primaryApplicant.consents,
              NEA.fromArray,
              O.fold(
                () => (
                  <Col>
                    <p> Consents are not provided yet by the applicant</p>
                  </Col>
                ),
                (array) => {
                  return (
                    <>
                      {array.map((consent, index) => {
                        const matchingConsentItem = pipe(
                          props.constentMeta,
                          deferredToOption,
                          O.chain(O.fromEither),
                          O.chain((meta) =>
                            pipe(
                              meta,
                              A.findFirst(
                                (m) =>
                                  m.consentVersionId ==
                                  consent.consentVersionId,
                              ),
                            ),
                          ),
                        );
                        return pipe(
                          matchingConsentItem,
                          O.fold(
                            () => {
                              return (
                                <Col gap="xs" foreground="no50" key={index}>
                                  <span>
                                    Invalid matching consent found for versionId
                                    :
                                    <span className="text-bold">
                                      {consent.consentVersionId}
                                    </span>
                                  </span>
                                </Col>
                              );
                            },
                            (matchingConsent) => (
                              <Row
                                alignVertical="center"
                                gap="xs"
                                padding="xs"
                                key={index}
                              >
                                <Icon type="check" />
                                <Col padding="sm">
                                  <>
                                    <span> {matchingConsent.title}</span>
                                    <span className="text-bold">
                                      {startCase(
                                        pipe(
                                          consent.method,
                                          O.fromNullable,
                                          O.fold(constant("None"), identity),
                                        ),
                                      )}
                                    </span>
                                    <span>
                                      {consent.consentProvided ? "Yes" : "No"}
                                    </span>
                                  </>
                                </Col>
                              </Row>
                            ),
                          ),
                        );
                      })}
                    </>
                  );
                },
              ),
            )}
          </div>
        </TogglePanel>
      </div>
    </>
  );
}

type ApplicantSummaryItemProps = ApplicantPayload & {
  index: number,
  selectedSection: string,
  setSelectedSection: React.Dispatch<React.SetStateAction<string>>
};

function ApplicantSummaryItem(props: ApplicantSummaryItemProps): JSX.Element {
  const { index, selectedSection, setSelectedSection } = props;
  return (
    <Col>
      <Col gap="xxs">
        <Label className="text-md text-mbold action-70 mb-sm">{props.fullName}</Label>
        <Row
          gap="xs"
          padding="sm"
          className={`text-md pointer 
            ${(selectedSection === `personal-${index}`)
              ? "highlight-selected-menu" : ""
            }`}
          onClick={() => {
            setSelectedSection(`personal-${index}`)
            scrollIntoView("personal", "personal", index);
          }}
        >
          <Icon type="user" />
          <span className="flex-grow-1">Personal Information</span>
          <Icon type="circle-check" />
        </Row>
        <Row
          gap="xs"
          padding="sm"
          className={`text-md pointer 
            ${(selectedSection === `mortgage-${index}`)
              ? "highlight-selected-menu" : ""
            }`}
          onClick={() => {
            setSelectedSection(`mortgage-${index}`)
            scrollIntoView("mortgage", "mortgage", index);
          }}
        >
          <Icon type="house" />
          <span className="flex-grow-1">Mortgage Information</span>
          <Icon type="circle-check" />
        </Row>
        <Row
          gap="xs"
          padding="sm"
          className={`text-md pointer 
            ${(selectedSection === `financial-${index}`)
              ? "highlight-selected-menu" : ""
            }`}
          onClick={() => {
            setSelectedSection(`financial-${index}`)
            scrollIntoView("financial", "financial", index);
          }}
        >
          <Icon type="piggy-bank" />
          <span className="flex-grow-1">Financial Information</span>
          <Icon type="circle-check" />
        </Row>
        {A.isNonEmpty(props.employers) && (
          <Row
            gap="xs"
            padding="sm"
            className={`text-md pointer 
            ${(selectedSection === `employer-${index}`)
                ? "highlight-selected-menu" : ""
              }`}
            onClick={() => {
              setSelectedSection(`employer-${index}`)
              scrollIntoView("employer", "employer", index);
            }}
          >
            <Icon type="user-tie" />
            <span className="flex-grow-1">Employer Information</span>
            <Icon type="circle-check" />
          </Row>
        )}
      </Col>
    </Col>
  );
}

export type EmployerInformationProps = {
  employers: EmployersPayload[];
};

function EmployerInformationView(props: EmployerInformationProps): JSX.Element {
  const { employers } = props;

  return (
    <>
      {employers.map(({ employerInfo, name }, index) => (
        <Fragment key={index}>
          <Col gap="xs" padding="sm" className="grid-item-1">
            <b>Employer Name</b>
            <span>{name}</span>
          </Col>

          {pipe(
            employerInfo,
            O.fold(constant(<></>), (empInfo) =>
              isFullEmployerInfo(empInfo) ? (
                <>
                  <Col gap="xs" padding="sm" className="grid-item-1">
                    <b>Start Date</b>
                    <span>
                      {empInfo.startDate.toLocaleString(DateTime.DATE_SHORT) ??
                        "-"}
                    </span>
                  </Col>
                  <Col
                    gap="xs"
                    padding="sm"
                    className={ViewResolver({
                      viewModes: [
                        ["Default"],
                        ["Tablet-Portrait", "Tablet-Landscape"],
                      ],
                      resolvedContent: ["grid-item-1", "column-span-2"],
                    })}
                  >
                    <b>Is Still Employed</b>
                    <span>{empInfo.isStillEmployed ? "Yes" : "No"}</span>
                  </Col>
                  <Col gap="xs" padding="sm" className="grid-item-1">
                    <b>End Date</b>
                    <span>
                      {empInfo.endDate
                        ? pipe(
                            empInfo.endDate,
                            O.chain(O.fromNullable),
                            O.fold(
                              () => <>{"-"}</>,
                              (date) => (
                                <>
                                  {date.toLocaleString(DateTime.DATE_SHORT) ??
                                    "-"}
                                </>
                              ),
                            ),
                          )
                        : "-"}
                    </span>
                  </Col>
                </>
              ) : (
                <></>
              ),
            ),
          )}

          <hr className="grid-item-3" />
        </Fragment>
      ))}
    </>
  );
}

function isFullEmployerInfo(
  employerInfo: EmployerInfoPayload,
): employerInfo is FullEmployerInfo {
  return employerInfo.employerId != null;
}

function ActionButtons(props: Props): JSX.Element {
  const { router } = useContext(RouterContext);
  const { isBackOfficeUser } = useRights();
  const collapseViewClass = ViewResolver({
    viewModes: [["Default"], ["Mobile-Portrait"]],
    resolvedContent: ["", "w-100"],
  });
  const isApplicationEditable =
    props.payload.status === ApplicationStatusType.Exported ||
    props.payload.status === ApplicationStatusType.Archived
      ? false
      : true;

  const buttons = (
    <>
      <Button
        type="flat"
        only={isBackOfficeUser}
        onClick={O.some(() => router.navigate(RouteNames.CLIENTS_LISTING))}
        className={`text-md go-back-btn ${collapseViewClass}`}
      >
        <Icon type="arrow-left" /> Go Back
      </Button>
      <Button
        type="primary"
        onClick={props.onExportApplication}
        only={isBackOfficeUser}
        className={`${collapseViewClass}`}
        disabledTooltip="Restricted mode"
      >
        Export
      </Button>

      <Button
        type="secondary"
        onClick={isApplicationEditable ? O.some(props.onEdit) : O.none}
        className={`${collapseViewClass}`}
      >
        Edit
      </Button>
    </>
  );
  const normalView = (
    <Row gap="xs" alignHorizontal="right">
      {buttons}
    </Row>
  );

  const collapseView = (
    <Col gap="xs" padding="xs">
      {buttons}
    </Col>
  );

  const ViewWrapper = ViewResolver({
    viewModes: [["Default"], ["Mobile-Portrait"]],
    resolvedContent: [normalView, collapseView],
  });
  return <>{ViewWrapper}</>;
}
