import { Api } from "@/utils/api";
import { Model } from "./model";
import {
    Effect,
    effectOfAsync,
    effectOfFunc_,
    noEffect,
  } from "@/utils/reducerWithEffect";
import { flow, pipe } from "fp-ts/lib/function";
import { InProgress, Resolved } from "@/utils/deferred";
import * as E from "fp-ts/Either";
import { ApiResult } from "@/utils/request";
export type Action = 
{
    type: "LeaveApplicationSelected"

}
 | {
    type: "SaveUserUnFinishedApplicationExit"
    result: ApiResult<unknown>
 }

export const LeaveApplicationSelected = (): Action => ({
    type: 'LeaveApplicationSelected'
});

export const SaveUserUnFinishedExit = (result: ApiResult<unknown>): Action => ({
    type: 'SaveUserUnFinishedApplicationExit',
    result
});

export const onUserUnFinishedExit = (dispatch: (action: Action) => void) => {
  return flow(LeaveApplicationSelected, dispatch)()
}


export const update =
  (api: Api) =>
  (action: Action) =>
  (model: Model): [Model, Effect<Action>] => {
    switch (action.type) {
      case "LeaveApplicationSelected": 
        return [
            {
                ...model,
                dialogApiResult: InProgress()
            },
            effectOfAsync(
                api.unfinishApplicationExit(),
                SaveUserUnFinishedExit
            )
        ]
      case "SaveUserUnFinishedApplicationExit":
        return [
            {
                ...model,
                dialogApiResult: Resolved(pipe(true, E.right))
            },
            pipe(
               action.result,
               E.fold(
                (err) => effectOfFunc_(() => console.error(err), undefined),
                () => noEffect
              ),
            )
        ]
    }
  };