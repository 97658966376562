import {
  AssignmentsPayload,
  assignmentsPayloadCodec,
} from "@/data/application-assignments";
import { ApplicationListItem } from "@/data/applicationsList";
import { Client, ExtensionRole, User } from "@/data/client";
import { Started } from "@/utils/asyncOperationStatus";
import { Deferred, NotStarted } from "@/utils/deferred";
import { Effect, effectOfAction } from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import { Validation } from "io-ts";
import { Action, GetCurrentClient } from "./action";
import * as A from "fp-ts/Array";
import { getLoanOfficers, getSupportUsers } from "@/utils/user";

export type Model = {
  client: Deferred<ApiResult<Client>>;
  selectedLoanOfficer: O.Option<User>;
  selectedSupportPartner: O.Option<User>;
  application: ApplicationListItem;
  assignmentStatus: Deferred<ApiResult<unknown>>;
};

export const init = (
  application: ApplicationListItem,
): [Model, Effect<Action>] => {
  const model: Model = {
    client: NotStarted(),
    application,
    selectedLoanOfficer: O.none,
    selectedSupportPartner: O.none,
    assignmentStatus: NotStarted(),
  };
  return [model, effectOfAction(flow(Started, GetCurrentClient)())];
};

export const result = (
  model: Model,
  isSuperUser: boolean,
): Validation<AssignmentsPayload> => {
  if (O.isNone(model.selectedLoanOfficer)) {
    return assignmentsPayloadCodec.decode({
      applicationId: model.application.applicationId,
    });
  }
  const userIds = isSuperUser ? [model.selectedLoanOfficer.value.userId] : [];
  if (O.isSome(model.selectedSupportPartner)) {
    userIds.push(model.selectedSupportPartner.value.userId);
  }
  return assignmentsPayloadCodec.decode({
    applicationId: model.application.applicationId,
    userIds,
  });
};

export const assignedOfficer = (
  role: ExtensionRole,
  application: ApplicationListItem,
  clientResponse: ApiResult<Client>,
): O.Option<User> => {
  const assignedUser = pipe(
    application.assignees,
    A.findFirst((v) => v.authorizationRoleType == role),
  );

  return pipe(
    clientResponse,
    O.fromEither,
    O.map((client) =>
      role == ExtensionRole.LoanOfficer
        ? getLoanOfficers(client)
        : getSupportUsers(client),
    ),
    O.chain((team) =>
      pipe(
        team,
        A.findFirst((u) =>
          pipe(
            assignedUser,
            O.map((v) => v.userId == u.userId),
            O.isSome,
          ),
        ),
      ),
    ),
  );
};
