import { OrdExtensionRole, User } from "@/data/client";
import { pipe } from "fp-ts/lib/function";
import { useMemo } from "react";
import * as S from "fp-ts/Set";
import { Label } from "@/components/basic";

export type UserRolesProps = {
  user: User;
};

export function UserRoles({ user }: UserRolesProps): JSX.Element {
  const roles = useMemo(
    () => pipe(user.roles, S.toArray(OrdExtensionRole)).join(", "),
    [user.roles],
  );

  return <Label className="black">{roles}</Label>;
}
