import { Button, Col, Icon, Label, Row } from "@/components/basic";
import {
  Branch,
  BranchId,
  Client,
  EqTeamId,
  Team,
  TeamId,
  User,
  UserId
} from "@/data/client";
import { ChildProps } from "@/utils/reducerWithEffect";
import { RouterContext } from "@/utils/router-context";
import * as M from "fp-ts/lib/Map";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import { constant, identity, pipe } from "fp-ts/lib/function";
import { useContext, useMemo } from "react";
import { Action } from "./action";
import { Model } from "./model";
import { ViewResolver } from "@/utils/viewResolver";

export type Props = ChildProps<Model, Action> & {
  onSelectUser: (userId: UserId) => void;
  branches: Map<BranchId, Branch>;
  teams: Map<TeamId, Team>;
  users: Map<UserId, User>;
  client: Client;
  branchName: (branchId: BranchId) => O.Option<string>;
  onNewUser: (branchId: Option<BranchId>, teamId: Option<TeamId>) => void;
};

export function View(props: Props): JSX.Element {
  const { model, branchName } = props;
  const selectedTeam = useMemo(
    () => M.lookup(EqTeamId)(model.teamId, props.teams),
    [model.teamId, props.teams],
  );

  const { router } = useContext(RouterContext);

  const selectedBranchName = useMemo(
    () =>
      pipe(
        selectedTeam,
        O.chain((team) => branchName(team.branchId)),
        O.fold(constant(""), identity),
      ),
    [selectedTeam, branchName],
  );

  return pipe(
    selectedTeam,
    O.fold(
      () => <Label>Team not found</Label>,
      (team) => (
        <Col grow={1}>
          <Row
            alignVertical="center"
            alignHorizontal="space-between"
            wrap={true}
            gap="sm"
            className={ViewResolver({
              viewModes: [
                ["Default"],
                ["Mobile-Landscape", "Mobile-Portrait"]
              ],
              resolvedContent: [
                "", "user-management-mb-buttons"
              ]
            })}
          >
            <Label>
              <Row gap="xxxs" alignVertical="center">
                <Button
                  onClick={O.some(() => router.navigate(`/org/branches`))}
                  type="flat"
                  className="link"
                >
                  {props.client.name}
                </Button>
                <div className="text-xs">
                  <Icon type="chevron-right"></Icon>
                </div>
                <Button
                  onClick={O.some(() =>
                    router.navigate(
                      `/org/branches/${pipe(
                        selectedTeam,
                        O.map((team) => team.branchId),
                        O.fold(constant(0), identity),
                      )}`,
                    ),
                  )}
                  type="flat"
                  className="link"
                >
                  <div>{selectedBranchName}</div>
                </Button>
                <div className="text-xs">
                  <Icon type="chevron-right"></Icon>
                </div>
                <div className="text-bold">{team.name}</div>
              </Row>
            </Label>
            <Button
              type="secondary"
              onClick={O.some(() =>
                props.onNewUser(O.some(team.branchId), O.some(model.teamId)),
              )}
            >
              Add new user
            </Button>
          </Row>
        </Col>
      ),
    ),
  );
}
