import { Button, Col, Label } from "@/components/basic";
import {
  Branch,
  BranchId,
  ClientsReassignment,
  EqBranchId,
  EqTeamId,
  EqUserId,
  OrdBranchId,
  OrdTeamId,
  Team,
  TeamId,
  User,
  UserId,
} from "@/data/client";
import { UserPayload } from "@/data/payload";
import { Started } from "@/utils/asyncOperationStatus";
import { ChildProps } from "@/utils/reducerWithEffect";
import { showApiError } from "@/utils/request";
import { getClientUsers } from "@/utils/user";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/lib/Either";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import * as M from "fp-ts/Map";
import * as SG from "fp-ts/Semigroup";
import { useCallback, useMemo } from "react";
import {
  Action,
  DeleteUserConfirmed,
  DeleteUserInitiated,
  DialogClosed,
  EditUserInitiated,
  GetCurrentClient,
  UserPayloadPrepared,
  UserSelected,
} from "./action";
import { Model, Page } from "./model";
import { pageSections } from "./PageSections";
import { PageViewProps } from "./types";
import * as UsersListing from "./UsersListing";
import { UserManagementModals } from "./UsersListing/UserManagementModals";
import * as Skeleton from "../basic/Loaders/skeleton";

export type Props = ChildProps<Model, Action>;

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  switch (model.client.status) {
    case "NotStarted":
      return (
        <Button
          type="primary"
          onClick={O.some(flow(Started, GetCurrentClient, dispatch))}
        >
          Load
        </Button>
      );

    case "InProgress":
    case "Updating":
      return getPreLoadSkeleton();

    case "Resolved":
      return pipe(
        model.client.value,
        E.fold(
          (error) => <div>Error: {showApiError(error)}</div>,
          (client) => (
            <>
              <PageView
                clientListing={model.clientsListing}
                client={client}
                page={model.page}
                dispatch={dispatch}
                dialogApiResult={model.dialogApiResult}
                userManagementDialog={model.userManagementDialog}
              />
            </>
          ),
        ),
      );
  }
}

function PageView(props: PageViewProps): JSX.Element {
  const { page } = props;

  const branches: Map<BranchId, Branch> = useMemo(
    () =>
      pipe(
        props.client.branches,
        A.map((branch): [BranchId, Branch] => [branch.branchId, branch]),
        M.fromFoldable(EqBranchId, SG.last<Branch>(), A.Foldable),
      ),
    [props.client.branches],
  );

  const teams: Map<TeamId, Team> = useMemo(
    () =>
      pipe(
        props.client.branches,
        A.chain(({ teams }) => teams),
        A.map((team): [TeamId, Team] => [team.teamId, team]),
        M.fromFoldable(EqTeamId, SG.last<Team>(), A.Foldable),
      ),
    [props.client.branches],
  );

  const users: Map<UserId, User> = useMemo(
    () =>
      pipe(
        props.client.branches,
        A.chain((branch) =>
          pipe(
            branch.teams,
            A.chain(({ users }) => users),
            A.concat(branch.users),
          ),
        ),
        A.concat(props.client.users),
        A.map((user): [UserId, User] => [user.userId, user]),
        M.fromFoldable(EqUserId, SG.last<User>(), A.Foldable),
      ),
    [props.client],
  );

  const branchName = useCallback(
    (branchId: BranchId) =>
      pipe(
        M.lookup(EqBranchId)(branchId, branches),
        O.map((branch) => branch.name),
      ),
    [branches],
  );

  const team = useMemo(
    () => (teamId: TeamId) => pipe(M.lookup(EqTeamId)(teamId, teams)),
    [teams],
  );

  const teamName = useMemo(
    () => (teamId: TeamId) =>
      pipe(
        M.lookup(EqTeamId)(teamId, teams),
        O.map((team) => team.name),
      ),
    [teams],
  );

  const pageContent = pageSections(
    page,
    props,
    branches,
    teams,
    users,
    branchName,
    teamName,
    props.clientListing,
    props.dialogApiResult,
  );

  const usersListing = listUsers(page, props, team);

  const userModals = O.isSome(props.userManagementDialog) ? (
    <UserManagementModals
      dialog={props.userManagementDialog.value}
      branches={branches}
      teams={teams}
      users={users}
      dialogApiResult={props.dialogApiResult}
      dispatch={props.dispatch}
      onCloseModal={flow(DialogClosed, props.dispatch)}
      onSaveUser={(userid: O.Option<UserId>) => (payload: UserPayload) =>
        flow(Started, UserPayloadPrepared(userid, payload), props.dispatch)()
      }
      onDeleteUser={(userId: UserId, reassignment: ClientsReassignment) =>
        flow(
          Started,
          DeleteUserConfirmed(userId, reassignment),
          props.dispatch,
        )()
      }
    />
  ) : (
    <></>
  );

  return (
    <>
      <Col gap="lg">
        {pageContent}
        {usersListing}
      </Col>
      {userModals}
    </>
  );
}

function listUsers(
  page: Page,
  props: PageViewProps,
  team: (teamId: TeamId) => Option<Team>,
) {
  if (page.type == "User") {
    return <></>;
  }
  return pipe(page, (v) => {
    let users: User[] = getClientUsers(props.client);

    switch (v.type) {
      case "Branch":
        users = users.filter((u) =>
          O.getEq(OrdBranchId).equals(u.branchId, O.some(v.model.branchId)),
        );
        break;
      case "Team":
        {
          const _team = team(v.model.teamId);
          users = users.filter((u) =>
            O.getEq(OrdBranchId).equals(
              u.branchId,
              pipe(
                _team,
                O.chain((v) => O.some(v.branchId)),
              ),
            ),
          );
          users = users.filter((u) =>
            O.getEq(OrdTeamId).equals(
              u.teamId,
              pipe(
                _team,
                O.chain((v) => O.some(v.teamId)),
              ),
            ),
          );
        }
        break;
    }
    return (
      <Col>
        <Label className="black text-mbold">Users</Label>
        <hr />
        <UsersListing.View
          users={users}
          onSelectUser={flow(UserSelected, props.dispatch)}
          onDeleteUserInitiated={(v) =>
            flow(Started, DeleteUserInitiated(v), props.dispatch)
          }
          onEditUserInitiated={flow(EditUserInitiated, props.dispatch)}
        />
      </Col>
    );
  });
}

function getPreLoadSkeleton() {
  return <Col padding="sm" gap="sm" grow={1}>
    <Col grow={3} gap="sm" wrap basis="16vh">
      <Skeleton.View></Skeleton.View>
    </Col>
    <Col grow={3} gap="sm" wrap basis="2vh">
      <Skeleton.View></Skeleton.View>
    </Col>
    <Col grow={3} gap="sm" wrap basis="2vh">
      <Skeleton.View></Skeleton.View>
    </Col>
    <Col grow={3} gap="sm" wrap basis="2vh">
      <Skeleton.View></Skeleton.View>
    </Col>
    <Col grow={3} gap="sm" wrap basis="2vh">
      <Skeleton.View></Skeleton.View>
    </Col>
    <Col grow={3} gap="sm" wrap basis="2vh">
      <></>
    </Col>
    <Col grow={3} gap="sm" wrap basis="2vh">
      <Skeleton.View></Skeleton.View>
    </Col>
    <Col grow={3} gap="sm" wrap basis="2vh">
      <Skeleton.View></Skeleton.View>
    </Col>
  </Col>
}
